import React, { Fragment, Component } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Paper, Button, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from '@material-ui/core';
import { EmptyCollection, FilterUIComp, PageLoader, } from '../../../shared_elements';
import FBVHD from './FBVHD'
import AssetFBV from './AssetFBV'
import FBVFleetGraph from './FBVFleetGraph'
import AssetFBVGraph from './AssetFBVGraph'
import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { getPortfolioFBVLogsApi, exportPortfolioReportApi } from '../apiServices';
import { convertFilterObject, removeEmptyKey } from '../../../utils';
import { portfolioFBVFilterOps } from '../';

class PortfolioFBVModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listIndex: 0,
      pageLoader: false,
      modal: false,
      assetFBVModel: false,
      assetFBVIndex: null,
      tabIndex: 0,
      filter: {},
      applyingFilter: {},
      portfolioFBVLogs: { logs: [], total: {} },
    }
    this.getPortfolioFBVLogsApi = getPortfolioFBVLogsApi.bind(this);
    this.exportPortfolioReportApi = exportPortfolioReportApi.bind(this);
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if (rangeKey) {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]: value
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, portfolioFBVLogs } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getPortfolioFBVLogsApi(this.props, { ...query });
    this.setState({ applyingFilter: delete applyingFilter[keyParam] })
  }
  render() {
    const { listIndex, applyingFilter, assetFBVModel, assetFBVIndex, pageLoader, modal, filter, tabIndex, portfolioFBVLogs } = this.state;
    const { portfolioDetail, portfolioAircrafts } = this.props;
    let filterOptions = portfolioFBVFilterOps;
    filterOptions = {
      ...filterOptions,
      aircraft_type: {
        ...filterOptions.aircraft_type,
        options: portfolioAircrafts
      }
    }
    return (
      <Fragment>
        {!modal ?
          <Button onClick={() => this.getPortfolioFBVLogsApi(this.props, {})} variant="contained" color="primary">SHOW</Button> :
          <Dialog
            className="portfolio-fbv-projection"
            open={modal}
            onClose={() => this.setState({ modal: false })}
            aria-labelledby="scroll-dialog-title"
            fullScreen
          >
            <DialogTitle id="scroll-dialog-title">
              Portfolio Future Base Value
              <ul className="list-inline flex-centered portfolio-fbv-cta">
                <li className="list-inline-item">
                  <ListAltIcon onClick={() => this.setState({ listIndex: 0 })} color={listIndex === 0 ? 'primary' : 'default'} />
                </li>

                <li className="list-inline-item">
                  <Button onClick={() => this.exportPortfolioReportApi(this.props)} color="primary" variant="outlined" size="small">Export</Button>
                </li>
              </ul>
            </DialogTitle>
            <DialogContent dividers={true}>
              {listIndex === 0 &&
                <div>
                  <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => this.setState({ tabIndex: newValue })}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="0% Inflation" />
                    <Tab label={`${portfolioDetail.inflation_rate}% Inflation`} />
                  </Tabs>
                  <Paper className="portfolio-fbv-table">
                    <FilterUIComp
                      filter={filter}
                      applyingFilter={applyingFilter}
                      removeFilter={this.removeFilter}
                      removeAllFilter={() => { this.getPortfolioFBVLogsApi(this.props, {}, 'pageLoader'); this.setState({ applyingFilter: {} }) }}
                      submitFilter={() => this.getPortfolioFBVLogsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions) }, 'pageLoader')}
                      applyFilter={this.applyFilter}
                      filterMenu={filterOptions}
                    />
                    {tabIndex === 0 &&

                      <div>
                        <table className="outer-table">
                          { portfolioFBVLogs.logs.length ?
                          <FBVHD
                            years={portfolioFBVLogs.total && portfolioFBVLogs.total.future_value ? Object.keys(portfolioFBVLogs.total.future_value).map(year => year.slice(0, 4)) : []}
                          />
                          :null
                          }
                          <tbody className="outer-body">

                            { portfolioFBVLogs.logs.map((item, index) =>
                              <AssetFBV
                                item={item}
                                projectionKey='future_value'
                                openAssetFBVGraph={() => this.setState({ assetFBVIndex: index, assetFBVModel: true })}
                              />
                            )}
                          </tbody>
                        </table>
                        { !portfolioFBVLogs.logs.length ?
                         <EmptyCollection
                              title="No records found"
                            />
                            :null
                            }
                      </div>
                    }
                    {tabIndex === 1 &&
                      <div>
                        <table className="outer-table">
                          <FBVHD
                            years={portfolioFBVLogs.total && portfolioFBVLogs.total.future_value ? Object.keys(portfolioFBVLogs.total.future_value).map(year => year.slice(0, 4)) : []}
                          />
                          <tbody className="outer-body">
                            {portfolioFBVLogs.logs.map((item, index) =>
                              <AssetFBV
                                item={item}
                                projectionKey='inflated_future_value'
                                openAssetFBVGraph={() => this.setState({ assetFBVIndex: index, assetFBVModel: true })}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    }
                  </Paper>
                </div>
              }
              {listIndex === 1 &&
                <FBVFleetGraph
                  portfolioFBVLogs={portfolioFBVLogs}
                  portfolioDetail={portfolioDetail}
                />
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ modal: false })} color="primary">Close</Button>
            </DialogActions>
          </Dialog>
        }
        {assetFBVModel && <AssetFBVGraph portfolioDetail={portfolioDetail} fbvLog={portfolioFBVLogs.logs[assetFBVIndex]} toggleModalFn={() => this.setState({ assetFBVModel: false, assetFBVIndex: null })} assetFBVModel={assetFBVModel} />}
        {pageLoader ? <PageLoader /> : null}
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(PortfolioFBVModal));
