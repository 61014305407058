import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Paper, Grid, Breadcrumbs, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants'; // format functions
import moment from 'moment';
import { PageLoader } from '../../../shared_elements';
import { getEngineTypesAc, getAircraftTypesAc, getLesseeListAc, getAPUTypesAc } from '../../../shared_elements/actionCreators'; // shared apis
import { getAircraftModsApi, getMntEventsApi, generateHlaLogApi, getSeatingConfigApi, getDefaultValueApi } from '../apiServices'; // apis
import { airframeHlaGenInfo, engineGenInfo, apuGenInfo, lgGenInfo, genericHlaInfo, propellerGenInfo } from '../'; // variables
import { AirframeInitiate, EngineHlaInitiate, ApuHlaInitiate, LgHlaInitiate, PropellerInitiate } from '../components'; // components
import { trackActivity } from '../../../utils/mixpanel';
import { getLocalStorageInfo, permissionCheckFn } from '../../../utils';
class InitiateHlam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hlaCrud: {
        airframe: airframeHlaGenInfo,
        apu: apuGenInfo,
        generic: genericHlaInfo,
        engine: engineGenInfo,
        landing_gear: lgGenInfo,
        propeller: propellerGenInfo
      },
      error: {},
      mods: [],
      mntGroups: [],
      pageLoader: false,
      seatingConfigList: [],
      engineInfoNo: 1,
      utilModal: false,
      lgUtilization: true,
      mlgUtilization: false,
      apuOptional: true,
      propellerUtilFlag: false,
      futureValueFlag: false
    }
    this.getAircraftModsApi = getAircraftModsApi.bind(this)
    this.getMntEventsApi = getMntEventsApi.bind(this)
    this.generateHlaLogApi = generateHlaLogApi.bind(this)
    this.getSeatingConfigApi = getSeatingConfigApi.bind(this)
    this.getDefaultValueApi = getDefaultValueApi.bind(this)
  }
  componentDidMount() {
    const { hlaCrud } = this.state
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.props.getLesseeList()
    this.getSeatingConfigApi()
    this.updateEngineGenInfo(1)
    if (hlaCrud.engine.identical_data) {
      this.setState({
        engineInfoNo: 1
      })
    }
    trackActivity('Page Visited', {
      page_title: 'Initiate Half Life Adjustment',
    })
  }
  onFieldChange = (event, keyParam, value, tableType) => {
    const { hlaCrud } = this.state
    this.updateFormFields(keyParam, value, tableType)
    if (keyParam == 'aircraftType') {
      this.updateFormFields('mods', [], 'generic');
      this.updateFormFields('engineType', null, 'engine');
      this.updateFormFields('no_of_engines', 1, 'engine');
      this.updateFormFields('identical_data', true, 'engine')
      this.updateFormFields('utilization_type', 1, 'airframe')
      this.updateFormFields('planned_fc', 0, 'airframe')
      this.updateFormFields('planned_fh', 0, 'airframe')
      this.updateFormFields('maintenance_program', null, 'airframe')
      this.setState({ apuOptional: true })
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id });
        this.getMntEventsApi(this.props, value.id)
        this.props.getAPUTypes({ 'aircraft-type': value.id });
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': value.id });
        this.propellerDecisionFn(value.oem)
      }
    }
    if (keyParam == 'engineType') {
      this.updateEngineGenInfo(1)
    }
    if (keyParam == "utilization_type" && value == 2 && hlaCrud.airframe.aircraftType) {
      this.getDefaultValueApi(hlaCrud)
    }
    if (keyParam == 'utilization_as_of_date' && tableType == "airframe") {
      this.updateFormFields('utilization_as_of_date', value, tableType)
    }
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(2), 'generic');
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(2), 'generic');
      }
    }
    if (keyParam == "no_of_engines") {
      if (value == 1) {
        this.updateEngineInfoField(event, 'tsn', '', 1)
        this.updateEngineInfoField(event, 'csn', '', 1)
        this.updateEngineInfoField(event, 'utilization_as_of_date', null, 1)
        this.updatePropellerData(1)
      }
      if (value == 2) {
        if (['ATR', 'Bombardier Aerospace'].includes(hlaCrud.airframe.aircraftType.oem)) {
          this.updateFormFields('identical_data', true, 'propeller')
        }

      }

    }
  }
  updateFormFields = (keyParam, value, tableType) => {
    this.setState(prevState => ({
      ...prevState,
      hlaCrud: {
        ...prevState.hlaCrud,
        [tableType]: {
          ...prevState.hlaCrud[tableType],
          [keyParam]: value
        }
      }
    }))
  }
  updateErrorField = (keyParam, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: message
      }
    }))
  }
  updateEngineInfoError = (keyParam, message, engineinfoIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [`engine${engineinfoIndex}`]: {
          ...prevState.error[`engine${engineinfoIndex}`],
          [keyParam]: message
        }
      }
    }))
  }
  updatePropellerInfoError = (keyParam, message, propellerIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [`propeller${propellerIndex}`]: {
          ...prevState.error[`propeller${propellerIndex}`],
          [keyParam]: message
        }
      }
    }))
  }
  updateLgInfoError = (keyParam, message, lgInfoIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [lgInfoIndex]: {
          ...prevState.error[lgInfoIndex],
          [keyParam]: message
        }
      }
    }))
  }
  updateEngineGenInfo = (value, engine) => {
    this.setState(prevState => ({
      ...prevState,
      engineInfoNo: value,
    }))
    if (engine) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          engine: {
            ...prevState.hlaCrud.engine,
            no_of_engines: engine
          }
        }
      }))
    }
  }
  updateEngineInfoField = (event, keyParam, value, index) => {
    this.setState(prevState => ({
      ...prevState,
      hlaCrud: {
        ...prevState.hlaCrud,
        engine: {
          ...prevState.hlaCrud.engine,
          engineInfo: prevState.hlaCrud.engine.engineInfo.map((item, i) => i === index ?
            { ...item, [keyParam]: value }
            : prevState.hlaCrud.engine.engineInfo[i])
        }
      }
    }))
  }
  updatePropellerInfoField = (event, keyParam, value, index) => {
    this.setState(prevState => ({
      ...prevState,
      hlaCrud: {
        ...prevState.hlaCrud,
        propeller: {
          ...prevState.hlaCrud.propeller,
          propellerInfo: prevState.hlaCrud.propeller.propellerInfo.map((item, i) => i === index ?
            { ...item, [keyParam]: value }
            : prevState.hlaCrud.propeller.propellerInfo[i])
        }
      }
    }))
  }
  autoPopulateAirframeDate = (value) => {
    const { hlaCrud } = this.state
    this.setState(prevState => ({
      ...prevState,
      hlaCrud: {
        ...prevState.hlaCrud,
        engine: {
          ...prevState.hlaCrud.engine,
          engineInfo: prevState.hlaCrud.engine.engineInfo.map(item => ({ ...item, utilization_as_of_date: value }))
        },
        landing_gear: {
          ...prevState.hlaCrud.landing_gear,
          lgInfo: prevState.hlaCrud.landing_gear.lgInfo.map(item => ({ ...item, utilization_as_of_date: value }))
        },
      }
    }))
    if (['ATR'].includes(hlaCrud.airframe.aircraftType.oem)) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          propeller: {
            ...prevState.hlaCrud.propeller,
            propellerInfo: prevState.hlaCrud.propeller.propellerInfo.map(item => ({ ...item, utilization_as_of_date: value }))
          },
          apu: {}
        }
      }))
    }
    if (['Bombardier Aerospace'].includes(hlaCrud.airframe.aircraftType.oem)) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          propeller: {
            ...prevState.hlaCrud.propeller,
            propellerInfo: prevState.hlaCrud.propeller.propellerInfo.map(item => ({ ...item, utilization_as_of_date: value }))
          }
        }
      }))
    }
  }
  updateCheckBox = (flag, tableType) => {
    const { hlaCrud } = this.state
    this.setState(prevState => ({
      ...prevState,
      hlaCrud: {
        ...prevState.hlaCrud,
        [tableType]: {
          ...prevState.hlaCrud[tableType],
          identical_data: flag
        }
      },
    }), () => this.updateEngineGenInfo(flag ? 1 : hlaCrud.engine.no_of_engines, hlaCrud.engine.no_of_engines))
    if (tableType == 'propeller') {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          [tableType]: {
            ...prevState.hlaCrud[tableType],
            identical_data: flag,
            propellerInfo: flag ? this.updatePropellerData(1) : this.updatePropellerData(2)
          }
        }
      }))
    }
  }
  populateUtilDate = () => {
    const { hlaCrud, apuOptional } = this.state
    if (apuOptional) {
      this.updateFormFields('utilization_as_of_date', hlaCrud.airframe.utilization_as_of_date, 'apu')
    }
    this.updateFormFields('utilization_as_of_date', hlaCrud.airframe.utilization_as_of_date, 'landing_gear')
    this.autoPopulateAirframeDate(hlaCrud.airframe.utilization_as_of_date)
    this.setState({ propellerUtilFlag: true })
    this.setState({ utilModal: false })
  }
  lgInfoUpdate = (e, keyParam, value, keyIndex) => {
    this.setState(prevState => ({
      ...prevState,
      hlaCrud: {
        ...prevState.hlaCrud,
        landing_gear: {
          ...prevState.hlaCrud.landing_gear,
          lgInfo: prevState.hlaCrud.landing_gear.lgInfo.map((item, index) => index === keyIndex ? { ...item, [keyParam]: value } :
            item
          )
        }
      }
    }))
  }
  updatePropellerData = (value) => {
    const { hlaCrud, propellerUtilFlag } = this.state
    if (value == 1) {
      if (['Bombardier Aerospace', 'ATR'].includes(hlaCrud.airframe.aircraftType.oem)) {
        this.setState(prevState => ({
          ...prevState,
          hlaCrud: {
            ...prevState.hlaCrud,
            propeller: {
              identical_data: true,
              propellerInfo: hlaCrud.propeller.propellerInfo.length > 1 ? hlaCrud.propeller.propellerInfo.splice(-1) : hlaCrud.propeller.propellerInfo
            }
          }
        }))
      }
    }
    if (value == 2) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          propeller: {
            ...prevState.hlaCrud.propeller,
            identical_data: false,
            propellerInfo: [...hlaCrud.propeller.propellerInfo, { id: 2, tsn: '', csn: '', utilization_as_of_date: propellerUtilFlag ? hlaCrud.airframe.utilization_as_of_date : moment().format(backendDateFormat), position: 2 }]
          }
        }
      }))
    }
  }
  updateApuOptional = () => {
    this.setState(prevState => ({
      ...prevState,
      apuOptional: !prevState.apuOptional,
      hlaCrud: {
        ...prevState.hlaCrud,
        apu: prevState.apuOptional == true ? {} : { apuType: null, apu_hours: '', utilization_as_of_date: moment().format(backendDateFormat) }
      }

    }))
  }
  propellerDecisionFn = (value) => {
    if (['ATR'].includes(value)) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          apu: {},
          propeller: propellerGenInfo
        }
      }))
    }
    if (['Bombardier Aerospace'].includes(value)) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          propeller: propellerGenInfo,
          apu: apuGenInfo
        }
      }))
    }
    if (!['ATR', 'Bombardier Aerospace'].includes(value)) {
      this.setState(prevState => ({
        ...prevState,
        hlaCrud: {
          ...prevState.hlaCrud,
          apu: apuGenInfo,
          propeller: {}
        }
      }))
    }
  }
  handleForecastChange = () => {
    this.setState(prevState =>
    (
      {
        ...prevState,
        futureValueFlag: !prevState.futureValueFlag,
        hlaCrud: {
          ...prevState.hlaCrud,
          generic: {
            ...prevState.hlaCrud.generic,
            forecast_date: null
          }
        }
      }
    )
    )
    this.updateErrorField('forecast_date', '')
  }
  render() {
    const { engineTypes, aircraftTypes, lessee, apuTypes } = this.props;
    const { hlaCrud, error, skeletonLoader, mods, pageLoader, mntGroups, seatingConfigList, engineInfoNo, utilModal, mlgUtilization, apuOptional } = this.state;
    let engineInfoArr = []
    if (hlaCrud.engine.identical_data) {
      engineInfoArr = hlaCrud.engine.engineInfo.filter(item => item.id < 2)
    } else {
      engineInfoArr = hlaCrud.engine.engineInfo.filter(item => item.id <= engineInfoNo)
    }
    return (
      <div className="valuation-sections">
        <div className="create-valuation-logs create-valuation-logs-mobile">
          <form>
            <Grid container >
              <Grid xs={12} md={6} >
                <Breadcrumbs aria-label="breadcrumb" >
                  <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                    <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                      Logs
                    </Typography>
                  </Link>
                  {permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'C' }) ||
                    permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'C' }) ||
                    permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'C' }) ||
                    permissionCheckFn({ secondaryKey: 'lev', keyIndex: 'C' }) ?
                    <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                      <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                        Asset Value Options
                      </Typography>
                    </Link> : null
                  }
                  <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                    Maintenance Adjusted Base Value
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid xs={12} md={6}>
                <Button className="flex-centered" style={{ float: 'right' }} onClick={() => this.generateHlaLogApi(this.props, hlaCrud, engineInfoNo)} variant="contained" size="large" color="primary">Proceed</Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="report_date"
                    label="Report Date"
                    placeholder="DD-MM-YYYY"
                    format={fieldDateFormat}
                    fullWidth
                    minDate={moment().subtract(1, 'years')}
                    InputLabelProps={{ shrink: true }}
                    maxDate={moment().add(1, 'years')}
                    onChange={(data, value) => this.onFieldChange(value, 'report_date', data ? moment(data).format(backendDateFormat) : data, 'generic')}
                    value={hlaCrud.generic.report_date}
                    error={error.report_date ? true : false}
                    helperText={error.report_date ? error.report_date : ''}
                    onFocus={() => this.updateErrorField('report_date', '')}
                    onOpen={() => this.updateErrorField('report_date', '')}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Paper elevation={0} style={{ padding: '10px' }}>
              <AirframeInitiate
                id={this.props.match.params.id}
                airframeHlaGenInfo={hlaCrud.airframe}
                genericHlaInfo={hlaCrud.generic}
                hlaCrud={hlaCrud}
                error={error}
                mods={mods}
                skeletonLoader={skeletonLoader}
                aircraftTypes={aircraftTypes}
                lessee={lessee}
                onFieldChange={this.onFieldChange}
                updateErrorField={this.updateErrorField}
                mntGroups={mntGroups}
                seatingConfigList={seatingConfigList}
                openUtilModal={() => moment(hlaCrud.airframe.utilization_as_of_date).isValid() ? this.setState({ utilModal: true }) : null}
                openUtilModalDatePicker={() => this.setState({ utilModal: true })}
                closeUtilModal={() => this.setState({ utilModal: false })}
                utilModal={utilModal}
                populateUtilDate={this.populateUtilDate}
              />
              <div style={{ marginTop: '20px' }}>
                <EngineHlaInitiate
                  engineGenInfo={hlaCrud.engine}
                  genericHlaInfo={hlaCrud.generic}
                  airframeHlaGenInfo={hlaCrud.airframe}
                  onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'engine')}
                  engineTypes={engineTypes}
                  error={error}
                  updateErrorField={this.updateErrorField}
                  updateEngineGenInfo={this.updateEngineGenInfo}
                  updateEngineInfoField={this.updateEngineInfoField}
                  updateCheckBox={this.updateCheckBox}
                  engineInfoArr={engineInfoArr}
                  updateEngineInfoError={this.updateEngineInfoError}
                />
                {
                  hlaCrud.airframe && hlaCrud.airframe.aircraftType && (hlaCrud.airframe.aircraftType && hlaCrud.airframe.aircraftType.has_propeller == true) ?
                    <Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={12}>
                          <h4 className="hla-initiate-card-title"><strong>Propeller</strong></h4>
                        </Grid>
                        {
                          hlaCrud && hlaCrud.engine && hlaCrud.engine.no_of_engines > 1 ?
                            <Grid xs={12} md={6} >
                              <ul className="list-inline lg-head-mobile">
                                <li className="list-inline-item" style={{ marginTop: '13px', marginLeft: '3px' }}>
                                  <FormLabel component="legend">Do all Propeller have same utilization ? </FormLabel>
                                </li>
                                <li className="list-inline-item" style={{ marginLeft: '10px' }}>
                                  <FormControl component="fieldset" >
                                    <RadioGroup aria-label="engine" name="lg" value="lg" row>
                                      <FormControlLabel value={'yes'} control={<Radio checked={hlaCrud.propeller.identical_data} color="primary" />} onChange={(e) => this.updateCheckBox(true, 'propeller')} color="primary" label="Yes" />
                                      <FormControlLabel value={'no'} control={<Radio checked={!hlaCrud.propeller.identical_data} color="primary" />} onChange={(e) => this.updateCheckBox(false, 'propeller')} color="primary" label="No" />
                                    </RadioGroup>
                                  </FormControl>
                                </li>
                              </ul>
                            </Grid> : null
                        }
                      </Grid>
                      <PropellerInitiate
                        propellerGenInfo={hlaCrud.propeller}
                        engineGenInfo={hlaCrud.engine}
                        onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'propeller')}
                        error={error}
                        updatePropellerInfoError={this.updatePropellerInfoError}
                        airframeHlaGenInfo={hlaCrud.airframe}
                        updatePropellerInfoField={this.updatePropellerInfoField}
                        genericHlaInfo={hlaCrud.generic}
                        updateCheckBox={this.updateCheckBox}
                      />
                    </Fragment>
                    : null
                }
                {
                  Object.keys(hlaCrud.apu).length || !apuOptional ?
                    <ApuHlaInitiate
                      apuGenInfo={hlaCrud.apu}
                      airframeHlaGenInfo={hlaCrud.airframe}
                      genericHlaInfo={hlaCrud.generic}
                      apuTypes={apuTypes}
                      error={error}
                      onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'apu')}
                      updateErrorField={this.updateErrorField}
                      apuOptional={apuOptional}
                      updateApuOptional={() => this.updateApuOptional()}
                    /> : null
                }

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={12}>
                    <h4 className="hla-initiate-card-title"><strong>Landing Gear (NLG, LH MLG and RH MLG)</strong></h4>
                  </Grid>
                  <Grid xs={12} md={6} >
                    <ul className="list-inline lg-head-mobile">
                      <li className="list-inline-item" style={{ marginTop: '13px', marginLeft: '3px' }}>
                        <FormLabel component="legend">Do all Landing Gear have same utilization ? </FormLabel>
                      </li>
                      <li className="list-inline-item" style={{ marginLeft: '10px' }}>
                        <FormControl component="fieldset" >
                          <RadioGroup aria-label="engine" name="lg" value="lg" row>
                            <FormControlLabel value={hlaCrud.landing_gear.lg_utilization_flag} control={<Radio checked={hlaCrud.landing_gear.lg_utilization_flag} color="primary" />} onChange={(e) => this.onFieldChange(e, 'lg_utilization_flag', true, 'landing_gear')} color="primary" label="Yes" />
                            <FormControlLabel value={!hlaCrud.landing_gear.lg_utilization_flag} control={<Radio checked={!hlaCrud.landing_gear.lg_utilization_flag} color="primary" />} onChange={(e) => this.onFieldChange(e, 'lg_utilization_flag', false, 'landing_gear')} color="primary" label="No" />
                          </RadioGroup>
                        </FormControl>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
                {
                  hlaCrud.landing_gear.lg_utilization_flag ?
                    <LgHlaInitiate
                      lgGenInfo={hlaCrud.landing_gear}
                      genericHlaInfo={hlaCrud.generic}
                      error={error}
                      onFieldChange={(e, keyParam, value) => this.lgInfoUpdate(e, keyParam, value, 0)}
                      updateLgInfoError={this.updateLgInfoError}
                      title=""
                      keyIndex={0}
                    />
                    :
                    <Fragment>
                      <LgHlaInitiate
                        lgGenInfo={hlaCrud.landing_gear}
                        genericHlaInfo={hlaCrud.generic}
                        error={error}
                        onFieldChange={(e, keyParam, value) => this.lgInfoUpdate(e, keyParam, value, 0)}
                        updateLgInfoError={this.updateLgInfoError}
                        title="NLG"
                        keyIndex={0}
                      />
                      {
                        hlaCrud.landing_gear.mlg_utilization_flag ?
                          <LgHlaInitiate
                            lgGenInfo={hlaCrud.landing_gear}
                            genericHlaInfo={hlaCrud.generic}
                            error={error}
                            onFieldChange={(e, keyParam, value) => this.lgInfoUpdate(e, keyParam, value, 1)}
                            updateLgInfoError={this.updateLgInfoError}
                            changeUtilizationFlag={this.onFieldChange}
                            mlgUtilization={mlgUtilization}
                            title="LH MLG and RH MLG"
                            keyIndex={1}
                          />
                          :
                          <Fragment>
                            <LgHlaInitiate
                              lgGenInfo={hlaCrud.landing_gear}
                              genericHlaInfo={hlaCrud.generic}
                              error={error}
                              onFieldChange={(e, keyParam, value) => this.lgInfoUpdate(e, keyParam, value, 1)}
                              updateLgInfoError={this.updateLgInfoError}
                              changeUtilizationFlag={this.onFieldChange}
                              title="LH MLG"
                              keyIndex={1}
                            />
                            <LgHlaInitiate
                              lgGenInfo={hlaCrud.landing_gear}
                              genericHlaInfo={hlaCrud.generic}
                              error={error}
                              onFieldChange={(e, keyParam, value) => this.lgInfoUpdate(e, keyParam, value, 2)}
                              updateLgInfoError={this.updateLgInfoError}
                              title="RH MLG"
                              keyIndex={2}
                            />
                          </Fragment>
                      }
                    </Fragment>
                }
              </div>
            </Paper>
            <Grid container>
              <Grid xs={12}>
                <Button className="flex-centered" style={{ marginTop: '20px' }} onClick={() => this.generateHlaLogApi(this.props, hlaCrud, engineInfoNo)} variant="contained" fullWidth={true} color="primary">Proceed</Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {pageLoader ? <PageLoader /> : null}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
  apuTypes: state.shareReducer.apuTypes
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAircraftTypes: (query) => dispatch(getAircraftTypesAc(ownProps, query)),
    getLesseeList: (query) => dispatch(getLesseeListAc(query)),
    getAPUTypes: (query) => dispatch(getAPUTypesAc(query))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(InitiateHlam))
