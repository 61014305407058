import React from 'react';
import { Paper } from '@material-ui/core';
import placeholderImg from '../../../assets/images/valuation_output_plh.png'
const ValuationOutPlaceHolder = ({}) => {
  return(
    <Paper>
      <img style={{width:'100%'}} src={placeholderImg} alt="" />
    </Paper>
  )
}
export default ValuationOutPlaceHolder;
