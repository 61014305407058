import { globalGetService, globalPostService, globalPutService, globalDeleteService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import moment from 'moment';
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';

export function getPortfolioAircraftsApi(props, query) {
  globalGetService(`console/aircraft-types/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        if (response.data.data.aircraftTypes) {
          this.setState({ portfolioAircrafts: response.data.data.aircraftTypes })
        }
      }
    })
}
export function getPortfolioEngineTypesApi(props, query) {
  globalGetService(`console/engine-types/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        if (response.data.data.engineTypes) {
          this.setState({ portfolioEngineTypes: response.data.data.engineTypes })
        }
      }
    })
}
export function getPortfoliosApi(props = {}, query = {}, loaderType) {
  this.setState({ [loaderType]: true });
  let filterQuery = Object.assign({}, query);
  delete filterQuery.report_date
  if (query.report_date && query.report_date.date_from) {
    filterQuery = {
      ...filterQuery,
      date_from: query.report_date.date_from
    }
  }
  if (query.report_date && query.report_date.date_to) {
    filterQuery = {
      ...filterQuery,
      date_to: query.report_date.date_to
    }
  }
  globalGetService(`valuation/portfolio-valuation/`, filterQuery)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        delete query.per_page;
        delete query.sort;
        delete query.sort_by;
        delete query.page;
        this.setState({
          portfolios: response.data.data,
          filter: query
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function lockUnlockPortfolioApi(props, data, pageType) {
  this.setState({ pageLoader: true })
  globalPostService(`valuation/portfolio-valuation/${data.slug}/lockPortfolio/`, { is_locked: data.is_locked ? 0 : 1 })
    .then(response => {
      this.setState({ pageLoader: false })
      trackActivity('Portfolio', {
        event_type: 'Lock & Unlock',
        page_title: pageType === 'detail' ? 'Portfolio Detail' : 'Portfolio Listing',
        lock_status: data.is_locked,
        log_id: data.id ? data.id : '',
        log_name: data.name ? data.name : '',
        slug: data.slug ? data.slug : '',
        response_msg: response.data.data.message
      })
      if (checkApiStatus(response)) {
        if (pageType === 'detail') {
          this.getPortfolioApi(this.props, 'portfolioLoader');
        } else {
          this.setState(prevState => ({
            ...prevState,
            portfolios: {
              ...prevState.portfolios,
              list: prevState.portfolios && prevState.portfolios.list && prevState.portfolios.list.map((portfolio) => portfolio.id === data.id ? { ...portfolio, is_locked: !data.is_locked } : portfolio)
            },
            deleteModal: false,
            deleteIds: null,
            lockModal: false,
            lockIds: null
          }));
        }
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function exportPortfoliosApi(props, query = {}) {
  let filterQuery = Object.assign({}, query);
  delete filterQuery.report_date
  if (query.report_date && query.report_date.date_from) {
    filterQuery = {
      ...filterQuery,
      date_from: query.report_date.date_from
    }
  }
  if (query.report_date && query.report_date.date_to) {
    filterQuery = {
      ...filterQuery,
      date_to: query.report_date.date_to
    }
  }
  this.setState({ pageLoader: true });
  globalExportService(`valuation/portfolio-valuation/`, { 'download': '1', ...filterQuery })
    .then(response => {
      this.setState({ pageLoader: false })
      this.props.enqueueSnackbar('Portfolio file will be sent to your registered email', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      trackActivity('Portfolio Exported', {
        type: 'Portfolio',
        extension: query.extension
      })
    })
}

export function getPortfolioApi(props = {}, loaderType = 'pageLoader') {
  this.setState({ pageLoader: true });
  globalGetService(`valuation/portfolio-valuation/${props.match.params.slug}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState({ portfolioDetail: response.data.data })
        this.getPortfolioAircraftsApi(props, { valuation: 'valuation', portfolio_id: response.data.data.id })
        this.getPortfolioEngineTypesApi(props, { valuation: 'valuation', portfolio_id: response.data.data.id })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        props.history.push('/portfolios')
      }
    })
}

export function addEditPortfolioApi(props, data, id) {
  return new Promise(function (resolve, reject) {
    if (id) {
      globalPutService(`valuation/portfolio-valuation/${props.match.params.slug}/`, data)
        .then(response => {
          trackActivity(' Portfolio ', {
            event_type: 'Modify',
            page_title: 'Portfolio View',
            id: id ? id : '',
          })
          resolve(response);
        })
    } else {
      globalPostService(`valuation/portfolio-valuation/`, data)
        .then(response => {
          trackActivity(' Portfolio', {
            event_type: 'Add',
            page_title: 'Portfolio View',
          })
          resolve(response);
        })
    }
  })
}

export function getPortfolioBVLogsApi(props, query, loaderType = 'pageLoader') {
  this.setState({ [loaderType]: true });
  globalGetService(`valuation/portfolio-valuation/${props.match.params.slug}/logs/`, query)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        delete query.per_page;
        delete query.sort;
        delete query.sort_by;
        delete query.page;
        this.setState({
          portfolioBV: response.data.data,
          filter: query
        });
      }
    })
}
export function getPortfolioFBVLogsApi(props, query = {}) {
  this.setState({ pageLoader: true });
  globalGetService(`valuation/portfolio-valuation/${props.match.params.slug}/future-values/`, query)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        trackActivity('Portfolio', {
          event_type: 'Page Visited',
          page_title: 'Portfolio FBV'
        })
        this.setState({
          portfolioFBVLogs: response.data.data,
          filter: query,
          modal: true
        });
      }
    })
}

export function deletePortfolioLogApi(props, id) {
  this.setState({ pageLoader: true });
  globalDeleteService(`valuation/portfolio-valuation/${props.match.params.slug}/logs/${id}/`)
    .then(response => {
      trackActivity('Portfolio Delete', {
        event_type: 'Delete',
        page_title: 'Portfolio View',
        Sub_id: id ? id : '',
        Parent_id: props.match.params.slug ? props.match.params.slug : '',
        response_msg: response.data.message,
        utility_type: response.data.data.utility_type ? response.data.data.utility_type : '',
        name: response.data.data.name ? response.data.data.name : '',
      })
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ deleteModal: false, deleteIds: '' });
        this.getPortfolioApi(props, 'pageLoader');
        this.getPortfolioBVLogsApi(props, {}, 'pageLoader');
        this.getPortfolioAnalyticApi(props, {}, 'pageLoader');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}

export function exportPortfolioReportApi(props, query = {}) {
  this.setState({ pageLoader: true });
  globalGetService(`valuation/portfolio-valuation/${props.match.params.slug}/future-values/`, { 'download': '1', ...query })
    .then(response => {
      trackActivity('Portfolio Exported', {
        event_type: 'Report Export',
        page_title: 'Portfolio View',
        id: props.match.params.slug,
        response_msg: response.data.data.message
      })
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function exportPortfolioSampleApi(props) {
  globalExportService(`valuation/sample-portfolio-download/`)
    .then(response => {
      downloadFileType(response.data, 'Sample Portfolio Sheet', 'xls');
    })
}

export function getPortfolioAnalyticApi(props) {
  globalGetService(`valuation/portfolio-valuation/${props.match.params.slug}/graph/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          portfolioAnalytic: response.data.data
        })
      }
    })
}
