export const addAssetObj = {
    serial_number:'',
    aircraft_type:null,
    engine_type:null,
    date_of_manufacture:null,
    mtow:''
}
export const fleetFilters = {
    'serial_number':{
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'aircraft_type':{
        'inputType': 'dropdown',
        'title': 'Aircraft Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id'        
    },
    'engine_type':{
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id'        
    }
}