import React from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import BVCMVPieChart from './BVCMVPieChart';
import AssetCountBarChart from './AssetCountBarChart';
import AircraftAgesBarChart from './AircraftAgesBarChart';
const PortfolioAnalytic = ({portfolioAnalytic}) => {
  return(
    <div className="profolio-analytic">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <div className="graph-desc">
              <h4>BV & CMV Value based on Aircraft Type</h4>
              <p>
                The below chart provides the BV & CMV allocation per Aircraft Type in this portfolio
              </p>
            </div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <BVCMVPieChart
                  data={portfolioAnalytic.aircraft_types_total_bv}
                  legend="Base Value"
                  title="Base Value"
                />
              </Grid>
              <Grid item xs={6}>
                <BVCMVPieChart
                  data={portfolioAnalytic.aircraft_types_total_cmv}
                  legend="Current Market Value"
                  title="Current Market Value"

                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <div className="graph-desc">
              <h4>BV & CMV Value based on Aircraft Age</h4>
              <p>
                The below chart represents the BV & CMV based on the age grouping in this portfolio
              </p>
            </div>
            <AircraftAgesBarChart
              data={portfolioAnalytic.agewise_bv_cmv_values}
              categories={portfolioAnalytic.agewise_categories}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <div className="graph-desc">
              <h4>Asset Count based on Aircraft Type</h4>
              <p>
                The below chart provides the asset count per Aircraft Type in this portfolio
              </p>
            </div>
            <AssetCountBarChart
              data={portfolioAnalytic.aircraft_types_total_msn.y_axis}
              categories={portfolioAnalytic.aircraft_types_total_msn.x_axis.categories}
              legend="Asset Count"
            />
          </Paper>
        </Grid>

        <Grid item xs={6}>
        </Grid>
      </Grid>
    </div>
  )
}
export default PortfolioAnalytic;
