import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Paper, Tooltip, Grid, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { RevisionHistory } from '../../../shared_elements';
import { permissionCheckFn } from '../../../utils';
import AddEditPortfolio from './AddEditPortfolio';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
const PortfolioHeader = ({ match, portfolioDetail, pageLoader, exportPortfolioReport, getResponseBack, changeLockStatus,skeletonLoader }) => {
  return (
    <div className="portfolio-breadcrumb breadcrumbs-section flex-centered">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/portfolios" style={{ cursor: 'pointer' }} >
          <Typography className="flex-centered" component="h1">
            Portfolio
          </Typography>
        </Link>
        <Typography className="flex-centered">
          {portfolioDetail.name}
        </Typography>
      </Breadcrumbs>
      { !skeletonLoader ?
        <ul className="list-inline flex-centered portfolio-report-cta">
            {permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'LOCK_UNLOCK' }) ?
              <li className="list-inline-item" onClick={(e) => { e.stopPropagation(); e.preventDefault(); changeLockStatus(portfolioDetail) }}>
                <Tooltip title={'Lock/Unlock'}>
                  {portfolioDetail.is_locked ?
                    <LockIcon color="primary" fontSize="small" /> :
                    <LockOpenIcon color="primary" fontSize="small" />
                  }
                </Tooltip>
              </li> : null
            }
            <li className="list-inline-item">
              <RevisionHistory
                url="audit/valuation/vtportfolio/"
                queryParams={{ action: 1, object_id: portfolioDetail.id }}
                buttonType={true}
                component={false}
                exportRevisionHistory={true}
                exportQueryparam={{ action: 1, object_id: portfolioDetail.id, download: 'xls' }}
                moduleType={"Portfolio Valuation"}
                exportPermission={true}
              />
            </li>
            {portfolioDetail && !portfolioDetail.is_locked && !pageLoader && permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'U' }) &&
              <li className="list-inline-item">
                <AddEditPortfolio getResponseBack={getResponseBack} id={match.params.slug} m={'E'} portfolioDetail={portfolioDetail} />
              </li>
            }
            {permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'EXP_XLS' }) ?
              <li className="list-inline-item">
                <Button onClick={() => exportPortfolioReport()} color="primary" size="small" variant="outlined"><SaveAltIcon size="small"/> Export</Button>
              </li> : null
            }
          </ul>:null
      }

    </div>
  )
}
export default withRouter(PortfolioHeader);
