import React, { Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import EngineShopVisit from './EngineShopVisit';
import { getLocalStorageInfo } from '../../../utils';

const MaintenaceCondition = ({ mode, error, onFieldChange, updateErrorField, onChangeEvents, mntGroups, maintenaceObj }) => {
  let mntConditionFlag = maintenaceObj.aircraft_type && maintenaceObj.aircraft_type.id && maintenaceObj.engine_type && maintenaceObj.engine_type.id;
  return (
    <Fragment>
      { maintenaceObj.asset_type &&  maintenaceObj.asset_type === 2 ?
        <Grid item xs={12} md={12} style={{marginTop:'8px',marginBottom:'8px'}}>
          <FormControl component="fieldset" >
            <FormLabel component="legend">Maintenance Condition*</FormLabel>
            <RadioGroup row aria-label="position" name="is_full_life_maintenance_condition">
              <FormControlLabel disabled={mode === 'view' ? true : false} value="is_full_life_maintenance_condition" control={<Radio size="small" color="primary" checked={maintenaceObj.is_full_life_maintenance_condition === 0} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', 0)} />} label="Half Life" />
              <FormControlLabel disabled={mode === 'view' ? true : false} value="is_full_life_maintenance_condition" control={<Radio size="small" color="primary" checked={maintenaceObj.is_full_life_maintenance_condition === 1} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', 1)} />} label="Full Life" />
              <FormControlLabel disabled={mode === 'view' ? true : false} value="is_full_life_maintenance_condition" control={<Radio size="small" color="primary" checked={maintenaceObj.is_full_life_maintenance_condition === 2} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', 2)} />} label="Both" />
            </RadioGroup>
          </FormControl>
        </Grid>:
        <Grid item xs={12} md={12} style={{marginTop:'8px',marginBottom:'8px'}}>
          <FormControl component="fieldset" >
            <FormLabel component="legend">Maintenance Condition*</FormLabel>
            <RadioGroup row aria-label="position" name="is_full_life_maintenance_condition">
              <FormControlLabel disabled={mode === 'view' ? true : false} value="is_full_life_maintenance_condition" control={<Radio size="small" color="primary" checked={maintenaceObj.is_full_life_maintenance_condition === 0} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', 0)} />} label="Half Life" />
              <FormControlLabel disabled={mode === 'view' || !mntConditionFlag ? true : false} value="is_full_life_maintenance_condition" control={<Radio size="small" color="primary" checked={maintenaceObj.is_full_life_maintenance_condition === 1} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', 1)} />} label="Full Life" />
              <FormControlLabel disabled={mode === 'view' || !mntConditionFlag ? true : false} value="is_full_life_maintenance_condition" control={<Radio size="small" color="primary" checked={maintenaceObj.is_full_life_maintenance_condition === 2} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', 2)} />} label="Both" />
            </RadioGroup>
          </FormControl>

          {!maintenaceObj.is_full_life_maintenance_condition && !maintenaceObj.engine_type? <p className="note-message">Please select Aircraft and Engine to enable full life</p> : null}
          {maintenaceObj.is_full_life_maintenance_condition && (maintenaceObj.utility_type==4)? <p className="note-message">Aircraft age should be min. of 8 years as of Value date for Full Life values</p> : null}
        </Grid>
      }
      { maintenaceObj.asset_type &&  maintenaceObj.asset_type === 2 ?null:
        <Fragment>
            {maintenaceObj.is_full_life_maintenance_condition && getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id===46).length?
                <Fragment>
                    <Grid item xs={12} md={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Engine Calculation Type*</FormLabel>
                          <RadioGroup row aria-label="position" name="fbv_engine_calculation_type">
                            <FormControlLabel disabled={mode === 'view' ? true : false} value="fbv_engine_calculation_type" control={<Radio size="small" color="primary" onChange={(e) => onFieldChange(e, 'fbv_engine_calculation_type', 1)} checked={maintenaceObj.fbv_engine_calculation_type ===  1 ? true : false} />} label='Individual Engine' />
                            <FormControlLabel disabled={mode === 'view' ? true : false} value="fbv_engine_calculation_type" control={<Radio size="small" color="primary" onChange={(e) => onFieldChange(e, 'fbv_engine_calculation_type', 2)} checked={maintenaceObj.fbv_engine_calculation_type ===  2 ? true : false} />} label='Average of all engines' />
                            <FormControlLabel disabled={mode === 'view' ? true : false} value="fbv_engine_calculation_type" control={<Radio size="small" color="primary" onChange={(e) => onFieldChange(e, 'fbv_engine_calculation_type', 3)} checked={maintenaceObj.fbv_engine_calculation_type ===  3 ? true : false} />} label='Average of Single Engine OEM' />

                          </RadioGroup>
                        </FormControl>
                      </Grid>
                  </Fragment>:null}
          {maintenaceObj.is_full_life_maintenance_condition && maintenaceObj.engine_sv_details && Object.keys(maintenaceObj.engine_sv_details).length ?
            <Fragment>

             {maintenaceObj.utility_type==4?
                      <Grid item xs={12} md={4}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">No. Of Engines*</FormLabel>
                          <RadioGroup row aria-label="position" name="no_of_engines">
                            {Array(maintenaceObj.aircraft_type.no_of_engines).fill(1).map((engine, index) =>
                              <FormControlLabel disabled={mode === 'view' ? true : false} value="no_of_engines" control={<Radio size="small" color="primary" onChange={(e) => onChangeEvents(e, 'no_of_engines', index + 1)} checked={maintenaceObj.engine_sv_details.no_of_engines === index + 1 ? true : false} />} label={index + 1} />
                            )}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                   :null}

              {maintenaceObj.aircraft_type.has_optional_apu ?
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">APU Status</FormLabel>
                    <RadioGroup row aria-label="position" name="is_apu_disabled" defaultValue="YYYY-MM-DD">
                      <FormControlLabel disabled={mode === 'view' ? true : false} value="is_apu_disabled" control={<Radio size="small" color="primary" checked={!maintenaceObj.is_apu_disabled} onChange={(e) => onFieldChange(e, 'is_apu_disabled', false)} />} label="Enable" />
                      <FormControlLabel disabled={mode === 'view' ? true : false} value="is_apu_disabled" control={<Radio size="small" color="primary" checked={maintenaceObj.is_apu_disabled} onChange={(e) => onFieldChange(e, 'is_apu_disabled', true)} />} label="Disable" />
                    </RadioGroup>
                  </FormControl>
                </Grid> : null
              }
              <Grid item xs={12} md={maintenaceObj.utility_type==4?4:6}>
                <Autocomplete
                  disabled={mode === 'view' ? true : false}
                  options={mntGroups}
                  getOptionLabel={option => option.label}
                  id="maintenance_program"
                  value={maintenaceObj.maintenance_program ? maintenaceObj.maintenance_program : null}
                  disableClearable
                  onChange={(e, value) => { onFieldChange(e, 'maintenance_program', value); updateErrorField('maintenance_program', '') }}
                  renderInput={params => <TextField required error={error.maintenance_program ? true : false} helperText={error.maintenance_program ? error.maintenance_program : ''} onFocus={() => updateErrorField('maintenance_program')} {...params} label="Maintenance Program" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                />
              </Grid>
{/*              {!(maintenaceObj.utility_type==1 || maintenaceObj.utility_type==2 ) ?
                  <Grid item xs={12} md={12}>
                    <EngineShopVisit mode={mode} onChangeEvents={onChangeEvents} maintenaceObj={maintenaceObj} />
                                      </Grid> :null}
               */}
            </Fragment> : null
          }
        </Fragment>
      }
    </Fragment>
  )
}
export default MaintenaceCondition;
