import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Footer, BackBanner, ResetPwdForm } from '../components';
import { updateAuthInfoState, resetPasswordApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
class Reset extends Component {
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader: false,
      authInfo: {
        password: '',
        confirm_password: '',
      },
      error: {},
      errorCode:{
        password: {
          0:'',
          1: 'Please enter Password',
          4: 'Password does not meet the policy requirements'
        },
        passwordObj: {
          required: true,
          regexPattern: regexConstants.password
        },
        confirm_password: {
          0:'',
          1: 'Please enter Confirm Password',
          4: 'Password does not meet the policy requirements'
        },
        confirm_passwordObj: {
          required: true,
          regexPattern: regexConstants.password
        }
      }
    }
    this.updateAuthInfoState = updateAuthInfoState.bind(this);
    this.resetPasswordApi = resetPasswordApi.bind(this);
  }
  handleChange = (value, key) => {
    this.updateAuthInfoState(value, key);
  }
  onReset = () => {
    const { errorCode, authInfo } = this.state;
    let validationInputs = {
      password:errorCode['password'][fieldValidation({...errorCode['passwordObj'], fieldval: authInfo.password})],
      confirm_password:errorCode['confirm_password'][fieldValidation({...errorCode['confirm_passwordObj'], fieldval: authInfo.confirm_password})]
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      if(authInfo.password === authInfo.confirm_password){
        this.resetPasswordApi(this.props, this.state.authInfo);
      }else{
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            confirm_password: 'Passwords do not match. Try Again'
          }
        }))
      }

    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { error, authInfo, formSubmitLoader } = this.state;
    return(
      <Fragment>
        <BackBanner>
          <div className="auth-form-wrapper">
            <Link to="/login">← Back to Login</Link>
            <h2 className="form-title">Password Reset</h2>
            <p className="form-para">Please set your new password. Ensure the new password is following our Password Policy</p>
            <ResetPwdForm
              error={error}
              authInfo={authInfo}
              handleChange={this.handleChange}
              onReset={this.onReset}
              formSubmitLoader={formSubmitLoader}
            />
          </div>
        </BackBanner>
        <Footer />
      </Fragment>
    )
  }
}
export default withSnackbar(Reset);
