import React from 'react';
import { Provider } from 'react-redux';
import { withOrientationChange } from 'react-device-detect'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Offline } from "react-detect-offline";
import { SnackbarProvider } from 'notistack';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core';
import configureStore from './store/configureStore';
import { valuationRoutes } from './application/valuation/valuationRoutes';
import { authRoutes } from './application/auth/authRoutes';
import { settingsRoutes } from './application/settings/settingsRoutes';
import { portfolioRoutes } from './application/portfolios/portfolioRoutes';
import { bvFbvRoutes } from './application/bv_fbv/bvFbvRoutes';
import { levRoutes } from './application/lev/levRoutes';
import { blrRoutes } from './application/blr_mlr/blrRoutes';
import { hlaRoutes } from './application/hla/hlaRoutes';
import { fleetRoutes } from './application/fleets/routes';
import PrivateRoute from './hocs/PrivateRoute';
import { PageNotFound } from './shared_elements';
const store = configureStore();
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Conv_IBMPlexSans-Regular',
  }
});
const App = ({ isLandscape, isPortrait }) => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
        <ThemeProvider theme={theme}>
          <Offline><span style={{ position: 'fixed', top: '0', left: '0', right: '0', margin: '0 auto', width: '300px', background: 'yellow', zIndex: 999999, textAlign: 'center' }}>You are offline currently</span></Offline>
          <Router>
            <Switch>
              {authRoutes.map(({ path, component, key }, index) =>
                <Route exact path={path} component={component} key={key} />
              )}
              {[...fleetRoutes, ...bvFbvRoutes, ...levRoutes, ...blrRoutes, ...hlaRoutes, ...valuationRoutes, ...portfolioRoutes, ...settingsRoutes].map(({ path, component, key }, index) =>
                <PrivateRoute exact path={path} component={component} key={key} />
              )}
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
}
export default withOrientationChange(App)
