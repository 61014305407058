import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Button, Tabs, Tab, Paper, Grid, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ValuationOutPlaceHolder } from '../../Elements'
import { PageLoader } from '../../../shared_elements';
import { EventWrapper, EventsTab, HlaOutputwidget, AirframeBasic, AirframeEvent, APUBasic, ApuEvent, EngineBasic, EngineHSIEvent, EngineEvent, PropellarBasic, PropellarEvent, LgBasic, LgEvent, HlaBreadCrumbs } from '../components';
import EditIcon from '@material-ui/icons/Edit';
import { getEngineTypesAc, getAircraftTypesAc, getLesseeListAc, getAPUTypesAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators'; // shared apis
import { getValuationHlaApi, getValuationHlaResultApi, lockUnlockHlaApi, exportHlaReportApi } from '../apiServices'
import { getAircraftModsApi, getMntEventsApi } from '../../Elements/apiServices'
import { checkApiStatus, permissionCheckFn } from '../../../utils';
import { lgTypesObj } from '../'
class HlaOutput extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader: true,
      mode:'view',
      airframeIndex:0,
      engineIndex:0,
      propellerIndex:0,
      lgIndex:0,
      mods:[],
      mntGroups:[],
      halfLifeCrud:{},
      halfLifeResult:{},
      error:{}
    };
    this.getValuationHlaApi = getValuationHlaApi.bind(this);
    this.getValuationHlaResultApi = getValuationHlaResultApi.bind(this);
    this.lockUnlockHlaApi = lockUnlockHlaApi.bind(this);
    this.exportHlaReportApi = exportHlaReportApi.bind(this);
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getMntEventsApi = getMntEventsApi.bind(this);
  }
  componentDidMount(){
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.props.getLesseeList();
    this.props.getPlatformConstants();
    this.getValuationHlaApi(this.props);
    this.getValuationHlaResultApi(this.props)
    .then(response => {
      this.setState({skeletonLoader:false});
      if(checkApiStatus(response)){
        let payout = response.data.data;
        this.setState({halfLifeResult:payout});
        this.getAircraftModsApi(this.props, { aircraft_type: payout.aircraft_type.id })
        .then(response => {
          this.setState({mods:response});
        });
        this.getMntEventsApi(this.props, payout.aircraft_type.id)
        .then(response => {
          this.setState({mntGroups:response});
        });
        this.props.getAPUTypes({'aircraft-type': payout.aircraft_type.id });
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': payout.aircraft_type.id });
      }
    });
  }
  handleTabChange = (event, newValue, tabType) => {
    this.setState({[tabType]:newValue});
  }
  onFieldChange = (e, keyParam, value, objectKey) => {
    const { halfLifeCrud } = this.state;
    this.updateFormFields(keyParam, value, objectKey)
    if(keyParam == 'aircraftType'){
      this.updateFormFields('mods', [], 'generic');
      this.updateFormFields('engineType', null, 'engine');
      this.updateFormFields('no_of_engines', 1, 'engine');
      this.updateFormFields('identical_data', true, 'engine')
      this.updateFormFields('utilization_type', 1, 'airframe')
      this.updateFormFields('planned_fc', 0, 'airframe')
      this.updateFormFields('planned_fh', 0, 'airframe')
      this.updateFormFields('maintenance_program', null, 'airframe')
      this.setState(prevState => ({...prevState,halfLifeCrud:{...prevState.halfLifeCrud, apuOptional:true}}))
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
        .then(response => {
          this.setState({mods:response});
        });
        this.getMntEventsApi(this.props, value.id)
        .then(response => {
          this.setState({mntGroups:response});
        });
        this.props.getAPUTypes({'aircraft-type': value.id });
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': value.id });
        // this.propellerDecisionFn(value.oem)
      }
    }
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(2), 'generic');
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(2), 'generic');
      }
    }
    // if(keyParam === 'no_of_engines'){
    //   this.updateAssemblyInfo('engine', 'no_of_engines', value);
    // }
  }
  updateFormFields = (keyParam, value, objectKey) => {
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud: {
        ...prevState.halfLifeCrud,
        [objectKey]: {
          ...prevState.halfLifeCrud[objectKey],
          [keyParam]: value
        }
      }
    }))
  }
  updateErrorField = (keyParam, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: message
      }
    }))
  }
  updateAssemblyInfo = (assemblyType, keyParam, value) => {
    const { halfLifeCrud } = this.state;
    if(keyParam === 'no_of_engines'){
      let engineList = halfLifeCrud.engine.engineInfo.length;
      if(engineList < value){
        let newEgines = [], count = value - engineList;
        for(let i=0;i<count;i++){
          newEgines.push({id:'',tsn:'', csn:'', utilization_as_of_date:null})
        }
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            engine:{
              ...prevState.halfLifeCrud.engine,
              engineInfo:[...prevState.halfLifeCrud.engine.engineInfo,...newEgines]
            },
            propeller:{
              ...prevState.halfLifeCrud.propeller,
              propellerInfo:[...prevState.halfLifeCrud.propeller.propellerInfo, ...newEgines]
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            engine:{
              ...prevState.halfLifeCrud.engine,
              engineInfo:prevState.halfLifeCrud.engine.engineInfo.slice(0, value)
            },
            propeller:{
              ...prevState.halfLifeCrud.propeller,
              propellerInfo:prevState.halfLifeCrud.propeller.propellerInfo.slice(0, value)
            }
          }
        }))
      }
    }
  }
  updateLgType = (flag) => {
    const { halfLifeCrud } = this.state;
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud:{
        ...prevState.halfLifeCrud,
        landing_gear:{
          ...prevState.halfLifeCrud.landing_gear,
          lgInfo: flag ? prevState.halfLifeCrud.landing_gear.lgInfo.slice(0,1):lgTypesObj[halfLifeCrud.airframe.aircraftType.no_of_landing_gears]
        }
      }
    }))
  }
  updateIndexedAssembly = (e, keyParam, value, assembly, objectKey , index) => {
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud:{
        ...prevState.halfLifeCrud,
        [assembly]:{
          ...prevState.halfLifeCrud[assembly],
          [objectKey]:prevState.halfLifeCrud[assembly][objectKey].map((item, assemblyIndex) => assemblyIndex === index ? {
            ...item, [keyParam]:value
          } :item)
        }
      }
    }))
  }
  render(){
    const { skeletonLoader, pageLoader, mode, error, airframeIndex, engineIndex, propellerIndex, lgIndex, mods, mntGroups, halfLifeCrud, halfLifeResult } = this.state;
    const { aircraftTypes, engineTypes, apuTypes, lessee, pltConstants } = this.props;
    const editPermission = permissionCheckFn({secondaryKey:'hthl', keyIndex:'U'});
    return(
      <section className="valuation-app-section">
        { skeletonLoader ? <Skeleton variant="text" />:
          <HlaBreadCrumbs
            logResult={halfLifeResult}
            changeLogStatus={(data) => this.lockUnlockHlaApi(this.props, data)}
            mode={mode}
            exportValuationLog={(file)=>this.exportHlaReportApi(this.props, file)}
          />
        }
        <div className="hla-section">
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              { skeletonLoader ? <Skeleton variant="rect" width={'100%'} height={540} /> :
                <Paper elevation={1} className="assembly-sections">
                  { editPermission && !halfLifeResult.lock_status ? <span onClick={() => this.setState({ mode: 'edit' })} className="edit-icon"><EditIcon color="primary" fontSize="small" /></span>:null}
                  { halfLifeCrud.airframe && Object.keys(halfLifeCrud.airframe).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Airframe Details" flag={true}>
                        <Grid container spacing={1}>
                          <AirframeBasic
                            lessee={lessee}
                            aircraftTypes={aircraftTypes}
                            mods={mods}
                            mntGroups={mntGroups}
                            seatConfigs={pltConstants.filter(item => item.type === 'seating_configuration')}
                            mode={mode}
                            permissionFlag={editPermission && !halfLifeResult.lock_status}
                            crudData={halfLifeCrud}
                            error={{}}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={this.updateErrorField}
                          />
                        </Grid>
                        <EventsTab
                          tabIndex={airframeIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'airframeIndex')}
                          tabs={halfLifeCrud.airframe.maintenance_events.map(item => `${item.event} Year Check`)}
                        />
                        {halfLifeCrud.airframe.maintenance_events.map((mntProgram, index) =>
                          index === airframeIndex ?
                          <Grid container spacing={1}>
                            <AirframeEvent
                              item={mntProgram}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={{}}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.engine && Object.keys(halfLifeCrud.engine).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Engine Details" flag={false}>
                        <EventsTab
                          tabIndex={engineIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'engineIndex')}
                          tabs={halfLifeCrud.engine.engineInfo.map((engine, index) => `Engine #${index+1}`)}
                        />

                        { halfLifeCrud.engine.engineInfo.map((item, index) =>
                          index === engineIndex ?
                          <Fragment>
                            <Grid container spacing={1}>
                              <EngineBasic
                                engineTypes={engineTypes}
                                mode={mode}
                                permissionFlag={editPermission && !halfLifeResult.lock_status}
                                crudData={halfLifeCrud}
                                error={error}
                                item={item}
                                itemIndex={index}
                                updateIndexedAssembly={this.updateIndexedAssembly}
                                updateErrorField={this.updateErrorField}
                              />
                            </Grid>
                            { item.engine_hsi && halfLifeCrud.airframe.aircraftType.has_propeller ?
                              <Grid container spacing={1}>
                                <EngineHSIEvent
                                  item={item}
                                  itemIndex={index}
                                  mode={mode}
                                  permissionFlag={editPermission && !halfLifeResult.lock_status}
                                  crudData={{}}
                                  error={{}}
                                  onFieldChange={this.onFieldChange}
                                  updateErrorField={this.updateErrorField}
                                />
                              </Grid>:null
                            }
                            <Grid container spacing={1}>
                              <EngineEvent
                                item={item}
                                itemIndex={index}
                                mode={mode}
                                permissionFlag={editPermission && !halfLifeResult.lock_status}
                                crudData={halfLifeCrud}
                                error={{}}
                                onFieldChange={this.onFieldChange}
                                updateErrorField={this.updateErrorField}
                              />
                            </Grid>
                          </Fragment>
                          :null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.propeller && Object.keys(halfLifeCrud.propeller).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Propellar Details" flag={false}>
                        <EventsTab
                          tabIndex={propellerIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'propellerIndex')}
                          tabs={halfLifeCrud.propeller.map((propeller) => `Propellar #${propeller.position}`)}
                        />
                        { halfLifeCrud.propeller.map((item, index) =>
                          propellerIndex === index ?
                          <Grid container spacing={1}>
                            <PropellarBasic
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={halfLifeCrud}
                              error={error}
                              item={item}
                              itemIndex={index}
                              updateIndexedAssembly={this.updateIndexedAssembly}
                              updateErrorField={this.updateErrorField}
                            />
                            <PropellarEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={{}}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.apu && Object.keys(halfLifeCrud.apu).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="APU Details" flag={false}>
                        <Grid container spacing={1}>
                          <APUBasic
                            apuTypes={apuTypes}
                            mode={mode}
                            permissionFlag={editPermission && !halfLifeResult.lock_status}
                            crudData={halfLifeCrud}
                            error={error}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={this.updateErrorField}
                          />
                          <ApuEvent
                            item={halfLifeCrud.apu}
                            mode={mode}
                            permissionFlag={editPermission && !halfLifeResult.lock_status}
                            crudData={{}}
                            error={{}}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={this.updateErrorField}
                          />
                        </Grid>
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.landing_gear && halfLifeCrud.landing_gear.length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Landing Gear Details" flag={false}>
                        <EventsTab
                          tabIndex={lgIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'lgIndex')}
                          tabs={halfLifeCrud.landing_gear.map((lg) => `${lg.position}`)}
                        />
                        { halfLifeCrud.landing_gear.map((item, index) =>
                          lgIndex === index ?
                          <Grid container spacing={1}>
                            <LgBasic
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={halfLifeCrud}
                              error={error}
                              item={item}
                              itemIndex={index}
                              updateIndexedAssembly={this.updateIndexedAssembly}
                              updateErrorField={this.updateErrorField}
                            />
                            <LgEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={{}}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }

                </Paper>
              }

            </Grid>
            <Grid item md={4} xs={12}>
              { skeletonLoader ? <Skeleton variant="rect" width={'100%'} height={540} /> :
                <Fragment>
                  { Object.keys(halfLifeResult).length ? <HlaOutputwidget halfLifeResult={halfLifeResult} />:null}
                </Fragment>
              }
            </Grid>
          </Grid>
        </div>
        { pageLoader ? <PageLoader />:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
  apuTypes: state.shareReducer.apuTypes,
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAircraftTypes: (query) => dispatch(getAircraftTypesAc(ownProps, query)),
    getLesseeList: (query) => dispatch(getLesseeListAc(query)),
    getAPUTypes: (query) => dispatch(getAPUTypesAc(query)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['seating_configuration']})),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(HlaOutput))
