import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import { checkValuationAdminFn } from '../../../utils';
const LeaseDetail = ({ mode, pltConstants, crudData, error, onFieldChange, updateErrorField, addStepRentalRow, removeStepRentalRow, editStepRentalRow, updateStepErrorField, match }) => {
  return (
    <Fragment>
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                required
                margin="normal"
                id="lease_start_date"
                label="Lease Start Date"
                placeholder="DD-MM-YYYY"
                format={fieldDateFormat}
                fullWidth
                disableFuture={true}
                minDate={moment(crudData.date_of_manufacture)}
                disabled={(mode === 'view' || mode === 'edit') ? true : false}
                InputLabelProps={{ shrink: true }}
                onChange={(data, value) => onFieldChange(value, 'lease_start_date', data ? moment(data).format(backendDateFormat) : data)}
                value={crudData.lease_start_date}
                error={error.lease_start_date ? true : false}
                helperText={error.lease_start_date ? error.lease_start_date : ''}
                onFocus={() => updateErrorField('lease_start_date', '')}
                onOpen={() => updateErrorField('lease_start_date', '')}
                inputVariant="outlined"
                inputProps={{ readOnly: false }}
                autoComplete='off'
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                required
                margin="normal"
                id="lease_end_date"
                label="Lease End Date"
                placeholder="DD-MM-YYYY"
                format={fieldDateFormat}
                minDate={moment(crudData.lease_start_date)}
                maxDate={moment(crudData.date_of_manufacture).add(25, 'years')}
                fullWidth
                disabled={(mode === 'view' || mode === 'edit') ? true : false}
                InputLabelProps={{ shrink: true }}
                onChange={(data, value) => onFieldChange(value, 'lease_end_date', data ? moment(data).format(backendDateFormat) : data)}
                value={crudData.lease_end_date}
                error={error.lease_end_date ? true : false}
                helperText={error.lease_end_date ? error.lease_end_date : ''}
                onFocus={() => updateErrorField('lease_end_date', '')}
                onOpen={() => updateErrorField('lease_end_date', '')}
                inputVariant="outlined"
                inputProps={{ readOnly: false }}
                autoComplete='off'
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <Autocomplete
              disabled={(mode === 'view' || mode === 'edit') ? true : false}
              options={pltConstants.filter(item => item.type === 'lev_rental_type')}
              getOptionLabel={option => option.label}
              id="rental_type"
              value={crudData.rental_type}
              onChange={(e, value) => { onFieldChange(e, 'rental_type', value); updateErrorField('rental_type', '') }}
              renderInput={params => <TextField required error={error.rental_type ? true : false} helperText={error.rental_type ? error.rental_type : ''} onFocus={() => updateErrorField('rental_type', '')} {...params} label="Rental Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Autocomplete
              disabled={(mode === 'view' || mode === 'edit') ? true : false}
              options={pltConstants.filter(item => item.type === 'lev_rental_payment_type')}
              getOptionLabel={option => option.label}
              id="rental_payment_type"
              value={crudData.rental_payment_type}
              onChange={(e, value) => { onFieldChange(e, 'rental_payment_type', value); updateErrorField('rental_payment_type', '') }}
              renderInput={params => <TextField required error={error.rental_payment_type ? true : false} helperText={error.rental_payment_type ? error.rental_payment_type : ''} onFocus={() => updateErrorField('rental_payment_type', '')} {...params} label="Rental Payment Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            />
          </Grid>
          {crudData.rental_type && crudData.rental_type.value && crudData.rental_type.value === 2 || crudData.rental_type && crudData.rental_type.id && crudData.rental_type.id === 2 ? null :
            <Grid item md={4} xs={12}>
              <TextField
                disabled={mode === 'view' ? true : false}
                required
                id="rent"
                label={(crudData.rental_type?.value === 3 || crudData.rental_type?.id === 3 )? 'Quarterly Rent' : 'Monthly Rent'}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={crudData.rent}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'rent', e.target.value) : e.preventDefault(); updateErrorField('rent', '') }}
                error={error.rent ? true : false}
                helperText={error.rent ? error.rent : ''}
                onFocus={() => updateErrorField('rent', '')}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                autoComplete='off'
              />
            </Grid>
          }
          {
            checkValuationAdminFn() ?
              <Grid item md={4} xs={12}>
                <TextField
                  disabled={mode === 'view' ? true : false}
                  id="asset_management_charges"
                  label='Asset Management Charges'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{ shrink: true }}
                  value={crudData.asset_management_charges}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'asset_management_charges', e.target.value) : e.preventDefault() }}
                  error={error.asset_management_charges ? true : false}
                  helperText={error.asset_management_charges ? error.asset_management_charges : ''}
                  onFocus={() => updateErrorField('asset_management_charges', '')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  variant="outlined"
                  autoComplete='off'
                />
              </Grid>
              : null
          }

          <Grid item md={4} xs={12}>
            <TextField
              disabled={mode === 'view' ? true : false}
              required
              autoComplete='off'
              id="annual_discount"
              label='Annual Discount'
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.annual_discount}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && parseFloat(e.target.value ? e.target.value : 0) <= 100 ? onFieldChange(e, 'annual_discount', e.target.value) : e.preventDefault() }}
              error={error.annual_discount ? true : false}
              helperText={error.annual_discount ? error.annual_discount : ''}
              onFocus={() => updateErrorField('annual_discount', '')}
              InputProps={{
                startAdornment: <InputAdornment InputAdornment position="start" style={mode === "view" ? { display: 'none' } : {}} >
                  <span >
                    <Tooltip
                      title={<>
                        <p>Good: 6.5% </p>
                        <p>Average: 7.5% </p>
                        <p>Poor: 8.5% - 20% </p>
                      </>

                      }
                      arrow
                      placement="top"
                    >
                      <InfoIcon fontSize="small" color="primary" style={{ cursor: 'pointer', fontSize: '16px' }} />
                    </Tooltip>
                  </span>
                </InputAdornment>,
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              disabled={mode === 'view' ? true : false}
              required
              autoComplete='off'
              id="markdown_on_residual_value"
              label='Markdown on Residual Value'
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.markdown_on_residual_value}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'markdown_on_residual_value', e.target.value) : e.preventDefault(); updateErrorField('markdown_on_residual_value', '') }}
              error={error.markdown_on_residual_value ? true : false}
              helperText={error.markdown_on_residual_value ? error.markdown_on_residual_value : ''}
              onFocus={() => updateErrorField('markdown_on_residual_value', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start" style={mode === "view" ? { display: 'none' } : {}}>
                  <span>
                    <Tooltip
                      title="The input value should be between 0 and 100"
                      arrow
                      placement="top"
                    >
                      <InfoIcon fontSize="small" color="primary" style={{ cursor: 'pointer', fontSize: '16px' }} />
                    </Tooltip>
                  </span>
                </InputAdornment>
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              disabled={mode === 'view' ? true : false}
              id="annual_inflation_rate"
              label='Annual Inflation Rate'
              fullWidth
              autoComplete='off'
              margin="normal"
              InputLabelProps={{ shrink: true }}
              value={crudData.annual_inflation_rate}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 5 ? onFieldChange(e, 'annual_inflation_rate', e.target.value) : e.preventDefault(); updateErrorField('annual_inflation_rate', '') }}
              error={error.annual_inflation_rate ? true : false}
              helperText={error.annual_inflation_rate ? error.annual_inflation_rate : ''}
              onFocus={() => updateErrorField('annual_inflation_rate', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end"> %</InputAdornment>,
                startAdornment: <InputAdornment position='start' style={mode === "view" ? { display: 'none' } : {}}>
                  <span>
                    <Tooltip
                      title="The input value should be between 1% and 5%"
                      arrow
                      placement="top"
                    >
                      <InfoIcon fontSize="small" color="primary" style={{ cursor: 'pointer', fontSize: '16px' }} />
                    </Tooltip>
                  </span>
                </InputAdornment>
              }}
              variant="outlined"
            />
          </Grid>
          {crudData.rental_type && crudData.rental_type.value && crudData.rental_type.value == 2 || crudData.rental_type && crudData.rental_type.id && crudData.rental_type.id == 2 ?
            <Grid item md={12} xs={12}>
              <div className="lev-step-rental">
                <h4>Rental Step(s) {match.params.id ? null : <Button onClick={addStepRentalRow} startIcon={<AddCircleOutlineIcon color="primary" fontSize="small" />} color="primary" size="small" variant="outlined">Add</Button>} </h4>
                <table>
                  <thead>
                    <tr>
                      <th align="left">Start Date*</th>
                      <th align="left">End Date*</th>
                      <th align="left">Rent*</th>
                      {!match.params.id ? <th align="left">Action</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {crudData.step_rentals.map((item, index) =>
                      <tr>
                        <td>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              disabled={match.params.id || !crudData.lease_start_date ? true : false}
                              required
                              margin="normal"
                              id="start_date"
                              placeholder="DD-MM-YYYY"
                              format={fieldDateFormat}
                              fullWidth
                              minDate={crudData.step_rentals[index - 1] ? moment(crudData.step_rentals[index - 1].end_date).add(1, 'days') : moment(crudData.lease_start_date)}
                              InputLabelProps={{ shrink: true }}
                              onChange={(data, value) => editStepRentalRow('start_date', data ? moment(data).format(backendDateFormat) : data, index)}
                              value={item.start_date}
                              error={error && error[index] && error[index].start_date ? true : false}
                              helperText={error && error[index] && error[index].start_date ? error[index].start_date : ''}
                              onFocus={() => error && error[index] && error[index].start_date ? updateStepErrorField('start_date', index) : null}
                              onOpen={() => error && error[index] && error[index].start_date ? updateStepErrorField('start_date', index) : null}
                              inputVariant="outlined"
                              inputProps={{ readOnly: false }}
                              autoComplete='off'
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                        <td>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              disabled={match.params.id || !crudData.lease_start_date ? true : false}
                              required
                              margin="normal"
                              id="end_date"
                              placeholder="DD-MM-YYYY"
                              format={fieldDateFormat}
                              fullWidth
                              minDate={moment(item.start_date).add(1, 'days')}
                              maxDate={crudData.step_rentals[index + 1] ? moment(crudData.step_rentals[index + 1].start_date).subtract(1, 'days') : moment(crudData.lease_end_date)}
                              InputLabelProps={{ shrink: true }}
                              onChange={(data, value) => editStepRentalRow('end_date', data ? moment(data).format(backendDateFormat) : data, index)}
                              value={item.end_date}
                              error={error && error[index] && error[index].end_date ? true : false}
                              helperText={error && error[index] && error[index].end_date ? error[index].end_date : ''}
                              onFocus={() => error && error[index] && error[index].end_date ? updateStepErrorField('end_date', index) : null}
                              onOpen={() => error && error[index] && error[index].end_date ? updateStepErrorField('end_date', index) : null}
                              inputVariant="outlined"
                              inputProps={{ readOnly: false }}
                              autoComplete='off'
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                        <td>
                          <TextField
                            disabled={match.params.id || !crudData.lease_start_date ? true : false}
                            required
                            id="rent"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{ shrink: true }}
                            value={item.rent}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? editStepRentalRow('rent', e.target.value, index) : e.preventDefault() }}
                            error={error && error[index] && error[index].rent ? true : false}
                            helperText={error && error[index] && error[index].rent ? error[index].rent : ''}
                            onFocus={() => error && error[index] && error[index].rent ? updateStepErrorField('rent', index) : null}
                            variant="outlined"
                          />
                        </td>
                        {match.params.id ?
                          null :
                          <td>
                            <DeleteOutlineIcon onClick={() => removeStepRentalRow(index)} color="secondary" fontSize="small" />
                          </td>
                        }
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Grid> : null
          }
        </Grid>
      </Grid>
    </Fragment >
  )
}
export default LeaseDetail;
