import React, { Component} from 'react';
import {Button} from '@material-ui/core';

class EnableDisableButton extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { enableStaticLLp, disableStaticLLp, enableButtonFlag, disableButtonFlag } = this.props;
        return (
            <ul className="list-inline">
                <li className="list-inline-item"><Button color="primary" variant="contained"  onClick={enableStaticLLp} disabled={enableButtonFlag} >Enable</Button></li>
                <li className="list-inline-item" style={{marginLeft:'5px'}}><Button color="primary" variant="contained" onClick={disableStaticLLp} disabled={disableButtonFlag} >Disable</Button></li>
            </ul>
        )
    }
}
export default EnableDisableButton;
