import React, { Fragment } from 'react';
import { Grid, Paper, Chip, Button, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { LabelValueCard } from '../../../shared_elements';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

const AirframeHlaIntermediate = ({ skeletonLoader, hlaLogDetail, airframeEdit, toggleAirframeEdit, onCloseEdit, onFieldChange, updateAirframeLog, maintenanceEventChange, error, updateErrorField, updateAirframeErrorField, airframeRemarksFlag, remarksMandatory, isLocked, editMode, airframeWarningText }) => {
  const diff = parseFloat(moment(hlaLogDetail.generic.report_date).diff(moment(hlaLogDetail.airframe.date_of_manufacture), 'days') * 0.00273973).toString()
  let diffArr = []
  let years = ''
  let months = ""

  if (diff > 0) {
    diffArr = diff.split('.')
    years = diffArr[0]
    months = parseInt(`0.${diffArr[1]}` * 12)
  }
  return (
    <Paper>
      <Grid container spacing={0} style={isMobile ? { marginBottom: 'auto' } : { marginBottom: '15px', padding: '10px' }}>
        <Grid item xs={5} sm={6} md={6}>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <h4 className="hla-initiate-card-title"><strong>Airframe</strong></h4>
            </li>
            {
              airframeWarningText ?
                <li className='list-inline-item'>
                  <p className='warning-text'>{airframeWarningText}</p>
                </li> : null
            }

          </ul>
        </Grid>
        <Grid item xs={7} sm={6} md={6}>
          <div style={{ float: 'right' }}>
            {getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('U') != -1 ?
              !airframeEdit ?
                !isLocked ?
                  <EditIcon color="primary" style={{ cursor: 'pointer', zIndex: '1000' }} onClick={toggleAirframeEdit} /> : null :
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Button size="small" color="primary" variant="contained" onClick={updateAirframeLog}>Save</Button>
                  </li>
                  <li className="list-inline-item" style={{ marginLeft: '5px' }}>
                    <Button size="small" color="primary" variant="outlined" onClick={onCloseEdit}>Cancel</Button>
                  </li>
                </ul>
              : null
            }
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ padding: '10px' }}>
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Serial Number' value={hlaLogDetail.airframe.serial_number ? hlaLogDetail.airframe.serial_number : '--'} />
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Aircraft Type' value={hlaLogDetail.airframe.aircraftType.name ? hlaLogDetail.airframe.aircraftType.name : '--'} />
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Date of Manufacture' value={hlaLogDetail.airframe.date_of_manufacture ? moment(hlaLogDetail.airframe.date_of_manufacture).format(displayDateFormatShort) : '--'} />
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='OEM' value={hlaLogDetail.airframe.aircraftType.oem ? hlaLogDetail.airframe.aircraftType.oem : '--'} />
        {airframeEdit ?
          <Grid item xs={6} md={3}>
            <TextField
              required
              id="mtow_lbs"
              label='Max Take Off Weight'
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              value={hlaLogDetail.generic.mtow}
              onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow', e.target.value, 'generic') : e.preventDefault() }}
              error={error && error.mtow ? true : false}
              helperText={error && error.mtow ? error.mtow : ''}
              onFocus={() => { updateErrorField('mtow', '', 'airframe'); updateErrorField('mtow_kgs', '', 'airframe'); }}
              InputProps={{
                endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid> :
          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Max Take Off Weight' value={hlaLogDetail.generic.mtow ? `${hlaLogDetail.generic.mtow} Lbs` : '--'} />
        }
        {
          airframeEdit ?
            <Grid item xs={6} md={3}>
              <TextField
                required
                id="mtow_kgs"
                label='Max Take Off Weight'
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={hlaLogDetail.generic.mtow_kgs}
                onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow_kgs', e.target.value, 'generic') : e.preventDefault() }}
                error={error && error.mtow_kgs ? true : false}
                helperText={error && error.mtow_kgs ? error.mtow_kgs : ''}
                onFocus={() => { updateErrorField('mtow', '', 'airframe'); updateErrorField('mtow_kgs', '', 'airframe'); }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
                }}
                variant="outlined"
              />
            </Grid> :
            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Max Take Off Weight' value={hlaLogDetail.generic.mtow_kgs ? `${hlaLogDetail.generic.mtow_kgs} Kgs` : '--'} />
        }
        {hlaLogDetail.generic.mods && hlaLogDetail.generic.mods.length ?
          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Modifications/Enhancements' value={hlaLogDetail.generic.mods.map(mod => <Chip size="small" style={{ margin: '0 4px 4px 0', fontFamily: 'Conv_IBMPlexSans-Medium' }} label={mod.name} variant="outlined" />)} /> : null
        }
        {
          airframeEdit ?
            <Grid item xs={6} md={3}>
              <TextField
                id="registration_number"
                label="Registration Number"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={hlaLogDetail.airframe.registration_number}
                onChange={(e) => onFieldChange(e, 'registration_number', e.target.value, 'airframe')}
                variant="outlined"
              />
            </Grid> :
            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Registration Number' value={hlaLogDetail.airframe.registration_number ? hlaLogDetail.airframe.registration_number : '--'} />
        }
        {
          airframeEdit ?
            <Grid item xs={6} md={3}>
              <TextField
                required
                id="tsn"
                label="Time Since New"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={hlaLogDetail.airframe.tsn}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, 'airframe') : e.preventDefault() }}
                error={error && error.tsnAirframe ? true : false}
                helperText={error && error.tsnAirframe ? error.tsnAirframe : ''}
                onFocus={(e) => error && error.tsnAirframe ? updateErrorField('tsnAirframe', '', 'airframe') : e.preventDefault()}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                }}
                variant="outlined"
              />
            </Grid> :
            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Time Since New' value={hlaLogDetail.airframe.tsn ? `${hlaLogDetail.airframe.tsn} FH` : '--'} />
        }
        {
          airframeEdit ?
            <Grid item xs={6} md={3}>
              <TextField
                required
                id="csn"
                label="Cycles Since New"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={hlaLogDetail.airframe.csn}
                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value, 'airframe') : e.preventDefault() }}
                error={error && error.csnAirframe ? true : false}
                helperText={error && error.csnAirframe ? error.csnAirframe : ''}
                onFocus={(e) => error && error.csnAirframe ? updateErrorField('csnAirframe', '', 'airframe') : null}
                InputProps={{
                  endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                }}
                variant="outlined"
              />
            </Grid> :
            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Cycles Since New' value={hlaLogDetail.airframe.csn ? `${hlaLogDetail.airframe.csn} FC` : '--'} />
        }
        {
          airframeEdit ?
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  required
                  margin="normal"
                  id="as_of_date"
                  label=" Utilization as of date"
                  placeholder="DD-MM-YYYY"
                  fullWidth
                  format={fieldDateFormat}
                  InputLabelProps={{ shrink: true }}
                  minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                  maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                  value={hlaLogDetail.airframe.utilization_as_of_date}
                  onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, 'airframe')}
                  error={error && error.utilization_as_of_date_airframe ? true : false}
                  helperText={error && error.utilization_as_of_date_airframe ? error.utilization_as_of_date_airframe : ''}
                  onFocus={(e) => error && error.utilization_as_of_date_airframe ? updateErrorField('utilization_as_of_date_airframe', '', 'airframe') : e.preventDefault()}
                  onOpen={(e) => error && error.utilization_as_of_date_airframe ? updateErrorField('utilization_as_of_date_airframe', '', 'airframe') : null}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid> :
            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Utilization as of date' value={hlaLogDetail.airframe.utilization_as_of_date ? moment(hlaLogDetail.airframe.utilization_as_of_date).format(displayDateFormatShort) : '--'} />
        }
        {hlaLogDetail.airframe.utilization_type == 2 ?
          airframeEdit ?
            <Fragment>
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  id="planned_fc"
                  label="Planned FC (Per Year)"
                  fullWidth
                  margin="normal"
                  value={hlaLogDetail.airframe.planned_fc}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => onFieldChange(e, 'planned_fc', e.target.value, 'airframe')}
                  error={error && error.planned_fc ? true : false}
                  helperText={error && error.planned_fc ? error.planned_fc : ''}
                  onFocus={() => updateErrorField('planned_fc', '', 'airframe')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  id="planned_fh"
                  label="Planned FH (Per Year)"
                  fullWidth
                  margin="normal"
                  value={hlaLogDetail.airframe.planned_fh}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => onFieldChange(e, 'planned_fh', e.target.value, 'airframe')}
                  error={error && error.planned_fh ? true : false}
                  helperText={error && error.planned_fh ? error.planned_fh : ''}
                  onFocus={() => updateErrorField('planned_fh', '', 'airframe')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Fragment> :
            <Fragment>
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Planned FC (Per Year)' value={hlaLogDetail.airframe.planned_fc ? hlaLogDetail.airframe.planned_fc : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Planned FH (Per Year)' value={hlaLogDetail.airframe.planned_fh ? hlaLogDetail.airframe.planned_fh : '--'} />
            </Fragment>
          : null
        }
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Age as on Report Date' value={diff > 0 ? `${years} Years and ${months} ${months == 1 ? 'month' : 'months'}` : 0} />
        {editMode ? getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id === 46).length ?
          airframeEdit ?
            <Grid item xs={12} md={12}>
              <TextField
                id="assumptions"
                label="Assumptions"
                fullWidth
                multiline
                margin="normal"
                value={hlaLogDetail.generic.assumptions}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => onFieldChange(e, 'assumptions', e.target.value, 'generic')}
                onFocus={() => updateErrorField('assumptions', '', 'generic')}
                variant="outlined"
              />
            </Grid> :
            <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={12} label='Assumptions' value={hlaLogDetail.generic.assumptions ? hlaLogDetail.generic.assumptions : '--'} />
          : null : null
        }
      </Grid>
      <div style={{ background: 'linear-gradient(90deg,#2c45c1,rgba(150,95,222,.9294117647058824))', color: 'white' }} className="maintanence-events event-info">
        {
          hlaLogDetail.airframe.maintenance_events.map((event, index) =>
            <Fragment>
              <Grid container spacing={2} style={{ padding: '10px' }} >
                <Grid item xs={12} md={12}>
                  <h4 style={{ textDecoration: 'underline', marginBottom: isMobile ? "5px" : "-10px" }}><strong>{event.event} Year Check</strong></h4>
                </Grid>
                <Grid container spacing={4} style={{ padding: '10px' }}>
                  {airframeEdit ?
                    <Fragment>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="tsn_at_lsv"
                          label={
                            <p>TSN at LSV
                              <Tooltip
                                title="Time Since New at Last Shop Visit"
                                arrow
                                placement="top"
                              >
                                <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                              </Tooltip>
                            </p>
                          }
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 10 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? maintenanceEventChange(e, 'tsn_at_lsv', e.target.value, index) : e.preventDefault() }}
                          value={event.tsn_at_lsv}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="tcsn_at_lsv"
                          label={
                            <p>CSN at LSV
                              <Tooltip
                                title="Cycles Since New at Last Shop Visit"
                                arrow
                                placement="top"
                              >
                                <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                              </Tooltip>
                            </p>
                          }
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 10 }}
                          InputLabelProps={{ shrink: true }}
                          minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                          onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? maintenanceEventChange(e, 'csn_at_lsv', e.target.value, index) : e.preventDefault() }}
                          value={event.csn_at_lsv}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="as_of_date"
                            label={
                              <p style={error && error[index] && error[index].lsv_date ? { color: '#f44336' } : {}} >LSV as of Date
                                <Tooltip
                                  title="Last Shop Visit details to be entered before the report date"
                                  arrow
                                  placement="top"
                                >
                                  <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                                </Tooltip>
                              </p>
                            }
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            format={fieldDateFormat}
                            minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                            maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                            error={error && error[index] && error[index].lsv_date ? true : false}
                            helperText={error && error[index] && error[index].lsv_date ? <span style={{ color: '#f44336' }}>{error[index].lsv_date}</span> : ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => maintenanceEventChange(value, 'lsv_date', data ? moment(data).format(backendDateFormat) : data, index)}
                            value={event.lsv_date}
                            onFocus={(e) => error && error[index] && error[index].lsv_date ? updateAirframeErrorField('lsv_date', '', index) : e.preventDefault()}
                            onOpen={(e) => error && error[index] && error[index].lsv_date ? updateAirframeErrorField('lsv_date', '', index) : null}
                            inputVariant="outlined"
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      {
                        (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) &&
                          getLocalStorageInfo().user.account_type && getLocalStorageInfo().user.account_type == 'Acumen' ?
                          <Grid item xs={6} md={3} >
                            <TextField
                              required
                              id="cost"
                              label="Event Cost"
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 12 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? maintenanceEventChange(e, 'cost', e.target.value, index) : e.preventDefault(); maintenanceEventChange(e, 'airframeRemarksFlag', true, index) }}
                              value={event.cost}
                              error={error && error[index] && error[index].cost_airframe ? true : false}
                              helperText={error && error[index] && error[index].cost_airframe ? <span style={{ color: '#f44336' }}>{error[index].cost_airframe}</span> : ''}
                              onFocus={(e) => error && error[index] && error[index].cost_airframe ? updateAirframeErrorField('cost_airframe', '', index) : e.preventDefault()}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              variant="outlined"
                            />
                          </Grid> :
                          ((getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) &&
                            getLocalStorageInfo().user.account_type && getLocalStorageInfo().user.account_type == 'Acumen') ?
                            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={<NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{event.cost}</NumericalLabel>} />
                            : null
                      }
                      {
                        (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) ?
                          <Grid item xs={6} md={3} >
                            <TextField
                              required={(getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) && event.airframeRemarksFlag}
                              id="remarks"
                              label="Remarks"
                              fullWidth
                              multiline
                              rows={4}
                              margin="normal"
                              inputProps={{ maxLength: 250 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => maintenanceEventChange(e, 'remarks', e.target.value, index)}
                              value={event.remarks}
                              error={error && error[index] && error[index].remarks_airframe ? true : false}
                              helperText={error && error[index] && error[index].remarks_airframe ? <span style={{ color: '#f44336' }}>{error[index].remarks_airframe}</span> : ''}
                              onFocus={(e) => error && error[index] && error[index].remarks_airframe ? updateAirframeErrorField('remarks_airframe', '', index) : e.preventDefault()}
                              variant="outlined"
                            />
                          </Grid> : null
                      }
                    </Fragment> :
                    <Fragment>
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                        label={
                          <p>TSN at LSV
                            <Tooltip
                              title="Time Since New at Last Shop Visit"
                              arrow
                              placement="top"
                            >
                              <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                            </Tooltip>
                          </p>
                        }
                        value={event.tsn_at_lsv ? `${event.tsn_at_lsv} FH` : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                        label={
                          <p>CSN at LSV
                            <Tooltip
                              title="Cycles Since New at Last Shop Visit"
                              arrow
                              placement="top"
                            >
                              <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                            </Tooltip>
                          </p>
                        }
                        value={event.csn_at_lsv ? `${event.csn_at_lsv} FC` : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='LSV as of Date' value={event.lsv_date ? moment(event.lsv_date).format(displayDateFormatShort) : '--'} />
                      {
                        ((getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) &&
                          getLocalStorageInfo().user.account_type && getLocalStorageInfo().user.account_type == 'Acumen') ?
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={<NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{event.cost}</NumericalLabel>} />
                          : null
                      }
                      {
                        getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1 ?
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Remarks" value={event.remarks ? event.remarks : '--'} />
                          : null
                      }

                    </Fragment>
                  }
                </Grid>
              </Grid>
            </Fragment>
          )
        }
      </div>
    </Paper>
  )
}
export default AirframeHlaIntermediate;
