import React, { Fragment, useState } from 'react';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableRow, TableCell, TextField } from '@material-ui/core';
import { TableListComp } from '../../../shared_elements';
import { getCurrencyFormat } from '../../../utils';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { permissionCheckFn } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const FbvProjection = ({ prevMntCond, logResult, minYear, maxYear }) => {
  const [modal, setModal] = useState(false);
  const [filter_start, setFilterStart] = useState(null);
  const [filter_end, setFilterEnd] = useState(null);
  const [mode, setMode] = useState('list')
  let series = [];
  const getPlots = (projection, returnType) => {
    let plotOptions = {}, plotData = [];
    if (filter_start && filter_end) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) >= filter_start && key.slice(0, 4) <= filter_end)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    } else if (filter_start) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) >= filter_start)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    } else if (filter_end) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) <= filter_end)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    } else {
      plotOptions = projection
    }
    Object.keys(plotOptions).map(year => {
      let d = [];
      d.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
      d.push(plotOptions[year]);
      plotData.push(d);
    });
    if (returnType === 'plots') {
      return plotOptions
    }
    return plotData
  }
  if (logResult.future_value && (logResult.is_full_life_maintenance_condition != 1)) {
    series = [...series,
    {
      name: 'HL BV at 0% Annual Inflation',
      data: getPlots(logResult.future_value),
    }
    ]
  }
  if (logResult.inflated_future_value && (logResult.is_full_life_maintenance_condition != 1)) {
    series = [...series,
    {
      name: `HL BV at ${logResult.escalation_percentage}% Annual Inflation`,
      data: getPlots(logResult.inflated_future_value),
    }
    ]
  }
  if (logResult.is_full_life_maintenance_condition && logResult.full_life_future_values) {
    series = [...series,
    {
      name: 'FL BV at 0% Annual Inflation',
      data: getPlots(logResult.full_life_future_values),
    }
    ]
  }
  if (logResult.is_full_life_maintenance_condition && logResult.full_life_inflated_future_value) {
    series = [...series,
    {
      name: `FL BV at ${logResult.escalation_percentage}% Annual Inflation`,
      data: getPlots(logResult.full_life_inflated_future_value),
    }
    ]
  }

  const options = {
    chart: {
      type: 'line',
      marginTop: 60,
      zoomType: 'x',
    },
    exporting: permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'U' }) ? {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
                trackActivity('Report Exported', {
                  file_type: 'PNG',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
                trackActivity('Report Exported', {
                  file_type: 'SVG',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
                trackActivity('Report Exported', {
                  file_type: 'PDF',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            }
          ]
        }
      }
    } : '',
    credits: {
      enabled: false,
      style: {
        fontSize: 12
      },
      position: {
        align: 'left',
        x: 20,
        y: -380
      }
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Projection Year(s)'
      }
    },
    plotOptions: { area: { fillOpacity: 0.3 } },
    tooltip: {
      formatter: function () {
        return 'The value as of <b>' + moment(this.x).format('MMM YYYY') +
          '</b> is <b>US$' + this.y.toFixed(3) + ' m</b>';
      }
    },
    yAxis: {
      title: {
        text: 'US$ m'
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(4);
        }
      }
    },
    series: series
  }
  const getHeadColumn = (label) => {
    return (
      <table style={{ width: '100%' }}>
        <thead>
          <tr><th colSpan={2} align="center">{label}</th></tr>
          <tr>
            <th align="center">@ 0% Inflation</th>
            {logResult.escalation_percentage > 0 ?
              <th align="center">@ {logResult.escalation_percentage}% Inflation</th>
              : null
            }
          </tr>
        </thead>
      </table>
    )
  }
  const getTableHead = (logResult) => {
    let heads = [{ label: 'Date', sortOption: false }]
    if (prevMntCond === 2) {
      heads = [
        ...heads,
        { label: getHeadColumn('Half Life'), sortOption: false },
        { label: getHeadColumn('Full Life'), sortOption: false },
      ]
    } else if (prevMntCond === 1) {
      heads = [
        ...heads,
        { label: 'Full Life @ 0%', sortOption: false },
      ]
      if(logResult.escalation_percentage){
          heads =   [
            ...heads,
            { label: `Full Life @ ${logResult.escalation_percentage}%`, sortOption: false },
          ]
      }
    } else {
      heads =   [
        ...heads,
        { label: 'Half Life @ 0%', sortOption: false },
      ]
      if(logResult.escalation_percentage){
          heads =   [
            ...heads,
            { label: `Half Life @ ${logResult.escalation_percentage}%`, sortOption: false },
          ]
      }
    }
    return heads;
  }
  return (
    <Fragment>
      {!modal ?
        <Button onClick={() => setModal(!modal)} color="primary" variant="contained" size="small">Show</Button> :
        <Dialog
          open={modal}
          onClose={() => setModal(!modal)}
          maxWidth="md"
          fullWidth={true}
          fullScreen={false}
        >
          <DialogTitle id="scroll-dialog-title">
            Future Base Value
          </DialogTitle>
          <DialogContent dividers={true}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>

                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} >

                </Grid>
                <Grid item xs={12} sm={4} m={4} lg={4}>
                  <div className='portfolio-fbv-projection'>
                    <ul className='list-inline flex-centered portfolio-fbv-cta' >
                      <li className='list-inline-item'><ListAltIcon onClick={() => setMode('list')} color={mode === 'list' ? 'primary' : 'default'} /></li>
                      <li className='list-inline-item'><TrendingDownIcon color={mode === "graph" ? 'primary' : 'default'} fontSize='large' onClick={() => setMode('graph')} /></li>
                    </ul>
                  </div>

                </Grid>
              </Grid>
              {
                mode === "list" && (
                  <TableListComp
                    heads={getTableHead(logResult)}
                    data={Object.keys(logResult.future_value).map((label, index) => {
                      let date = new Date(label.slice(0, 4), `${label.slice(4) - 1}`);
                      if (prevMntCond == 2) {
                        return (
                          <TableRow>
                            <TableCell>{moment(date).format('MMM YYYY')}</TableCell>
                            <TableCell>
                              <table width="100%">
                                <TableRow>
                                  <TableCell  style={{borderBottom:'none',textAlign:'center'}}>
                                    <NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.future_value[label]}</NumericalLabel> m
                                  </TableCell>
                                  {logResult && logResult.inflated_future_value && Object.keys(logResult.inflated_future_value).length ?
                                    <TableCell style={{borderBottom:'none',textAlign:'center'}} ><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.inflated_future_value[label]}</NumericalLabel> m</TableCell> : null
                                  }
                                </TableRow>
                              </table>
                            </TableCell>
                            <TableCell>
                              <table width="100%">
                                <TableRow>
                                <TableCell style={{borderBottom:'none',textAlign:'center'}}>
                                  {logResult && logResult.full_life_future_values && Object.keys(logResult.full_life_future_values).length ?
                                   <><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.full_life_future_values[label]}</NumericalLabel> m </>:<>-</>
                                  }
                                 </TableCell>

                                  {logResult && logResult.full_life_inflated_future_value && Object.keys(logResult.full_life_inflated_future_value).length ?
                                    <TableCell style={{borderBottom:'none',textAlign:'center'}}>{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.full_life_inflated_future_value[label]}</NumericalLabel>} m</TableCell> :
                                     <TableCell style={{borderBottom:'none',textAlign:'center'}}>-</TableCell>
                                  }
                                </TableRow>
                              </table>
                            </TableCell>
                          </TableRow>
                        )
                      } else if (prevMntCond == 1) {
                        return (
                          <TableRow>
                            <TableCell style={{ width: '120px' }}>{moment(date).format('MMM YYYY')}</TableCell>
                            <TableCell style={{ width: '120px' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.full_life_future_values[label]}</NumericalLabel> m</TableCell>
                            {logResult && logResult.full_life_inflated_future_value && Object.keys(logResult.full_life_inflated_future_value).length ?
                              <TableCell style={{ width: '120px' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.full_life_inflated_future_value[label]}</NumericalLabel> m</TableCell> : null
                            }
                          </TableRow>
                        )
                      } else {
                        return (
                          <TableRow>
                            <TableCell style={{ width: '120px' }}>{moment(date).format('MMM YYYY')}</TableCell>
                            <TableCell style={{ width: '120px' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.future_value[label]}</NumericalLabel> m</TableCell>
                            {logResult && logResult.inflated_future_value && Object.keys(logResult.inflated_future_value).length ?
                              <TableCell style={{ width: '120px' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.inflated_future_value[label]}</NumericalLabel> m</TableCell> : null
                            }

                          </TableRow>
                        )
                      }
                    })}
                  />
                )
              }
              {
                mode === 'graph' && (
                  <div style={{ clear: 'both' }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </div>
                )
              }

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(!modal)} color="primary" variant="outlined" size="small">Close</Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment>
  )
}
export default FbvProjection;
