import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography, Tooltip, Button } from '@material-ui/core'
import { RevisionHistory, ExportManu } from '../../../shared_elements'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';

const LevBreadCrumbs = ({ match,skeletonLoader, leaseEnCrud, changeLogStatus, exportResultApi }) => {
    return (
        <div className='breadcrumbs-section flex-centered '>
            <Breadcrumbs>
                <Link to="/logs">Logs</Link>
                <Typography color="textPrimary">Lease Encumbered Value (LEV)</Typography>
            </Breadcrumbs>
            {match.params.id  && !skeletonLoader ?
                <ul className="list-inline utils-cta flex-centered">
                    <li className="list-inline-item">
                        <Tooltip title="Lock/Unlock" arrow>
                            {leaseEnCrud.lock_status ? <LockIcon onClick={() => changeLogStatus({ log_id: match.params.id, is_locked: false })} color="primary" fontSize="small" /> : <LockOpenIcon onClick={() => changeLogStatus({ log_id: match.params.id, is_locked: true })} color="primary" fontSize="small" />}
                        </Tooltip>
                    </li>
                    <li className="list-inline-item">
                        <Tooltip title="Revision History" arrow>
                            <RevisionHistory
                                url="audit/valuation/vtutilitylogs/"

                                queryParams={{ action: 1, object_id: leaseEnCrud.id }}
                                buttonType={true}
                                component={true}
                                exportRevisionHistory={false}
                                exportQueryparam={{ action: 1, object_id: leaseEnCrud.id, download: 'xls' }}
                                moduleType={"lev"}
                            />
                        </Tooltip>
                    </li>
                    {/* <li className="list-inline-item">
                        <Button color="primary" variant="outlined" size='small'>Request Signed Report</Button>
                    </li> */}
                    <li className="list-inline-item">
                        <ExportManu
                            variant="contained"
                            title="Export"
                            files={[
                                { title: 'PDF', extension: 'pdf', key: '' },
                                { title: "Docx", extension: 'docx', key: '' },
                                { title: 'Excel', extension: 'xls' }
                            ]}
                            exportReportFn={(file) => exportResultApi(file)}
                        />
                    </li>
                </ul> : null
            }

        </div>
    )
}

export default LevBreadCrumbs