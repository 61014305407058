import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { withSnackbar } from 'notistack';
import { Paper, Grid, Typography, Breadcrumbs } from '@material-ui/core';
import { PageLoader } from '../../../shared_elements';
import { permissionCheckFn, getLocalStorageInfo } from '../../../utils';
import baseValueIcon from '../../../assets/images/bv_icon.svg'
import futureValueIcon from '../../../assets/images/fv_icon.svg'
import hlhtAdjIcon from '../../../assets/images/hla_icon.svg'
import leaseRentalIcon from '../../../assets/images/lease_rental_icon.svg'
import leaseEncumbIcon from '../../../assets/images/lease_encumbered_value.svg'
import portfolioIcon from '../../../assets/images/portfolio_black.svg';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class CreateLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
    }
  }
  cardPermissionAccess = (cardType, cardId, url = '',module='') => {
    if (permissionCheckFn({ secondaryKey: cardType, keyIndex: 'C' })) {
      this.props.history.push(url);
    } else {
      this.props.enqueueSnackbar('You are not subscribed to ' +module +', please contact support@sparta.aero to subscribe', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  }
  render() {
    const { pageLoader, licenseModal } = this.state
    return (
      <div className="valuation-sections">
        <DeploymentMessage />
        <div className="create-valuation-logs create-valuation-logs-mobile">
          <div className="">
            <Breadcrumbs aria-label="breadcrumb" style={isMobile ? { marginTop: '50px', marginBottom: '10px' } : { marginTop: 'auto', marginBottom: '20px' }}>
              <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                  Logs
                </Typography>
              </Link>
              <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                Asset Value Options
              </Typography>
            </Breadcrumbs>
            <Grid container spacing={3} direction="row" className="flex-not-centered">
              <Grid item xs={6} className="flex-not-centered">
                <Paper className="card active" onClick={(e) => { this.cardPermissionAccess('base_value', 1, 'logs/create?utility_type=1','BV and CMV') }} elevation={0} style={ permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'C' })?null:{opacity:'0.5'}}>
                  <img src={baseValueIcon} alt="" />
                  <h3>Base Value and Current Market Value</h3>
                </Paper>
              </Grid>
              <Grid item xs={6} className="flex-not-centered">
                <Paper className="card active" onClick={(e) => { this.cardPermissionAccess('future_value', 2, 'logs/create?utility_type=2','Future Base Value') }} elevation={0} style={ permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'C' })?null:{opacity:'0.5'}}>
                  <img src={futureValueIcon} alt="" />
                  <h3>Future Base Value</h3>
                </Paper>
              </Grid>
              <Grid item xs={6} className="flex-not-centered">
                <Paper className="card active" onClick={() => this.cardPermissionAccess('blr_mlr', 3, '/blr/create','Base Lease Rates')} elevation={0} style={ permissionCheckFn({ secondaryKey: 'blr_mlr', keyIndex: 'C' })?null:{opacity:'0.5'}}>
                  <img src={leaseRentalIcon} alt=""/>
                  <h3>Base Lease Rates</h3>
                </Paper>
              </Grid>
              <Grid item xs={6} className="flex-not-centered">
                <Paper className="card active" onClick={(e) => { this.cardPermissionAccess('lev', 4, '/lev/create','Lease Encumbered Value') }} style={{ cursor: 'pointer' }} elevation={0} style={ permissionCheckFn({ secondaryKey: 'lev', keyIndex: 'C' })?null:{opacity:'0.5'}}>
                  <img src={leaseEncumbIcon} alt="" />
                  <h3>Lease Encumbered Value (LEV)</h3>
                </Paper>
              </Grid>
              <Grid item xs={6} className="flex-not-centered">
                <Paper className="card acive" onClick={(e) => { this.cardPermissionAccess('hthl', 5, '/half-life/initiate','Maintenance Adjusted Base Value') }} style={{ cursor: 'pointer' }} elevation={0} style={ permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'C' })?null:{opacity:'0.5'}}>
                  <img src={hlhtAdjIcon} alt="" />
                  <h3>Maintenance Adjusted Base Value</h3>
                </Paper>
              </Grid>
              <Grid item xs={6} className="flex-not-centered" >
                <Paper className="card active" onClick={() => this.cardPermissionAccess('portfolio', 6, '/portfolios','Portfolio')} elevation={0} style={ permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'C' })?null:{opacity:'0.5'}}>
                  <img src={portfolioIcon} alt="" style={{ fill: '#000' }} />
                  <h3>Portfolio</h3>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
        {pageLoader ? <PageLoader /> : null}
      </div>
    )
  }
}
export default withSnackbar(CreateLog);