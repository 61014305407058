import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const AircraftAgesBarChart = ({data, categories}) => {
  const options = {
    chart: {
      type: 'column'
    },
    title:{text:''},
    credits:{enabled: false},
    xAxis:{
      categories:categories,
      crosshair:true
    },
    yAxis:{
      min:0,
      title: {
        text: 'In Million(US $)'
      }
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    plotOptions: {
     column: {
       pointPadding: 0.2,
       borderWidth: 0
     }
   },
   tooltip: {
     pointFormat: '{series.name}: <b>US${point.y:.3f} m</b>'
    },
    series: data
  }
  return(
    <div style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default AircraftAgesBarChart;
