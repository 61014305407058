import React from 'react';
import { Tooltip, TableRow, TableCell, IconButton, TextField, InputAdornment } from '@material-ui/core';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import { RevisionHistory } from '../../../shared_elements';
const EngineLLPList = ({item, enginesEdit, llpIndex, onChangeLLP, engineIndex, cyclesRemaining, llpCsnValFlag,updateEngineField }) => {
  const style = {
    color: item.module == "Fan" ? 'rgb(74, 153, 254)' : item.module == 'HPC' ? 'rgb(151, 152, 156)': item.module == 'HPT' ? 'rgb(93, 173, 94)':item.module == "LPT" ? 'rgb(252, 152, 37)':''
  }
  let remainingCycle= cyclesRemaining.filter(item =>  item )
  const cycleRem = item.cycles_limit - item.csn
  let rowStyle  = {}
  if(parseInt(item.cycles_limit) - parseInt(item.csn) < 0 ){
    rowStyle={
      color:'red'
    }
  }
  return(
    <TableRow hover tabIndex={-1} style={rowStyle}  >
      <TableCell style={parseInt(item.cycles_limit) - parseInt(item.csn) < 0 ? rowStyle:style}>{item.module}</TableCell>
      <TableCell style={rowStyle}>{item.description}</TableCell>
      <TableCell style={rowStyle}>
        { enginesEdit && (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) ?
          <TextField
            id="life_limit"
            margin="normal"
            InputLabelProps={{shrink: true}}
            value={item.cycles_limit}
            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value)  ? onChangeLLP(e, 'cycles_limit', e.target.value, llpIndex, engineIndex, 'primary') : e.preventDefault()}}
            error={item.lifeLimitvalFlag ? true :false}
            helperText={item.lifeLimitvalFlag ? 'Please enter Life Limit' : ''}
            onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
            InputProps={{
              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
            }}
            variant="outlined"
          />
          :
          `${item.cycles_limit} FC`}</TableCell>
      <TableCell style={rowStyle}>{`${parseFloat(item.cycles_limit - item.csn)} FC`}</TableCell>
      <TableCell style={rowStyle}>
        { enginesEdit ?
          <TextField
            id="csn"
            margin="normal"
            color={parseInt(item.cycles_limit) - parseInt(item.csn) < 0 ? 'secondary' :''}
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{shrink: true}}
            value={item.csn}
            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onChangeLLP(e, 'csn', e.target.value, llpIndex, engineIndex, 'primary') : e.preventDefault()}}
            error={(parseInt(item.cycles_limit) - parseInt(item.csn) < 0) || item.llpCsnValFlag  ? true : false}
            helperText={(parseInt(item.cycles_limit) - parseInt(item.csn) < 0) || item.llpCsnValFlag ? 'Please enter valid CSN' :''}
            onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
            InputProps={{
              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
            }}
            variant="outlined"
          /> :
           item.csn ? `${item.csn} FC` : `0 FC`
        }
      </TableCell>
      {
        enginesEdit && (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) ?
        <TableCell>
          <TextField
            id="cost"
            margin="normal"
            InputLabelProps={{shrink: true}}
            value={item.cost}
            inputProps={{maxLength:10}}
            onChange={(e) => {regexConstants.numberWithDotUpto4.test(e.target.value) ? onChangeLLP(e, 'cost', e.target.value  , llpIndex, engineIndex, 'primary') : e.preventDefault()}}
            error={item.llpCostFlag ? true: false}
            helperText={item.llpCostFlag ? "Please enter Cost": ''}
            onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="outlined"
          />
        </TableCell> :
        ((getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) ||
        [13].includes(getLocalStorageInfo().defaultLessor.id)) ?
        <TableCell style={rowStyle}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.cost}</NumericalLabel></TableCell> : null
      }
      <TableCell>
        <RevisionHistory
          url={`audit/valuation/vtenginellplogs/`}
          queryParams={{action:1, object_id:item.id}}
          buttonType={true}
          component={true}
          exportRevisionHistory={true}
          exportQueryparam={{action:1, object_id:item.id, download:'xls'}}
          moduleType={"Primary LLP"}
          exportPermission={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('EXP_PDF') != -1 }
        />
      </TableCell>
    </TableRow>
  )
}
export default EngineLLPList;
