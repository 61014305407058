import React, { Fragment } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
const FbvInputs = ({ valuationCrud, onFieldChange, updateErrorField, error, mode }) => {
  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <TextField
          required
          id="escalation_percentage"
          label='Inflation Rate'
          fullWidth
          margin="normal"
          disabled={mode === 'view'}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={valuationCrud.escalation_percentage}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && parseFloat(e.target.value ? e.target.value : 0) <= 5 ? onFieldChange(e, 'escalation_percentage', e.target.value) : e.preventDefault(); updateErrorField('escalation_percentage', '') }}
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <span >
                <Tooltip
                  title="Value should be between 1.0% to 5.0%"
                  arrow
                  placement="top"
                >
                  <InfoIcon fontSize="small" color="primary" style={{ cursor: 'pointer', fontSize: '16px' }} />
                </Tooltip>
              </span>
            </InputAdornment>,
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          variant="outlined"
          error={error.escalation_percentage ? true : false}
          helperText={error.escalation_percentage ? error.escalation_percentage : ''}
          onFocus={() => updateErrorField('escalation_percentage', '')}
          autoComplete='off'

        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoComplete='off'
            required
            margin="normal"
            id="projection_to"
            label="Projection To"
            format={fieldDateFormat}
            disabled={mode === 'view'}
            fullWidth
            disablePast={true}
            minDate={moment().add(1, 'days')}
            maxDate={valuationCrud.asset_type && valuationCrud.asset_type === 2 ? valuationCrud.base_value_date ? moment(valuationCrud.base_value_date).add(10, 'years').endOf('year') : moment().add(10, 'years').endOf('year') : valuationCrud.date_of_manufacture ? moment(valuationCrud.date_of_manufacture).add(9131.25, 'days') : moment().add(9125, 'days')}
            InputLabelProps={{ shrink: true }}
            onChange={(data, value) => onFieldChange(value, 'projection_to', data ? moment(data).format(backendDateFormat) : data)}
            value={valuationCrud.projection_to}
            error={error.projection_to ? true : false}
            helperText={error.projection_to ? error.projection_to : ''}
            onFocus={() => updateErrorField('projection_to', '')}
            onOpen={() => updateErrorField('projection_to', '')}
            inputVariant="outlined"
            inputProps={{ readOnly: false }}
            placeholder="DD-MM-YYYY"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Fragment>
  )
}
export default FbvInputs;
