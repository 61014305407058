import React, { Fragment, useState } from 'react';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableRow, TableCell } from '@material-ui/core';
import { TableListComp, ExportManu } from '../../../shared_elements';
import { permissionCheckFn, getCurrencyFormat } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const BlrProjection = ({ logResult, filter_start, filter_end }) => {
  const [modal, setModal] = useState(false);
  const [mode, setMode] = useState('list')
  let series = [];
  const getPlots = (projection, returnType) => {
    let plotOptions = {}, plotData = [];
    if (filter_start && filter_end) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) >= filter_start && key.slice(0, 4) <= filter_end)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    } else if (filter_start) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) >= filter_start)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    } else if (filter_end) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) <= filter_end)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    } else {
      plotOptions = projection
    }
    Object.keys(plotOptions).map(year => {
      let d = [];
      d.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
      d.push(plotOptions[year]);
      plotData.push(d);
    });
    if (returnType === 'plots') {
      return plotOptions
    }
    return plotData
  }
  if (logResult.future_blr_value) {
    series = [...series,
    {
      name: 'BLR at 0% Annual Inflation',
      data: getPlots(logResult.future_blr_value),
    }
    ]
  }
  if (logResult.escalation_percentage) {
    series = [...series,
    {
      name: `BLR at ${logResult.escalation_percentage}% Annual Inflation`,
      data: getPlots(logResult.inflated_future_blr_value),
    }
    ]
  }
  const options = {
    chart: {
      type: 'line',
      marginTop: 60,
      zoomType: 'x',
    },
    exporting: permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'U' }) ? {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
                trackActivity('Report Exported', {
                  file_type: 'PNG',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
                trackActivity('Report Exported', {
                  file_type: 'SVG',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
                trackActivity('Report Exported', {
                  file_type: 'PDF',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            }
          ]
        }
      }
    } : '',
    credits: {
      enabled: false,
      style: {
        fontSize: 12
      },
      position: {
        align: 'left',
        x: 20,
        y: -380
      }
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Projection Year(s)'
      }
    },
    plotOptions: { area: { fillOpacity: 0.3 } },
    tooltip: {
      formatter: function () {
        return 'The value as of <b>' + moment(this.x).format('MMM YYYY') +
          '</b> is <b>US$' + this.y.toFixed(3) + ' k</b>';
      }
    },
    yAxis: {
      title: {
        text: 'US$ k'
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(4);
        }
      }
    },
    series: series
  }
  return (
    <Fragment>
      {!modal ? <Button onClick={() => setModal(!modal)} color="primary" variant="contained" size="small">Show</Button> :
        <Dialog
          open={modal}
          onClose={() => setModal(!modal)}
          maxWidth="sm"
          fullWidth={true}
          fullScreen={false}
        >
          <DialogTitle id="scroll-dialog-title">
            <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Grid item xs={12} sm={6} lg={6} >
                Base Lease Rates Projection
              </Grid>
              <Grid item xs={12} sm={6} m={6} lg={6}>
                <div className='portfolio-fbv-projection'>
                  <ul className='list-inline flex-centered portfolio-fbv-cta' >
                    <li className='list-inline-item'><ListAltIcon onClick={() => setMode('list')} color={mode === 'list' ? 'primary' : 'default'} /></li>
                    <li className='list-inline-item'><TrendingDownIcon color={mode === "graph" ? 'primary' : 'default'} fontSize='large' onClick={() => setMode('graph')} /></li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers={true}>
            {mode === 'list' && <div>
              <TableListComp
                heads={[{ label: 'Date', sortOption: false }, { label: '@ 0% Inflation', sortOption: false }, { label: logResult && logResult.escalation_percentage?'@ '+logResult.escalation_percentage+ '% Inflation':null, sortOption: false }]}
                data={Object.keys(logResult.future_blr_value).map((label, index) => {
                  let date = new Date(label.slice(0, 4), `${label.slice(4) - 1}`);
                  return (
                    <TableRow>
                      <TableCell style={{ width: '65px' }}>{moment(date).format('MMM YYYY')}</TableCell>
                      <TableCell style={{ width: '85px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.future_blr_value[label]}</NumericalLabel>} k</TableCell>
                      {logResult && logResult.inflated_future_blr_value && Object.keys(logResult.inflated_future_blr_value).length ?
                        <TableCell style={{ width: '85px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{logResult.inflated_future_blr_value[label]}</NumericalLabel>} k
                        </TableCell>
                        : null
                      }
                    </TableRow>
                  )
                })}

              />
            </div>}
            {
              mode === "graph" &&
              <div style={{ clear: 'both' }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(!modal)} color="primary" variant="outlined" size="small">Close</Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment>
  )
}
export default BlrProjection;
