import React from 'react';
import { Button, TextField, CircularProgress, InputAdornment, IconButton, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';
const SignUpForm = ({onSignUp, handleChange, error, authInfo, formSubmitLoader, resetErrorKey, handleError,}) => {
  const [values, setValues] = React.useState({
    showPassword: false,
    toolTipPassword:false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowToolTip = () => {
    setValues({ ...values, toolTipPassword: !values.toolTipPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return(
    <form onSubmit={(e) => {e.preventDefault();onSignUp()}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl shrink={true}  component="div">
            <FormLabel className="MuiInputLabel-shrink"  component="label">I am a</FormLabel>
            <RadioGroup row aria-label="position" name="position" defaultValue="top">
              <FormControlLabel value="lessor"  control={<Radio checked={authInfo.is_lessor} onChange={(e) => handleChange(true, 'is_lessor')} />} color="primary" label="Lessor/Asset Manager/Airline" />
              <FormControlLabel value="inspector"  control={<Radio checked={!authInfo.is_lessor} onChange={(e) => handleChange(false, 'is_lessor')} />} color="primary" label="Inspector/Servicer/MRO" />
            </RadioGroup>
          </FormControl>
        </Grid>
        { authInfo.is_lessor ?
        <Grid item xs={12} md={6}>
          <TextField
            id="company_name"
            label="Company Name"
            fullWidth
            margin="normal"
            value={authInfo.company_name}
            error={error.company_name ? true:false }
            helperText={error.company_name}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => handleChange(e.target.value, 'company_name')}
            onFocus={(e) => resetErrorKey('company_name')}
          />
        </Grid>:null
        }
        { authInfo.is_lessor ?
        <Grid item xs={12} md={6}>
          <TextField
            id="company_domain"
            label="Website"
            fullWidth
            margin="normal"
            value={authInfo.company_domain}
            error={error.company_domain ? true:false }
            helperText={error.company_domain}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => handleChange(e.target.value, 'company_domain')}
            onFocus={(e) => resetErrorKey('company_name')}
          />
        </Grid>:null
        }
        <Grid item xs={12}>
          <TextField
            id="email"
            label="Email Address (Company)"
            fullWidth
            margin="normal"
            value={authInfo.email}
            error={error.email ? true:false }
            helperText={error.email}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => handleChange(e.target.value, 'email')}
            onFocus={(e) => resetErrorKey('email')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="full_name"
            label="Full Name"
            fullWidth
            margin="normal"
            value={authInfo.full_name}
            error={error.full_name ? true:false }
            helperText={error.full_name}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 20 }}
            onChange={(e) => handleChange(e.target.value, 'full_name')}
            onFocus={(e) => resetErrorKey('full_name')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contact_no"
            label="Mobile Number (Optional)"
            fullWidth
            margin="normal"
            value={authInfo.contact_no}
            error={error.contact_no ? true:false }
            helperText={error.contact_no}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 16 }}
            onChange={(e) => handleChange(e.target.value, 'contact_no')}
            onFocus={(e) => resetErrorKey('contact_no')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="password"
            type={values.showPassword ? 'text' : 'password'}
            label={
              <div>
                Password
                <sup>*</sup>
                <Tooltip
                  style={{ fontSize: "1.1em", verticalAlign:"middle", marginLeft:"5px", cursor:'pointer' }}
                  title={
                    <div>
                      <strong>Password rules</strong>  <br/>
                      <ul className="list-unstyled password-tooltip">
                        <li>Password must contain minimum of 8 characters</li>
                        <li>Password must contain at least 1 digit</li>
                        <li>Password must contain at least 1 uppercase character</li>
                        <li>Password must contain at least 1 special character</li>
                      </ul>
                    </div>
                  }
                  placement="right"
                  open={values.toolTipPassword}
                  onClose={() =>  setValues({ ...values, toolTipPassword: false })}
                  arrow={true}
                >
                  <InfoIcon onClick={handleClickShowToolTip}/>
                </Tooltip>
              </div>
            }
            fullWidth
            margin="normal"
            value={authInfo.password}
            error={error.password ? true:false }
            helperText={error.password}
            InputLabelProps={{shrink: true}}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
              }}
            onChange={(e) => handleChange(e.target.value, 'password')}
            onFocus={(e) => resetErrorKey('password')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="confirm_password"
            type={values.showPassword ? 'text' : 'password'}
            label="Confirm Password"
            fullWidth
            margin="normal"
            value={authInfo.confirm_password}
            error={error.confirm_password ? true:false }
            helperText={error.confirm_password}
            InputLabelProps={{shrink: true}}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
              }}
            onChange={(e) => handleChange(e.target.value, 'confirm_password')}
            onFocus={(e) => resetErrorKey('confirm_password')}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display:'none'}}>
          <TextField
            id="referral_code"
            label="Referral Code (Optional)"
            fullWidth
            margin="normal"
            value={authInfo.referral_code}
            error={error.referral_code ? true:false }
            helperText={error.referral_code}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => handleChange(e.target.value, 'referral_code')}
            onFocus={(e) => resetErrorKey('referral_code')}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="text-center" style={{marginTop:'10px'}}>
            <Button style={{width:'120px'}} variant="contained" color="primary" type="submit" disabled={formSubmitLoader}>{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Sign Up' }</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
export default SignUpForm;
