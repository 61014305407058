import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import BaseValue from './containers/BaseValue'
export const bvFbvRoutes = [
  {
    path:'/logs/create',
    component:pageLayoutHoc(userRoleBaseAccessHoc(BaseValue,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'BaseValue'
  },
  {
    path:'/logs/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(BaseValue,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'BaseValue'
  }
]
