export const portfolioCrud = {
  name:'',
  report_date:null,
  utility_type:1,
  inflation_rate:'',
  projection_to:null,
  dom_format:'YYYY-MM-DD',
  remarks:'',
  file:null
}
export const portfolioHd = [
    { id:'id', label: 'ID', sortOption: false },
    { id:'name', label: 'Name', sortOption: true },
    { id:'utility_type', label: 'Utility Type', sortOption: true },
    { id:'assets_count', label: 'Asset Count', sortOption: true },
    { id:'report_date', label: 'Report Date', sortOption: true },
    { label: 'Total BV', sortOption: false },
    { label: 'Total CMV', sortOption: false },
    { label: 'Remarks', sortOption: false },
    { id:'status', label: 'Status', sortOption: true },
    { label: 'Actions', sortOption: false }
]
export const assetBVCMVHd = [
    { label: 'Asset', sortOption: false },
    { label: 'Airline', sortOption: false },
    { label: 'DOM', sortOption: false },
    { label: 'Engine Type', sortOption: false },
    { label: 'Engine S/N', sortOption: false },
    { label: 'MTOW', sortOption: false },
    { label: 'MODS', sortOption: false },
    { label: 'CMV', sortOption: false },
    { label: 'BV', sortOption: false },
    { label: 'Action', sortOption: false }
];

export const portfolioFilterOps = {
  name: {
    inputType: 'text',
    placeholder: 'Search by Name',
    title:'Name'
  },
  report_date: {
    inputType: 'date',
    title: 'Report Date',
    firstKey:'date_from',
    lastKey:'date_to',
    range:true
  },
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Status',
    options: [{label:'Processing', value:'0'}, {label:'Completed', value:'1'}],
    labelKey: 'label',
    valueKey: 'value',
    title:'Status',
    showDropdown:true,
    multiple:true
  },
};
export const portfolioBVFilterOps = {
  serial_number: {
    inputType: 'text',
    placeholder: 'Search by Serial Number',
    title:'Serial Number'
  },
  aircraft_type: {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    title:'Aircraft Type',
    showDropdown:true,
    multiple:true
  }
};
export const portfolioFBVFilterOps = {
  serial_number: {
    inputType: 'text',
    placeholder: 'Search by Serial Number',
    title:'Serial Number'
  },
  aircraft_type: {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    title:'Aircraft Type',
    showDropdown:true,
    multiple:true
  }
};
export const errorCode = {
  name: {
    0:'',
    1:'Please enter Name'
  },
  nameObj: {
    required:true,
  },
  report_date: {
    0:'',
    1:'Please enter Report Date'
  },
  report_dateObj: {
    required:true,
  },

  projection_to: {
    0: '',
    1: 'Pease enter Projection Date',
  },
  projection_toObj: {
    required:true
  },
  inflation_rate: {
    0:'',
    1:'Please enter Inflation Rate'
  },
  inflation_rateObj: {
    required:true
  },
}
