import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from '../../../constants/regEx';
const EngineMods = ({item, mode, index, onChangeEngineMods}) => {
    return(
        <TableRow>
            <TableCell>
                <TextField
                    id="name"
                    placeholder='Mod name'
                    fullWidth
                    disabled={mode === 'view' ? true : false}
                    margin="none"
                    inputProps={{ maxLength: 40 }}
                    InputLabelProps={{ shrink: true }}
                    value={item.name?item.name:''}
                    onChange={(e) => onChangeEngineMods(e, 'name', e.target.value, index, 'edit')}
                    variant="outlined"
                />
            </TableCell>
            <TableCell>
                <TextField
                    id="value"
                    fullWidth
                    disabled={mode === 'view' ? true : false}
                    margin="none"
                    inputProps={{ maxLength: 40 }}
                    InputLabelProps={{ shrink: true }}
                    value={item.value?item.value:''}
                    onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onChangeEngineMods(e, 'value', e.target.value, index, 'edit') : e.preventDefault() }}
                    variant="outlined"
                />
            </TableCell>
            {mode !== 'view' ? 
                <TableCell>
                    <DeleteOutlineIcon style={{cursor:'pointer'}} onClick={(e) => onChangeEngineMods(e, null,null, index, 'delete')} fontSize="small" color="secondary" />
                </TableCell>:null
            }
        </TableRow>
    )
}
export default EngineMods;