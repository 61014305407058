import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { ValuationOutPlaceHolder, MaintenaceCondition, BasicDetail } from '../../Elements'
import { LeaseDetail, LevOutput, LevBreadCrumbs } from '../components';
import EditIcon from '@material-ui/icons/Edit';
import { getAircraftTypesAc, getEngineTypesAc, getLesseeListAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { getLevLogApi, changeLogStatusApi, exportResultApi, addEditLevLogApi } from '../apiServices';
import { getFleetAssetsApi, getAircraftModsApi, getMntEventsApi, getEnginePRSVEventsApi } from '../../Elements/apiServices';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { checkApiStatus, removeEmptyKey } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { levCrud, levErrorCode } from '../'
import { PageLoader } from '../../../shared_elements';
import * as actions from '../../../shared_elements/actions';
class LeaseEncumberedValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: this.props.match.params.id ? false : true,
      pageLoader: false,
      mode: this.props.match.params && this.props.match.params.id ? 'view' : 'add',
      leaseEnCrud: levCrud,
      leaseResult: null,
      error: {},
      mods: [],
      mntGroups: [],
      fleetInfo:[]
    }
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getMntEventsApi = getMntEventsApi.bind(this);
    this.getLevLogApi = getLevLogApi.bind(this);
    this.changeLogStatusApi = changeLogStatusApi.bind(this);
    this.exportResultApi = exportResultApi.bind(this);
    this.addEditLevLogApi = addEditLevLogApi.bind(this);
    this.getEnginePRSVEventsApi = getEnginePRSVEventsApi.bind(this);
    this.getFleetAssetsApi = getFleetAssetsApi.bind(this);
  }
  componentDidMount() {
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.props.getLesseeList();
    this.props.getPlatformConstants();
    this.getFleetAssetsApi()
    .then(response => {
      this.setState({fleetInfo:response});
    })
    if (this.props.match.params && this.props.match.params.id) {
      this.fetchValuationLog('skeletonLoader');
    }
  }
  fetchValuationLog = (loaderType = "pageLoader") => {
    this.setState({ [loaderType]: true });
    this.setState({ error: {} })
    this.getLevLogApi(this.props)
      .then(response => {
        this.setState({ [loaderType]: false });
        if (checkApiStatus(response)) {
          let result = Object.assign({}, response.data.data);
          let data = {};
          data = {
            ...data, ...result.lev, ...result.generic
          }
          this.setState({ leaseEnCrud: data, leaseResult: data, mode: 'view' });
          this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': data.aircraft_type.id });
          this.getAircraftModsApi(this.props, { aircraft_type: data.aircraft_type.id })
            .then(response => {
              this.setState({ mods: response });
            })
          this.getMntEventsApi(this.props, data.aircraft_type.id)
            .then(response => {
              this.setState({ mntGroups: response });
            });
          if (data.is_full_life_maintenance_condition) {
            this.getEnginePRSVEventsApi(this.props, data.aircraft_type.id, data.engine_type.id)
              .then(response => {
                let oldEngines = data.engine_sv_details;
                if (checkApiStatus(response)) {
                  let newEngines = {}
                  if (data.engine_sv_details && data.engine_sv_details.length) {
                    newEngines = {
                      no_of_engines: data.engine_sv_details.length,
                      is_propeller_aircraft: false,
                      engines: response.data.data.engines.map((item, index) => oldEngines[index] ? oldEngines[index] : item)
                    };
                  } else {
                    newEngines = response.data.data;
                  }
                  data = {
                    ...data,
                    mtow_kgs: (data.mtow * 0.4535970244).toFixed(4),
                    engine_sv_details: newEngines,
                  }
                  this.setState({ leaseEnCrud: data, leaseResult: data, mode: 'view' });
                }
              })
          }
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          this.props.history.push('/logs');
        }
      })
  }
  updateErrorField = (keyParam, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: message
      }
    }))
  }
  updateStepErrorField = (keyParam, index) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [index]: {
          ...prevState.error[index],
          [keyParam]: ""
        }
      }
    }))
  }
  updateFormFields = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        [keyParam]: value
      }
    }))
  }
  onChangeSerialNo = (e, keyParam, value) => {
    const { aircraftTypes } = this.props;
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        engine_type: value ? value.engine_type:null,
        mtow_kgs:value ? value.mtow:'',
        mtow:value ?(value.mtow * 2.2046).toFixed(4):'',
        date_of_manufacture:value ? value.date_of_manufacture:null,
        serial_number:value ? value.serial_number:'',
        aircraft_type:value ? aircraftTypes.find(item => item.id === value.aircraft_type.id):null
      }
    }));
    if(value){
      this.getAircraftModsApi(this.props, { aircraft_type: value.aircraft_type.id })
      .then(response => {
        this.setState({ mods: response });
      })
      this.getMntEventsApi(this.props, value.aircraft_type.id)
      .then(response => {
        this.setState({ mntGroups: response })
      })
      this.props.getEngineTypes({ aircraft_type: value.aircraft_type.id, valuation: 'valuation' })
    }else{
      this.setState({ mods: [], mntGroups: [] });
      this.props.clearEngineTypes();
    }
  }

  onFieldChange = (event, keyParam, value) => {
    const { leaseEnCrud } = this.state;
    this.updateFormFields(keyParam, value);
    if (keyParam == "aircraft_type") {
      this.updateFormFields('mods', [])
      this.updateFormFields('engine_type_id', null)
      // this.updateFormFields('maintenance_program', null)
      this.updateFormFields('is_full_life_maintenance_condition', 0)
      this.updateFormFields('engine_sv_details', []);
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
          .then(response => {
            this.setState({ mods: response });
          });
        this.getMntEventsApi(this.props, value.id)
          .then(response => {
            this.setState({ mntGroups: response });
          });
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': value.id });
      }
    }
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(4));
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(4));
      }
    }
    if (keyParam == 'rental_type' && value) {
      if (value.label == "Stepped") {
        this.addStepRentalRow()
      } else {
        this.updateFormFields('step_rentals', [])
      }
    }
    if (keyParam == 'is_full_life_maintenance_condition') {
      if (value) {
        this.getEnginePRSVEventsApi(this.props, leaseEnCrud.aircraft_type.id, leaseEnCrud.engine_type.id)
          .then(response => {
            if (checkApiStatus(response)) {
              this.setState(prevState => ({
                ...prevState,
                leaseEnCrud: {
                  ...prevState.leaseEnCrud,
                  engine_sv_details: response.data.data
                }
              }))
            }
          })
      } else {
        this.updateFormFields('engine_sv_details', [])
        this.updateFormFields('maintenance_program', null)
      }
    }
    if (['aircraft_type', 'engine_type'].includes(keyParam)) {
      this.updateFormFields('is_full_life_maintenance_condition', 0);
      this.updateFormFields('engine_sv_details', null);
    }
    if(keyParam === 'is_global_msn'){
      if(!value){
        this.props.clearEngineTypes();
      }
      this.setState(prevState => ({
        ...prevState,
        leaseEnCrud: {
          ...prevState.leaseEnCrud,
          serial_number: '',
          mtow:'',
          mtow_kgs:'',
          date_of_manufacture:null,
          engine_type:null,
          aircraft_type:null
        }
      }));
    }
  }
  addStepRentalRow = () => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: [...prevState.leaseEnCrud.step_rentals, { start_date: null, end_date: null, rent: '' }]
      }
    }))
  }
  removeStepRentalRow = (index) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: prevState.leaseEnCrud.step_rentals.filter((item, rentalIndex) => rentalIndex !== index)
      }
    }))
  }
  editStepRentalRow = (keyParam, value, index) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: prevState.leaseEnCrud.step_rentals.map((item, rentalIndex) => rentalIndex === index ? { ...item, [keyParam]: value } : item)
      }
    }))
  }
  onChangeEvents = (e, keyParam, value, index) => {
    if (keyParam === 'no_of_engines') {
      this.setState(prevState => ({
        ...prevState,
        leaseEnCrud: {
          ...prevState.leaseEnCrud,
          engine_sv_details: {
            ...prevState.leaseEnCrud.engine_sv_details,
            no_of_engines: value
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        leaseEnCrud: {
          ...prevState.leaseEnCrud,
          engine_sv_details: {
            ...prevState.leaseEnCrud.engine_sv_details,
            engines: prevState.leaseEnCrud.engine_sv_details.engines.map((engine, engineIndex) => engineIndex === index ? { ...engine, [keyParam]: value } : engine)
          }
        }
      }))
    }
  }
  stepRentalValidation(data, leaseEnCrud) {
    let validationInputs = {}
    validationInputs = {
      ...validationInputs,
      start_date: levErrorCode['start_date'][fieldValidation({ ...levErrorCode['start_dateObj'], fieldval: data.start_date })],
      end_date: moment(data.end_date) > moment(leaseEnCrud.lease_end_date) ? 'Please enter End Date less than Lease End Date' : levErrorCode['end_date'][fieldValidation({ ...levErrorCode['end_dateObj'], fieldval: data.end_date })],
      rent: levErrorCode['stepRent'][fieldValidation({ ...levErrorCode['stepRentObj'], fieldval: data.rent })],
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
      return ""
    } else {
      return validationInputs
    }
  }
  createValuationFn = () => {
    const { leaseEnCrud } = this.state;
    let validationInputs = {
      serial_number: levErrorCode['serial_number'][fieldValidation({ ...levErrorCode['serial_numberObj'], fieldval: leaseEnCrud.serial_number })],
      aircraft_type: levErrorCode['aircraft_type'][fieldValidation({ ...levErrorCode['aircraft_typeObj'], fieldval: leaseEnCrud.aircraft_type })],
      base_value_date: (moment(leaseEnCrud.base_value_date) < moment(leaseEnCrud.date_of_manufacture) || moment(leaseEnCrud.base_value_date).isSame(leaseEnCrud.date_of_manufacture)) ? "Report Date cannot be less than or equal to Date of Manufacture" : levErrorCode['base_value_date'][fieldValidation({ ...levErrorCode['base_value_dateObj'], fieldval: leaseEnCrud.base_value_date })],
      engine_type: levErrorCode['engine_type'][fieldValidation({ ...levErrorCode['engine_typeObj'], fieldval: leaseEnCrud.engine_type })],
      lease_start_date: levErrorCode['start_date'][fieldValidation({ ...levErrorCode['start_dateObj'], fieldval: leaseEnCrud.lease_start_date })],
      lease_end_date: levErrorCode['end_date'][fieldValidation({ ...levErrorCode['end_dateObj'], fieldval: leaseEnCrud.lease_end_date })],
      mtow: levErrorCode['mtow'][fieldValidation({ ...levErrorCode['mtowObj'], fieldval: leaseEnCrud.mtow })],
      mtow_kgs: levErrorCode['mtow_kgs'][fieldValidation({ ...levErrorCode['mtow_kgsObj'], fieldval: leaseEnCrud.mtow_kgs })],
      rental_type: levErrorCode['rental_type'][fieldValidation({ ...levErrorCode['rental_typeObj'], fieldval: leaseEnCrud.rental_type })],
      rental_payment_type: levErrorCode['rental_payment_type'][fieldValidation({ ...levErrorCode['rental_payment_typeObj'], fieldval: leaseEnCrud.rental_payment_type })],
      markdown_on_residual_value: !leaseEnCrud.markdown_on_residual_value ? "Please enter Markdown Residual Value" : leaseEnCrud.markdown_on_residual_value > 100 ? "Please enter a value between 0 and 100" : leaseEnCrud.markdown_on_residual_value < 0 ? 'Please enter a value between 0 and 100' : '',
      date_of_manufacture: moment(leaseEnCrud.date_of_manufacture) > moment(leaseEnCrud.base_value_date) ? "Please enter Date of Manufacture less than Report Date" : levErrorCode['date_of_manufacture'][fieldValidation({ ...levErrorCode['date_of_manufactureObj'], fieldval: leaseEnCrud.date_of_manufacture })],
      annual_discount: leaseEnCrud.annual_discount < 4.5 ? 'Annual Discount should be in between 4.5% to 20%' : leaseEnCrud.annual_discount > 20 ? 'Annual Discount should be in between 4.5% to 20%' : '',
      annual_inflation_rate: leaseEnCrud.annual_inflation_rate < 1 ? 'Annual Inflation rate should be in between 1% to 5%' : leaseEnCrud.annual_inflation_rate > 5 ? 'Annual Inflation rate should be in between 1% to 5%' : '',
      maintenance_program: leaseEnCrud.is_full_life_maintenance_condition !== 0 && !Object.keys(leaseEnCrud.maintenance_program).length ? "Please Select Maintenance Program " : '',
      annual_inflation_rate: leaseEnCrud.annual_inflation_rate && leaseEnCrud.annual_inflation_rate.toString().trim().length ? parseFloat(leaseEnCrud.annual_inflation_rate) >= 1 && parseFloat(leaseEnCrud.annual_inflation_rate) <= 5 ? '':'Value should be b/w 1 to 5' :'',
      rent: (leaseEnCrud?.rental_type?.value===2 || leaseEnCrud?.rental_type?.id===2) ? '': levErrorCode['rent'][fieldValidation({ ...levErrorCode['rentObj'], fieldval: leaseEnCrud.rent })],
    };
    if (leaseEnCrud.step_rentals) {
      leaseEnCrud.step_rentals.map((item, index) => {
        validationInputs = {
          ...validationInputs,
          [index]: this.stepRentalValidation(item, leaseEnCrud)
        }
      })
    }
    if(leaseEnCrud.base_value_date){
      validationInputs = {
        ...validationInputs,
        base_value_date: moment(leaseEnCrud.base_value_date).isBetween(moment().subtract(1,'year').subtract(1, 'day'), moment().endOf('year')) ? '':`Value as of date should be in current year or previous year`
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
      this.addEditLevLogApi(this.props, leaseEnCrud, this.fetchValuationLog)

    } else {
      let target = Object.keys(removeEmptyKey(validationInputs))[0]
      if (this.props.match.params.id) {
        window.location.href = `/lev/${this.props.match.params.id}#${target}`
      } else {
        window.location.href = `/lev/create#${target}`
      }
      this.setState({ error: validationInputs });
    }
  }
  render() {
    const { pageLoader, fleetInfo, mode, leaseEnCrud, leaseResult, error, mods, mntGroups, skeletonLoader } = this.state;
    const { aircraftTypes, engineTypes, lessee, pltConstants, match } = this.props;
    return (
      <section className="valuation-app-section">
        {pageLoader ? <PageLoader /> : null}
        <LevBreadCrumbs skeletonLoader={skeletonLoader} match={this.props.match} leaseEnCrud={leaseEnCrud} changeLogStatus={(data) => this.changeLogStatusApi(this.props, data)} exportResultApi={(file) => this.exportResultApi(this.props, file, leaseEnCrud)} />
        <Grid container spacing={1}>
          <Grid item md={8} sm={8} xs={12}>
            <Paper className="add-bv-fbv-form">
              <div style={{maxHeight:`${window.innerHeight-195}px`, overflow:'auto', padding:'10px 15px', position:'relative'}}>
                {!leaseEnCrud.lock_status && mode === 'view' && !skeletonLoader ?
                  <span onClick={() => this.setState({ mode: 'edit' })} className="edit-icon">
                    <EditIcon color="primary" fontSize="small" /></span> : null
                }
                <form className="form">
                  <Grid container spacing={1}>
                    <BasicDetail
                      cardType={'lev'}
                      mode={mode}
                      crudData={leaseEnCrud}
                      onFieldChange={this.onFieldChange}
                      onChangeSerialNo={this.onChangeSerialNo}
                      updateErrorField={this.updateErrorField}
                      error={error}
                      mods={mods}
                      aircraftTypes={aircraftTypes}
                      engineTypes={engineTypes}
                      lessees={lessee}
                      fleetInfo={fleetInfo}
                    />
                    <MaintenaceCondition
                      mode={mode}
                      error={error}
                      maintenaceObj={leaseEnCrud}
                      onFieldChange={this.onFieldChange}
                      updateErrorField={this.updateErrorField}
                      onChangeEvents={this.onChangeEvents}
                      mntGroups={mntGroups}
                    />
                    <LeaseDetail
                      mode={mode}
                      crudData={leaseEnCrud}
                      onFieldChange={this.onFieldChange}
                      updateErrorField={this.updateErrorField}
                      error={error}
                      pltConstants={pltConstants}
                      addStepRentalRow={this.addStepRentalRow}
                      removeStepRentalRow={this.removeStepRentalRow}
                      editStepRentalRow={this.editStepRentalRow}
                      updateStepErrorField={this.updateStepErrorField}
                      match={match}
                    />
                    
                  </Grid>
                </form>
              </div>
              <Paper className="fixed-compute-cta">
                <Grid alignItems='center' container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={mode !== 'add' ? true : false}
                        required
                        margin="normal"
                        id="base_value_date"
                        placeholder="DD-MM-YYYY"
                        label="Value as Of"
                        format={fieldDateFormat}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        maxDate={moment().add(1, 'years')}
                        minDate={moment().subtract(1, 'years')}
                        onChange={(data, value) => this.onFieldChange(value, 'base_value_date', data ? moment(data).format(backendDateFormat) : data)}
                        value={leaseEnCrud.base_value_date}
                        error={error.base_value_date ? true : false}
                        helperText={error.base_value_date ? error.base_value_date : ''}
                        onFocus={() => this.updateErrorField('base_value_date', '')}
                        inputVariant="outlined"
                        inputProps={{ readOnly: false }}
                        autoComplete='off'
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {mode !== 'view' ?
                    <Grid style={{ position: 'relative' }} item xs={12} md={8}>
                      <ul className="list-inline submit-bv-fbv">
                        <li className="list-inline-item">
                          <Button onClick={this.createValuationFn} color="primary" variant="contained">Compute</Button>
                        </li>
                        <li className="list-inline-item">
                          <Button onClick={() => { this.props.match.params.id ? this.fetchValuationLog() : this.props.history.push('/logs') }} color="primary" variant="outlined">Cancel</Button>
                        </li>
                      </ul>
                    </Grid> : null
                  }
                </Grid>
              </Paper>
            </Paper>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <div style={{ maxHeight: `${window.innerHeight - 100}px`, overflow: 'auto' }}>
              {this.props.match.params && this.props.match.params.id && leaseResult && Object.keys(leaseResult).length ?
                <LevOutput logResult={leaseResult} /> :
                <ValuationOutPlaceHolder />
              }
            </div>
          </Grid>
        </Grid>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: () => dispatch(getAircraftTypesAc(ownProps, { valuation: 'valuation' })),
    getLesseeList: () => dispatch(getLesseeListAc(ownProps, { valuation: 'valuation' })),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['lev_rental_type', 'lev_rental_payment_type'] })),
    clearEngineTypes: () => dispatch({ type: actions.ENGINE_TYPES, payload: [] })
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(LeaseEncumberedValue))
