import axios from 'axios';
import config from '../config';
import Cookies from 'universal-cookie'
import { getGlobalCookie, eraseGlobalCookie } from './';
const cookies = new Cookies();
var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = config.api.networkInterface;
axiosInstance.interceptors.request.use(function (config) {
  let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
    if(lessorAccess){
      config['headers']['Authorization'] = lessorAccess.access;
    }
    return config;
},function (error) {
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  function (response) {
  if(response.data.statusCode >= 210){
    if(response.data.statusCode == 400){
      if(!window.location.href.includes("/logs")){
        window.location.assign("/logs")
      }
   }else if (response.data.statusCode == 1001) {
     localStorage.clear()
     eraseGlobalCookie('userName')
     cookies.remove('lessorAccess' , {path: "/", domain: "sparta.aero"})
     window.location.assign('/login')
   }else if (response.data.statusCode == 1002) {
     localStorage.clear();
     eraseGlobalCookie('userName')
     cookies.remove('lessorAccess' , {path: "/", domain: "sparta.aero"})
     window.location.assign('/login')
   }else if (response.data.statusCode == 3004) {
     localStorage.clear();
     eraseGlobalCookie('userName')
     cookies.remove('lessorAccess' , {path: "/", domain: "sparta.aero"})
     window.location.assign('/login')
   }else if (response.data.statusCode == 3001 || response.data.statusCode == 3002) {
     if(!window.location.href.includes("/logs") && !window.location.href.includes("/login")){
       window.location.assign("/logs")
     }
   }
  }
    // Valid Lessor Acccess

    return response;
  },
  function (error) {
    return error.response;
  }
);
export default axiosInstance;
