import React from 'react';
import moment from 'moment';
import { TableCell, TableRow,Button, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InfoIcon from '@material-ui/icons/Info';
import { displayDateFormatShort } from '../../../constants'
const FleetList = ({item, index, onEdit, onDelete}) => {
    return(
        <TableRow key={index}>
            <TableCell>{item?.serial_number||'--'}</TableCell>
            <TableCell>{item?.aircraft_type?.name||'--'}</TableCell>
            <TableCell>{item?.engine_type?.name||'--'}</TableCell>
            <TableCell>
                {item?.mtow||'--'}
                <Tooltip title={`${(parseFloat(item.mtow)*2.20462).toFixed(4)} Lbs`} arrow><InfoIcon style={{fontSize:'13px'}} /></Tooltip>
            </TableCell>
            <TableCell>{ item?.date_of_manufacture ? moment(item.date_of_manufacture).format(displayDateFormatShort):'--'}</TableCell>
            <TableCell align='right'>
                <ul className='list-inline'>
                    <li style={{cursor:'pointer'}} className='list-inline-item'>
                        <EditIcon onClick={onEdit} color='primary' fontSize='small' />
                    </li>
                    <li style={{cursor:'pointer'}} className='list-inline-item'>
                        <DeleteOutlineIcon onClick={onDelete} color='secondary' fontSize='small' />
                    </li>
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default FleetList;
