import React, { Fragment } from 'react';
import moment from 'moment';
import {  withRouter } from 'react-router-dom';
import { displayDateFormatShort } from '../../../constants';
import { Paper, Grid, Chip } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
const BasicDetail = ({valuationLog, skeletonLoader, location}) => {
  return(
    <Grid container spacing={3}>
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Serial Number' value={valuationLog.serial_number ? valuationLog.serial_number:'--'} />
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Aircraft Type' value={valuationLog.aircraft_type && valuationLog.aircraft_type.name ? valuationLog.aircraft_type.name:'--'} />
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Date of Manufacture' value={valuationLog.date_of_manufacture ? moment(valuationLog.date_of_manufacture).format(displayDateFormatShort):'--'} />
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Utility Type' value={valuationLog.utility_type} />
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Aircraft Max Take Off Weight' value={valuationLog.mtow ? `${valuationLog.mtow.toFixed(4)} Lbs`:'--'} />
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Aircraft Max Take Off Weight' value={valuationLog.mtow ? `${(valuationLog.mtow*0.4535970244).toFixed(4)} Kgs` :'--'} />
      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Engine Type' value={valuationLog.engine_type && valuationLog.engine_type.name ? valuationLog.engine_type.name:'--'} />
      {
        location.pathname.includes('base-value') ? null :   <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Annual Inflation" value={valuationLog.escalation_percentage ? `${valuationLog.escalation_percentage.toFixed(2)} %` : '--'} />
      }
      { valuationLog.mods && valuationLog.mods.length ?
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Modifications/Enhancements' value={valuationLog.mods.map(mod => <Chip size="small" style={{margin:'0 4px 4px 0', fontFamily:'Conv_IBMPlexSans-Medium'}}  label={mod.name} variant="outlined" />)} />:null
      }
      { valuationLog.engine_sv_details && valuationLog.maintenance_program ?
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Maintenance Program' value={valuationLog.maintenance_program && valuationLog.maintenance_program.label ? valuationLog.maintenance_program.label:'--'} />:null
      }
      { valuationLog.aircraft_type && valuationLog.aircraft_type.has_optional_apu ?
        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Status' value={valuationLog.is_apu_disabled ? 'Enabled':'Disabled'} />:null
      }

      { valuationLog.is_full_life_maintenance_condition && Object.keys(valuationLog.engine_sv_details).length ?
        <Grid item md={12} xs={12}>
          <Paper style={{padding:'15px'}} elevation={0}>
            <Grid container spacing={3}>
              { valuationLog.engine_sv_details.map((engine) =>
                <Fragment>
                  <LabelValueCard
                    skeletonLoader={skeletonLoader}
                    xs={6} md={3}
                    label={engine.name}
                    value={!valuationLog.aircraft_type.has_propeller ?
                      `Last PRSV Performed: ${engine.last_performed_sv ? engine.engine_prsv.find(item => item.id === engine.last_performed_sv).event:'No' }`:
                      <span>Last HSI Performed: {engine.last_performed_hsi?engine.engine_hsi.find(item => item.id === engine.last_performed_hsi).event:'No'} <br/> Last OVH Performed: {engine.last_performed_sv?engine.engine_prsv.find(item => item.id === engine.last_performed_sv).event:'No'}</span>
                    }
                  />
                </Fragment>
              )}
            </Grid>
          </Paper>
        </Grid>:null
      }

    </Grid>
  )
}
export default  withRouter(BasicDetail);
