import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import SerialNoComp from './SerialNoComp'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { getLocalStorageInfo } from '../../../utils';

const BasicDetail = ({ fleetInfo, cardType, mode, lessees, aircraftTypes, engineTypes, mods, crudData, error, onFieldChange, onChangeSerialNo, updateErrorField }) => {
  let selectedModIds = crudData.mods ? crudData.mods.map(item => item.id) : []
  return (
    <Fragment>
      {crudData.asset_type && crudData.asset_type === 2 ? null:
        <Grid item md={4}>
          {(() => {

            let options =  [{id:1, name:'Global Fleet'}, {id:2, name:'Lessor Fleet'}]
            let optionValue = crudData.is_global_msn ? options.find(item => item.id === crudData.is_global_msn) :null
            return(
              <Autocomplete
                disabled={mode !== 'add' ? true : false}
                options={options}
                getOptionLabel={option => option.name}
                id="is_global_msn"
                value={optionValue}
                onChange={(e, value) => { onFieldChange(e, 'is_global_msn', value ? value.id:null);}}
                renderInput={params => <TextField {...params} label="Fleet Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
              />
            )
          })()}
        </Grid>
      }
      <Grid item md={4}>
        <SerialNoComp 
          crudData={crudData}
          mode={mode}
          error={error}
          onFieldChange={onFieldChange}
          onChangeSerialNo={onChangeSerialNo}
          updateErrorField={updateErrorField}
        />
      </Grid>
      { crudData.asset_type && crudData.asset_type === 2 ?null:
        <Grid item md={4} xs={12}>
          <Autocomplete
            disabled={mode !== 'add' ? true : crudData.is_global_msn ? true:false}
            options={aircraftTypes}
            getOptionLabel={option => option.name}
            id="aircraft_type"
            value={crudData.aircraft_type ? crudData.aircraft_type : null}
            onChange={(e, value) => { onFieldChange(e, 'aircraft_type', value); updateErrorField('aircraft_type', '') }}
            renderInput={params => <TextField required error={error.aircraft_type ? true : false} helperText={error.aircraft_type ? error.aircraft_type : ''} onFocus={() => updateErrorField('aircraft_type', '')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          />
        </Grid>
       }
      { crudData.asset_type && crudData.asset_type === 2 ?null:
        <Grid item md={4} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required
              disabled={((mode === 'view') || (cardType == 'lev' && mode !== 'add')) ? true : false}
              margin="normal"
              id="date_of_manufacture"
              label="Date of Manufacture"
              placeholder="DD-MM-YYYY"
              format={fieldDateFormat}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ readOnly: false }}
              minDate={moment().subtract(25, 'years')}
              maxDate={(cardType != 'lev' ) ? moment().add(2, 'years').endOf('year') : moment() }
              onChange={(data, value) => onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat) : data)}
              value={crudData.date_of_manufacture}
              error={error.date_of_manufacture ? true : false}
              helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
              onFocus={() => updateErrorField('date_of_manufacture', '')}
              onOpen={() => updateErrorField('date_of_manufacture', '')}
              inputVariant="outlined"
              autoComplete='off'
            />
          </MuiPickersUtilsProvider>
        </Grid>
      }
      <Grid item md={6} xs={12}>
        <Autocomplete
          disabled={mode !== 'add' ? true : false}
          options={engineTypes}
          getOptionLabel={option => option.name}
          id="engine_type"
          value={crudData.engine_type ? crudData.engine_type : null}
          onChange={(e, value) => { onFieldChange(e, 'engine_type', value); updateErrorField('engine_type', '') }}
          renderInput={params => <TextField required error={error.engine_type ? true : false} helperText={error.engine_type ? error.engine_type : ''} onFocus={() => updateErrorField('engine_type', '')} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>
      { crudData.asset_type && crudData.asset_type === 2 ? null:
        <Fragment>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete='off'
              required
              id="mtow"
              label='Max Take Off Weight'
              fullWidth
              disabled={((mode === 'view') || (cardType == 'lev' && mode !== 'add')) ? true : false}
              margin="normal"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.mtow}
              onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow', e.target.value) : e.preventDefault(); updateErrorField('mtow', '') }}
              error={error.mtow ? true : false}
              helperText={error.mtow ? error.mtow : ''}
              onFocus={() => { updateErrorField('mtow', ''); updateErrorField('mtow_kgs', ''); }}
              InputProps={{
                endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete='off'
              required
              id="mtow_kgs"
              label='Max Take Off Weight'
              fullWidth
              disabled={((mode === 'view') || (cardType == 'lev' && mode !== 'add')) ? true : false}
              margin="normal"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.mtow_kgs}
              onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow_kgs', e.target.value) : e.preventDefault(); updateErrorField('mtow_kgs', '') }}
              error={error.mtow_kgs ? true : false}
              helperText={error.mtow_kgs ? error.mtow_kgs : ''}
              onFocus={() => { updateErrorField('mtow_kgs', ''); updateErrorField('mtow', ''); }}
              InputProps={{
                endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
        </Fragment>
      }
      {cardType === 'lev' ?
        <Fragment>
          <Grid item xs={12} md={6}>
            <TextField
              id="registration_number"
              label='Registration'
              fullWidth
              margin="normal"
              disabled={mode === 'view' ? true : false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.registration_number}
              onChange={(e) => { onFieldChange(e, 'registration_number', e.target.value); updateErrorField('registration_number', '') }}
              error={error.registration_number ? true : false}
              helperText={error.registration_number ? error.registration_number : ''}
              onFocus={() => updateErrorField('registration_number', '')}
              variant="outlined"
              autoComplete='off'
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              options={lessees}
              disabled={mode === 'view' ? true : false}
              getOptionLabel={option => option.name}
              id="lessee_id"
              value={crudData.lessee}
              onChange={(e, value) => { onFieldChange(e, 'lessee', value); updateErrorField('lessee_id', '') }}
              renderInput={params => <TextField error={error.lessee_id ? true : false} helperText={error.lessee_id ? error.lessee_id : ''} onFocus={() => updateErrorField('lessee_id', '')} {...params} label="Operator / Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            />
          </Grid>
        </Fragment> : null
      }
      { crudData.asset_type && crudData.asset_type === 2 ? null:
        <Grid item xs={12} md={12}>
          <Autocomplete
            disabled={mode === 'view' ? true : false}
            options={selectedModIds ? mods.filter(mod => !selectedModIds.includes(mod.id)) : mods}
            getOptionLabel={option => option.name}
            id="mods"
            value={crudData.mods ? crudData.mods : []}
            onChange={(e, value) => onFieldChange(e, 'mods', value)}
            renderInput={params => <TextField {...params} label="Modifications/Enhancements" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            multiple={true}
            filterSelectedOptions={true}
            openOnFocus={true}
          />
        </Grid>
      }
    </Fragment>
  )
}
export default BasicDetail;
