import React from 'react';
import {Card,Link,CardContent} from "@material-ui/core";
import config from "../../config";
import notFoundIcon from "../../assets/images/404_icon.png";

export default function PageNotFound() {
  return (
    <div className="page-not-found">
      <Card className="main">
        <CardContent>
          <img src={notFoundIcon} alt="Not Found Icon"/>
          <p>Click Here to Redirect to
            <Link href='/'> Home</Link>.
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
