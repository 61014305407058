import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import config from '../../../config';
const AuthHeader = ({match}) => {
  return(
    <Fragment>
      <section className="auth-sec-hd">
        <Container maxWidth="lg">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={3}>
              <img className="auth-logo" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/sparta_logo.svg" alt="" />
            </Grid>
            <Grid item xs={9}>
            <ul className="list-inline">
                <li className="list-inline-item">
                  <a href={`${config.domain.subDomian}signup#features`} target="_blank">Features</a>
                </li>
                { !isMobile ?
                  <li className="list-inline-item">
                    Don’t have an account ?
                  </li>:null
                }
                <li className="list-inline-item sign-in">
                  <a className="sign-in-link" href={`${config.domain.subDomian}signup`} target="_blank">{'Sign Up'}</a>
                </li>
                <li className="list-inline-item">
                  <p className="text-right buy-now-btn">
                 <a href={`${config.domain.subDomian}pricing`} target="_blank" style={{marginLeft: '0px', borderRadius: '15px', border: '1.5px solid #fff'}}>Buy Now</a>
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fragment>
  )
}
export default withRouter(AuthHeader);
