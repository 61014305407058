import React from 'react';
import { Grid, Avatar, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import greenTickIcon from '../../../assets/images/greenTick.svg'
import deactivateIcon from '../../../assets/images/deactivae.svg'
import { getLocalStorageInfo } from '../../../utils';
import config from '../../../config';
const User = ({item, onChangeRole}) => {
  return(
    <Grid item xs={3}>
      <div className="user-card text-center">
        {getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.slug && getLocalStorageInfo().user.slug !== item.slug ?
          <ul className="list-inline">
            <li className="list-inline-item">
              <Tooltip title="View" arrow>
                <VisibilityIcon onClick={() => window.open(config.domain.subDomian+'users/manage/'+item.slug,'_blank')} color="primary" fontSize="small" />
              </Tooltip>
            </li>
            <li className="list-inline-item">
              <Tooltip title="Change Role" arrow>
                <CreateIcon onClick={onChangeRole} color="primary" fontSize="small" />
              </Tooltip>
            </li>
          </ul>:null
        }
        <span className="user-pic">
          <Avatar src={item.profile_pic} alt={item.last_name}>{item.last_name ? item.last_name.charAt(0) : 'U'}</Avatar>
          <img className="account-status" src={item.is_active ? greenTickIcon:deactivateIcon} alt="icon" />
        </span>
        <div className="user-name-dsi">
          <h3>{`${item.first_name} ${item.last_name}`}</h3>
          <Tooltip title={item.sparta_group}><p>{item.sparta_group.length < 100 ?item.sparta_group:item.sparta_group.slice(0,97)+'...'}</p></Tooltip>
        </div>
        <div className="user-other-info">
          <h6>{item.email}</h6>
        </div>
      </div>
    </Grid>
  )
}
export default User;
