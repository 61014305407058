import React, { Fragment } from 'react';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
const LgBasic = ({permissionFlag, mode, error, crudData, item, itemIndex, updateIndexedAssembly, updateErrorField}) => {
  return(
    <Fragment>
      <Grid item md={4} xs={12}>
        <TextField
          id="tsn"
          label='TSN'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.tsn}
          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? updateIndexedAssembly(e, 'tsn', e.target.value, 'landing_gear', 'lgInfo', itemIndex): e.preventDefault()}}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          required
          id="csn"
          label='CSN'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.csn}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value)? updateIndexedAssembly(e, 'csn', e.target.value, 'landing_gear', 'lgInfo', itemIndex): e.preventDefault()}}
          error={error[itemIndex] && error[itemIndex].csn ? true : false}
          helperText={error[itemIndex] && error[itemIndex].csn ? error[itemIndex] && error[itemIndex].csn : ''}
          onFocus={() => updateErrorField('csn', '', 'lgError', itemIndex)}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            disabled={mode === 'view' || !permissionFlag? true:false}
            margin="normal"
            id="utilization_as_of_date"
            label="Utilization as of Date"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            minDate={crudData.generic.date_of_manufacture ? moment(crudData.generic.date_of_manufacture) : moment().subtract(25, 'years') }
            maxDate={crudData.generic.report_date ? moment(crudData.generic.report_date) : moment()}
            onChange={(data, value) => updateIndexedAssembly(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, 'landing_gear', 'lgInfo', itemIndex)}
            value={crudData.utilization_as_of_date}
            error={error[itemIndex] && error[itemIndex].utilization_as_of_date ? true : false}
            helperText={error[itemIndex] && error[itemIndex].utilization_as_of_date ? error[itemIndex] && error[itemIndex].utilization_as_of_date : ''}
            onFocus={() => updateErrorField('utilization_as_of_date', '', 'lgError', itemIndex)}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Fragment>
  )
}
export default LgBasic;
