import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';

const UserPermission  = ({permissionDetail, permissionValue}) => {
  return (
    <div>
      {
        permissionDetail.true.includes(permissionValue) ?
        <CheckCircleIcon style={{color:'#006400'}} /> :
          permissionDetail.false.includes(permissionValue) ?
          <CancelIcon style={{color:'#8B0000'}} /> :
            <BlockIcon style={{color:'#FF8C00'}} />
      }
    </div>
  )
}
export default UserPermission;
