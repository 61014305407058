import React, { Fragment, useState } from 'react';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableRow, TableCell } from '@material-ui/core';
import { TableListComp } from '../../../shared_elements';
import { getLocalStorageInfo, getCurrencyFormat, checkValuationAdminFn } from '../../../utils';
import { levListPerHd, levListPerHdForValAdmin } from '../'
import { displayDateFormatShort } from '../../../constants';
const LevProjection = ({ payments }) => {
  const [modal, setModal] = useState(false);
  return (
    <Fragment>
      {!modal ? <Button onClick={() => setModal(!modal)} color="primary" variant="contained" size="small">Show</Button> :
        <Dialog
          open={modal}
          onClose={() => setModal(!modal)}
          maxWidth="md"
          fullWidth={true}
          fullScreen={false}
        >
          <DialogTitle id="scroll-dialog-title">
            Lease Rental Payments
          </DialogTitle>
          <DialogContent dividers={true}>
            <div>
              <TableListComp
                heads={checkValuationAdminFn() ? levListPerHdForValAdmin : levListPerHd}
                data={payments.map((item, index) =>
                  <TableRow>
                    <TableCell style={{ width: '120px'}}>{moment(item.date).format(displayDateFormatShort)}</TableCell>
                    <TableCell style={{ width: '120px'}}>{item.payment_number}</TableCell>
                    <TableCell style={{ width: '120px'}}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{item.rent}</NumericalLabel> </TableCell>
                    {
                      checkValuationAdminFn() ?
                        <>
                          <TableCell style={{ width: '220px' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{item.asset_management_expenses}</NumericalLabel> </TableCell>

                          <TableCell style={{ width: '120px' }}> <NumericalLabel>{item.pvf}</NumericalLabel></TableCell>
                        </> : null
                    }

                    <TableCell style={{ width: '120px' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{item.pv_of_rent}</NumericalLabel> </TableCell>
                  </TableRow>
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(!modal)} color="primary" variant="outlined" size="small">Close</Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment>
  )
}
export default LevProjection;
