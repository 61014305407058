import React, { Fragment, Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
class SecondarySideNav extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  componentDidMount(){

  }
  render(){
    const { match } = this.props;
    return(
      <Fragment>
        { match.path.includes('settings') ?
          <Fragment>
            <ul className="list-unstyled secondary-sidenav">
            <li>
                <Link to="/settings/users" className={match.path.includes('settings/users') ? 'active':''}>Users</Link>
              </li>
              <li>
                <Link to="/settings/roles" className={ match.path.includes('settings/roles') ? 'active':''}>Roles</Link>
              </li>
            </ul>
          </Fragment>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(SecondarySideNav);
