import { regexConstants } from '../constants/regEx'
import moment from 'moment';
import config from '../config';
import Cookies from 'universal-cookie'
import { globalPostService, globalGetService } from './globalApiServices';
const cookies = new Cookies();
export function checkApiStatus(response) {
  return (response && response.data && response.data.statusCode >= 200 && response.data.statusCode < 300)
}
export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}
export const getLocalStorageInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo'))
}

export const getFileSize = (fileSize) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0, n = parseInt(fileSize, 10) || 0;
  while (n >= 1024 && ++l)
    n = n / 1024;
  return (n.toFixed(4) + ' ' + units[l]);
}

export const getPltConstantsList = (pltConstants, constantTypes) => {
  let constants = [];
  constantTypes.map(type => {
    if (!pltConstants.find(item => item.type === 'type')) {
      constants = [...constants, type]
    }
  });
  return constants;
}

export const removeEmptyKey = (obj) => {
  for (let keyParam in obj) {
    if (obj[keyParam] === null || obj[keyParam] === undefined || obj[keyParam] === '' || obj[keyParam].length === 0) {
      delete obj[keyParam];
    }
  }
  return obj;
}

export const convertFilterObject = (filter = {}, filterManu = {}) => {
  for (let keyParam in filter) {
    if (typeof filter[keyParam] !== 'string' && Array.isArray(filter[keyParam])) {
      filter = {
        ...filter,
        [keyParam]: filter[keyParam].map(item => item[filterManu[keyParam].valueKey]).join(',')
      }
    }
  }
  return filter;
}
export const permissionCheckFn = (args) => {
  let primaryKey = args.primaryKey ? args.primaryKey : 'valuation'
  if (getLocalStorageInfo()
    && getLocalStorageInfo().user.permission
    && getLocalStorageInfo().user.permission[primaryKey]
    && getLocalStorageInfo().user.permission[primaryKey][args.secondaryKey]
    && getLocalStorageInfo().user.permission[primaryKey][args.secondaryKey].indexOf(args.keyIndex) !== -1) {
    return true
  } else {
    return false
  }
}
export const checkUserTypeFn = () => {
  if (getLocalStorageInfo()
    && getLocalStorageInfo().user
    && getLocalStorageInfo().user.account_type
    && getLocalStorageInfo().user.account_type == "Acumen") {
    return true
  } else {
    return false
  }
}
export const checkValuationAdminFn = () => {
  if (getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id === 46).length) {
    return true
  } else {
    return false
  }
}

export const downloadFileType = (fileData, fileName, fileExtension) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(fileData, fileName + '.' + fileExtension);
  } else {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + '.' + fileExtension);
    document.body.appendChild(link);
    link.click();
  }
}

export const localTimeFn = (timeData) => {
  let utcTime = timeData;
  let offset = moment().utcOffset();
  let localText = moment.utc(utcTime).utcOffset(offset).format("lll");
  return localText;
}
export const dateTransform = (date1, date2 = moment()) => {
  return moment(date2).diff(moment(date1), 'days') + ' Day(s)';
}

export const dateTransformAllSet = (date1, date2 = moment()) => {
  let b = moment(date1);
  let a = moment(date2);
  let years = a.diff(b, 'year');
  b.add(years, 'years');
  let months = a.diff(b, 'months');
  b.add(months, 'months');
  let days = a.diff(b, 'days');
  let hours = a.diff(b, 'hours')
  let mins = a.diff(b, 'minutes')
  if (years > 0) {
    return (years + ' Year(s) ago');
  }
  if (months > 0) {
    return (months + ' Month(s) ago');
  }
  if (days > 0) {
    return (days + ' Day(s) ago');
  }
  if (hours > 0) {
    return (hours + ' Hour(s) ago');
  }
  if (mins > 0) {
    return (mins + ' Min(s) ago');
  } else {
    return 'Just now';
  }
}

export const getNumberFormat = () => {
  return {
    justification: 'L',
    currency: false,
    commafy: true,
    shortFormat: false,
  }
}
export const getNumberDecimalFormat = () => {
  return {
    justification: 'L',
    currency: false,
    commafy: true,
    shortFormat: false,
    precision: 2
  }
}
export const getCurrencyFormat = (params) => {
  let defaults = { currency: 'USD', shortFormat: true, precision: 2, show_currency_text: true };
  let newParams = { ...defaults, ...params }
  return {
    justification: 'L',
    currency: newParams.show_currency_text,
    precision: newParams.precision,
    commafy: true,
    shortFormat: newParams.shortFormat,
    currencyIndicator: newParams.currency,
    shortFormatMinValue: 100000
  }
}
export const getCookie = () => {
  var nameEQ = "mantenanceWarning=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export const getGlobalCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export const eraseGlobalCookie = (name) => {
  cookies.remove(name, { path: "/", domain: "sparta.aero" })
}

export const setGlobalCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export const clearCookie = (name, domain, path) => {
  var date = new Date(1560807962)
  var domain = domain || document.domain;
  var path = path || "/";
  document.cookie = name + "=; expires=" + date + "; domain=" + domain + "; path=" + path;
};
export const checkEnv = () => {
  let baseUrl = config.api.networkInterface;
  let env = ''
  if (baseUrl.includes('api.')) {
    return env = 'api'
  } else if (baseUrl.includes('dev.')) {
    return env = 'dev'
  } else {
    return env = 'qa'
  }
}
export function authorizeAutoLogin(data = {}) {
  document.querySelector("#customLoader").style = "position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: 9999;background: rgba(0,0,0,0.1);overflow: hidden;cursor: not-allowed;"
  globalPostService(`api/user-authorization/`, data)
    .then(response => {
      document.querySelector("#customLoader").style = "display: none;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: 9999;background: rgba(0,0,0,0.1);overflow: hidden;cursor: not-allowed;"
      if (checkApiStatus(response)) {
        let baseDomain = '.sparta.aero', expireAfter = new Date();
        expireAfter.setDate(expireAfter.getDate() + 3);
        let cookieData = JSON.stringify({ access: response.data.data.lessor_access_token, id: response.data.data.current_lessor.id, refresh: '', environment: checkEnv() })
        let cookieName = JSON.stringify({ name: response.data.data.user.name, designation: response.data.data.user.designation })
        setGlobalCookie('userName', cookieName, 3)
        document.cookie = "lessorAccess=" + cookieData + "; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        userInfo = {
          ...userInfo,
          access: response.data.data.lessor_access_token,
          defaultLessor: response.data.data.current_lessor,
          user: response.data.data.user,

        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        setTimeout(() => {
          if (response.data.data.user.permission['valuation']) {
            if (
              response.data.data.user.permission && response.data.data.user.permission['valuation'] && response.data.data.user.permission['valuation']['base_value'] && response.data.data.user.permission['valuation']['base_value'].indexOf('C') != -1 ||
              response.data.data.user.permission && response.data.data.user.permission['valuation'] && response.data.data.user.permission['valuation']['future_value'] && response.data.data.user.permission['valuation']['future_value'].indexOf('C') != -1 ||
              response.data.data.user.permission && response.data.data.user.permission['valuation'] && response.data.data.user.permission['valuation']['hthl'] && response.data.data.user.permission['valuation']['hthl'].indexOf('C') != -1
            ) {
              window.location.href = "/create-log"
            } else {
              window.location.href = "/logs"
            }
          } else {
            window.location.href = "/logs"
          }
        }, 500)
      }
    })
}
export const getLessorsList = () => {
  globalGetService('/sso-lessor/list/')
    .then(response => {
      if (checkApiStatus(response)) {
        let userInfo = getLocalStorageInfo();
        userInfo = {
          ...userInfo,
          lessor_list: response.data.data
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      }
    })
}
