import React from 'react';
import { Table, TableRow, TableCell, Tooltip } from '@material-ui/core';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
const AssetFBV = ({item, projectionKey, openAssetFBVGraph}) => {
  return(
    <tr>
      <th>
        <div className="asset-details" style={{width:'150px', position:'relative'}}>
          <h4>{item.serial_number ? `MSN ${item.serial_number}`:'--'}</h4>
          <p>{item.aircraft_type && item.aircraft_type.name ? item.aircraft_type.name:'--'}</p>
          {item.registration_number ? <span>REG:  <b>{item.registration_number}</b></span>:null}
          <Tooltip title="Click to view Graphical View" arrow><TrendingDownIcon onClick={openAssetFBVGraph} color="primary" style={{position:'absolute', top:'40%', right:'3px', cursor:'pointer'}} fontSize="13" /></Tooltip>
        </div>
      </th>
      {Object.keys(item[projectionKey]).map((key, index) =>
        <td className="content-box" align="center">
          <div style={{width:'140px'}}>{item[projectionKey][key] ? `US$ ${item[projectionKey][key].toFixed(3)} m`:'--'}</div>
        </td>
      )}
    </tr>
  )
}
export default AssetFBV;
