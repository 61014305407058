import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, ListItemText, Avatar } from '@material-ui/core';
import { getLocalStorageInfo, getGlobalCookie } from '../../utils';
import  PageLoader  from './PageLoader';
import config from '../../config';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
export default function UserDropDownMenu({logout}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = JSON.parse(getGlobalCookie('userName'))
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  let baseUrl = config.domain.subDomian;
  if(getLocalStorageInfo() && getLocalStorageInfo().user){
    return(
      <div className="user-menu-dropdown">
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{padding: '0'}}
        >
        {
          getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.name && getLocalStorageInfo() && getLocalStorageInfo().user.profile_pic ?
          <Fragment>
            <Avatar alt={ user && user.name ? user.name : ''} style={{marginRight: '10px', width: '34px', height: '34px'}} src={getLocalStorageInfo().user.profile_pic} />
            <div className="" style={{textAlign: 'left'}}>
           <h3>{user && user.name ? user.name : ''}</h3>
           <span>{user && user.designation ? user.designation : ''}</span>
         </div>
          </Fragment>
          :
          <Fragment>
            <Avatar alt={ user && user.name ? user.name : ''} style={{marginRight: '10px', width: '34px', height: '34px'}} />
            <div className="" style={{textAlign: 'left'}}>
           <h3>{user && user.name ? user.name : ''}</h3>
           <span>{user && user.designation ? user.designation : ''}</span>
         </div>
          </Fragment>
        }
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <ListItemText onClick={() => window.open(baseUrl+'user-profile', '_blank')} primary="Profile" />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText onClick={() => window.open(baseUrl+'assets-listing?addUser=true', '_blank')} primary="Invite User" />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText onClick={logout} primary="Logout" />
          </StyledMenuItem>
        </StyledMenu>
      </div>
    )
  }else {
    return <PageLoader  />
  }
}
