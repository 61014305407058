import React from 'react';
import MainNavLink from './MainNavLink';

export default function DesktopSideNav({match}){
  return(
    <div className="desktop-py-sidenav">
      <nav>
        <MainNavLink/>
      </nav>
    </div>
  )
}
