import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import {  Grid } from '@material-ui/core';
import { imgStoragePath } from '../../../constants';
import { Footer, BackBanner, SignUpForm } from '../components';
import { updateAuthInfoState, signUpApi } from '../apiServices';
import { withSnackbar } from 'notistack';
import { fieldValidation } from '../../../utils/formValidation';
import { errorCode } from '../';
class Register extends Component {
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader:false,
      error:{},
      authInfo:{
        is_lessor:true,
        company_name:'',
        company_domain:'',
        full_name:'',
        email:'',
        contact_no:'',
        password:'',
        confirm_password:'',
        referral_code:''
      }
    }
    this.updateAuthInfoState = updateAuthInfoState.bind(this);
    this.signUpApi = signUpApi.bind(this);
  }
  handleChange = (value, key) => {
    this.updateAuthInfoState(value, key);
  }
  handleError = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  onSignUp = () => {
    const { authInfo } = this.state;
    let validationInputs = {
      email:errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: authInfo.email})],
      full_name:errorCode['full_name'][fieldValidation({...errorCode['full_nameObj'], fieldval: authInfo.full_name})],
      password:errorCode['password'][fieldValidation({...errorCode['passwordObj'], fieldval: authInfo.password})],
      confirm_password:errorCode['confirm_password'][fieldValidation({...errorCode['confirm_passwordObj'], fieldval: authInfo.confirm_password})],
      contact_no:errorCode['contact_no'][fieldValidation({...errorCode['contact_noObj'], fieldval: authInfo.contact_no})]
    }
    if (authInfo.is_lessor){
      validationInputs = {
          ...validationInputs,
          company_name: errorCode['company_name'][fieldValidation({...errorCode['company_nameObj'], fieldval: authInfo.company_name})],
          company_domain: errorCode['company_domain'][fieldValidation({...errorCode['company_domainObj'], fieldval: authInfo.company_domain})],
      }
    }
    if(authInfo.password && authInfo.confirm_password){
      if(authInfo.password !== authInfo.confirm_password){
        validationInputs = {
              ...validationInputs,
              confirm_password:'Passwords do not match. Try Again'
          }
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.signUpApi(this.props, this.state.authInfo);
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { formSubmitLoader, error, authInfo } = this.state;
    return(
      <Fragment>
        <BackBanner>
          <div className="auth-form-wrapper">
            <h2 className="form-title">Sign up for a free account to SPARTA Asset Values</h2>
            <SignUpForm
              error={error}
              authInfo={authInfo}
              handleChange={this.handleChange}
              onSignUp={this.onSignUp}
              formSubmitLoader={formSubmitLoader}
              handleError={this.handleError}
              resetErrorKey={(key) => {
                this.setState(prevState => ({
                  ...prevState,
                  error: {
                    ...prevState.error,
                    [key]: ''
                  }

                }))
              }}
            />
          </div>
        </BackBanner>
        { !isMobile ?
          <div className="features-wrapper">
            <Grid container spacing={3} >
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12}>
                  <div className="heading-block">
                    <h2 id="features">FEATURES</h2>
                  </div>
                </Grid>
              </Grid>
              <div className="feature-content-block">
                <div className="tab-content lessor-features">
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={7}>
                      <h4 id="contractsFeatures">Contracts</h4>
                      <ul className="list-inline">
                        <li>Complete fleet management; aircraft, engines, APUs and landing gears.</li>
                        <li>End-to-end lease and contract management for all assets</li>
                        <li>Management of lease terms and conditions including delivery and redelivery requirements</li>
                        <li>Unified view of the assets</li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <img src={imgStoragePath+'phase-2/contracts.png'} alt="contracts image" />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={5}>
                      <img src={imgStoragePath+'phase-2/maintenance.png'} alt="maintenance image" />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <h4 id="contractsFeatures">Cashflow and Analysis</h4>
                      <ul className="list-inline">
                        <li>Maintenance related cash flow analysis</li>
                        <li>Capability to undertake fleet wide cash flow analysis for different scenarios including Asset Backed Securitization (ABS)</li>
                        <li style={{width:'90%'}} >Maintenance event tracking, status of findings, workscope approvals, Life Limited Parts (LLP) status, Airworthiness Directives (AD) and Service Bulletins (SB) status
                          <div className="download-links">
                            <span style={{float:'right'}}>
                            <a href="https://itunes.apple.com/za/app/empower/id1209983674" target="_blank" style={{display:'inline',padding:'0px',border:'none'}} >
                            <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
                            <a href="https://play.google.com/store/apps/details?id=com.emis.acumen"  target="_blank" style={{display:'inline',padding:'0px',border:'none'}}>
                            <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a> </span>
                            <h4 style={{fontSize:'19px',fontWeight:'700'}}>Download EMPower iOS and Android apps</h4>
                            <p>Maintenance reserve calculations &amp; analysis of <br/> over 100 aircraft and engine types</p>
                          </div>
                        </li>
                       </ul>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={7}>
                      <h4 id="recordFeatures">Records</h4>
                        <ul className="list-inline">
                          <li>Interconnected data links to other modules for seamless access to scanned records for assets</li>
                          <li>Robust "search" features optimized using Natural Language Processing (NLP), Artificial Intelligence (AI) and Machine Learning (ML)</li>
                          <li>Intuitive interface and data indexing</li>
                        </ul>
                      </Grid>
                    <Grid item xs={12} md={5}>
                      <img src={imgStoragePath+'phase-2/records.png'} alt="records image" />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={5}>
                      <img src={imgStoragePath+'phase-2/technical.png'} alt="technical image" />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <h4 id="techFeatures">Technical</h4>
                      <ul className="list-inline">
                        <li>A single platform to schedule, plan and execute fleet-wide inspections</li>
                        <li>Interconnected data links to other modules for seamless access to inspection history, reports and findings</li>
                        <li style={{width:'90%'}}>An intuitive view of data points from inspection findings
                          <div className="download-links">
                            <span style={{float:'right'}}>
                            <a href="https://itunes.apple.com/us/app/sparta-acumen-aviation/id1270942972?mt=8" target="_blank" style={{display:'inline',padding:'0px',border:'none'}} >
                            <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
                            <a href="https://play.google.com/store/apps/details?id=acumenaviation.sparta.app"  target="_blank" style={{display:'inline',padding:'0px',border:'none'}}>
                            <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a> </span>
                            <h4 style={{fontSize:'19px',fontWeight:'700'}}>Download SPARTA iOS and Android apps</h4>
                            <p>Inspect aircraft &amp; records using mobile and web apps</p>
                          </div>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </div>:null
        }
        <Footer />
      </Fragment>
    )
  }
}
export default withSnackbar(Register);
