import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import homeIcon from '../../assets/images/home_icon.svg';
import logsIcon from '../../assets/images/fleet_list.svg';
import fleetIcon from '../../assets/images/fleet_icon.svg';
import settingIcon from '../../assets/images/setting_new.svg';
import portfolioIcon from '../../assets/images/portfolio_icon.svg';
import { getLocalStorageInfo, permissionCheckFn } from '../../utils';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Link as MuiLink} from '@material-ui/core'
import plansIcon from '../../assets/images/plans_icon.svg';
import { isMobile } from 'react-device-detect';
export default function MainNavLink(){
  return(
    <ul className="list-unstyled" style={{marginTop:'15px'}}>
      <li>
        <a href={config.domain.subDomian} target="_blank" rel="noreferrer"  >
          <img src={homeIcon} alt="Home" />
          <p>Home</p>
        </a>
      </li>
      <li className={window.location.href.includes('setting') && !window.location.href.includes('portfolios') ? 'active':''}>
        <Link to="/logs" className={!window.location.href.includes('setting') && !window.location.href.includes('portfolio') && !window.location.href.includes('fleets') ? 'active':''}>
          <img src={logsIcon} alt="Valuation" />
          <p>Logs</p>
        </Link>
      </li>
      <li className={window.location.href.includes('setting') && window.location.href.includes('fleets') ? 'active':''}>
        <Link to="/fleets" className={!window.location.href.includes('setting') && window.location.href.includes('fleets') && window.location.href.includes('fleets') ? 'active':''}>
          <img src={fleetIcon} alt="Valuation" />
          <p>Fleet</p>
        </Link>
      </li>
      {permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'R' }) ?
        <li className={window.location.href.includes('portfolio') ? 'active':''}>
          <Link to="/portfolios" className={window.location.href.includes('portfolio') ? 'active':''}>
            <img src={portfolioIcon} alt="Portfolio" />
            <p>Portfolio</p>
          </Link>
        </li>:null
       }
      { getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id === 242 ?
        getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id === 29).length ?
        <li className={window.location.href.includes('settings') ? 'active':''}>
          <MuiLink href={`${config.domain.subDomian}users/manage`} target='_blank'>
            <img src={settingIcon} alt="Setting" />
            <p>Settings</p>
          </MuiLink>
        </li>:null:<li className={window.location.href.includes('settings') ? 'active':''}>
          <MuiLink href={`${config.domain.subDomian}users/manage`} target='_blank'>
            <img src={settingIcon} alt="Setting" />
            <p>Settings</p>
          </MuiLink>
        </li>
      }
    </ul>
  )
}
