import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
const Role = ({item, onRoleDetail}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.users_count}</TableCell>
      <TableCell className="actions-cell">
        <VisibilityIcon onClick={onRoleDetail} color="primary" />
      </TableCell>
    </TableRow>
  )
}
export default Role;
