import React, { Component } from 'react';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Grid, Button, TableRow, TableCell, Typography, Breadcrumbs, IconButton, Tooltip, Paper } from '@material-ui/core';
import { BasicDetail, OtherLinks, BvFbvValueCard, FbvProjection } from '../components';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { TableListComp, ExportManu, PageLoader, RevisionHistory } from '../../../shared_elements';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { getValuationLogApi, exportFutureValuationLogApi, updateLockApi } from '../apiServices';
import { displayDateFormatShort } from '../../../constants';
import { checkApiStatus, permissionCheckFn } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
class FutureValueProjection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valuationLog: {},
      pageLoader: false,
      query: {},
      type: '',
      minYear: null,
      maxYear: null,
      filter_start: null,
      filter_end: null,
      applyFilter: false,
      error: '',
    }
    this.getValuationLogApi = getValuationLogApi.bind(this);
    this.exportFutureValuationLogApi = exportFutureValuationLogApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this)
  }
  componentDidMount() {
    this.setState({ pageLoader: true })
    this.getValuationLogApi(this.props, this.props.match.params.id)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          let maxY
          let minY
          if (Object.keys(response.data.data.future_value)[0]) {
            minY = Object.keys(response.data.data.future_value)[0];
          } else {
            minY = '0000';
          }
          if (Object.keys(response.data.data.future_value)[Object.keys(response.data.data.future_value).length - 1]) {
            maxY = Object.keys(response.data.data.future_value)[Object.keys(response.data.data.future_value).length - 1]
          } else {
            maxY = '0000';
          }
          this.setState({ valuationLog: response.data.data, minYear: minY.slice(0, 4), maxYear: maxY.slice(0, 4) });
        }
      })
    trackActivity('Page Visited', {
      page_title: 'Future Value Projection',
      log_id: this.props.match.params.id
    })
  }


  updatePlots = () => {
    const { filter_start, filter_end } = this.state;
    if (filter_start || filter_end) {
      if (filter_start && filter_end) {
        if (filter_start <= filter_end) {
          this.setState({ applyFilter: true });
        } else {
          this.setState({ error: 'Start Year cannot be greater then End Year' });
        }
      } else {
        this.setState({ applyFilter: true });
      }
    } else {
      this.setState({ error: 'Please enter Start Date or End Year' });
    }
  }
  onFieldChange = (keyParam, year) => {
    this.setState({ [keyParam]: year, error: '' });
  }
  render() {
    const { valuationLog, minYear, maxYear, filter_start, filter_end, error, pageLoader } = this.state;
    let data = [], plots = [], inflated = [], inflatedPlots = [];
    if (Object.keys(valuationLog).length) {
      if (filter_start && filter_end) {
        if (valuationLog && valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length) {
          inflatedPlots = Object.keys(valuationLog.inflated_future_value)
            .filter(key => key.slice(0, 4) >= filter_start && key.slice(0, 4) <= filter_end)
            .reduce((obj, key) => {
              obj[key] = valuationLog.inflated_future_value[key];
              return obj;
            }, {});
        }
        if (Object.keys(valuationLog.future_value).length) {
          plots = Object.keys(valuationLog.future_value)
            .filter(key => key.slice(0, 4) >= filter_start && key.slice(0, 4) <= filter_end)
            .reduce((obj, key) => {
              obj[key] = valuationLog.future_value[key];
              return obj;
            }, {});
        }
      } else if (filter_start) {
        if (valuationLog && valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length) {
          inflatedPlots = Object.keys(valuationLog.inflated_future_value)
            .filter(key => key.slice(0, 4) >= filter_start)
            .reduce((obj, key) => {
              obj[key] = valuationLog.inflated_future_value[key];
              return obj;
            }, {});
        }
        if (valuationLog && valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length) {
          plots = Object.keys(valuationLog.future_value)
            .filter(key => key.slice(0, 4) >= filter_start)
            .reduce((obj, key) => {
              obj[key] = valuationLog.future_value[key];
              return obj;
            }, {});
        }
      } else if (filter_end) {
        if (valuationLog && valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length) {
          inflatedPlots = Object.keys(valuationLog.inflated_future_value)
            .filter(key => key.slice(0, 4) <= filter_end)
            .reduce((obj, key) => {
              obj[key] = valuationLog.inflated_future_value[key];
              return obj;
            }, {});
        }
        if (Object.keys(valuationLog.future_value).length) {
          plots = Object.keys(valuationLog.future_value)
            .filter(key => key.slice(0, 4) <= filter_end)
            .reduce((obj, key) => {
              obj[key] = valuationLog.future_value[key];
              return obj;
            }, {});
        }
      } else {
        if (valuationLog && valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length) {
          inflatedPlots = valuationLog.inflated_future_value
        }
        if (Object.keys(valuationLog.future_value).length) {
          plots = valuationLog.future_value
        }

      }
      Object.keys(plots).map(year => {
        let d = [];
        d.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
        d.push(plots[year]);
        data.push(d);
      });
      Object.keys(inflatedPlots).map(year => {
        let f = [];
        f.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
        f.push(inflatedPlots[year]);
        inflated.push(f);
      });
    }
    const options = {
      chart: {
        type: 'line',
        marginTop: 60,
        zoomType: 'x',
      },
      exporting: permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'U' }) ? {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                  trackActivity('Report Exported', {
                    file_type: 'PNG',
                    report_type: 'Chart',
                    type: 'Future Value'
                  })
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                  trackActivity('Report Exported', {
                    file_type: 'SVG',
                    report_type: 'Chart',
                    type: 'Future Value'
                  })
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                  trackActivity('Report Exported', {
                    file_type: 'PDF',
                    report_type: 'Chart',
                    type: 'Future Value'
                  })
                }
              }
            ]
          }
        }
      } : '',
      credits: {
        enabled: false,
        style: {
          fontSize: 12
        },
        position: {
          align: 'left',
          x: 20,
          y: -380
        }
      },
      title: { text: '' },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Projection Year(s)'
        }
      },
      tooltip: {
        formatter: function () {
          return 'The value as of <b>' + moment(this.x).format('MMM YYYY') +
            '</b> is <b>US$' + this.y.toFixed(3) + ' Million</b>';
        }
      },
      yAxis: {
        title: {
          text: 'US$ Million'
        },
        labels: {
          formatter: function () {
            return this.value.toFixed(4);
          }
        }
      },
      series: [
        {
          name: 'Base Value at 0% Annual Inflation',
          data: data,
          color: 'rgba(241,202,249,1)',
          lineColor: '#BD10E0',
          style: { opacity: 0.3 },
          marker: {
            fillColor: '#BD10E0'
          }

        },
        {
          name: `Base Value at ${valuationLog.escalation_percentage ? valuationLog.escalation_percentage : '--'}% Annual Inflation`,
          data: inflated,
          color: '#BAB9D3',
          opacity: '0.5',
          style: { opacity: 0.5 },
          lineColor: '#150F78',
          marker: {
            fillColor: '#BD10E0'
          }
        },


      ]
    }
    return (
      <div className="valuation-sections">
        {pageLoader ? <PageLoader /> : null}
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Breadcrumbs aria-label="breadcrumb" style={isMobile ? { marginTop: '50px' } : { marginTop: '0' }}>
              <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                  Logs
                </Typography>
              </Link>
              {permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'lev', keyIndex: 'C' }) ?
                <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                  <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                    Asset Value Options
                  </Typography>
                </Link> : null
              }
              <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                Future Value Projection
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul className="list-inline" style={{ float: 'right' }}>
              <li className="list-inline-item">
                {permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'U' }) ?
                  valuationLog.lock_status ?
                    <Tooltip title="Click to resume editing." placement="top" arrow>
                      <IconButton>
                        <img src={lockIcon} onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" style={{ width: '30px' }} />
                      </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Click to lock editing." placement="top" arrow>
                      <IconButton>
                        <img src={unlockIcon} onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" style={{ width: '30px' }} />
                      </IconButton>
                    </Tooltip>
                  :
                  valuationLog.lock_status ? <img src={lockIcon} alt="Icon" style={{ cursor: 'default', position: 'relative', top: '10px', width: '30px' }} /> : <img src={unlockIcon} alt="Icon" style={{ cursor: 'default', position: 'relative', top: '10px', width: '30px' }} />
                }
              </li>
              <li className="list-inline-item" style={{ margin: '5px' }}>
                <RevisionHistory
                  url="audit/valuation/vtutilitylogs/"
                  queryParams={{ action: 1, object_id: this.props.match.params.id }}
                  buttonType={false}
                  component={true}
                  exportRevisionHistory={true}
                  exportQueryparam={{ action: 1, object_id: this.props.match.params.id, download: 'xls' }}
                  moduleType={"Future Value"}
                  exportPermission={permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'EXP_PDF' })}
                />
              </li>
              {permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'EXP_XLS' }) ?
                <li className="list-inline-item" style={{ margin: 'unset' }}>
                  <span style={isMobile ? { position: 'unset', top: '50px', float: 'right' } : { position: 'relative', top: '10px', float: 'right' }}>
                    <ExportManu variant="contained" title="Export" files={[{ title: 'Yearly Excel', extension: 'xls', key: 'yearly' }, { title: 'Monthly Excel', extension: 'xls', key: 'monthly' }, { title: 'Yearly PDF', extension: 'pdf', key: 'yearly' }, { title: 'Monthly PDF', extension: 'pdf', key: 'monthly' }, { title: "Yearly Docx", extension: 'docx', key: 'yearly' }, { title: "Monthly Docx", extension: 'docx', key: 'monthly' }]} exportReportFn={(file) => this.exportFutureValuationLogApi(this.props, file)} />
                  </span>
                </li>
                : null
              }
            </ul>
          </Grid>
        </Grid>
        <div className="future-value-projection">
          <BasicDetail valuationLog={valuationLog} />
          <div className="base-value-sec">
            <BvFbvValueCard valuationLog={valuationLog} />
          </div>

          <div className="future-value-graph">
            <h6>Projection of future value as of {moment(valuationLog.projection_year).format(displayDateFormatShort)}</h6>
            <p style={{ fontSize: '14px' }}>To select a specific time period, enter start and end year</p>
            <div className="">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      format={'YYYY'}
                      placeholder='Start Year'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: true }}
                      clearable={true}
                      minDate={minYear}
                      maxDate={maxYear}
                      views={['year']}
                      value={filter_start ? `${filter_start}-01-01` : null}
                      onChange={(data, value) => this.onFieldChange('filter_start', data ? moment(data).year() : data)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      fullWidth
                      placeholder='End Year'
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: true }}
                      clearable={true}
                      minDate={minYear}
                      maxDate={maxYear}
                      views={['year']}
                      value={filter_end ? `${filter_end}-01-01` : null}
                      onChange={(data, value) => this.onFieldChange('filter_end', data ? moment(data).year() : data)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {error ? <h6 style={{ color: '#ff0000', marginLeft: '10px' }} className="error-msg">{error}</h6> : null}
              </Grid>
              <ul className="list-inline flex-centered" style={{ float: 'right', margin: '10px 0', display: 'none' }}>
                <li className="list-inline-item" style={{ marginRight: '10px' }}>
                  <Button color="primary" variant="contained" onClick={this.updatePlots}>Filter</Button>
                </li>
                {filter_start || filter_end ?
                  <li className="list-inline-item" onClick={() => this.setState({ filter_start: null, filter_end: null, applyFilter: false, error: '' })}>Clear Filter</li> : null
                }
              </ul>
            </div>
            <div style={{ clear: 'both' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
            </div>
            <Grid container >
              {valuationLog.future_value && Object.keys(valuationLog.future_value).length ?
                <Grid item xs={12} alignContent="center" alignItems="center" justify="center" md={12} style={{ marginTop: '30px' }}>
                  <TableListComp
                    heads={valuationLog && valuationLog.inflated_future_value ?
                      [
                        { label: 'Date', sortOption: false },
                        { label: `Base Value at 0% Annual Inflation`, sortOption: false },
                        { label: `Base Value at ${valuationLog.escalation_percentage}% Annual Inflation`, sortOption: false },
                      ] :
                      [
                        { label: 'Year', sortOption: false },
                        { label: `Value at 0% ($ Million)`, sortOption: false }
                      ]
                    }
                    data={Object.keys(plots).map((label, index) => {
                      let date = new Date(label.slice(0, 4), `${label.slice(4) - 1}`)
                      return (
                        <TableRow>
                          <TableCell style={{ width: '140px' }}>{moment(date).format('MMM YYYY')}</TableCell>
                          <TableCell style={{ width: '200px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.future_value[label]}</NumericalLabel>} M</TableCell>
                          {valuationLog && valuationLog.inflated_future_value ?
                            <TableCell style={{ width: '300px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.inflated_future_value[label]}</NumericalLabel>} M</TableCell> : null
                          }
                        </TableRow>
                      )
                    }

                    )}
                  />
                </Grid> : null
              }
            </Grid>


          </div>
          <OtherLinks type="future-value" valuationLog={valuationLog} module="future_value" />
        </div>

      </div>
    )
  }
}
export default withSnackbar(FutureValueProjection);
