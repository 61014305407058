import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Footer, BackBanner, ForgotPwdForm } from '../components';
import { forgotApi, updateAuthInfoState } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
class Forgot extends Component {
  constructor(props){
    super(props);
    this.forgotApi = forgotApi.bind(this);
    this.updateAuthInfoState = updateAuthInfoState.bind(this);
    this.state = {
      formSubmitLoader: false,
      authInfo: {
        email:''
      },
      error: {},
      errorCode: {
        email:{
          0:'',
          1: 'Please enter Registered Email Address',
          4: 'Please enter valid Registered Email Address'
        },
        emailObj: {
          required: true,
          regexPattern: regexConstants.email
        }
      }
    }
  }
  onForgot = () => {
    // this.forgotApi()
    const { errorCode, authInfo } = this.state;
    let validationInputs = {
      email:errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: authInfo.email})],
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.forgotApi(this.props, this.state.authInfo);
    }else{
      this.setState({error: validationInputs});
    }
  }
  handleChange = (value, key) => {
    this.updateAuthInfoState(value, key);
  }
  render(){
    const { error, authInfo, formSubmitLoader } = this.state;
    return(
      <Fragment>
        <BackBanner>
          <div className="auth-form-wrapper">
            <Link to="/login">← Back to Login</Link>
            <h2 className="form-title">Request Reset Password Link!</h2>
            <p className="form-para">We will send a link to your email account to reset your password</p>
            <ForgotPwdForm
              error={error}
              authInfo={authInfo}
              handleChange={this.handleChange}
              onForgot={this.onForgot}
              formSubmitLoader={formSubmitLoader}
            />
          </div>
        </BackBanner>
        <Footer />
      </Fragment>
    )
  }
}

export default withSnackbar(Forgot);
