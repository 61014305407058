import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import moment from 'moment';
import { errorCode } from '.'
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';
export function getValuationLogApi(props = {}, id, query = {}) {
  return new Promise(
    function (resolve, reject) {
      globalGetService(`valuation/logs/${id}/`, query)
        .then(response => {
          resolve(response);
        })
    }
  )
}
export function addEditBlrApi(props, data, getResponseBack) {
  let payload = Object.assign({}, data);
  if (payload.mods && payload.mods.length) {
    payload = {
      ...payload,
      mods: payload.mods.map(mod => mod.id)
    }
  }
  if (payload.utility_type === 2) {
    payload = {
      ...payload,
      projection_from: payload.base_value_date
    }
  } else {
    // delete payload.projection_to
    // delete payload.escalation_percentage
  }
  this.setState({ pageLoader: true });
  if (props.match.params.id) {
    globalPutService(`valuation/logs/${props.match.params.id}/`, payload)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          this.setState({ valuationCrud: response.data.data, mode: 'view' })
          getResponseBack()
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    globalPostService(`valuation/initiate/`, payload)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          props.history.push(`/blr/${response.data.data.log_id}`)
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  }
}
export function exportValuationLogApi(props = {}, query = {}, data) {
  this.setState({ pageLoader: true });
  globalExportService(`valuation/logs/${props.match.params.id}/`, { download: query.extension, type: query.key, utility_type: 'future_value' })
    .then(response => {
      this.setState({ pageLoader: false, query: {}, type: '' });
      trackActivity('Report Exported', {
        type: 'Base Lease Rate',
        log_id: props.match.params.id,
        file_type: query.extension,
        report_type: query.key
      })
      downloadFileType(response.data, (`${data.id}_Base_Lease_Rate_${this.state.valuationCrud.aircraft_type.name}_${query.key}_${moment().format('YYYY-MM-DD')}`), query.extension);
    })
}
export function changeLogStatusApi(props, data) {
  this.setState({ pageLoader: true})
  globalPutService(`valuation/update-lock/`, data)
    .then(response => {
      this.setState({ pageLoader: false})
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState(prevState => ({
            ...prevState,
            valuationCrud:{
                ...prevState.valuationCrud,
                lock_status:data.is_locked,
            },
            mode: data.is_locked ? 'view':prevState.mode
        }))
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}