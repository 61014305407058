import React, { Fragment } from 'react';
import {TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import { displayDateFormatShort } from '../../../constants'

const ComponentBreakDownList = ({item, openLLpModal}) => {
  let style = {}
  if(item.flag && item.flag == 1){
    style = {
      color:'red',
    }
  }
  return (
      <TableRow>
        <TableCell className="mobile-tablecell" style={style} >
          {item.component} <br/>
        { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('U') != -1 &&
          item.flag && item.flag == 1 ?
          <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>
            {
              item.event == 'LLP' ?
              'LLPs have reached its life limit, view breakdown. Select Modify Query to update, if applicable.'
              :
              `${item.component} has consumed the expected MTBR interval. Select Modify Query to update, if applicable.`
            }
          </span>
          : item.flag && item.flag == 1 ?
          <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>
            {
              item.event == 'LLP' ?
              'LLPs have reached its life limit, view breakdown.'
              :
              `${item.component} has consumed the expected MTBR interval.`
            }
          </span>
          : null
        }


        </TableCell>
        <TableCell className="mobile-tablecell llp-table" style={style}>{item.event}</TableCell>
        {
          item.event !== "LLP" ?
          <Fragment>
            <TableCell className="mobile-tablecell llp-table" style={style}>
              {item.last_event_before_forecast ?
                 item.component.includes('Engine') || item.component.includes('APU')  ?
                 item.last_event_datatype == "date" ?
                 moment(item.last_event_before_forecast).format(displayDateFormatShort): item.last_event_before_forecast :
                 item.datatype == "date" ?
                 moment(item.last_event_before_forecast).format(displayDateFormatShort):
                 item.last_event_before_forecast : '--' }
               </TableCell>
            <TableCell className="mobile-tablecell" style={style}>{item.full_life_rem ? `${parseFloat(item.full_life_rem).toFixed(2)} %`:item.event === "LLP" ? '--' : '0 %'  }</TableCell>
            <TableCell className="mobile-tablecell" style={style}>{item.half_life_rem ? `${item.half_life_rem} %`: '--' }</TableCell>
          </Fragment>: <TableCell className="mobile-tablecell" colSpan={3} align="center"><span style={{color:'#3F51B5', cursor:'pointer', fontSize: '12px'}} onClick={() =>  openLLpModal(item.engine_no, item, "future_value")}>View LLP Breakdown</span></TableCell>
        }
        <TableCell className="mobile-tablecell" style={style}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adj}</NumericalLabel></TableCell>
        <TableCell className="mobile-tablecell" style={style}>{item.cost ?
            <Fragment><NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:3})}>{item.cost}</NumericalLabel> M</Fragment>
              : '--' }
        </TableCell>
        <TableCell className="mobile-tablecell llp-table" style={style}>
          {item.next_event_after_forecast ?
            item.component.includes('Engine') || item.component.includes('APU')   ?
            item.next_event_datatype == "date" ?
            moment(item.next_event_after_forecast).format(displayDateFormatShort): item.next_event_after_forecast :
            item.datatype == "date" ?
            moment(item.next_event_after_forecast).format(displayDateFormatShort) :
            item.next_event_after_forecast : '--' }
          </TableCell>
      </TableRow>
  )
}
export default ComponentBreakDownList;
