import React from 'react';
import moment from 'moment';
import { Button, Paper, Grid, Tooltip } from '@material-ui/core';
import { DialWidgetCard } from '../../Elements';
import { displayDateFormatShort } from '../../../constants';
const HlaIntermOutput = ({halfLifeCrud}) => {
  return(
    <div className="bv-fbv-output">
      <Paper className="output-widget-card">
        <div style={{borderBottom:'1px solid #c4c4c4', paddingBottom:'20px'}}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <DialWidgetCard value={`${halfLifeCrud.generic.base_value}`} label="Base Value" unit='m' />
            </Grid>
          </Grid>
        </div>
        { halfLifeCrud.generic.forecast_date && halfLifeCrud.generic.future_base_value ?
          <div style={{borderBottom:'1px solid #c4c4c4', paddingBottom:'20px'}}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <DialWidgetCard value={`${halfLifeCrud.generic.future_base_value}`} label="Future Base Value" unit='m' />
              </Grid>
            </Grid>
          </div>:null
        }
      </Paper>
    </div>
  )
}
export default HlaIntermOutput;
