import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const AssetCountBarChart = ({data, categories, legend}) => {
  const options = {
    chart: {
      type: 'column'
    },
    title:{text:''},
    credits: {enabled: false},
    yAxis:{
      allowDecimals:false,
      title:{
        text:'Asset Count'
      }
    },
    xAxis: {
        categories: categories,

    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y}'
        }
      }
    },
    series: [
      {
        name: legend,
        data: data,
        colorByPoint:true,
        showInLegend:false
      }
    ]
  }
  return(
    <div style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default AssetCountBarChart;
