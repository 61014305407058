import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Typography, Breadcrumbs, Button, Tooltip } from '@material-ui/core';
import { ExportManu, RevisionHistory } from '../../../shared_elements';
import RestoreIcon from '@material-ui/icons/Restore';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { permissionCheckFn } from '../../../utils';
const HlaBreadCrumbs = ({ match, logResult, changeLogStatus, mode, exportValuationLog }) => {
  return (
    <div className="breadcrumbs-section flex-centered">
      <Breadcrumbs>
        <Link to="/logs">Logs</Link>
        <Typography color="textPrimary">Half Life Maintenance Adjustment</Typography>
      </Breadcrumbs>
      {match.params.id && mode !== 'add' ?
        <ul className="list-inline utils-cta flex-centered">
          <li className="list-inline-item">
            <Tooltip title="Lock/Unlock" arrow>
              {logResult.lock_status ? <LockIcon onClick={() => changeLogStatus({ log_id: match.params.id, is_locked: false })} color="primary" fontSize="small" /> : <LockOpenIcon onClick={() => changeLogStatus({ log_id: match.params.id, is_locked: true })} color="primary" fontSize="small" />}
            </Tooltip>
          </li>
          <li className="list-inline-item">
            <Tooltip title="Revision History" arrow>
              <RevisionHistory
                url="audit/valuation/vtutilitylogs/"
                queryParams={{ action: 1, object_id: match.params.id }}
                buttonType={true}
                component={true}
                exportRevisionHistory={true}
                exportQueryparam={{ action: 1, object_id: match.params.id, download: 'xls' }}
                moduleType={"Half life Adjustment"}
                exportPermission={permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' })}
              />
            </Tooltip>
          </li>
          {/* <li className="list-inline-item">
            <Button color="primary" variant="outlined" size='small'>Request Signed Report</Button>
          </li> */}
          {permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' })}
          <li className="list-inline-item">
            <ExportManu
              variant="contained"
              title="Export"
              files={[
                { title: 'Pdf', extension: 'pdf', key: '' },
                { title: 'Excel', extension: 'xlsx', key: '' },
                { title: "Docx", extension: 'docx', key: '' },
              ]}
              exportReportFn={(file) => exportValuationLog(file)}
            />
          </li>
        </ul> : null
      }
    </div>
  )
}

export default withRouter(HlaBreadCrumbs);
