import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Tabs, Tab, Paper, Grid, Tooltip } from '@material-ui/core';
import { ValuationOutPlaceHolder } from '../../Elements'
import { EventWrapper, EventsTab, AirframeEvent, ApuEvent, EngineEvent, EngineHSIEvent, PropellarEvent, LgEvent, HlaIntermOutput, HlaBreadCrumbs } from '../components';
import { permissionCheckFn } from '../../../utils';
import { getValuationHlaApi } from '../apiServices';
class HlaEvents extends Component{
  constructor(props){
    super(props);
    this.state = {
      mode:'edit',
      pageLoader:false,
      airframeIndex:0,
      engineIndex:0,
      propellerIndex:0,
      lgIndex:0,
      halfLifeCrud:{}
    };
    this.getValuationHlaApi = getValuationHlaApi.bind(this);
  }
  componentDidMount(){
    this.getValuationHlaApi(this.props)
  }
  handleTabChange = (event, newValue, tabType) => {
    this.setState({[tabType]:newValue});
  }
  onFieldChange = (event, keyParam, value, assembly, objectKey, index) => {
    if(assembly === 'airframe' || assembly === 'engine'){
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          [assembly]:{
            ...prevState.halfLifeCrud[assembly],
            [objectKey]: prevState.halfLifeCrud[assembly][objectKey].map((item, itemIndex) => itemIndex === index ? {
              ...item,
              [keyParam]:value
            } :item )
          }
        }
      }))
    }
    if(assembly === 'propeller' || assembly === 'landing_gear'){
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          [assembly]:prevState.halfLifeCrud[assembly].map((item, itemIndex) => index===itemIndex ? {
            ...item,[keyParam]:value
          } :item)
        }
      }))
    }
    if(assembly === 'apu'){
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          apu:{
            ...prevState.halfLifeCrud.apu,
            [keyParam]:value
          }
        }
      }))
    }

  }
  updateErrorField = () => {}
  render(){
    const { pageLoader, mode, airframeIndex, engineIndex, propellerIndex, lgIndex, halfLifeCrud } = this.state;
    if(permissionCheckFn({secondaryKey:'hthl', keyIndex:'C'}) || permissionCheckFn({secondaryKey:'hthl', keyIndex:'U'})){
      return(
        <section className="valuation-app-section">
          <HlaBreadCrumbs
            logResult={halfLifeCrud}
            changeLogStatus={() => console.log('Lck')}
            mode={'add'}
            exportValuationLog={()=>console.log('Export')}
          />
          <div className="hla-section">
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <Paper elevation={1} className="assembly-sections">
                  { halfLifeCrud.airframe && Object.keys(halfLifeCrud.airframe).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Airframe Details" flag={true}>
                        <EventsTab
                          tabIndex={airframeIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'airframeIndex')}
                          tabs={halfLifeCrud.airframe.maintenance_events.map(item => `${item.event} Year Check`)}
                        />
                        {halfLifeCrud.airframe.maintenance_events.map((mntProgram, index) =>
                          index === airframeIndex ?
                          <Grid container spacing={1}>
                            <AirframeEvent
                              item={mntProgram}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={true}
                              crudData={halfLifeCrud}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.engine && Object.keys(halfLifeCrud.engine).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Engine Details" flag={false}>
                        <EventsTab
                          tabIndex={engineIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'engineIndex')}
                          tabs={halfLifeCrud.engine.engineInfo.map((engine, index) => `Engine #${index+1}`)}
                        />

                        { halfLifeCrud.engine.engineInfo.map((item, index) =>
                          index === engineIndex ?
                          <Fragment>
                            { item.engine_hsi && halfLifeCrud.airframe.aircraftType.has_propeller ?
                              <Grid container spacing={1}>
                                <EngineHSIEvent
                                  item={item}
                                  itemIndex={index}
                                  mode={mode}
                                  permissionFlag={true}
                                  crudData={{}}
                                  error={{}}
                                  onFieldChange={this.onFieldChange}
                                  updateErrorField={this.updateErrorField}
                                />
                              </Grid>:null
                            }
                            <Grid container spacing={1}>
                              <EngineEvent
                                item={item}
                                itemIndex={index}
                                mode={mode}
                                permissionFlag={true}
                                crudData={halfLifeCrud}
                                error={{}}
                                onFieldChange={this.onFieldChange}
                                updateErrorField={this.updateErrorField}
                              />
                            </Grid>
                          </Fragment>
                          :null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.propeller && Object.keys(halfLifeCrud.propeller).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Propellar Details" flag={false}>
                        <EventsTab
                          tabIndex={propellerIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'propellerIndex')}
                          tabs={halfLifeCrud.propeller.map((propeller) => `Propellar #${propeller.position}`)}
                        />
                        { halfLifeCrud.propeller.map((item, index) =>
                          propellerIndex === index ?
                          <Grid container spacing={1}>
                            <PropellarEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={true}
                              crudData={{}}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.apu && Object.keys(halfLifeCrud.apu).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="APU Details" flag={false}>
                        <Grid container spacing={1}>
                          <ApuEvent
                            item={halfLifeCrud.apu}
                            mode={mode}
                            permissionFlag={true}
                            crudData={{}}
                            error={{}}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={this.updateErrorField}
                          />
                        </Grid>
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.landing_gear && halfLifeCrud.landing_gear.length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Landing Gear Details" flag={false}>
                        <EventsTab
                          tabIndex={lgIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'lgIndex')}
                          tabs={halfLifeCrud.landing_gear.map((lg) => `${lg.position}`)}
                        />
                        { halfLifeCrud.landing_gear.map((item, index) =>
                          lgIndex === index ?
                          <Grid container spacing={1}>
                            <LgEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={true}
                              crudData={{}}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }

                </Paper>
              </Grid>
              <Grid item md={4} xs={12}>
                { Object.keys(halfLifeCrud).length ? <HlaIntermOutput halfLifeCrud={halfLifeCrud} />:null}

              </Grid>
            </Grid>
          </div>
        </section>
      )
    }else{
      return <Redirect to={`/hla/output/${this.props.match.params.id}`} />
    }
  }
}
export default HlaEvents;
