import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, FormControl, FormLabel, FormGroup, RadioGroup, FormControlLabel, Checkbox, Radio } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import {DropzoneArea} from 'material-ui-dropzone';
import { LabelValueCard } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter, checkApiStatus } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { fieldValidation } from '../../../utils/formValidation';
import { addEditPortfolioApi, getPortfolioApi, exportPortfolioSampleApi } from '../apiServices';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { portfolioCrud, errorCode } from '../'
class AddEditPortfolio extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      mode:'',
      portfolio: {},
      error:{}
    }
    this.addEditPortfolioApi = addEditPortfolioApi.bind(this);
    this.getPortfolioApi = getPortfolioApi.bind(this);
    this.exportPortfolioSampleApi = exportPortfolioSampleApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      portfolio: {
        ...prevState.portfolio,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditPortfolioFn = (data) => {
    const { portfolio } = this.state;
    let validationInputs = {
      name: errorCode['name'][fieldValidation({ ...errorCode['nameObj'], fieldval: portfolio.name })],
      report_date: errorCode['report_date'][fieldValidation({ ...errorCode['report_dateObj'], fieldval: portfolio.report_date })],

    }
    if(!portfolio.id){
      validationInputs = {
        ...validationInputs,
        file:portfolio.file ? portfolio.file.length ? '' : 'Please Upload Attachment' : ''
      }
    }
    if(portfolio.utility_type === 2){

      validationInputs = {
        ...validationInputs,
        inflation_rate: errorCode['inflation_rate'][fieldValidation({ ...errorCode['inflation_rateObj'], fieldval: portfolio.inflation_rate })],
        projection_to: errorCode['projection_to'][fieldValidation({ ...errorCode['projection_toObj'], fieldval: portfolio.projection_to })],
      }
    }
    if(portfolio.id && portfolio.file.length){
      validationInputs = {
        ...validationInputs,
        merge_replace: portfolio.merge_replace ? '':'Please select Attachment Option'
      }
    }


    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, portfolio);
      let formData = new FormData();
      let utility_int_val=payload.utility_type
      if(payload.utility_type==='Future Value'){
        utility_int_val=2
      }else if(payload.utility_type==='Base Value'){
        utility_int_val=1
      }
      formData.append('name', payload.name);
      formData.append('report_date', payload.report_date);
      formData.append('utility_type', utility_int_val);
      formData.append('inflation_rate', payload.inflation_rate);
      formData.append('projection_to', payload.projection_to);
      formData.append('dom_format', payload.dom_format);
      formData.append('merge_replace', payload.merge_replace);
      formData.append('remarks', payload.remarks);
      formData.append('file_1', payload.file[0]);
      this.setState({formSubmitLoader:true});
      this.addEditPortfolioApi(this.props, formData, portfolio.id)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', portfolio:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'},style: { whiteSpace: 'pre-line' }});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'},style: { whiteSpace: 'pre-line' }});
        }
      })
    }else{
      this.setState({error: validationInputs});
      trackActivity(' Portfolio', {
        event_type: 'Add',
        page_title: 'Add Portfolio',
        error_source: 'Frontend',
        error_type: 'Validation',
        errors: validationInputs
      })
    }
  }
  render(){
    const { formSubmitLoader, modal, mode, portfolio, error } = this.state;
    const { id, m, portfolioDetail } = this.props;
    return(
      <Fragment>
        { id ?
          <Tooltip title="Edit" arrow><EditIcon onClick={() => this.setState({modal:true, portfolio:portfolioDetail, mode:'edit'})} color="primary" /></Tooltip>:
          <Button onClick={() => this.setState({modal:true, portfolio:portfolioCrud, mode:'add'})} variant="contained" color="primary">Add Portfolio</Button>
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, mode:'', portfolio:{}, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter(mode)} Portfolio
            </DialogTitle>
            <DialogContent dividers={true} className="add-portfolio-dialog">
              <Grid container spacing={2}>
                { mode ===  'view' ?
                  <LabelValueCard md={6} label='Name' value={portfolio.name ? portfolio.name:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="name"
                      label="Name"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 100 }}
                      value={portfolio.name ? portfolio.name:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'name', e.target.value)}
                      onFocus={(e) => this.updateErrorField('name', '')}
                      error={error.name? true:false}
                      helperText={error.name?error.name:''}
                      variant="outlined"
                      autoComplete='off'

                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={6} label='Report Date' value={portfolio.reprt_date ? moment(portfolio.reprt_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         required
                         margin="normal"
                         id="report_date"
                         label="Report Date"
                         placeholder="DD-MM-YYYY"
                         format={fieldDateFormat}
                         fullWidth
                         minDate={moment().startOf('year')}
                         maxDate={moment().add('years',1).endOf('year')}
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: false}}
                         clearable={true}
                         value={portfolio.report_date ? portfolio.report_date:null}
                         onChange={(data, value) => this.onFieldChange(value, 'report_date', moment(data).format(backendDateFormat))}
                         onFocus={(e) => this.updateErrorField('report_date', '')}
                         error={error.report_date ? true:false }
                         helperText={error.report_date ? error.report_date: ''}
                         inputVariant="outlined"
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                }

               <Grid item xs={12} >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">DOM Format*</FormLabel>
                    <RadioGroup row aria-label="position" name="position" defaultValue="YYYY-MM-DD">
                      <FormControlLabel value="DD-MM-YYYY" control={<Radio color="primary" checked={portfolio.dom_format === 'DD-MM-YYYY'? true:false} onChange={(e) => this.onFieldChange(e, 'dom_format', 'DD-MM-YYYY')} />} label="DD-MM-YYYY" />
                      <FormControlLabel value="MM-DD-YYYY" control={<Radio color="primary" checked={portfolio.dom_format === 'MM-DD-YYYY'? true:false} onChange={(e) => this.onFieldChange(e, 'dom_format', 'MM-DD-YYYY')} />} label="MM-DD-YYYY" />
                      <FormControlLabel value="YYYY-MM-DD" control={<Radio color="primary" checked={portfolio.dom_format === 'YYYY-MM-DD'? true:false} onChange={(e) => this.onFieldChange(e, 'dom_format', 'YYYY-MM-DD')} />} label="YYYY-MM-DD" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Utility Type*</FormLabel>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="end"
                          control={<Checkbox style={{cursor:'disabled'}} checked={true} color="primary" />}
                          label={<Fragment>BV and CMV<sup style={{fontSize:'9px', color:'#3f51b5'}}>(DEFAULT)</sup></Fragment>}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={<Checkbox onChange={(e) => this.onFieldChange(e, 'utility_type', e.target.checked?2:1)} checked={((portfolio.utility_type === 2) || (portfolio.utility_type ==='Future Value'))?true:false} color="primary" />}
                          label="FBV"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>



                { ((portfolio.utility_type === 2) || (portfolio.utility_type ==='Future Value')) ?
                  <Fragment>
                    { mode === 'view' ?
                      <LabelValueCard md={6} label='Inflation Rate' value={portfolio.inflation_rate ? portfolio.inflation_rate:'--' } /> :
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="inflation_rate"
                          label="Inflation Rate *"
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 5 }}
                          value={portfolio.inflation_rate ? portfolio.inflation_rate:''}
                          InputLabelProps={{shrink: true}}
                          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'inflation_rate', e.target.value):e.preventDefault()}}
                          onFocus={(e) => this.updateErrorField('inflation_rate', '')}
                          error={error.inflation_rate ? true:false }
                          helperText={error.inflation_rate ? error.inflation_rate: ''}
                          variant="outlined"
                          autoComplete='off'

                        />
                      </Grid>
                    }

                    { mode === 'view' ?
                      <LabelValueCard md={6} label='Projection Year' value={portfolio.projection_year ? portfolio.projection_year:'--'} /> :
                      <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                             required
                             margin="normal"
                             id="projection_to"
                             label="Projection Year"
                             format={fieldDateFormat}
                             placeholder="DD-MM-YYYY"
                             fullWidth
                             disablePast
                             maxDate={moment().add('years',25)}
                             InputLabelProps={{shrink: true}}
                             inputProps={{readOnly: false}}
                             clearable={true}
                             value={portfolio.projection_to ? portfolio.projection_to:null}
                             onChange={(data, value) => this.onFieldChange(value, 'projection_to', moment(data).format(backendDateFormat))}
                             onFocus={(e) => this.updateErrorField('projection_to', '')}
                             error={error.projection_to ? true:false }
                             helperText={error.projection_to ? error.projection_to: ''}
                             inputVariant="outlined"
                           />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    }
                  </Fragment>:null
                }
                <Grid item xs={12}>
                  <p className="attachment-label"> <label>Attachment {id ?'':'*'}</label> <span>Please refer sample sheet, <a onClick={() => this.exportPortfolioSampleApi()} style={{textDecoration:'underline', cursor:'pointer', color:'#3f51b5'}}>Download</a></span></p>
                  <DropzoneArea
                     acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                     filesLimit={1}
                     showPreviewsInDropzone={false}
                     showPreviews={true}
                     useChipsForPreview={true}
                     dropzoneText={<p>Drop files or add attachments here</p>}
                     dropzoneClass="drag-drop-cnt"
                     maxWidth="sm"
                     showAlerts={['error', 'info']}
                     alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                     onChange={(files) => {this.onFieldChange('', 'file', files); this.updateErrorField('file', '')}}
                     onDelete={(files) => this.onFieldChange('', 'file', files)}
                   />
                   { error.file ? <h6 className="error-msg">{error.file ? error.file:''}</h6>:null }
                   { portfolio.file && portfolio.file.length && id ?
                     <FormControl component="fieldset">
                       <RadioGroup row aria-label="position" name="position" defaultValue="YYYY-MM-DD">
                         <FormControlLabel value="DD-MM-YYYY" control={<Radio color="primary" checked={portfolio.merge_replace === 'merge'? true:false} onChange={(e) => {this.onFieldChange(e, 'merge_replace', 'merge');this.updateErrorField('merge_replace', '')}} />} label="Merge the Logs" />
                         <FormControlLabel value="MM-DD-YYYY" control={<Radio color="primary" checked={portfolio.merge_replace === 'replace'? true:false} onChange={(e) => {this.onFieldChange(e, 'merge_replace', 'replace');this.updateErrorField('merge_replace', '')}} />} label="Replace the Logs" />
                       </RadioGroup>
                     </FormControl>:null
                   }
                   { error.merge_replace ? <h6 className="error-msg">{error.merge_replace ? error.merge_replace:''}</h6>:null}
                </Grid>

                { mode === 'view' ?
                  <LabelValueCard md={12} label='Remarks' value={portfolio.remarks ? portfolio.remarks:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="3"
                      inputProps={{ maxLength: 255 }}
                      value={portfolio.remarks ? portfolio.remarks:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, mode:'', portfolio:{}, error:{}})} color="primary">Cancel</Button>
              { mode !== 'view' ?
                <Button onClick={this.addEditPortfolioFn} variant="contained" color="primary" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
                </Button> :null
              }
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditPortfolio));
