import React, { Fragment, Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, getLessorsList } from '../utils';
import { lessorUpdateApi } from '../application/auth/apiServices';
import { DesktopHeader, MobileHeader, DeskTopSidebar, DesktopSideNav, MobileSecMenu, SecondarySideNav } from '../shared_elements';
import config from '../config';
import * as actions from '../shared_elements/actions'
window.addEventListener('visibilitychange', checkLogin)
function checkLogin() {
  if (document.visibilityState || document.hasFocus) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    if (!lessorAccess) {
      if (!location.includes('login') && !location.includes('otp')) {
      }
    } else {
      // getLessorsList()
      if (lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment)) {
        if (!getLocalStorageInfo()) {
          authorizeAutoLogin({ lessor_id: lessorAccess.id })
        } else if (!location.includes('select-lessor') && !location.includes('otp')) {
          if (lessorAccess.id !== userInfo.defaultLessor.id) {
            authorizeAutoLogin({ lessor_id: lessorAccess.id })
          } else {
            //window.location.assign('/')
          }
        }
      } else {
        if (userInfo && userInfo.defaultLessor == undefined) {
          // window.location.assign("/select-lessor");
        }
      }
    }
  }

}
export default function PageLayout(HocComponent, extraProps = {}) {
  class Layout extends Component {
    constructor(props) {
      super(props)
      this.state = {
        modal: false
      }
    }
    layoutMarginProps = () => {
      if (extraProps.layoutPhase) {
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return '50px 0 0 0'
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return '0px 0 0 70px'
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return '0 0 0 252px';
          default:
            return '0'
        }
      } else {
        return '0'
      }
    }
    render() {
      const lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
      if (lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment)) {
        return (
          <section>
            <Fragment>
              {isMobile ? <MobileHeader /> :
                <Fragment>
                  <DesktopHeader />
                  <DesktopSideNav />
                </Fragment>
              }
            </Fragment>
            {extraProps.layoutPhase === 2 ? <SecondarySideNav /> : null}
            <main style={{ margin: isMobile ? '10px 0 0 0' : this.layoutMarginProps(), padding: isMobile ? '15px 15px 50px 15px' : '15px 15px 0 15px' }}>
              <div>
                <HocComponent {...this.props} />
              </div>
            </main>
            <Dialog
              open={this.state.modal}
              onClose={() => this.setState({ modal: false })}
              aria-labelledby="scroll-dialog-title"
              fullWidth='true'
            >
              <DialogTitle id="scroll-dialog-title">
                Information<CloseIcon onClick={() => this.setState({ modal: false })} color="primary" style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <DialogContent dividers={true} className="data-upload-modal">
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Dear User,
                      <br /><br />
                      SPARTA will be under routine maintenance and feature upgrade release from Saturday 12 Feb 2022 0030 Hrs UTC to Sunday 13 Feb 2022 2300 Hrs UTC. During this time, SPARTA will be accessible but may pose intermittent connection problems. We regret the inconvenience caused.
                      <br /><br />
                      If you have any concerns or questions, please contact us on <a href="mailto:support@sparta.aero">support@sparta.aero</a>.
                      <br /><br />
                      Regards,
                      <br />
                      The SPARTA Team
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>
          </section>
        );
      } else {
        return <Redirect to="/login" />
      }
    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
  })
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      toggleTheAppSwitcher: (flag) => dispatch({ type: actions.APPS_SWITCHER, payload: flag }),
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(Layout)
}
