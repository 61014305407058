import { backendDateFormat } from '../../constants';
import { regexConstants, errorMsg1 } from '../../constants/regEx';
import moment from 'moment';
export const valuationCrud = {
  asset_type:1,
  serial_number: '',
  base_value_date: moment().format(backendDateFormat),
  date_of_manufacture: null,
  aircraft_type: null,
  engine_type: null,
  mods: [],
  engine_mods:[{name:'',value:null}],
  mtow: null,
  mtow_kgs: null,
  projection_to: null,
  maintenance_condition: 50,
  is_full_life_maintenance_condition: 0,
  is_apu_disabled: false,
  engine_sv_details: null,
  escalation_percentage: 0,
  utility_type: 1,
  fbv_engine_calculation_type: 1
};
export const errorCode = {
  aircraft_type: {
    0: '',
    1: 'Please select Aircraft Type'
  },
  aircraft_typeObj: {
    required: true,
  },
  maintenance_program: {
    0: '',
    1: 'Please select Maintenace Program'
  },
  maintenance_programObj: {
    required: true,
  },
  serial_number: {
    0: '',
    4: errorMsg1
  },
  serial_numberObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  date_of_manufacture: {
    0: '',
    1: 'Pease enter Date of Manufacture',
    5: 'Please enter Valid Date'
  },
  date_of_manufactureObj: {
    required: true,
    datePattern: true
  },
  engine_type: {
    0: '',
    1: 'Please select Engine Type'
  },
  engine_typeObj: {
    required: true
  },
  mtow: {
    0: '',
    1: 'Please enter MTOW in Lbs'
  },
  mtowObj: {
    required: true
  },
  mtow_kgs: {
    0: '',
    1: 'Please enter MTOW in Kgs '
  },
  mtow_kgsObj: {
    required: true
  },
  base_value_date: {
    0: '',
    1: 'Pease enter Base Value Date',
    5: 'Please enter Valid Date'
  },
  base_value_dateObj: {
    required: true,
    datePattern: true
  },
  escalation_percentage: {
    0: '',
    1: 'Please enter Inflation Rate'
  },
  escalation_percentageObj: {
    required: true
  },
  projection_to: {
    0: '',
    1: 'Pease enter Projection Date',
    5: 'Please enter Valid Date'
  },
  projection_toObj: {
    required: true,
    datePattern: true
  },
  hours: {
    0: '',
    1: 'Please enter APU Hours'
  },
  hoursObj: {
    required: true
  },
  utilization_as_of_date: {
    0: '',
    1: 'Please enter Utilization as of Date',
    5: 'Please enter a Valid Date'
  },
  utilization_as_of_dateObj: {
    required: true
  },
  csn: {
    0: '',
    1: 'Please enter CSN'
  },
  csnObj: {
    required: true
  },

}
