import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Paper, Grid, Breadcrumbs, Typography, Button,Tooltip } from '@material-ui/core';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ReorderIcon from '@material-ui/icons/Reorder';
import { permissionCheckFn } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
const FBVBreadCrumbs = ({match, onChangeView, fbvViewType, exportPortfolioReport, portfolioDetail}) => {
  return(
    <div className="portfolio-breadcrumb">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:'10px'}}>
            <Link color="inherit" to="/portfolios" style={{ cursor: 'pointer' }} >
              <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                Portfolio
              </Typography>
            </Link>
            <Link color="inherit" to={`/portfolio/${match.params.slug}`} style={{ cursor: 'pointer' }} >
              <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                {portfolioDetail && portfolioDetail.name ? portfolioDetail.name:''}
              </Typography>
            </Link>
            <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
              Future Base Value Projection
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <ul className=" flex-centered list-inline portfolio-report-cta">
            <li className="list-inline-item">
              <span className="list-graph-sw">
                <span onClick={() => {onChangeView(0); trackActivity('Portfolio',{event_type:'Button Clicked',page_title:'FBV',activity_name:'Listing',id:match.params.slug})}} className={fbvViewType === 0 ? 'active':''}><Tooltip title="Listing" arrow><ReorderIcon /></Tooltip></span>
                <span onClick={() => {onChangeView(1); trackActivity('Portfolio',{event_type:'Button Clicked',page_title:'FBV',activity_name:'Graph',id:match.params.slug})}} className={fbvViewType === 1 ? 'active':''}><Tooltip title="Graph" arrow><TrendingDownIcon /></Tooltip></span>
              </span>
            </li>
            { permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'EXP_XLS' }) ?
              <li className="list-inline-item">
                <Button onClick={() => exportPortfolioReport()} color="primary" variant="contained">Export Report</Button>
              </li>:null
            }

          </ul>
        </Grid>
      </Grid>
    </div>
  )
}
export default withRouter(FBVBreadCrumbs);
