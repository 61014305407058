import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Grid, Paper } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
const ValueAsOfCard = ({ match, history, mode, logResult, error, onFieldChange, updateErrorField, onCancel, onSubmit }) => {
  return (
    <Paper className="fixed-compute-cta">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required
              margin="normal"
              id="base_value_date"
              placeholder="DD-MM-YYYY"
              label="Value as Of"
              format={fieldDateFormat}
              disabled={mode === 'view'}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{readOnly: false}}
              minDate={logResult.asset_type && logResult.asset_type===2 ? moment().startOf('year') : moment().subtract(1, 'years')}
              maxDate={moment().endOf('year')}
              onChange={(data, value) => onFieldChange(value, 'base_value_date', data ? moment(data).format(backendDateFormat) : data)}
              value={logResult.base_value_date}
              error={error.base_value_date ? true : false}
              helperText={error.base_value_date ? error.base_value_date : ''}
              onFocus={() => updateErrorField('base_value_date', '')}
              onOpen={() => updateErrorField('base_value_date', '')}
              inputVariant="outlined"
              autoComplete='off'
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={8}>
          {
            ['add', 'edit'].includes(mode) && !logResult.lock_status && (
              <ul className="list-inline submit-bv-fbv">
                <li className="list-inline-item">
                  <Button onClick={onSubmit} color="primary" variant="contained">Compute</Button>
                </li>
                <li className="list-inline-item">
                  <Button onClick={() => { match.params.id ? onCancel() : history.push('/logs') }} color="primary" variant="outlined">Cancel</Button>
                </li>
              </ul>)
          }

        </Grid>
      </Grid>
    </Paper>
  )
}
export default withRouter(ValueAsOfCard);
