import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import PopOverInfo from './PopOverInfo';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ReplayIcon from '@material-ui/icons/Replay';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { displayDateFormatShort } from '../../../constants'
import { RevisionHistory } from '../../../shared_elements';
import { permissionCheckFn } from '../../../utils';
import portfolioExportIcon from '../../../assets/images/portfolio_export_arrow.svg';
const Portfolio = ({ match, item, history, downloadAttachments, changeLockStatusFn }) => {
  return (
    <TableRow style={{ cursor: 'pointer' }} hover tabIndex={-1} onClick={(e) => history.push(`/portfolio/${item.slug}`)}>
      <TableCell style={{ width: "60px" }} >{item.id}</TableCell>
      <TableCell style={{ width: "150px" }}>{item.name}</TableCell>
      <TableCell style={{ width: "150px" }}>{item.utility_type == 'Future Value' ? 'BV and CMV & FBV' : 'BV and CMV'}</TableCell>
      <TableCell>{item.assets_count ? item.assets_count : 0}</TableCell>
      <TableCell>{item.report_date ? moment(item.report_date).format(displayDateFormatShort) : '--'}</TableCell>
      <TableCell>{item.base_value ? `US$ ${item.base_value.toFixed(3)} m` : '--'}</TableCell>
      <TableCell>{item.current_market_value ? `US$ ${item.current_market_value.toFixed(3)} m` : '--'}</TableCell>
      <TableCell style={{ width: "200px" }}>{item.remarks ? item.remarks : '--'}</TableCell>
      <TableCell>{item.status && item.status.label ? <span className={`portfolio-status portfolio-status-${item.status.value}`}>{item.status.label}</span> : '--'}</TableCell>
      <TableCell style={{ width: "100px" }} >
        <ul className="list-inline flex-centered">
          {permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'LOCK_UNLOCK' }) ?
            <li className="list-inline-item" onClick={(e) => { e.stopPropagation(); e.preventDefault(); changeLockStatusFn(item) }}>
              <Tooltip title={item.is_locked ? 'Unlock' : 'Lock'}>
                {item.is_locked ?
                  <LockIcon color="primary" fontSize="small" /> :
                  <LockOpenIcon color="primary" fontSize="small" />
                }
              </Tooltip>
            </li> : null
          }
          <li className="list-inline-item" onClick={(e) => { e.stopPropagation(); e.preventDefault();; downloadAttachments() }}>
            <Tooltip title="Attachments">
                <img src={portfolioExportIcon} alt="" style={{width:'22px'}} />
            </Tooltip>
          </li>
          <li className="list-inline-item">
            <RevisionHistory
              url="audit/valuation/vtportfolio/"
              queryParams={{ action: 1, object_id: item.id }}
              buttonType={true}
              component={true}
              exportRevisionHistory={true}
              exportQueryparam={{ action: 1, object_id: item.id, download: 'xls' }}
              moduleType={"Portfolio Valuation"}
              exportPermission={true}
            />
          </li>
          <li className="list-inline-item">
            <PopOverInfo item={item} />
          </li>
        </ul>
      </TableCell>
    </TableRow>
  )
}
export default withRouter(Portfolio);
