import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import moment from 'moment';
import { errorCode } from '.'
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';

export function getAircraftModsApi(props = {}, query = {}) {
  globalGetService(`valuation/mods/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ mods: response.data.data.mods });
      }
    })
}
export function getMntEventsApi(props = {}, aircraftTypeId) {
  globalGetService(`console/aircraft-type/${aircraftTypeId}/events/`, { module: 'valuation' })
    .then(response => {
      if (checkApiStatus(response)) {
        let maintanenceList = [];
        const data = response.data.data;
        Object.keys(data).map((item, index) => {
          maintanenceList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
        });
        this.setState({ mntGroups: maintanenceList });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getLevLogApi(props) {
  return new Promise(function (resolve, reject) {
    globalGetService(`valuation/lease-encumbered-value/${props.match.params.id}/`)
      .then(response => {
        resolve(response);
      })
  })
}
export function changeLogStatusApi(props, data) {
  this.setState({ pageLoader: true })

  globalPutService(`valuation/update-lock/`, data)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState(prevState => ({
          ...prevState,
          leaseEnCrud: {
            ...prevState.leaseEnCrud,
            lock_status: data.is_locked,
          },
          mode: data.is_locked ? 'view' : prevState.mode
        }))
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function exportResultApi(props, query = {}, data) {
  this.setState({ pageLoader: true })
  globalExportService(`valuation/lease-encumbered-value/${props.match.params.id}/`, { download: query.extension })
    .then(response => {
      trackActivity('Exported Result', {
        type: "Lease Encumbered Value",
        log_id: props.match.params.id,
        extension: query.extension
      })
      this.setState({ pageLoader: false });
      downloadFileType(response.data, (`${data.id}_Lease_Encumbered_Value_${moment().format(displayDateFormatShort)}`), query.extension);
    })
}

export function addEditLevLogApi(props, data, getResponseback) {
  let payload = Object.assign({}, data);
  this.setState({ pageLoader: true })
  if (payload.mods.length) {
    payload = {
      ...payload,
      mods: payload.mods.map(item => item.id)
    }
  }
  if (payload.is_full_life_maintenance_condition > 0) {
    payload = {
      ...payload,
      engine_sv_details: payload.engine_sv_details.engines.slice(0, payload.engine_sv_details.no_of_engines),
    }
  }
  if (props.match.params.id) {
    globalPutService(`valuation/lease-encumbered-value/${props.match.params.id}/`, payload)
      .then(response => {
        if (checkApiStatus(response)) {
          trackActivity('Updated Log', {
            type: "Lease Encumbered Value",
            log_id: response.data.data.id
          })
          props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          props.history.push(`/lev/${response.data.data.generic.slug}`)
          getResponseback()
        } else {
          props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
        this.setState({ pageLoader: false })
      })
  } else {
    globalPostService(`valuation/lease-encumbered-value/`, payload)
      .then(response => {
        if (checkApiStatus(response)) {
          trackActivity('Created Log', {
            type: "Lease Encumbered Value",
            log_id: response.data.data.id
          })
          props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          props.history.push(`/lev/${response.data.data.generic.slug}`)
        } else {
          props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
        this.setState({ pageLoader: false })
      })
  }
}