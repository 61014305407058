import React from 'react';
import moment from 'moment';
import { TableRow, TableCell, Tooltip, Chip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import InfoIcon from '@material-ui/icons/Info';
import { displayDateFormatShort } from '../../../constants'
import { permissionCheckFn } from '../../../utils'
const AssetBVCMV = ({item, portfolioDetail, toggleDeleteModalFn}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div className="asset-details">
          <h4>MSN {item.serial_number}</h4>
          <p>{item.aircraft_type && item.aircraft_type.name ? item.aircraft_type.name:'--'}</p>
          <span style={{display:'none'}}>REG:  <b>{item.registration_number ? item.registration_number:'--'}</b></span>
        </div>
      </TableCell>
      <TableCell>{item.airlines_name ? item.airlines_name:'--'}</TableCell>
      <TableCell>{item.date_of_manufacture ? moment(item.date_of_manufacture).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.engine_type && item.engine_type.name ? item.engine_type.name:'--'}</TableCell>
      <TableCell>{item.engines_serial_numbers.split(',').map((label, index) => <Chip style={{marginRight:'3px'}} variant="outlined" size="small" label={label} />)}</TableCell>
      <TableCell>
        <div className="flex-centered">
          {item.mtow ? item.mtow.toFixed(2) : '--'} Lbs
          <Tooltip arrow title={item.mtow_kgs ? `${item.mtow_kgs.toFixed(2)} Kgs` : '--'}>
            <InfoIcon color="default" style={{fontSize:'13px', paddingLeft:'4px'}} />
          </Tooltip>
        </div>


      </TableCell>
      <TableCell>{item.mods && item.mods.length ? item.mods.map((mod) => <Chip style={{marginRight:'3px'}} variant="outlined" size="small" label={mod.name} />)  :'--'}</TableCell>
      <TableCell>${item.cmv_value ? item.cmv_value.toFixed(3) : '--'} m</TableCell>
      <TableCell>${item.base_value ? item.base_value.toFixed(3) : '--'} m</TableCell>
      <TableCell>
        { !portfolioDetail.is_locked && permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'D' }) ?
          <DeleteOutlinedIcon
            color="secondary"
            onClick={() => toggleDeleteModalFn()}
            style={{cursor:'pointer'}}
          />:null
        }

      </TableCell>
    </TableRow>
  )
}
export default AssetBVCMV;
