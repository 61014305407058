import React from 'react';
const FBVHD = ({years}) => {
  return(
    <thead className="outer-head">
      <tr>
        <th>Asset</th>
        {years.map((year,index) =>
          <th width="150">{year}</th>
        )}
      </tr>
    </thead>
  )
}
export default FBVHD;
