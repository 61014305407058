import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { permissionCheckFn } from '../../../utils';
const AddUtilsBtn = ({ history, props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const alertError = (module) => {
    props.enqueueSnackbar('You are not subscribed to ' + module + ', please contact support@sparta.aero to subscribe', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  };
  return (
    <Fragment>
      <Button endIcon={<ExpandMoreIcon color="white" fontSize="small" />} color="primary" variant="contained" onClick={handleClick}>
        Generate
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: '50px' }}
      >
        <MenuItem onClick={(event) => { handleClose(event); permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'C' }) ? history.push(`/logs/create?utility_type=1`) : alertError('BV and CMV'); event.preventDefault() }}>BV and CMV</MenuItem>
        <MenuItem onClick={(event) => { handleClose(event); permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'C' }) ? history.push(`/logs/create?utility_type=2`) : alertError('Future Base Value'); event.preventDefault() }}>Future Base Value</MenuItem>
        <MenuItem onClick={(event) => { handleClose(event); permissionCheckFn({ secondaryKey: 'blr_mlr', keyIndex: 'C' }) ? history.push(`/blr/create`) : alertError('Base Lease Rates'); event.preventDefault() }}>Base Lease Rates</MenuItem>
        <MenuItem onClick={(event) => { handleClose(event); permissionCheckFn({ secondaryKey: 'lev', keyIndex: 'C' }) ? history.push(`/lev/create`) : alertError('Lease Encumbered Value'); event.preventDefault() }}>Lease Encumbered Value (LEV)</MenuItem>
        <MenuItem onClick={(event) => { handleClose(event); permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'C' }) ? history.push(`/half-life/initiate`) : alertError('Maintenance Adjusted Base Value'); event.preventDefault() }}>Maint. Adj Base Value</MenuItem>
      </Menu>
    </Fragment>
  )
}
export default withRouter(withSnackbar(AddUtilsBtn));
