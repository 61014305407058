import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import HalfLifeMaintenance from './containers'
import HlaEvents from './containers/HlaEvents'
import HlaOutput from './containers/HlaOutput'
export const hlaRoutes = [
  {
    path:'/hla/create',
    component:pageLayoutHoc(userRoleBaseAccessHoc(HalfLifeMaintenance,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'HalfLifeMaintenance'
  },
  {
    path:'/hla/event/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(HlaEvents,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'HlaEvents'
  },
  {
    path:'/hla/output/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(HlaOutput,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'HlaOutput'
  },
]
