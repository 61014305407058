import React from 'react';
import { TableRow, TableCell, Avatar } from '@material-ui/core';
const RoleBaseUser = ({item}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell><span className="flex-centered"><Avatar style={{marginRight:'10px'}} src={item.profile_pic} /> {item.name}</span></TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.designation}</TableCell>
    </TableRow>
  )
}
export default RoleBaseUser;
