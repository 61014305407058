import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { checkUserTypeFn, permissionCheckFn } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const EngineHSIEvent = ({error, item, itemIndex, onFieldChange, updateErrorField, crudData, mode, permissionFlag}) => {
  return(
    <Fragment>
      <Grid item md={4} xs={12}>
        <TextField
          id={`tsn_at_hsi_${itemIndex}`}
          label='TSN @ Last HSI'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.tsn_at_hsi?item.tsn_at_hsi:''}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_hsi', e.target.value): e.preventDefault()}}
          error={error.tsn_at_hsi ? true : false}
          helperText={error.tsn_at_hsi ? error.tsn_at_hsi : ''}
          onFocus={() => updateErrorField('tsn_at_hsi', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          id={`csn_at_hsi_${itemIndex}`}
          label='CSN @ Last HSI'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.csn_at_hsi?item.csn_at_hsi:''}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_hsi', e.target.value): e.preventDefault()}}
          error={error.csn_at_hsi ? true : false}
          helperText={error.csn_at_hsi ? error.csn_at_hsi : ''}
          onFocus={() => updateErrorField('csn_at_hsi', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            id={`last_hsi_event_date_${itemIndex}`}
            required
            disabled={mode === 'view' || !permissionFlag? true:false}
            margin="normal"
            label="Last HSI Event Date"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            minDate={moment().subtract(25, 'years')}
            onChange={(data, value) => onFieldChange(value, 'last_hsi_event_date', data ? moment(data).format(backendDateFormat) : data)}
            value={item.last_hsi_event_date?item.last_hsi_event_date:null}
            error={error.last_hsi_event_date ? true : false}
            helperText={error.last_hsi_event_date ? error.last_hsi_event_date : ''}
            onFocus={() => updateErrorField('last_hsi_event_date', '')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode === 'view' || !permissionFlag? true:false}
          options={item.engine_hsi ? item.engine_hsi:[]}
          getOptionLabel={option => option.event}
          id={`last_performed_hsi_${itemIndex}`}
          value={item.last_performed_hsi?item.last_performed_hsi:null}
          onChange={(e, value) => onFieldChange(e, 'last_performed_hsi', value)}
          renderInput={params => <TextField error={error.last_performed_hsi ? true : false} helperText={error.last_performed_hsi ? error.last_performed_hsi : ''} onFocus={() => updateErrorField('last_performed_hsi', '')} {...params} label="Last Performed HSI" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>

      { checkUserTypeFn() && permissionCheckFn({secondaryKey:'hthl', keyIndex:'OV'}) ?
        <Fragment>
          <Grid item md={4} xs={12}>
            <TextField
              id={`interval_fh_hsi_${itemIndex}`}
              label='HSI Interval'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.interval_fh_hsi?item.interval_fh_hsi:''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'interval_fh_hsi', e.target.value): e.preventDefault()}}
              error={error.interval_fh_hsi ? true : false}
              helperText={error.interval_fh_hsi ? error.interval_fh_hsi : ''}
              onFocus={() => updateErrorField('interval_fh_hsi', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id={`cost_hsi_${itemIndex}`}
              id="cost_hsi"
              label='HSI Cost'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.cost_hsi?item.cost_hsi:''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'cost_hsi', e.target.value): e.preventDefault()}}
              error={error.cost_hsi ? true : false}
              helperText={error.cost_hsi ? error.cost_hsi : ''}
              onFocus={() => updateErrorField('cost_hsi', '')}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id={`remarks_hsi_${itemIndex}`}
              id="remarks_hsi"
              label='HSI Remarks'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 250 }}
              InputLabelProps={{ shrink: true }}
              value={item.remarks_hsi?item.remarks_hsi:''}
              onChange={(e) => onFieldChange(e, 'remarks_hsi', e.target.value)}
              error={error.remarks_hsi ? true : false}
              helperText={error.remarks_hsi ? error.remarks_hsi : ''}
              onFocus={() => updateErrorField('remarks_hsi', '')}
              variant="outlined"
              multiline={true}
            />
          </Grid>
        </Fragment>:null
      }

    </Fragment>
  )
}
export default EngineHSIEvent;
