import React, { Fragment, useState } from 'react';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import { Button, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableRow, TableCell } from '@material-ui/core';
import { TableListComp } from '../../../shared_elements';
import LlpBreakupTable from './LlpBreakupTable';
import LlpForecastBreakup from './LlpForecastBreakup';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import { displayDateFormatShort } from '../../../constants'
import { componentBreakdownHd, componentBreakdownHd1 } from '../';
const ComponentBreakDown = ({halfLifeResult}) => {
  const [modal, setModal] = useState(false);
  const [tabIndex, setTab] = useState(0);
  return(
    <Fragment>
      <Button onClick={() => setModal(!modal)} color="primary" variant="contained">Show</Button>
      { modal ?
        <Dialog
          open={modal}
          onClose={() => setModal(!modal)}
          fullScreen
        >
          <DialogTitle id="scroll-dialog-title">
            Component Breakdown
          </DialogTitle>
          <DialogContent dividers={true}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setTab(newValue)}
            >
              <Tab label='Report Date' />
              {halfLifeResult.forecast_date && halfLifeResult.forecasted_major_assembly && halfLifeResult.forecasted_major_assembly.length ? <Tab label='Forecast Date' />:null}
            </Tabs>
            { tabIndex === 0 ?
              <div>
                <TableListComp
                  heads={componentBreakdownHd}
                  data={halfLifeResult.major_assembly.map((item) =>
                    {
                      let style = {}
                      if(item.flag && item.flag == 1){
                        style = {color:'red'}
                      }
                      return(
                        <TableRow>
                          <TableCell style={style}>
                            {item.component}<br/>
                            { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('U') != -1 &&
                              item.flag && item.flag == 1 ?
                              <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>
                                { item.event == 'LLP' ?
                                  'LLPs have reached its life limit, view breakdown. Select Modify Query to update, if applicable.':`${item.component} has consumed the expected MTBR interval. Select Modify Query to update, if applicable.`
                                }
                              </span>
                              : item.flag && item.flag == 1 ?
                              <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>
                                { item.event == 'LLP' ?
                                  'LLPs have reached its life limit, view breakdown.':`${item.component} has consumed the expected MTBR interval.`
                                }
                              </span>
                              : null
                            }
                          </TableCell>
                          <TableCell style={style}>{item.event}</TableCell>
                          { item.event !== 'LLP'?
                            <Fragment>
                              <TableCell style={style}>
                                {item.next_due ? item.datatype == "date" ?  moment(item.next_due).format(displayDateFormatShort): item.next_due : '--' }
                              </TableCell>
                              <TableCell style={style}>
                                {item.full_life_rem ? `${parseFloat(item.full_life_rem).toFixed(2)} %`:item.event === "LLP" ? '--' : '0 %'  }
                              </TableCell>
                              <TableCell style={style}>
                                {item.half_life_rem ? `${item.half_life_rem} %`: '--' }
                              </TableCell>
                            </Fragment>:
                            <TableCell  align="center" colSpan={3}>
                              <LlpBreakupTable halfLifeResult={halfLifeResult} item={item} />
                            </TableCell>
                          }
                          <TableCell style={style}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adj}</NumericalLabel></TableCell>
                          <TableCell style={style}>
                            <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:3})}>{item.cost?item.cost:0}</NumericalLabel> m
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                />
              </div>:null
            }
            { tabIndex === 1 ?
              <div>
                <TableListComp
                  heads={componentBreakdownHd1}
                  data={halfLifeResult.forecasted_major_assembly.map((item) =>
                    {
                      let style = {}
                      if(item.flag && item.flag == 1){
                        style = {color:'red'}
                      }
                      return(
                        <TableRow>
                          <TableCell className="mobile-tablecell" style={style} >
                            {item.component} <br/>
                          { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('U') != -1 &&
                            item.flag && item.flag == 1 ?
                            <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>
                              {
                                item.event == 'LLP' ?
                                'LLPs have reached its life limit, view breakdown. Select Modify Query to update, if applicable.'
                                :
                                `${item.component} has consumed the expected MTBR interval. Select Modify Query to update, if applicable.`
                              }
                            </span>
                            : item.flag && item.flag == 1 ?
                            <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>
                              {
                                item.event == 'LLP' ?
                                'LLPs have reached its life limit, view breakdown.'
                                :
                                `${item.component} has consumed the expected MTBR interval.`
                              }
                            </span>
                            : null
                          }
                          </TableCell>
                          <TableCell className="mobile-tablecell llp-table" style={style}>{item.event}</TableCell>
                          {
                            item.event !== "LLP" ?
                            <Fragment>
                              <TableCell className="mobile-tablecell llp-table" style={style}>
                                {item.last_event_before_forecast ?
                                   item.component.includes('Engine') || item.component.includes('APU')  ?
                                   item.last_event_datatype == "date" ?
                                   moment(item.last_event_before_forecast).format(displayDateFormatShort): item.last_event_before_forecast :
                                   item.datatype == "date" ?
                                   moment(item.last_event_before_forecast).format(displayDateFormatShort):
                                   item.last_event_before_forecast : '--' }
                                 </TableCell>
                              <TableCell className="mobile-tablecell" style={style}>{item.full_life_rem ? `${parseFloat(item.full_life_rem).toFixed(2)} %`:item.event === "LLP" ? '--' : '0 %'  }</TableCell>
                              <TableCell className="mobile-tablecell" style={style}>{item.half_life_rem ? `${item.half_life_rem} %`: '--' }</TableCell>
                            </Fragment>:
                            <TableCell className="mobile-tablecell" colSpan={3} align="center">
                              <LlpForecastBreakup halfLifeResult={halfLifeResult} item={item} />
                            </TableCell>
                          }
                          <TableCell className="mobile-tablecell" style={style}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adj}</NumericalLabel></TableCell>
                          <TableCell className="mobile-tablecell" style={style}>{item.cost ?
                              <Fragment><NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:3})}>{item.cost}</NumericalLabel> M</Fragment>
                                : '--' }
                          </TableCell>
                          <TableCell className="mobile-tablecell llp-table" style={style}>
                            {item.next_event_after_forecast ?
                              item.component.includes('Engine') || item.component.includes('APU')   ?
                              item.next_event_datatype == "date" ?
                              moment(item.next_event_after_forecast).format(displayDateFormatShort): item.next_event_after_forecast :
                              item.datatype == "date" ?
                              moment(item.next_event_after_forecast).format(displayDateFormatShort) :
                              item.next_event_after_forecast : '--' }
                            </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                />
              </div>:null
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(!modal)} color="primary" variant="outlined" size="small">Close</Button>
          </DialogActions>
        </Dialog>:null
      }
    </Fragment>
  )
}
export default ComponentBreakDown;
