import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Grid, Paper, Table, TableBody } from '@material-ui/core';
import { FleetHeader, FleetList, AddEditAsset, ImportFleet } from '../components'
import { Pagination, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements'
import { STableLoader } from '../../../shared_elements/loaders'
import { globalGetService, globalExportService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus, removeEmptyKey, downloadFileType } from '../../../utils';
import { fleetFilters, addAssetObj } from '../'
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
const Fleet = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [fleetInfo, setFleetInfo] = useState({list:[], pagination:{}});
    const [addEditAsset, setAddEditAsset] = useState({modal:false, mode:'', data:null});
    const [sortInfo, setSortInfo] = useState({sort:'', sort_by:''});
    const [filter, setFilter] = useState({});
    const [deleteFleetAsset, setDeleteFleetAsset] = useState({modal:false, data:null})
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    useEffect(() => {
        getFleet({},'skeletonLoader');
        getAircraftType();
        getEngineTypes();
        console.log('Hi');
    },[]);
    const getAircraftType = () => {
        globalGetService(`console/aircraft-types/`,{valuation:'valuation'})
        .then(response => {
            if(checkApiStatus(response)){
                setAircraftTypes(response.data.data.aircraftTypes)
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`,{valuation:'valuation'})
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getFleet = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`valuation/fleet/`, removeEmptyKey(query))
        .then(response => {
            if(checkApiStatus(response)){
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFleetInfo(response.data.data);
                setFilter(query)
            }else{

            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getFleet({...filter, ...sortQuery, page:1, per_page:fleetInfo.pagination.per_page},'pageLoader');
    }

    const onDeleteFleetAsset = () => {
        setLoading(true);
        globalDeleteService(`valuation/fleet/${deleteFleetAsset.data.id}/`,)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteFleetAsset({modal:false,data:null})
                getFleet({...sortInfo, ...filter, page:1, per_page:fleetInfo.pagination.per_page}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }   
        })
    }
    const onExportFleet = () => {
        globalExportService(`valuation/fleet/`,{download:'xls'})
        .then(response => {
            downloadFileType(response.data, 'Fleet Data', 'xls');
            enqueueSnackbar('Fleet report exported successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
        })
    }
    let filterOptions = Object.assign({}, fleetFilters);
    filterOptions = {
        ...filterOptions,
        'aircraft_type':{
            ...filterOptions['aircraft_type'],
            'options': aircraftTypes
        },
        'engine_type':{
            ...filterOptions['engine_type'],
            'options': engineTypes
        }
    }
    return(
        <section className=''>
            <DeploymentMessage />
            {skeletonLoader ? <STableLoader count={5} />:
                <>
                    <Paper style={{padding:'5px 10px'}} square>
                        <Grid container spacing={1}>
                            <Grid item md={8}>
                                <FilterComponent 
                                    filter={filter} 
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getFleet({...applyFilter, ...sortInfo, page:1, per_page:fleetInfo.pagination.per_page}, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item md={4} style={{textAlign:'right'}}>
                                <ul className='list-inline'>
                                    <li className='list-inline-item'>
                                        <ImportFleet 
                                            getResponseBack={() => getFleet({...filter, ...sortInfo, page:1, per_page: fleetInfo.pagination.per_page}, 'pageLoader')}
                                        />
                                    </li>
                                    <li className='list-inline-item'>
                                        <Button onClick={() => setAddEditAsset({modal:true,mode:'add', data:addAssetObj})} color='primary' size='small' variant='contained'>Add Asset</Button>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Button onClick={onExportFleet} color='primary' size='small' variant='outlined'>Export</Button>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper>
                        <Table className='mui-styled-table'>
                            <FleetHeader 
                                filter={sortInfo}
                                createSortHandler={createSortHandler}
                            />
                            <TableBody>
                                {fleetInfo.list.map((item, index) =>
                                    <FleetList 
                                        index={index} 
                                        item={item} 
                                        onEdit={() => setAddEditAsset({modal:true,mode:'add', data:{...item, mtow:(parseFloat(item.mtow)*2.2046).toFixed(4), mtow_kgs:(parseFloat(item.mtow)).toFixed(4)}})}
                                        onDelete={() => setDeleteFleetAsset({modal:true, data:item})}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        <Pagination 
                            pagination={fleetInfo.pagination}
                            onChangePage={(event, newPage) => getFleet({...filter, ...sortInfo, page:newPage+1, per_page: fleetInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getFleet({...filter, ...sortInfo, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { fleetInfo.list.length ? null :<div className='text-center'><EmptyCollection title='No records found'/></div>}
                    </Paper>
                </>
            }
            { addEditAsset.modal ?
                <AddEditAsset 
                    aircraftTypes={aircraftTypes}
                    addEditAsset={addEditAsset}
                    toggleModalFn={() => setAddEditAsset({modal:false, mode:'', data:null})}
                    getResponseBack={() => getFleet({...filter, ...sortInfo, page:1, per_page: fleetInfo.pagination.per_page}, 'pageLoader')}
                />:null
            }
            {deleteFleetAsset.modal &&
                <DeletePopUp
                    modal={deleteFleetAsset.modal}
                    toggleModalFn={() => setDeleteFleetAsset({modal:false, data:null})}
                    title={'Delete Asset'}
                    content={<h4>Are you sure you want to Delete?</h4>}
                    deleteRecordFn={() => onDeleteFleetAsset()}
                />
            }
            { isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default Fleet;