import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Paper, Tooltip, Grid, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { TableListComp, EmptyCollection, FilterUIComp, PageLoader, LabelValueCard, DeletePopUp, RevisionHistory } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { AssetBVCMV, PortfolioBVCMVWidget, PortfolioBasicDetail, AddEditPortfolio, PortfolioAnalytic, PortfolioHeader } from '../components';
import PieChartIcon from '@material-ui/icons/PieChart';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getPortfolioAircraftsApi, getPortfolioEngineTypesApi, lockUnlockPortfolioApi, getPortfolioBVLogsApi, getPortfolioApi, exportPortfolioReportApi, deletePortfolioLogApi, getPortfolioAnalyticApi } from '../apiServices';
import { removeEmptyKey, convertFilterObject, permissionCheckFn } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
import { assetBVCMVHd, portfolioBVFilterOps } from '../'
class PortfolioView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticFlag: false,
      skeletonLoader: true,
      pageLoader: false,
      portfolioLoader: false,
      lockModal: false,
      lockIds: null,
      deleteModal: false,
      deleteIds: '',
      portfolioBV: { logs: [], pagination: {} },
      portfolioDetail: {},
      filter: {},
      applyingFilter: {},
      portfolioAircrafts: [],
      portfolioEngineTypes: [],
      portfolioAnalytic: {}
    };
    this.getPortfolioApi = getPortfolioApi.bind(this);
    this.getPortfolioBVLogsApi = getPortfolioBVLogsApi.bind(this);
    this.exportPortfolioReportApi = exportPortfolioReportApi.bind(this);
    this.deletePortfolioLogApi = deletePortfolioLogApi.bind(this);
    this.lockUnlockPortfolioApi = lockUnlockPortfolioApi.bind(this);
    this.getPortfolioAircraftsApi = getPortfolioAircraftsApi.bind(this);
    this.getPortfolioEngineTypesApi = getPortfolioEngineTypesApi.bind(this);
    this.getPortfolioAnalyticApi = getPortfolioAnalyticApi.bind(this);
  }
  componentDidMount() {
    this.getPortfolioApi(this.props, 'portfolioLoader');
    this.getPortfolioBVLogsApi(this.props, {}, 'skeletonLoader');
    this.getPortfolioAnalyticApi(this.props);
    trackActivity('Portfolio', {
      event_type: 'Page Visited',
      page_title: 'Portfolio View'
    })
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if (rangeKey) {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]: value
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, portfolioBV } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    delete applyingFilter[keyParam]
    this.getPortfolioBVLogsApi(this.props, { ...query, per_page: portfolioBV.pagination.per_page }, 'pageLoader');
    this.setState({ applyingFilter })
  }
  render() {
    const { analyticFlag, portfolioLoader, skeletonLoader, pageLoader, lockModal, lockIds, applyingFilter, filter, sort, sort_by, portfolioDetail, portfolioBV, deleteModal, deleteIds, portfolioAircrafts, portfolioEngineTypes, portfolioAnalytic } = this.state;
    const { engineTypes } = this.props;
    let filterOptions = portfolioBVFilterOps;
    filterOptions = {
      ...filterOptions,
      aircraft_type: {
        ...filterOptions.aircraft_type,
        options: portfolioAircrafts
      },
      engine_type: {
        ...filterOptions.engine_type,
        options: portfolioEngineTypes
      }
    }
    return (
      <section className="portfolio-section">
        <PortfolioHeader
          skeletonLoader={skeletonLoader}
          portfolioDetail={portfolioDetail}
          pageLoader={pageLoader}
          toggleLockFn={() => this.setState({ lockModal: true, lockIds: { slug: this.props.match.params.slug, is_locked: portfolioDetail.is_locked, id: portfolioDetail.id, name: portfolioDetail.name } })}
          getResponseBack={() => this.getPortfolioApi(this.props, 'portfolioLoader')}
          exportPortfolioReport={() => this.exportPortfolioReportApi(this.props)}
          changeLockStatus={(data) => this.lockUnlockPortfolioApi(this.props, data, 'detail')}
        />
        <Paper className="portfolio-widget-card">
          <ul className="list-inline analytic-sw flex-centered">
            <li className="list-inline-item">Value as Of: <span>{moment(portfolioDetail.report_date).format(displayDateFormatShort)}</span></li>
            <li className="list-inline-item">
              <Button
                startIcon={!analyticFlag ? <PieChartIcon color="white" fontSize="small" /> : <ArrowBackIosIcon color="white" fontSize="small" />}
                onClick={() =>
                  this.setState(prevState => ({
                    ...prevState,
                    analyticFlag: !prevState.analyticFlag
                  }))
                }
                color="primary"
                variant="contained"
                size="small">
                {analyticFlag ? 'List' : 'Dashboard'}
              </Button>

            </li>
          </ul>
          <PortfolioBVCMVWidget
            portfolioAnalytic={portfolioAnalytic}
            portfolioDetail={portfolioDetail}
            portfolioAircrafts={portfolioAircrafts}
          />
        </Paper>
        {skeletonLoader ? <STableLoader count={8} /> :
          !analyticFlag &&
          <div style={{ marginBottom: '30px' }}>
            <FilterUIComp
              filter={filter}
              applyingFilter={applyingFilter}
              removeFilter={this.removeFilter}
              removeAllFilter={() => { this.setState({ applyingFilter: {} }); this.getPortfolioBVLogsApi(this.props, removeEmptyKey({ per_page: portfolioBV.pagination.per_page, sort: sort, sort_by: sort_by }), 'pageLoader') }}
              submitFilter={() => this.getPortfolioBVLogsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions) }, 'pageLoader')}
              applyFilter={this.applyFilter}
              filterMenu={filterOptions}
            />
            <TableListComp
              heads={assetBVCMVHd}
              data={portfolioBV.logs.map((item, index) =>
                <AssetBVCMV
                  key={index}
                  item={item}
                  toggleDeleteModalFn={() => this.setState({ deleteModal: true, deleteIds: item.id })}
                  portfolioDetail={portfolioDetail}
                />
              )}
              noRecord={portfolioBV.logs.length ? null :
                <EmptyCollection
                  title="No records found"
                />
              }
              pagination={portfolioBV.pagination}
              onChangePage={(event, newPage) => this.getPortfolioBVLogsApi(this.props, removeEmptyKey({ ...filter, sort: sort, sort_by: sort_by, page: newPage + 1, per_page: portfolioBV.pagination.per_page }), 'pageLoader')}
              onChangeRowsPerPage={(event) => this.getPortfolioBVLogsApi(this.props, removeEmptyKey({ ...filter, sort: sort, sort_by: sort_by, page: 1, per_page: event.target.value }), 'pageLoader')}
            />
          </div>
        }
        {analyticFlag && Object.keys(portfolioAnalytic).length ?
          <PortfolioAnalytic
            portfolioAnalytic={portfolioAnalytic}
          /> : null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({ deleteModal: false, deleteIds: {} })}
          title={`Delete`}
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deletePortfolioLogApi(this.props, deleteIds)}
        />
        {lockModal &&
          <DeletePopUp
            modal={lockModal}
            toggleModalFn={() => this.setState({ lockModal: false, lockIds: null })}
            title={`Portfolio ${lockIds && lockIds.is_locked ? 'Unlock' : 'Lock'}`}
            content={<h4>Are you sure you want to {lockIds && lockIds.is_locked ? 'Unlock' : 'Lock'}?</h4>}
            deleteRecordFn={() => this.lockUnlockPortfolioApi(this.props, lockIds, 'detail')}
            confirmText='YES'
          />
        }
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
export default withSnackbar(PortfolioView)
