import React, { Fragment, useEffect, useState } from 'react';
import dialWidget from '../../../assets/images/dial_widget.png';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { Tooltip } from '@material-ui/core';

const DialWidgetCard = ({ label, value, unit, width = '204px', show_dollar = '$', precision = 3, tooltip }) => {
  const [dialWidth, setDialWidth] = useState(width);
  useEffect(() => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 1400) {
      setDialWidth('200px');
    }
  }, [dialWidth]);
  const getFontSize = () => {
    let string = value + '' + unit;
    if (string.length < 9) {
      return '20px'
    }
    else {
      return '20px'
    }
  }
  return (
    <div className='dial-widget-cnt'>
      <span className="dial-widget-card">
        <img style={{ width: dialWidth }} src={dialWidget} alt="" />
        <span style={{ fontSize: getFontSize() }} className="card-number">
          {tooltip ?
            <Tooltip title={<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: precision, show_currency_text: true })}>{tooltip}</NumericalLabel>}>
              <p>
                {value ?
                  <Fragment>
                    {show_dollar}
                    <NumericalLabel
                      params={getCurrencyFormat({ shortFormat: false, precision: precision, show_currency_text: false })}>{value ? parseFloat(value) : 0}</NumericalLabel>
                    {' ' + unit}
                  </Fragment> : '--'
                }
              </p>
            </Tooltip>
            :
            value ?
              <Fragment>
                {show_dollar}<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: precision, show_currency_text: false })}>{value ? value : 0}</NumericalLabel>
                {' ' + unit}
              </Fragment> : '--'
          }
        </span>
      </span>
      <p className="card-label">{label}</p>
    </div>
    
  )
}
export default DialWidgetCard;
