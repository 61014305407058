import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const BVCMVPieChart = ({data,legend,title}) => {
  const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height:250
    },
    title:{text:''},
    credits: {enabled: false},
    accessibility: {
      point: {
          valueSuffix: '$'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
            enabled: false
        }
      }
    },
      legend: {
        layout: 'vertical',
        backgroundColor: '#FFFFFF',
        floating: true,
        align: 'right',
        verticalAlign: 'middle',

      },
      title: {
       text: title,
       verticalAlign: 'bottom',
       style:{ "color": "#000000", "fontSize": "14px" },
      },
    tooltip: {
     pointFormat: '{series.name}: <b>US${point.y:.3f} m</b>'
    },
    series: [
      {
        name: legend,
        data: data,
        colorByPoint:true,
      }
    ]
  }
  return(
    <div style={{ clear: 'both' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default BVCMVPieChart;
