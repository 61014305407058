import { backendDateFormat } from '../../constants';
import { regexConstants, errorMsg1 } from '../../constants/regEx';
import moment from 'moment';
export const valuationCrud = {
  serial_number: '',
  base_value_date: moment().format(backendDateFormat),
  date_of_manufacture: null,
  aircraft_type: null,
  engine_type: null,
  mods: [],
  mtow: null,
  mtow_kgs: null,
  projection_to: null
};
export const airframeHlaGenInfo = {
  serial_number: '',
  maintenance_program: null,
  utilization_type: 1,
  tsn: '',
  csn: '',
  planned_fc: null,
  planned_fh: null,
  utilization_as_of_date: moment().format(backendDateFormat)
}
export const engineGenInfo = {
  engineType: null,
  no_of_engines: 1,
  identical_data: true,
  engineInfo: [
    { id: 1, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat) },
    { id: 2, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat) },
    { id: 3, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat) },
    { id: 4, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat) }
  ]
}
export const propellerGenInfo = {
  identical_data: false,
  propellerInfo: [
    { id: 1, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat), position: 1 },
  ]
}
export const genericHlaInfo = {
  mtow: "",
  mods: [],
  lessee_id: null,
  date_of_manufacture: null,
  asset_type: 1,
  report_date: moment().format(backendDateFormat),
  // forecast_date:moment().add(1, 'days').format(backendDateFormat)
}
export const apuGenInfo = {
  apuType: null,
  apu_hours: '',
  utilization_as_of_date: moment().format(backendDateFormat)
}
export const lgGenInfo = {
  lg_utilization_flag: true,
  mlg_utilization_flag: false,
  lgInfo: [
    { id: 1, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat), position: 1 }, // lg_utilization_flag :true// lg_utilization_flag :false
    { id: 2, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat), position: 2 }, // lg_utilization_flag :true mlg_utilization_flag : true // lg_utilization_flag: false mlg_utilization_flag: false
    { id: 3, tsn: '', csn: '', utilization_as_of_date: moment().format(backendDateFormat), position: 3 }, // lg_utilization_flag: false mlg_utilization_flag: false
  ]
}
export const hlaErrorCode = {
  serial_number: {
    0: '',
    4: errorMsg1
  },
  serial_numberObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  aircraft_type: {
    0: "",
    1: 'Please select Aircraft Type'
  },
  aircraft_typeObj: {
    required: true
  },
  maintenance_program: {
    0: '',
    1: 'Please select Maintenance Program'
  },
  maintenance_programObj: {
    required: true
  },
  date_of_manufacture: {
    0: '',
    1: 'Please enter Date of Manufacture',
    5: 'Please enter Valid Date'
  },
  date_of_manufactureObj: {
    required: true,
    datePattern: true
  },
  report_date: {
    0: '',
    1: 'Please enter Report Date',
    5: 'Please enter Valid Date'
  },
  report_dateObj: {
    required: true,
    datePattern: true
  },
  mtow: {
    0: '',
    1: 'Please enter MTOW'
  },
  mtowObj: {
    required: true
  },
  mtow_kgs: {
    0: '',
    1: 'Please enter MTOW in Kg'
  },
  mtow_kgsObj: {
    required: true
  },
  utilization_as_of_date: {
    0: '',
    1: 'Please enter Utilization as of Date',
    5: 'Please enter Valid Date'
  },
  utilization_as_of_dateObj: {
    required: true,
    datePattern: true
  },
  tsn: {
    0: '',
    1: 'Please enter TSN'
  },
  tsnObj: {
    required: true
  },
  csn: {
    0: '',
    1: 'Please enter CSN'
  },
  csnObj: {
    required: true
  },
  engine_type: {
    0: '',
    1: 'Please select Engine Type'
  },
  engine_typeObj: {
    required: true
  },
  region_of_operation: {
    0: '',
    1: 'Please select Region of Operation'
  },
  region_of_operationObj: {
    required: true
  },
  apu_type: {
    0: '',
    1: 'Please select APU Type'
  },
  apu_typeObj: {
    required: true
  },
  apu_hours: {
    0: '',
    1: 'Please enter TSN'
  },
  apu_hoursObj: {
    required: true
  },
  planned_fc: {
    0: '',
    1: 'Please enter Annual Planned FC'
  },
  planned_fcObj: {
    required: true
  },
  planned_fh: {
    0: '',
    1: 'Please enter Annual Planned FH'
  },
  planned_fhObj: {
    required: true
  },
  tsn_at_prsv: {
    0: '',
    1: 'Please enter TSN at Last PRSV'
  },
  tsn_at_prsvObj: {
    required: true
  },
  csn_at_prsv: {
    0: '',
    1: 'Please enter CSN at Last PRSV'
  },
  csn_at_prsvObj: {
    required: true
  },
  last_prsv_event_date: {
    0: '',
    1: 'Please enter Last PRSV Event Date',
    5: 'Please enter Valid Date'
  },
  last_prsv_event_dateObj: {
    datePattern: true
  },
  apu_hours_lsv: {
    0: '',
    1: 'Please enter APU Hours at LSV'
  },
  apu_hours_lsvObj: {
    required: true
  },
  apu_cycles_lsv: {
    0: '',
    1: 'Please enter APU Cycles at LSV'
  },
  apu_cycles_lsvObj: {
    required: true
  },
  apu_lsv_date: {
    0: '',
    1: 'Please enter LSV Date',
    5: 'Please enter Valid Date'
  },
  apu_lsv_dateObj: {
    required: true,
    datePattern: true
  },
  tsn_at_lsv: {
    0: '',
    1: 'Please enter TSN at LSV'
  },
  tsn_at_lsvObj: {
    required: true
  },
  csn_at_lsv: {
    0: '',
    1: 'Please enter CSN at LSV'
  },
  csn_at_lsvObj: {
    required: true
  },
  airframe_lsv_date: {
    0: '',
    1: 'Please enter LSV Date',
    5: 'Please enter Valid Date'
  },
  airframe_lsv_dateObj: {
    datePattern: true
  },
  cost: {
    0: '',
    1: 'Please enter Event Cost'
  },
  costObj: {
    required: true
  },
  remarks: {
    0: '',
    1: 'Please enter Remarks'
  },
  remarksObj: {
    required: true
  },
  interval: {
    0: '',
    1: 'Please enter Interval'
  },
  intervalObj: {
    required: true
  },
  interval_fc: {
    0: '',
    1: 'Please enter Interval FC'
  },
  interval_fcObj: {
    required: true
  },
  interval_mo: {
    0: '',
    1: 'Please enter Interval MO'
  },
  interval_moObj: {
    required: true
  },
  interval_fh: {
    0: '',
    1: 'Please enter interval FH'
  },
  interval_fhObj: {
    required: true
  },
  last_hsi_event_date: {
    0: '',
    1: 'Please enter HSI Event Date',
    5: 'Please enter Valid Date'
  },
  last_hsi_event_dateObj: {
    required: true,
    datePattern: true
  },
  last_event_date: {
    0: '',
    5: 'Please enter Valid Date'
  },
  last_event_dateObj: {
    datePattern: true
  },
  tsn_at_hsi: {
    0: '',
    1: 'Please enter TSN at Last HSI'
  },
  tsn_at_hsiObj: {
    required: true
  },
  tsn_at_ovh: {
    0: '',
    1: 'Please enter TSN at Last OVH'
  },
  tsn_at_ovhObj: {
    required: true
  },
  cost_hsi: {
    0: '',
    1: 'Please enter Cost'
  },
  cost_hsiObj: {
    required: true
  },
  forecast_date: {
    0: '',
    1: 'Please enter Forecast Date',
    5: 'Please enter Valid Date'
  },
  forecast_dateObj: {
    required: true,
    datePattern: true
  }

}
export const errorCode = {
  aircraft_type: {
    0: '',
    1: 'Please select Aircraft Type'
  },
  aircraft_typeObj: {
    required: true,
  },
  serial_number: {
    0: '',
    4: errorMsg1
  },
  serial_numberObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  date_of_manufacture: {
    0: '',
    1: 'Pease enter Date of Manufacture',
    5: 'Please enter Valid Date'
  },
  date_of_manufactureObj: {
    required: true,
    datePattern: true
  },
  engine_type: {
    0: '',
    1: 'Please select Engine Type'
  },
  engine_typeObj: {
    required: true
  },
  mtow: {
    0: '',
    1: 'Please enter MTOW in Lbs'
  },
  mtowObj: {
    required: true
  },
  mtow_kgs: {
    0: '',
    1: 'Please enter MTOW in kgs'
  },
  mtow_kgsObj: {
    required: true
  },
  mtow_kgs: {
    0: '',
    1: 'Please enter MTOW in kgs'
  },
  mtow_kgsObj: {
    required: true
  },
  base_value_date: {
    0: '',
    1: 'Please enter Base Value Date',
    5: 'Please enter Valid Date'
  },
  base_value_dateObj: {
    required: true,
    datePattern: true
  },
  projection_to: {
    0: '',
    1: 'Please enter Projection Date',
    5: 'Please enter Valid Date'
  },
  projection_toObj: {
    required: true,
    datePattern: true
  },
  hours: {
    0: '',
    1: 'Please enter APU Hours'
  },
  hoursObj: {
    required: true
  },
  utilization_as_of_date: {
    0: '',
    1: 'Please enter Utilization as of Date',
    5: 'Please enter a Valid Date'
  },
  utilization_as_of_dateObj: {
    required: true
  },
  csn: {
    0: '',
    1: 'Please enter CSN'
  },
  csnObj: {
    required: true
  },
}
export const valuationFilterOps = {
  aircraft_type: {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    title: 'Aircraft Type',
    multiple: true,
    showDropdown: true
  },
  engine_type: {
    inputType: 'dropdown',
    placeholder: 'Select Engine Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    title: 'Engine Type',
    multiple: true,
    showDropdown: true
  },
  asset_type: {
    inputType: 'dropdown',
    placeholder: 'Select Asset Type',
    options: [{name:'Aircraft', value:1}, {name:'Engine', value:2}],
    labelKey: 'name',
    valueKey: 'value',
    title: 'Asset Type',
    multiple: true,
    showDropdown: true
  },
  log_id: {
    inputType: 'text',
    placeholder: 'Search by ID',
    title: 'Log ID'
  },
  serial_number: {
    inputType: 'text',
    placeholder: 'Search by Serial Number',
    title: 'Serial Number'
  },
  utility: {
    inputType: 'dropdown',
    placeholder: 'Select Utility Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    title: 'Utility',
    multiple: true,
    showDropdown: true
  }

};
export const llpHlaHeads = [
  { label: 'Module', sortOption: false },
  { label: 'Part Number', sortOption: false },
  { label: 'LLP Description', sortOption: false },
  { label: 'Life Limit', sortOption: false },
  { label: 'LLP Cost', sortOption: false },
  { label: 'CSN', sortOption: false },
  { label: 'TCSN', sortOption: false },
  { label: 'Cycles Remaining', sortOption: false },
  { label: "Full Life Remaining (%)", sortOption: false },
  { label: 'Half Life Remaining (%)', sortOption: false },
  { label: 'Half Life MTX adjustment', sortOption: false },
]
export const outputStateData = {
  skeletonLoader: false,
  componentModal: false,
  componentModalForcast: false,
  pageLoader: false,
  hlaOutputData: {},
  llp: [],
  llpForcast: [],
  engineNo: 1,
  exportLoader: false,
  componentTabIndex: 0,
  utlisationDate: null,
  tabIndexLlp: 0,
  tabIndexForcastLlp: 0,
  llpTabForCastName: '',
  llpTabName: ''
}
export const intermediateStateData = {
  changeTab: 0,
  tabIndex: 0,
  lgTabIndex: 0,
  propellerTabIndex: 0,
  llpTabIndex: 0,
  llpTabName: 'PRIMARY LLP',
  forecastDate: null,
  hlaLogDetail: {},
  logDetailCopy: {},
  engineLlpList: [],
  selectedStaticLLps: [],
  files: [],
  engineLLP: {
    primary: [],
    secondary: [],
    static: []
  },
  airframeEdit: false,
  apuEdit: false,
  lgEdit: false,
  enginesEdit: false,
  propellerEdit: false,
  validatorConstant: 1,
  validatorModal: false,
  llpModal: false,
  airframeRemarksFlag: false,
  tabColor: 'primary',
  llpCsnValidationFlag: false,
  llpSecondaryValidationFlag: false,
  llpStaticValidationFlag: false,
  staticLLPEnableFlag: true,
  staticLLPDisableFlag: true,
  engineTabColor: 'primary',
  checkedA: false,
  checkedB: false,
  secondaryLLpEnableFlag: false,
  checkBoxLoader: false,
  confirmationModal: false,
  forecastEdit: false,
  lockEdit: false,
  apuWarningText: '',
  lgWarningText: '',
  airframeWarningText: '',
  propellerWarningText: '',
  engineWarningText: '',
  error: {
    airframe: {},
    engine: {},
    apu: {},
    lg: {},
    propeller: {}
  },
}
