import Roles from './containers/Roles';
import Users from './containers/Users';
import PageLayout from '../../hocs/pageLayoutHoc';
import UserRoleAccess from '../../hocs/userRoleBaseAccessHoc';
export const settingsRoutes = [
  {
    path: '/settings/roles',
    component: PageLayout(Roles, {apps: 'Settings', layoutPhase: 2}),
    key: 'Roles',
    apps:'valuations'
  },
  {
    path: '/settings/users',
    component: PageLayout(UserRoleAccess(Users, ['console', 'manage_user', 'R']), {apps: 'Settings', layoutPhase: 2}),
    key: 'Users',
    apps:'valuations'
  }
]
