import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from '@material-ui/core';
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const AssetFBVGraph = ({ assetFBVModel, fbvLog, portfolioDetail, toggleModalFn }) => {
  let data = [], inflated = [];
  Object.keys(fbvLog.future_value).map(year => {
    let d = [];
    d.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
    d.push(fbvLog.future_value[year]);
    data.push(d);
  });
  Object.keys(fbvLog.inflated_future_value).map(year => {
    let f = [];
    f.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
    f.push(fbvLog.inflated_future_value[year]);
    inflated.push(f);
  });
  const options = {
    chart: {
      type: 'line',
      marginTop: 60,
      zoomType: 'x',
    },
    credits: {
      enabled: false,
      style: {
        fontSize: 12
      },
      position: {
        align: 'left',
        x: 20,
        y: -380
      }
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Projection Year(s)'
      }
    },
    tooltip: {
      formatter: function () {
        return 'The value as of <b>' + moment(this.x).format('MMM YYYY') +
          '</b> is <b>US$ ' + this.y.toFixed(3) + ' M</b>';
      }
    },
    yAxis: {
      title: {
        text: 'US$ Million'
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(4);
        }
      }
    },
    series: [
      {
        name: 'Base Value at 0% Annual Inflation',
        data: data,
        color: 'rgba(241,202,249,1)',
        lineColor: '#BD10E0',
        style: { opacity: 0.3 },
        marker: {
          fillColor: '#BD10E0'
        }
      },
      {
        name: `Base Value at ${portfolioDetail.inflation_rate}% Annual Inflation`,
        data: inflated,
        color: '#BAB9D3',
        opacity: '0.5',
        style: { opacity: 0.5 },
        lineColor: '#150F78',
        marker: {
          fillColor: '#BD10E0'
        }
      },
    ]
  }
  return (
    <Dialog
      maxWidth={"lg"}
      open={assetFBVModel}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      fullScreen={true}
    >
      <DialogTitle id="scroll-dialog-title"  >
        Portfolio FBV: MSN {fbvLog.serial_number}
      </DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '680px' }}>
        <div style={{ clear: 'both' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary">Okay</Button>
      </DialogActions>
    </Dialog>
  )
}
export default AssetFBVGraph;
