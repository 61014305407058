import React from 'react';
import { TableHead, TableSortLabel, TableCell, TableRow } from '@material-ui/core';
const FleetHeader = ({filter, createSortHandler}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={filter?.sort === 'serial_number' ? true:false}
                        direction={filter?.sort === 'serial_number' ? filter.sort_by :'asc'}
                        onClick={()=>createSortHandler('serial_number')}
                    >
                        Serial No.
                    </TableSortLabel>    
                </TableCell>
                <TableCell>
                    <TableSortLabel
                            active={filter?.sort === 'aircraft_type_id' ? true:false}
                            direction={filter?.sort === 'aircraft_type_id' ? filter.sort_by :'asc'}
                            onClick={()=>createSortHandler('aircraft_type_id')}
                        >
                            Aircraft Type
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                            active={filter?.sort === 'engine_type_id' ? true:false}
                            direction={filter?.sort === 'engine_type_id' ? filter.sort_by :'asc'}
                            onClick={()=>createSortHandler('engine_type_id')}
                        >
                            Engine Type
                    </TableSortLabel>
                </TableCell>
                <TableCell>MTOW(Kgs)</TableCell>
                <TableCell>
                <TableSortLabel
                            active={filter?.sort === 'date_of_manufacture' ? true:false}
                            direction={filter?.sort === 'date_of_manufacture' ? filter.sort_by :'asc'}
                            onClick={()=>createSortHandler('date_of_manufacture')}
                        >
                           Date of Manufacture
                    </TableSortLabel>
                </TableCell>
                <TableCell align='right'>Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default FleetHeader;