import React, { Fragment, useState } from 'react';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Tooltip, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableRow, TableCell } from '@material-ui/core';
import { TableListComp } from '../../../shared_elements';
import { getCurrencyFormat, getLocalStorageInfo, checkUserTypeFn } from '../../../utils'
import { displayDateFormatShort } from '../../../constants'
const LlpBreakupTable = ({halfLifeResult, item}) => {
  let llps = halfLifeResult.llp[item.engine_no-1];
  let primaryLlp = llps.filter(llp => llp.llp_type === 1);
  let secondaryLlp = llps.filter(llp => llp.llp_type === 2);
  let staticLlp = llps.filter(llp => llp.llp_type === 3);
  const [modal, setModal] = useState(false);
  const [tabIndex, setTab] = useState(0);
  const llpbreakHeads = [
    { label: 'Module', sortOption: false },
    { label: 'LLP Description', sortOption: false },
    { label: 'LLP Cost', sortOption: false },
    { label: 'Life Limit', sortOption: false },
    {
      label: <p>TCSN
        <Tooltip
          title="Total Cycles Since New"
          arrow
          placement="top"
        >
          <InfoIcon style={{ fontSize: '16px', marginLeft: '3px' }} />
        </Tooltip>
      </p>,
      date: `(${moment(halfLifeResult.base_value_date).format(displayDateFormatShort)})`, sortOption: false
    },
    { label: 'Cycles Remaining', date: `(${moment(halfLifeResult.base_value_date).format(displayDateFormatShort)})`, sortOption: false },
    { label: "Full Life Remaining", sortOption: false },
    { label: 'Half Life Remaining', sortOption: false },
    { label: 'Half Life MTX adjustment', sortOption: false },
  ]
  return(
    <Fragment>
      <span onClick={() => setModal(!modal)} style={{color:'#3F51B5', cursor:'pointer', fontSize: '12px'}}>View LLP Breakdown</span>
      <Dialog
        open={modal}
        onClose={() => setModal(!modal)}
        fullScreen
      >
        <DialogTitle id="scroll-dialog-title">
          Engine #{item.engine_no} LLP Breakdown
        </DialogTitle>
        <DialogContent dividers={true}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newValue) => setTab(newValue)}
          >
            <Tab label='Primary LLP' />
            {secondaryLlp.length ? <Tab label='Secondary LLP' />:null}
            {staticLlp.length ? <Tab label='Static LLP' />:null}
          </Tabs>
          <div>
            <TableListComp
              heads={llpbreakHeads}
              data={llps.filter(llpItem => llpItem.llp_type === tabIndex+1).map((item) =>
                {
                  const style = {
                    color: item.module == "Fan" ? 'rgb(74, 153, 254)' : item.module == 'HPC' ? 'rgb(151, 152, 156)': item.module == 'HPT' ? 'rgb(93, 173, 94)':item.module == "LPT" ? 'rgb(252, 152, 37)':''
                  }
                  let rowStyle = item.remaining_cycle < 0 ? {color:"red"}:{whiteSpace:"nowrap"}

                  return(
                    <TableRow>
                      <TableCell style={item.remaining_cycle < 0 ? rowStyle : style}>{item.module ? item.module  : '--' }</TableCell>
                      <TableCell style={rowStyle}>
                        {item.description ? item.description : '--' } <br />
                        {item.remaining_cycle < 0 ? <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>LLPs have crossed life limit, hence full life remaining is considered 0%</span>:null}
                      </TableCell>
                      { checkUserTypeFn() ?
                        <TableCell style={rowStyle}>{ item.cost ?  <NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.cost}</NumericalLabel> : '--'}</TableCell>
                        : null
                      }
                      <TableCell style={rowStyle}>{item.cycles_limit ? `${item.cycles_limit} FC` : 0  }</TableCell>
                      <TableCell style={rowStyle}>{item.tcsn ? `${item.tcsn} FC` : 0 }</TableCell>
                      <TableCell style={rowStyle}>{item.remaining_cycle ? `${item.remaining_cycle} FC`: 0 }</TableCell>
                      <TableCell style={rowStyle}>{item.full_life_remaining_percentage ? `${parseFloat(item.full_life_remaining_percentage).toFixed(2)} %`:'0 %' }</TableCell>
                      <TableCell style={rowStyle}>{item.half_life_remaining_percentage ? `${parseFloat(item.half_life_remaining_percentage).toFixed(2)} %`: '--' }</TableCell>
                      <TableCell style={rowStyle}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adjustment}</NumericalLabel></TableCell>
                    </TableRow>
                  )
                }
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <span>
            Total LLP Cost:
              <b><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{llps.filter(item => item.llp_type === tabIndex+1).reduce((total, llp) => total+parseFloat(llp.cost),0)}</NumericalLabel></b>
          </span>
          <span>Total Half Life MTX adjustment:
            <b><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{llps.filter(item => item.llp_type === tabIndex+1).reduce((total, llp) => total+parseFloat(llp.half_life_adjustment),0)}</NumericalLabel></b>
          </span>
          <Button onClick={() => setModal(!modal)} color="primary" variant="outlined" size="small">Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default LlpBreakupTable;
