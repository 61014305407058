import React, { Component, Fragment } from 'react';
import { Paper, Grid, TextField, Button, InputAdornment, Tooltip, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import InfoIcon from '@material-ui/icons/Info';
const AirframeInitiate = ({ airframeHlaGenInfo, id, mods, error, skeletonLoader, aircraftTypes, lessee, onFieldChange, updateErrorField, mntGroups, engineGenInfo, engineTypes, genericHlaInfo, seatingConfigList, getDefaultValue, openUtilModal, utilModal, closeUtilModal, populateUtilDate, openUtilModalDatePicker }) => {
  const dom = moment(airframeHlaGenInfo.date_of_manufacture)
  const currentDate = moment()
  const diffCurrent = (currentDate.diff(dom) / 86400000) * 0.00273973
  const maxDate = moment().add(25 - diffCurrent, 'years')
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <TextField
            id="serial_number"
            label='Serial Number'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            value={airframeHlaGenInfo.serial_number}
            onChange={(e) => onFieldChange(e, 'serial_number', e.target.value, 'airframe')}
            error={error.serial_number ? true : false}
            helperText={error.serial_number ? error.serial_number : ''}
            onFocus={() => updateErrorField('serial_number', '')}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Autocomplete
            options={aircraftTypes}
            getOptionLabel={option => option.name}
            id="aircraftType"
            value={airframeHlaGenInfo.aircraftType}
            onChange={(e, value) => onFieldChange(e, 'aircraftType', value, 'airframe')}
            renderInput={params => <TextField required error={error.aircraft_type ? true : false} helperText={error.aircraft_type ? error.aircraft_type : ''} onFocus={() => updateErrorField('aircraft_type', '')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={mntGroups}
            getOptionLabel={option => option.label}
            id="maintenance"
            value={airframeHlaGenInfo.maintenance_program}
            onChange={(e, value) => onFieldChange(e, 'maintenance_program', value, 'airframe')}
            renderInput={params => <TextField required error={error.maintenance_program ? true : false} helperText={error.maintenance_program ? error.maintenance_program : ''} onFocus={() => updateErrorField('maintenance_program', '')} {...params} label="Maintenance Program" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required
              margin="normal"
              id="date_of_manufacture"
              label="Date of Manufacture"
              placeholder="DD-MM-YYYY"
              fullWidth
              format={fieldDateFormat}
              disableFuture={true}
              maxDate={genericHlaInfo.report_date ? moment(genericHlaInfo.report_date) : moment()}
              minDate={genericHlaInfo.report_date ? moment(genericHlaInfo.report_date).subtract(25, 'years') : moment().subtract(25, 'years')}
              InputLabelProps={{ shrink: true }}
              onChange={(data, value) => onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat) : data, 'generic')}
              value={genericHlaInfo.date_of_manufacture}
              error={error.date_of_manufacture ? true : false}
              helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
              onFocus={() => updateErrorField('date_of_manufacture', '')}
              onOpen={() => updateErrorField('date_of_manufacture', '')}
              inputVariant="outlined"
              autoComplete="off"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            required
            id="mtow"
            label='Max Take Off Weight'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            value={genericHlaInfo.mtow}
            onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow', e.target.value, 'generic') : e.preventDefault() }}
            error={error.mtow ? true : false}
            helperText={error.mtow ? error.mtow : ''}
            onFocus={() => { updateErrorField('mtow', ''); updateErrorField('mtow_kgs', '') }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
            }}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            required
            id="mtow_kgs"
            label='Max Take Off Weight'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            error={error.mtow_kgs ? true : false}
            helperText={error.mtow_kgs ? error.mtow_kgs : ''}
            onFocus={() => { updateErrorField('mtow_kgs', ''); updateErrorField('mtow', '') }}
            value={genericHlaInfo.mtow_kgs}
            onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow_kgs', e.target.value, 'generic') : e.preventDefault() }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
            }}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={mods}
            getOptionLabel={option => option.name}
            id="mods"
            value={mods.length && genericHlaInfo.mods ? genericHlaInfo.mods : []}
            onChange={(e, value) => onFieldChange(e, 'mods', value, 'generic')}
            renderInput={params => <TextField {...params} label="Modification/Enhancements" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
            multiple={true}
            filterSelectedOptions={true}
            openOnFocus={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl component="fieldset" style={{ float: 'left' }}>
            <FormLabel component="legend" style={{ color: '#6f6f6f', position: "relative" }} >
              Utilization Type
              <Tooltip title="Average Utilization Type processes with Time Since New & Cycles Since New values but Planned Utilization Type processes with data entered in Annual Planned FH & FC" placement="right" arrow>
                <InfoIcon fontSize="small" style={{ verticalAlign: "bottom", marginLeft: '3px' }} />
              </Tooltip>
            </FormLabel>
            <RadioGroup aria-label="utilization_type" name="utilization_type" value={airframeHlaGenInfo.utilization_type} row>
              <FormControlLabel value={airframeHlaGenInfo.utilization_type} onClick={getDefaultValue} control={<Radio color="primary" checked={airframeHlaGenInfo.utilization_type == 1 ? true : false} onClick={(e) => onFieldChange(e, 'utilization_type', 1, 'airframe')} />} label={<span style={{ color: '#6f6f6f', lineHeight: "16px" }}>Average</span>} />
              <FormControlLabel value={airframeHlaGenInfo.utilization_type} onClick={getDefaultValue} control={<Radio color="primary" checked={airframeHlaGenInfo.utilization_type == 2 ? true : false} onClick={(e) => onFieldChange(e, 'utilization_type', 2, 'airframe')} />} label={<span style={{ color: '#6f6f6f', lineHeight: '16px' }}>Planned</span>} />
            </RadioGroup>
          </FormControl>
        </Grid>
        {
          airframeHlaGenInfo.utilization_type == 2 ?
            <Fragment>
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  id="planned_fh"
                  label='Annual Planned FH'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{ shrink: true }}
                  value={airframeHlaGenInfo.planned_fh}
                  onChange={(e) => onFieldChange(e, 'planned_fh', e.target.value, 'airframe')}
                  error={error.planned_fh ? true : false}
                  helperText={error.planned_fh ? error.planned_fh : ''}
                  onFocus={() => updateErrorField('planned_fh', '')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                  }}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  id="planned_fc"
                  label='Annual Planned FC'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{ shrink: true }}
                  value={airframeHlaGenInfo.planned_fc}
                  onChange={(e) => onFieldChange(e, 'planned_fc', e.target.value, 'airframe')}
                  error={error.planned_fc ? true : false}
                  helperText={error.planned_fc ? error.planned_fc : ''}
                  onFocus={() => updateErrorField('planned_fc', '')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                  }}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={3} >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="utilization_as_of_date"
                    label="Utilization as of date"
                    placeholder="DD-MM-YYYY"
                    format={fieldDateFormat}
                    fullWidth
                    disableFuture={false}
                    InputLabelProps={{ shrink: true }}
                    minDate={genericHlaInfo.date_of_manufacture ? moment(genericHlaInfo.date_of_manufacture) : moment().subtract('24', 'years')}
                    maxDate={maxDate}
                    onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, 'airframe')}
                    value={airframeHlaGenInfo.utilization_as_of_date ? airframeHlaGenInfo.utilization_as_of_date : null}
                    error={error.utilization_as_of_dateAircraft ? true : false}
                    helperText={error.utilization_as_of_dateAircraft ? error.utilization_as_of_dateAircraft : ''}
                    onFocus={() => updateErrorField('utilization_as_of_dateAircraft', '')}
                    onOpen={() => updateErrorField('utilization_as_of_dateAircraft', '')}
                    onAccept={openUtilModalDatePicker}
                    onBlur={openUtilModal}
                    inputVariant="outlined"
                    autoComplete="off"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Fragment> : null}
        <Grid item xs={6} md={3}>
          <TextField
            required
            id="tsn"
            label='Aircraft Time Since New'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            value={airframeHlaGenInfo.tsn}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, 'airframe') : e.preventDefault() }}
            error={error.tsnAircraft ? true : false}
            helperText={error.tsnAircraft ? error.tsnAircraft : ''}
            onFocus={() => updateErrorField('tsnAircraft', '')}
            InputProps={{
              endAdornment: <InputAdornment position="end">FH</InputAdornment>
            }}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            required
            id="csn"
            label=' Aircraft Cycles Since New'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            value={airframeHlaGenInfo.csn}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value, 'airframe') : e.preventDefault() }}
            error={error.csnAircraft ? true : false}
            helperText={error.csnAircraft ? error.csnAircraft : ''}
            onFocus={() => updateErrorField('csnAircraft', '')}
            InputProps={{
              endAdornment: <InputAdornment position="end">FC</InputAdornment>
            }}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        {
          airframeHlaGenInfo.utilization_type == 1 ?
            <Grid item xs={12} md={3} >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  required
                  margin="normal"
                  id="utilization_as_of_date"
                  label="Utilization as of date"
                  placeholder="DD-MM-YYYY"
                  format={fieldDateFormat}
                  fullWidth
                  disabled={!airframeHlaGenInfo.aircraftType}
                  disableFuture={false}
                  InputLabelProps={{ shrink: true }}
                  minDate={genericHlaInfo.date_of_manufacture ? moment(genericHlaInfo.date_of_manufacture) : moment().subtract(24, 'years')}
                  maxDate={maxDate}
                  onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, 'airframe')}
                  value={airframeHlaGenInfo.utilization_as_of_date ? airframeHlaGenInfo.utilization_as_of_date : null}
                  error={error.utilization_as_of_dateAircraft ? true : false}
                  helperText={error.utilization_as_of_dateAircraft ? error.utilization_as_of_dateAircraft : ''}
                  onFocus={() => updateErrorField('utilization_as_of_dateAircraft', '')}
                  onOpen={() => updateErrorField('utilization_as_of_dateAircraft', '')}
                  onAccept={openUtilModalDatePicker}
                  onBlur={openUtilModal}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid> : null
        }
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={lessee}
            getOptionLabel={option => option.name}
            id="lessee_id"
            value={genericHlaInfo.lessee_id}
            onChange={(e, value) => onFieldChange(e, 'lessee_id', value, 'generic')}
            renderInput={params => <TextField error={error.lessee ? true : false} helperText={error.lessee ? error.lessee : ''} onFocus={() => updateErrorField('lessee', '')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={seatingConfigList}
            getOptionLabel={option => option.label}
            id="seating_configuration"
            value={airframeHlaGenInfo.seating_configuration}
            onChange={(e, value) => onFieldChange(e, 'seating_configuration', value, 'airframe')}
            renderInput={params => <TextField {...params} label="Main Deck Configuration" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          />
        </Grid>
      </Grid>
      {utilModal ?
        <Dialog
          open={utilModal}
          aria-labelledby="scroll-dialog-title"
          onClose={closeUtilModal}
        >
          <DialogContent dividers={true}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '28%' }}>
                <p align="center"> Does utilization as of date is same for all assemblies? </p>
              </div >
              <div align="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <Button color="primary" variant="contained" onClick={populateUtilDate} >YES</Button>
                <Button color="primary" varinat="outlined" onClick={closeUtilModal} >Close</Button>
              </div>
            </div>
          </DialogContent>

        </Dialog> : null
      }
    </Fragment>
  )

}
export default AirframeInitiate;
