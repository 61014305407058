import React from 'react';
import moment from 'moment';
import { Button, Paper, Grid, Tooltip } from '@material-ui/core';
import { DialWidgetCard } from '../../Elements';
import LevProjection from './LevProjection';
import { displayDateFormatShort } from '../../../constants';

const LevOutput = ({logResult}) => {
   var annual_discount_fixed=logResult.annual_discount
  return(
    <div className="bv-fbv-output">
    <Paper className="output-fbv-card flex-centered">
        <div>
          <h4>LEV Payments</h4>
          <p>Projection Till: <span>{moment(logResult.lease_end_date).format(displayDateFormatShort)}</span></p>
        </div>
        <LevProjection payments={logResult.monthly_pv ? logResult.monthly_pv:[]} />
      </Paper>
      <Paper className="output-widget-card">
        <p style={{textAlign:'right',paddingTop:'10px', paddingRight:'10px'}}> Value as of: {moment(logResult.base_value_date).format(displayDateFormatShort)}</p>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <DialWidgetCard tooltip={`${logResult.rent_nominal ? logResult.rent_nominal.toFixed(3):null}`} value={logResult.rent_nominal ? (logResult.rent_nominal/1000000).toFixed(3):null } label="Nominal $ " unit='m' />
            </Grid>
            <Grid item md={6} xs={12}>
              <DialWidgetCard  tooltip={`${logResult.rent_discounted ? logResult.rent_discounted.toFixed(3):null}`}  value={logResult.rent_discounted?(logResult.rent_discounted/1000000).toFixed(3):null}
              label={`Discounted $ @${logResult.annual_discount?logResult.annual_discount:'0'}%`}
               unit='m' />
            </Grid>
          </Grid>
          <h4>Rent</h4>
        </div>
        { logResult.is_full_life_maintenance_condition!=1 ?
        <div>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <DialWidgetCard  tooltip={`${logResult.residual_nominal ? logResult.residual_nominal.toFixed(3):null}`}  value={logResult.residual_nominal ? (logResult.residual_nominal/1000000).toFixed(3):null} label="Nominal $" unit='m' />
            </Grid>
            <Grid item md={6} xs={12}>
              <DialWidgetCard  tooltip={`${logResult.residual_discounted ? logResult.residual_discounted.toFixed(3):null}`}  value={logResult.residual_discounted?(logResult.residual_discounted/1000000).toFixed(3):null} label={`Discounted $ @${logResult.annual_discount?logResult.annual_discount:'0'}%`} unit='m' />
            </Grid>
          </Grid>
          <h4>Residual Value (Half Life)</h4>
        </div>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <DialWidgetCard  tooltip={`${logResult.lev_nominal ? logResult.lev_nominal.toFixed(3):null}`}  value={logResult.lev_nominal?(logResult.lev_nominal/1000000).toFixed(3):null} label="Nominal $" unit='m' />
            </Grid>
            <Grid item md={6} xs={12}>
              <DialWidgetCard  tooltip={`${logResult.lev_discounted ? logResult.lev_discounted.toFixed(3):null}`}  value={logResult.lev_discounted?(logResult.lev_discounted/1000000).toFixed(3):null} label={`Discounted $ @${logResult.annual_discount?logResult.annual_discount:'0'}%`} unit='m' />
            </Grid>
          </Grid>
          <h4>Lease Encumbered Value (Half Life)</h4>
        </div>
        </div>
        :null }

        { logResult.is_full_life_maintenance_condition ?
        <div>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <DialWidgetCard  tooltip={`${logResult.full_life_residual_nominal ? logResult.full_life_residual_nominal.toFixed(3):null}`}  value={logResult.full_life_residual_nominal ? (logResult.full_life_residual_nominal/1000000).toFixed(3):null} label="Nominal $" unit='m' />
            </Grid>
            <Grid item md={6} xs={12}>
              <DialWidgetCard   tooltip={`${logResult.full_life_residual_discounted ? logResult.full_life_residual_discounted.toFixed(3):null}`}  value={logResult.full_life_residual_discounted?(logResult.full_life_residual_discounted/1000000).toFixed(3):null} label={`Discounted $ @${logResult.annual_discount?logResult.annual_discount:'0'}%`} unit='m' />
            </Grid>
          </Grid>
          <h4>Residual Value (Full Life)</h4>
        </div>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <DialWidgetCard tooltip={`${logResult.full_life_lev_nominal ? logResult.full_life_lev_nominal.toFixed(3):null}`} value={logResult.full_life_lev_nominal?(logResult.full_life_lev_nominal/1000000).toFixed(3):null} label="Nominal $" unit='m' />
            </Grid>
            <Grid item md={6} xs={12}>
              <DialWidgetCard   tooltip={`${logResult.full_life_lev_discounted ? logResult.full_life_lev_discounted.toFixed(3):null}`}  value={logResult.full_life_lev_discounted?(logResult.full_life_lev_discounted/1000000).toFixed(3):null} label={`Discounted $ @${logResult.annual_discount?logResult.annual_discount:'0'}%`} unit='m' />
            </Grid>
          </Grid>
          <h4>Lease Encumbered Value (Full Life)</h4>
        </div>
        </div>
        :null }

      </Paper>

    </div>
  )
}
export default LevOutput;
