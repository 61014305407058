import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Paper, Grid, FormControl, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { ValuationOutPlaceHolder, BasicDetail, FbvInputs, ValueAsOfCard } from '../../Elements'
import { BvFbvOutput, BvBreadCrumbs } from '../components';
import { getAircraftTypesAc, getEngineTypesAc } from '../../../shared_elements/actionCreators';
import { getValuationLogApi, addEditBlrApi, exportValuationLogApi, changeLogStatusApi } from '../apiServices';
import { getFleetAssetsApi, getAircraftModsApi } from '../../Elements/apiServices';
import { checkApiStatus, removeEmptyKey } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { valuationCrud, errorCode } from '../'
import { PageLoader } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import * as actions from '../../../shared_elements/actions';
class BaseLeaseRates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skeletonLoader: this.props.match.params.id ? false : true,
      mode: this.props.match.params.id ? 'view' : 'add',
      pageLoader: false,
      valuationCrud: valuationCrud,
      error: {},
      mods: [],
      fleetInfo:[]
    }
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getValuationLogApi = getValuationLogApi.bind(this);
    this.addEditBlrApi = addEditBlrApi.bind(this);
    this.exportValuationLogApi = exportValuationLogApi.bind(this);
    this.changeLogStatusApi = changeLogStatusApi.bind(this);
    this.getFleetAssetsApi = getFleetAssetsApi.bind(this);
  }
  componentDidMount() {
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.getFleetAssetsApi()
      .then(response => {
        this.setState({fleetInfo:response});
      })
    if (this.props.match.params.id) {
      this.fetchValuationLog('skeletonLoader');
    }
  }
  fetchValuationLog = (loaderType = "pageLoader") => {
    this.setState({ [loaderType]: true });
    this.setState({ error: {} })
    this.getValuationLogApi(this.props, this.props.match.params.id)
      .then(response => {
        this.setState({ [loaderType]: false });
        if (checkApiStatus(response)) {
          let data = Object.assign({}, response.data.data);
          let utility_type = 1;
          if (data.future_blr_value && Object.keys(data.future_blr_value).length) {
            utility_type = 2
          }
          this.props.getEngineTypes({ aircraft_type: response.data.data.aircraft_type.id });
          this.getAircraftModsApi(this.props, { aircraft_type: response.data.data.aircraft_type.id })
            .then(response => {
              this.setState({ mods: response })
            })
          data = {
            ...data,
            mtow_kgs: (response.data.data.mtow * 0.4535970244).toFixed(4),
            is_blr_mlr: true,
            utility_type: utility_type
          }
          this.setState({ valuationCrud: data, mode: 'view' });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          this.props.history.push('/logs');
        }
      })
  }
  onChangeSerialNo = (e, keyParam, value) => {
    const { aircraftTypes } = this.props;
    this.setState(prevState => ({
      ...prevState,
      valuationCrud: {
        ...prevState.valuationCrud,
        engine_type: value ? value.engine_type:null,
        mtow_kgs:value ? value.mtow:'',
        mtow:value ?(value.mtow * 2.2046).toFixed(4):'',
        date_of_manufacture:value ? value.date_of_manufacture:null,
        serial_number:value ? value.serial_number:'',
        aircraft_type:value ? aircraftTypes.find(item => item.id === value.aircraft_type.id):null
      }
    }));
    if(value){
      this.getAircraftModsApi(this.props, { aircraft_type: value.aircraft_type.id })
      .then(response => {
        this.setState({ mods: response });
      })
      this.props.getEngineTypes({ aircraft_type: value.aircraft_type.id, valuation: 'valuation' })
    }else{
      this.setState({ mods: [] });
      this.props.clearEngineTypes();
    }
  }
  onFieldChange = (e, keyParam, value) => {
    const { valuationCrud } = this.state;
    this.updateFormFields(keyParam, value);
    if (keyParam === 'aircraft_type') {
      this.updateFormFields('mods', []);
      this.updateFormFields('engine_type', null);
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
          .then(response => {
            this.setState({ mods: response })
          })
        this.props.getEngineTypes({ aircraft_type: value.id, valuation: 'valuation' })
      } else {
        this.setState({ mods: [] })
      }
    }
    if ((keyParam === 'mtow' || keyParam === 'mtow_kgs') && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(4));
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(4));
      }
    }
    if (keyParam === 'date_of_manufacture') {
      this.updateFormFields('base_value_date', null);
      this.updateFormFields('projection_to', null);
    }
    if (keyParam === 'is_future_blr') {
      this.updateFormFields('projection_to', null);
    }
    if(keyParam === 'is_global_msn'){
      if(!value){
        this.props.clearEngineTypes();
      }
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          serial_number: '',
          mtow:'',
          mtow_kgs:'',
          date_of_manufacture:null,
          engine_type:null,
          aircraft_type:null
        }
      }));
    }
  }
  updateFormFields = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      valuationCrud: {
        ...prevState.valuationCrud,
        [key]: value
      }
    }));
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  createValuationFn = () => {
    const { valuationCrud } = this.state;
    let validationInputs = {
      aircraft_type: errorCode['aircraft_type'][fieldValidation({ ...errorCode['aircraft_typeObj'], fieldval: valuationCrud.aircraft_type })],
      serial_number: errorCode['serial_number'][fieldValidation({ ...errorCode['serial_numberObj'], fieldval: valuationCrud.serial_number })],
      date_of_manufacture: errorCode['date_of_manufacture'][fieldValidation({ ...errorCode['date_of_manufactureObj'], fieldval: valuationCrud.date_of_manufacture })],
      engine_type: errorCode['engine_type'][fieldValidation({ ...errorCode['engine_typeObj'], fieldval: valuationCrud.engine_type })],
      mtow: errorCode['mtow'][fieldValidation({ ...errorCode['mtowObj'], fieldval: valuationCrud.mtow })],      mtow_kgs: valuationCrud.mtow && (valuationCrud.mtow_kgs < 453.5970 || valuationCrud.mtow_kgs > 4535970.2395) ? "Please enter MTOW values between 453.59 to 4535970.23 in kgs" : errorCode['mtow_kgs'][fieldValidation({ ...errorCode['mtow_kgsObj'], fieldval: valuationCrud.mtow_kgs })],
      mtow_kgs: errorCode['mtow_kgs'][fieldValidation({ ...errorCode['mtow_kgsObj'], fieldval: valuationCrud.mtow_kgs })],
      base_value_date: errorCode['base_value_date'][fieldValidation({ ...errorCode['base_value_dateObj'], fieldval: valuationCrud.base_value_date })],
      projection_to: valuationCrud.utility_type === 2 ? errorCode['projection_to'][fieldValidation({ ...errorCode['projection_toObj'], fieldval: valuationCrud.projection_to })] : '',
      escalation_percentage: parseInt(valuationCrud.utility_type) !== 1 ? valuationCrud.escalation_percentage && valuationCrud.escalation_percentage.toString().trim().length ? parseFloat(valuationCrud.escalation_percentage) >= 1 && parseFloat(valuationCrud.escalation_percentage) <= 5 ? '':'Value should be b/w 1 to 5' :'Please enter Escalation Percentage'  : ''
    }
    if(valuationCrud.base_value_date){
      validationInputs = {
        ...validationInputs,
        base_value_date: moment(valuationCrud.base_value_date).isBetween(moment().subtract(1,'year').subtract(1, 'day'), moment().endOf('year')) ? '':`Value as of date should be in current year or previous year`
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      this.addEditBlrApi(this.props, valuationCrud, this.fetchValuationLog);
    } else {
      let target = Object.keys(removeEmptyKey(validationInputs))[0]
      if (this.props.match.params.id) {
        window.location.href = `/blr/${this.props.match.params.id}#${target}`
      } else {
        window.location.href = `/blr/create#${target}`
      }

      this.setState({ error: validationInputs })
    }
  }
  render() {
    const { fleetInfo, valuationCrud, error, mods, mode, pageLoader, skeletonLoader } = this.state;
    const { aircraftTypes, engineTypes } = this.props;
    return (
      <section className="valuation-app-section">
        {pageLoader && <PageLoader />}
        {<BvBreadCrumbs skeletonLoader={skeletonLoader} mode={mode} logResult={valuationCrud} changeLogStatus={(data) => this.changeLogStatusApi(this.props, data)} exportValuationLog={(file) => this.exportValuationLogApi(this.props, file, valuationCrud)} />}
        <Grid container spacing={1}>
          <Grid item md={8} sm={8} xs={12}>
            <Paper className="add-bv-fbv-form">
              <div style={{maxHeight:`${window.innerHeight-195}px`, overflow:'auto', padding:'10px 15px', position:'relative'}}>
                {!valuationCrud.lock_status && mode === 'view' && !skeletonLoader ? <span onClick={() => this.setState({ mode: 'edit' })} className="edit-icon"><EditIcon color="primary" fontSize="small" /></span> : null}
                <form className="form">
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <div className="select-utils-type">
                        <div className="util-card" style={{marginTop:"15px"}}>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                disabled={mode === 'view' ? true : false}
                                value="end"
                                control={<Checkbox style={{ cursor: 'disabled' }} checked={true} color="primary" />}
                                label={<Fragment>BLR and MLR<sup style={{ fontSize: '9px', color: '#3f51b5' }}>(DEFAULT)</sup></Fragment>}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                disabled={mode === 'view' ? true : false}
                                value="end"
                                control={<Checkbox onChange={(e) => { this.onFieldChange(e, 'utility_type', e.target.checked ? 2 : 1); this.onFieldChange(e, 'is_future_blr', e.target.checked) }} checked={((valuationCrud.utility_type === 2) || (valuationCrud.utility_type === 'Future Value')) ? true : false} color="primary" />}
                                label="FBLR"
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                    <BasicDetail
                      mode={mode}
                      crudData={valuationCrud}
                      onFieldChange={this.onFieldChange}
                      onChangeSerialNo={this.onChangeSerialNo}
                      updateErrorField={this.updateErrorField}
                      error={error}
                      mods={mods}
                      aircraftTypes={aircraftTypes}
                      engineTypes={engineTypes}
                      fleetInfo={fleetInfo}
                    />
                    {valuationCrud.utility_type === 2 ?
                      <FbvInputs
                        mode={mode}
                        valuationCrud={valuationCrud}
                        onFieldChange={this.onFieldChange}
                        updateErrorField={this.updateErrorField}
                        error={error}
                      /> : null
                    }
                  </Grid>
                </form>
              </div>
              <ValueAsOfCard
                mode={mode}
                error={error}
                onFieldChange={this.onFieldChange}
                updateErrorField={this.updateErrorField}
                logResult={valuationCrud}
                onSubmit={this.createValuationFn}
                onCancel={this.fetchValuationLog}
              />
            </Paper>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <div style={{ maxHeight: `${window.innerHeight - 100}px`, overflow: 'auto' }}>
              {this.props.match.params.id && Object.keys(valuationCrud).length ?
                <BvFbvOutput logResult={valuationCrud} /> : <ValuationOutPlaceHolder />
              }
            </div>
          </Grid>
        </Grid>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: () => dispatch(getAircraftTypesAc(ownProps, { valuation: 'valuation' })),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    clearEngineTypes: () => dispatch({ type: actions.ENGINE_TYPES, payload: [] })
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(BaseLeaseRates))
