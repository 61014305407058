import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { LabelValueCard } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
import { Grid, Button, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Chip, TableRow, TableCell, Breadcrumbs, Typography, Tooltip, Tabs, Tab } from '@material-ui/core';
import { ComponentBreakDownList, LLPBreakTable, ComponentBreakDownForcastList, ForecastLLPBreakTable } from '../components';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, permissionCheckFn, checkUserTypeFn, getLocalStorageInfo } from '../../../utils';
import { TableListComp, PageLoader, ExportManu, EmptyCollection } from '../../../shared_elements';
import { getHlaOutputDataApi, exportResultApi, exportLLpStackApi, getHlaLogApi, updateLockApi } from '../apiServices';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { trackActivity } from '../../../utils/mixpanel';
import { isMobile } from 'react-device-detect';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import { outputStateData } from '..';

class OutputHlam extends Component {
  constructor(props) {
    super(props);
    this.state = outputStateData
    this.getHlaOutputDataApi = getHlaOutputDataApi.bind(this)
    this.exportResultApi = exportResultApi.bind(this)
    this.exportLLpStackApi = exportLLpStackApi.bind(this)
    this.getHlaLogApi = getHlaLogApi.bind(this)
    this.updateLockApi = updateLockApi.bind(this)
  }
  componentDidMount() {
    this.getHlaOutputDataApi(this.props)
    trackActivity('Page Visited', {
      page_title: 'Output Half Life Adjustment'
    })
  }
  openLLpModal = (engineNo, item, type) => {
    this.setState(prevState => ({
      ...prevState,
      llp: prevState.hlaOutputData.llp[engineNo - 1],
      engineNo: engineNo,
      utlisationDate: item.last_event_date ? `(${moment(item.last_event_date).format(displayDateFormatShort)})` : null
    }))
    if (type == "base_value") {
      this.setState(prevState => ({
        ...prevState,
        componentModal: true,
      }))
    }
    if (type == "future_value") {
      this.setState(prevState => ({
        ...prevState,
        componentModalForcast: true,
        llpForcast: prevState.hlaOutputData.forecasted_llp[engineNo - 1]
      }))
    }
  }
  changeTabs = (event, newValue, tabLabel) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndexLlp: newValue,
      llpTabName: tabLabel
    }))
  }
  changeForCastLLPTabs = (event, newValue, tabLabel) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndexForcastLlp: newValue,
      llpTabForCastName: tabLabel
    }))
  }
  changeComponentTabs = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      componentTabIndex: newValue
    }))
  }
  render() {
    const { skeletonLoader, componentModal, hlaOutputData, llp, engineNo, exportLoader, utlisationDate, tabIndexLlp, llpTabName, componentTabIndex, componentModalForcast, llpForcast, pageLoader } = this.state;
    const secondaryLlpList = llp.filter(item => item.llp_type === 3);
    const staticLlpList = llp.filter(item => item.llp_type === 2);
    if (Object.keys(hlaOutputData).length) {
      const costArr = hlaOutputData.major_assembly.map(item => item.cost)
      const costArrForcast = hlaOutputData.forecasted_major_assembly ? hlaOutputData.forecasted_major_assembly.map(item => item.cost) : null
      const sumForcastLLp = hlaOutputData.forecasted_major_assembly ? costArrForcast.reduce((a, b) => a + b, 0).toFixed(3) : null
      const sum = costArr.reduce((a, b) => a + b, 0).toFixed(3)
      const llpCost = llp.map(item => item.cost).reduce((a, b) => a + b, 0)
      const forcastLLpCost = llpForcast.map(item => item.cost).reduce((a, b) => a + b, 0)
      const mtxAdjustment = llp.map(item => item.half_life_adjustment).reduce((a, b) => a + b, 0)
      const mtxAdjustmentForcast = llpForcast.map(item => item.future_half_life_adjustment).reduce((a, b) => a + b, 0)
      const LLPbreakHeads = [
        { label: 'Module', sortOption: false },
        { label: 'LLP Description', sortOption: false },
        { label: 'LLP Cost', sortOption: false },
        { label: 'Life Limit', sortOption: false },
        {
          label: <p>TCSN
            <Tooltip
              title="Total Cycles Since New"
              arrow
              placement="top"
            >
              <InfoIcon style={{ fontSize: '16px', marginLeft: '3px' }} />
            </Tooltip>
          </p>,
          date: `(${moment(hlaOutputData.base_value_date).format(displayDateFormatShort)})`, sortOption: false
        },
        { label: 'Cycles Remaining', date: `(${moment(hlaOutputData.base_value_date).format(displayDateFormatShort)})`, sortOption: false },
        { label: "Full Life Remaining", sortOption: false },
        { label: 'Half Life Remaining', sortOption: false },
        { label: 'Half Life MTX adjustment', sortOption: false },
      ]
      const LLPbreakHeadsForcast = [
        { label: 'Module', sortOption: false },
        { label: 'LLP Description', sortOption: false },
        { label: 'LLP Cost', sortOption: false },
        { label: 'Life Limit', sortOption: false },
        {
          label: <p>TCSN
            <Tooltip
              title="Total Cycles Since New"
              arrow
              placement="top"
            >
              <InfoIcon style={{ fontSize: '16px', marginLeft: '3px' }} />
            </Tooltip>
          </p>,
          date: `(${moment(hlaOutputData.forecast_date).format(displayDateFormatShort)})`, sortOption: false
        },
        { label: 'Cycles Remaining', date: `(${moment(hlaOutputData.forecast_date).format(displayDateFormatShort)})`, sortOption: false },
        { label: "Full Life Remaining", sortOption: false },
        { label: 'Half Life Remaining', sortOption: false },
        { label: 'Half Life MTX adjustment', sortOption: false },
      ]
      const fileteredLLP = LLPbreakHeads.filter(item => !['LLP Cost'].includes(item.label))
      const fileteredLLPForcast = LLPbreakHeadsForcast.filter(item => !['LLP Cost'].includes(item.label))
      const breakdownHeads = [
        { label: 'Component', sortOption: false },
        { label: `Event`, sortOption: false },
        { label: 'Next Due', sortOption: false },
        { label: "Full Life Remaining", sortOption: false },
        { label: "Half Life Remaining", sortOption: false },
        { label: `Half Life Maintenance Adjustment`, sortOption: false },
        { label: `Final Values in Million`, sortOption: false },
      ]
      const breakdownHeadsForcast = [
        { label: 'Component', sortOption: false },
        { label: `Event`, sortOption: false },
        { label: 'Last Event before Forecast Date', sortOption: false },
        { label: "Full Life Remaining", sortOption: false },
        { label: "Half Life Remaining", sortOption: false },
        { label: `Half Life Maintenance Adjustment`, sortOption: false },
        { label: `Final Values in Million`, sortOption: false },
        { label: `Next Event after Forecast Date`, sortOption: false },
      ]

      return (
        <div className="valuation-sections">
          {exportLoader || pageLoader ? <PageLoader /> : null}
          <div className="create-valuation-logs create-valuation-logs-hthl-mobile">
            <Grid container spacing={2} >
              <Grid item xs={12} md={7}>
                <div className="valuation-title" style={isMobile ? { marginBottom: 'auto' } : { marginBottom: '16px', paddingTop: '0px' }}>
                  <Breadcrumbs aria-label="breadcrumb" >
                    <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                      <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline' }}>
                        Logs
                      </Typography>
                    </Link>
                    {permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'C' }) ||
                      permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'C' }) ||
                      permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'C' }) ||
                      permissionCheckFn({ secondaryKey: 'lev', keyIndex: 'C' }) ?
                      <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                        <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline' }}>
                          Asset Value Options
                        </Typography>
                      </Link> : null
                    }
                    <Link color="inherit" to={`/half-life/intermediate/${this.props.match.params.id}`} style={{ cursor: 'pointer' }} >
                      <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline' }}>
                        {this.props.match.params.id}
                      </Typography>
                    </Link>
                    <h1 className="flex-centered" color="primary">Maintenance Adjusted Base Value</h1>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item xs={12} md={5} >
                <ul className="list-inline" style={{ float: 'right', }}>
                  <li className="list-inline-item">
                    {permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'U' }) ?
                      hlaOutputData.lock_status ?
                        <Tooltip title="Click to resume editing." placement="top" arrow>
                          <img src={lockIcon} width="25" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" style={{ marginTop: '8px', cursor: 'pointer' }} />
                        </Tooltip>
                        :
                        <Tooltip title="Click to lock editing." placement="top" arrow>
                          <img src={unlockIcon} width="30" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" style={{ marginTop: '8px', cursor: "pointer" }} />
                        </Tooltip>
                      :
                      hlaOutputData.lock_status ? <img src={lockIcon} width="30" alt="Icon" style={{ cursor: 'default', position: 'relative', top: '10px' }} /> : <img src={unlockIcon} width="35" alt="Icon" style={{ cursor: 'default', position: 'relative', top: '10px' }} />
                    }
                  </li>
                  <li className="list-inline-item">
                    {permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) && permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_XLS' }) ?
                      <div>
                        <ExportManu variant="contained" title="Export" files={[{ title: 'PDF', extension: 'pdf' }, { title: 'Excel', extension: 'xlsx' }, { title: 'Docx', extension: 'docx' }]} exportReportFn={(file) => this.exportResultApi(this.props, file)} />
                      </div>
                      : permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) ?
                        <div >
                          <ExportManu variant="contained" title="Export" files={[{ title: 'PDF', extension: 'pdf' }, { title: 'Excel', extension: 'xlsx' },{ title: 'Docx', extension: 'docx' }]} exportReportFn={(file) => this.exportResultApi(this.props, file)} />
                        </div> : null
                    }
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Serial Number' value={hlaOutputData.serial_number ? hlaOutputData.serial_number : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Aircraft Type' value={hlaOutputData.aircraft_type.name ? hlaOutputData.aircraft_type.name : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Date of Manufacture' value={hlaOutputData.date_of_manufacture ? moment(hlaOutputData.date_of_manufacture).format(displayDateFormatShort) : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='OEM' value={hlaOutputData.aircraft_type.oem ? hlaOutputData.aircraft_type.oem : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Max Take Off Weight' value={hlaOutputData.mtow ? `${hlaOutputData.mtow} Lbs` : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Max Take Off Weight' value={hlaOutputData.mtow ? `${(hlaOutputData.mtow * 0.4535970244).toFixed(2)} Kgs` : '--'} />
              {hlaOutputData.mods && hlaOutputData.mods.length ?
                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Modifications/Enhancements' value={hlaOutputData.mods.map(mod => <Chip size="small" style={{ margin: '0 4px 4px 0', fontFamily: 'Conv_IBMPlexSans-Medium' }} label={mod.name} variant="outlined" />)} /> :
                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Modifications/Enhancements' value="--" />
              }
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Registration Number' value={hlaOutputData.registration_number ? hlaOutputData.registration_number : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Engine Type' value={hlaOutputData.engine_type.name ? hlaOutputData.engine_type.name : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Lessee' value={hlaOutputData.lessee ? hlaOutputData.lessee : '--'} />
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Main Deck Configuration' value={hlaOutputData.seating_configuration ? hlaOutputData.seating_configuration : '--'} />
              {
                getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => group.id === 46).length ?
                  <Grid container spacing={3} style={{ margin: '20px 0px 25px 0px' }}>
                    <Grid item sm={12} xs={12} md={12} >
                      <LabelValueCard label="Assumption" value={hlaOutputData.assumptions ? hlaOutputData.assumptions : '--'} />
                    </Grid>
                  </Grid> : null
              }
            </Grid>
            <div>
              <h4 className="flex-centered" style={{ fontSize: '16px', marginBottom: '5px', marginTop: '20px' }}>
                Value as of {moment(hlaOutputData.base_value_date).format(displayDateFormatShort)}
              </h4>
              <Paper style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: 'transparent', boxShadow: 'none' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3} alignItems="center">
                    <div className="value-card">
                      <Paper className="value-info vl color-blue">
                        <h4 align="center" >Base Value</h4>
                        <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{hlaOutputData.base_value}</NumericalLabel> Million</h2>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3} alignItems="center">
                    <div className="value-card">
                      <Paper className="value-info vl color-blue">
                        <h4 align="center">Maintenance adjustment</h4>
                        <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{sum}</NumericalLabel> Million</h2>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3} alignItems="center">
                    <div className="value-card">
                      <Paper className="value-info vl color-blue">
                        <h4 align="center" >Maintenance Adjusted Base Value</h4>
                        <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{hlaOutputData.maintenance_adj_bv}</NumericalLabel> Million</h2>
                      </Paper>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
            {
              hlaOutputData.forecast_date ?
                <div>
                  <h4 className="flex-centered" style={{ fontSize: '16px', marginBottom: '5px', marginTop: '20px' }}>
                    Value as of {moment(hlaOutputData.forecast_date).format(displayDateFormatShort)}
                  </h4>
                  <Paper style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3} alignItems="center">
                        <div className="value-card">
                          <Paper className="value-info vl color-blue">
                            <h4 align="center" >Future Base Value</h4>
                            <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{hlaOutputData.future_base_value}</NumericalLabel> Million</h2>
                          </Paper>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3} alignItems="center">
                        <div className="value-card">
                          <Paper className="value-info vl color-blue">
                            <h4 align="center">Future Maintenance adjustment</h4>
                            <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{sumForcastLLp}</NumericalLabel> Million</h2>
                          </Paper>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4} alignItems="center">
                        <div className="value-card">
                          <Paper className="value-info vl color-blue">
                            <h4 align="center" >Maintenance Adjusted Future Base Value</h4>
                            <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{hlaOutputData.forecasted_maintenance_adj_bv}</NumericalLabel> Million</h2>
                          </Paper>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
                : null
            }
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className="base-value-sec-hla">
                <div className="component-breakdown">
                  <h4 className="flex-centered" style={{ fontSize: '16px', marginBottom: '10px', color: '#bd10e0' }}>
                    Component Breakdown
                  </h4>
                  <Grid item xs={12} md={12}>
                    <Tabs
                      value={componentTabIndex}
                      onChange={(event, newValue) => this.changeComponentTabs(event, newValue, event.currentTarget.innerText)}
                      variant="scrollable"
                      indicatorColor='primary'
                      scrollButtons={isMobile ? 'on' : 'auto'}

                    >
                      <Tab label='Report Date' />
                      {hlaOutputData.forecasted_major_assembly ? <Tab label='Forecast Date' /> : null}
                    </Tabs>
                  </Grid>
                  {
                    componentTabIndex == 0 ?
                      <TableListComp
                        heads={breakdownHeads}
                        data={
                          <Fragment>
                            {hlaOutputData.major_assembly.map((item, index) =>
                              <ComponentBreakDownList
                                item={item}
                                key={index}
                                openLLpModal={this.openLLpModal}
                              />
                            )}
                            <TableRow>
                              <TableCell colSpan={6} style={{ fontSize: '16px' }}><strong>Total adjustment from Half Time Half Life as of {moment(hlaOutputData.base_value_date).format(displayDateFormatShort)}  </strong></TableCell>
                              <TableCell className="mobile-tablecell" style={{ fontSize: '16px' }}><strong><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{sum}</NumericalLabel> M</strong></TableCell>
                            </TableRow>
                          </Fragment>
                        }
                      /> : null
                  }
                  {
                    componentTabIndex == 1 ?
                      <TableListComp
                        heads={breakdownHeadsForcast}
                        data={
                          <Fragment>
                            {hlaOutputData.forecasted_major_assembly && hlaOutputData.forecasted_major_assembly.map((item, index) =>
                              <ComponentBreakDownForcastList
                                item={item}
                                key={index}
                                openLLpModal={this.openLLpModal}
                              />
                            )}
                            <TableRow>
                              <TableCell colSpan={7} style={{ fontSize: '16px' }}><strong>Total adjustment from Half Time Half Life as of {moment(hlaOutputData.forecast_date).format(displayDateFormatShort)}  </strong></TableCell>
                              <TableCell className="mobile-tablecell" style={{ fontSize: '16px' }}><strong><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{sumForcastLLp}</NumericalLabel> M</strong></TableCell>
                            </TableRow>
                          </Fragment>
                        }
                      /> : null
                  }

                </div>
              </Grid>
            </Grid>
            {permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'U' }) && !hlaOutputData.lock_status ?
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="other-links-options" style={{ marginTop: '10px', cursor: 'pointer' }}>
                    <Paper onClick={() => this.props.history.push(`/half-life/intermediate/${this.props.match.params.id}/?edit-mode`)} >
                      <Grid container alignContent="center">
                        <Grid item xs={12} md={12} alignItems="center">
                          <h4 align="center" >Modify Query</h4>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                </Grid>
              </Grid> : null
            }

          </div>
          {componentModal ?
            <Dialog
              open={breakdownHeads}
              onClose={() => this.setState({ componentModal: false })}
              aria-labelledby="scroll-dialog-title"
              fullWidth={true}
              fullScreen
            >
              <DialogTitle id="scroll-dialog-title" >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    Engine #{engineNo} LLP Breakdown
                  </Grid>
                  {permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) && permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_XLS' }) ?
                    <Grid item xs={12} md={6}>
                      <div style={{ float: 'right' }}>
                        <ExportManu
                          variant="contained"
                          title="Export"
                          files={[{ title: 'PDF', extension: 'pdf', engine: engineNo }, { title: 'Excel', extension: 'xlsx', engine: engineNo }]}
                          exportReportFn={(file) => this.exportLLpStackApi(this.props, file)} />
                      </div>
                    </Grid> :
                    permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) ?
                      <Grid item xs={12} md={6}>
                        <div style={{ float: 'right' }}>
                          <ExportManu
                            variant="contained"
                            title="Export"
                            files={[{ title: 'PDF', extension: 'pdf', engine: engineNo }, { title: 'Excel', extension: 'xlsx', engine: engineNo }]}
                            exportReportFn={(file) => this.exportLLpStackApi(this.props, file)} />
                        </div>
                      </Grid> : null
                  }
                </Grid>
              </DialogTitle>
              <DialogContent dividers={true}>
                <div className="llp-breakdown-tabs">
                  <Tabs
                    value={tabIndexLlp}
                    onChange={(event, newValue) => this.changeTabs(event, newValue, event.currentTarget.innerText)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label='Primary LLP' />
                    {secondaryLlpList.length ? <Tab label='Secondary LLP' /> : null}
                    {staticLlpList.length ? <Tab label='Static LLP' /> : null}
                  </Tabs>
                </div>
                <TableListComp
                  heads={checkUserTypeFn() ? LLPbreakHeads : fileteredLLP}
                  data={llp.length ? llp.map((item, index) =>
                    <LLPBreakTable item={item} key={index} tabIndexLlp={tabIndexLlp} llpTabName={llpTabName} />
                  ) : null}
                  noRecord={llp.length ? null :
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </DialogContent>
              <DialogActions>
                {checkUserTypeFn() ?
                  <p style={{ marginRight: '10px' }}>Total LLP Cost: <span style={{ fontWeight: 'bold' }}><strong><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{llpCost}</NumericalLabel> </strong></span>&nbsp; &nbsp;|</p> : null
                }
                <p style={{ marginRight: '10px' }}>Total Half Life MTX adjustment: <span style={{ fontWeight: 'bold', paddingRight: '20px' }}><strong><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{mtxAdjustment}</NumericalLabel></strong></span></p>
                <Button onClick={() => this.setState({ componentModal: false })} color="primary" variant="contained" >Close</Button>

              </DialogActions>
            </Dialog>
            : null
          }
          {componentModalForcast ?
            <Dialog
              open={breakdownHeads}
              onClose={() => this.setState({ componentModal: false })}
              aria-labelledby="scroll-dialog-title"
              fullWidth={true}
              fullScreen
            >
              <DialogTitle id="scroll-dialog-title" >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    Engine #{engineNo} LLP Breakdown
                  </Grid>
                  {permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) && permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_XLS' }) ?
                    <Grid item xs={12} md={6}>
                      <div style={{ float: 'right' }}>
                        <ExportManu
                          variant="contained"
                          title="Export"
                          files={[{ title: 'PDF', extension: 'pdf', engine: engineNo }, { title: 'Excel', extension: 'xlsx', engine: engineNo }]}
                          exportReportFn={(file) => this.exportLLpStackApi(this.props, file)} />
                      </div>
                    </Grid> :
                    permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) ?
                      <Grid item xs={12} md={6}>
                        <div style={{ float: 'right' }}>
                          <ExportManu
                            variant="contained"
                            title="Export"
                            files={[{ title: 'PDF', extension: 'pdf', engine: engineNo }, { title: 'Excel', extension: 'xlsx', engine: engineNo }]}
                            exportReportFn={(file) => this.exportLLpStackApi(this.props, file)} />
                        </div>
                      </Grid> : null
                  }
                </Grid>
              </DialogTitle>
              <DialogContent dividers={true}>
                <div className="llp-breakdown-tabs">
                  <Tabs
                    value={tabIndexLlp}
                    onChange={(event, newValue) => this.changeForCastLLPTabs(event, newValue, event.currentTarget.innerText)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label='Primary LLP' />
                    {secondaryLlpList.length ? <Tab label='Secondary LLP' /> : null}
                    {staticLlpList.length ? <Tab label='Static LLP' /> : null}
                  </Tabs>
                </div>
                <TableListComp
                  heads={checkUserTypeFn() ? LLPbreakHeadsForcast : fileteredLLPForcast}
                  data={llpForcast.length ? llpForcast.map((item, index) =>
                    <ForecastLLPBreakTable item={item} key={index} tabIndexLlp={tabIndexLlp} llpTabName={llpTabName} />
                  ) : null}
                  noRecord={llpForcast.length ? null :
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </DialogContent>
              <DialogActions>
                {checkUserTypeFn() ?
                  <p style={{ marginRight: '10px' }}>Total LLP Cost: <span style={{ fontWeight: 'bold' }}><strong><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{forcastLLpCost}</NumericalLabel> </strong></span>&nbsp; &nbsp;|</p> :
                  null
                }
                <p style={{ marginRight: '10px' }}>Total Half Life MTX adjustment: <span style={{ fontWeight: 'bold', paddingRight: '20px' }}><strong><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{mtxAdjustmentForcast}</NumericalLabel></strong></span></p>
                <Button onClick={() => this.setState({ componentModalForcast: false })} color="primary" variant="contained" >Close</Button>

              </DialogActions>
            </Dialog>
            : null
          }
        </div>
      )
    } else {
      return <PageLoader />
    }
  }
}
export default withSnackbar(OutputHlam);
