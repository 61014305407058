import React, { Component} from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid} from '@material-ui/core';

class ConfirmationModal extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { modal, toggleModalFn, importLLP } = this.props;
        return (
            <Dialog
            open={modal}
            onClose={toggleModalFn}
            aria-labelledby="scroll-dialog-title"
            fullWidth= 'true'
            maxWidth= 'xs'
          >
            <DialogTitle id="scroll-dialog-title">
              Confirmation
            </DialogTitle>
            <DialogContent dividers={true}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4 align="center" style={{marginBottom:'10px', marginTop:'30px'}}>File import values would override any change’s done on the web page</h4>
              </Grid>
              <Grid item xs={12} align="center">
                <ul className="list-inline">
                  <li className="list-inline-item">
                  <Button variant="contained"  color="secondary" onClick={importLLP}>Ok</Button>
                  </li>
                  <li className="list-inline-item">
                  <Button variant="contained" onClick={toggleModalFn} color="primary" style={{fontSize:'14px', marginLeft:'10px'}}>Cancel</Button>
                  </li>
                </ul>
              </Grid>
            </Grid>
            </DialogContent>
          </Dialog>
        )
    }
}
export default ConfirmationModal;
