import React, { Fragment } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants'
const PortfolioBasicDetail = ({portfolioDetail}) => {
  return(
    <div className="portfolio-basic-details">
      <Grid container spacing={2}>
        {/* <LabelValueCard xs={6} md={4} label=" Portfolio Name" value={portfolioDetail.name ? portfolioDetail.name:'--'} /> */}
        <LabelValueCard xs={6} md={4} label="Report Date" value={portfolioDetail.report_date ? moment(portfolioDetail.report_date).format(displayDateFormatShort):'--'} />
        <LabelValueCard xs={6} md={4} label="Status" value={portfolioDetail.status && portfolioDetail.status.label ? portfolioDetail.status.label:'--'} />
        <LabelValueCard xs={6} md={4} label="Inflation Rate (%)" value={portfolioDetail.inflation_rate ? `${portfolioDetail.inflation_rate}`:'--'} />
         <LabelValueCard xs={6} md={4} label="Projection Year" value={portfolioDetail.projection_to ? moment(portfolioDetail.projection_to).format(displayDateFormatShort):'--'} />
         <LabelValueCard xs={6} md={4} label="Utility Type" value={portfolioDetail.utility_type=="Future Value" ?"BV/CMV & FBV":'BV/CMV'} />
         <LabelValueCard xs={6} md={4} label="Created At" value={ moment(portfolioDetail.created_at).format(displayDateFormatShort)} />

       <LabelValueCard xs={6} md={6} label="Remarks" value={portfolioDetail.remarks?portfolioDetail.remarks:'--'} />
      </Grid>
    </div>
  )
}
export default PortfolioBasicDetail;
