import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import LeaseEncumberedValue from './containers'
export const levRoutes = [
  {
    path:'/lev/create',
    component:pageLayoutHoc(userRoleBaseAccessHoc(LeaseEncumberedValue,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'LeaseEncumberedValue'
  },
  {
    path:'/lev/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(LeaseEncumberedValue,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'LeaseEncumberedValue'
  }
]
