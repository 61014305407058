import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Grid, Button, Paper } from '@material-ui/core';
import { DialWidgetCard } from '../../Elements'
import PortfolioFBVModal from './PortfolioFBVModal';
const  PortfolioBVCMVWidget = ({match, portfolioAircrafts, portfolioAnalytic, portfolioDetail, history}) => {
  return(
    <div className="portfolio-bv-cmv-card">
      <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={9}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={4} className="text-center">
              <DialWidgetCard
                label='Avg. Age of Portfolio'
                value={Object.keys(portfolioAnalytic).length && portfolioAnalytic.avg_age_portfolio?`${portfolioAnalytic.avg_age_portfolio.toFixed(2)}`:null}
                unit='Yrs'
                show_dollar=''
                precision='2'

              />
            </Grid>
            <Grid item xs={6} md={4} className="text-center">
              <DialWidgetCard
                label='Total Base Value'
                value={portfolioDetail.base_value?`${portfolioDetail.base_value.toFixed(3)}`:null}
                unit='m'
              />
            </Grid>
            <Grid item xs={6} md={4} className="text-center">
              <DialWidgetCard
                label='Total Current Market Value'
                value={portfolioDetail.current_market_value ? `${portfolioDetail.current_market_value.toFixed(3)}`:null}
                unit='m'
              />
            </Grid>
          </Grid>
        </Grid>

        { portfolioDetail.utility_type && portfolioDetail.utility_type === 'Future Value' && portfolioDetail.assets_count>0 &&
          <Grid item xs={12} md={3}>
            <Paper className="widget-card flex-centered">
              <div>
                <h4>Future Base Value</h4>
                <p>From: {moment(portfolioDetail.report_date).format('YYYY')} - {moment(portfolioDetail.projection_to).format('YYYY')}</p>
              </div>
              <PortfolioFBVModal portfolioAircrafts={portfolioAircrafts} portfolioDetail={portfolioDetail} />
            </Paper>
          </Grid>
        }
      </Grid>
    </div>
  )
}
export default withRouter(PortfolioBVCMVWidget);
