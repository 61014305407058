import React from 'react';
import moment from 'moment';
import { Button, Paper, Grid, Tooltip } from '@material-ui/core';
import { DialWidgetCard } from '../../Elements';
import BlrProjection from './BlrProjection';
import { displayDateFormatShort } from '../../../constants';
const BvFbvOutput = ({ logResult }) => {
  return (
    <div className="bv-fbv-output">
      {logResult.future_blr_value && Object.keys(logResult.future_blr_value).length ?
        <Paper className="output-fbv-card flex-centered">
          <div>
            <h4>Future BLR</h4>
            <p>Projection Till: <span>{moment(logResult.projection_to).format(displayDateFormatShort)}</span></p>
          </div>
          <BlrProjection logResult={logResult} />
        </Paper> : null
      }
      <Paper className="output-widget-card">
      <p style={{textAlign:'right',paddingTop:'10px', paddingRight:'10px'}}> Value as of: {moment(logResult.base_value_date).format(displayDateFormatShort)}</p>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <DialWidgetCard value={logResult.blr_value ? logResult.blr_value : null} label="Base Lease Rate" unit='k' />
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingBottom: '20px' }}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <DialWidgetCard value={logResult.mlr_value ? logResult.mlr_value : null} label="Market Lease Rate" unit='k' />
            </Grid>
          </Grid>
        </div>
      </Paper>

    </div>
  )
}
export default BvFbvOutput;
