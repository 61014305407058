import React, { Fragment } from 'react';
import {Popover, Avatar} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { localTimeFn } from '../../../utils'
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
const PopOverInfo = ({item}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return(
    <Fragment>
      <MoreVertIcon
        color="primary"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="portfolio-list-more">
          <ul className="list-unstyled">
            <li className="">
              <h6>Created By:</h6>
              <div className="user-information">
                <Avatar className="profile-pic" alt={item.created_by && item.created_by.name ? item.created_by.name:'--'} src={item.created_by && item.created_by.profile_pic ? item.created_by.profile_pic:'--'} />
                <div className="user-details">
                  <p>{item.created_by && item.created_by.name ? item.created_by.name:'--'}</p>
                  <span style={{fontSize:'11px'}}>{localTimeFn(item.created_at)}</span>
                </div>
              </div>
            </li>
            { item.updated_by && item.updated_by.name ?
              <li className="">
                <h6>Last Modified By:</h6>
                <div className="user-information">
                  <Avatar className="profile-pic" alt={item.updated_by && item.updated_by.name ? item.updated_by.name:'--'} src={item.updated_by && item.updated_by.profile_pic ? item.updated_by.profile_pic:'--'} />
                  <div className="user-details">
                    <p>{item.updated_by && item.updated_by.name ? item.updated_by.name:'--'}</p>
                    <span style={{fontSize:'11px'}}>{localTimeFn(item.updated_at)}</span>
                  </div>
                </div>
              </li>:null
            }

          </ul>
        </div>

      </Popover>
    </Fragment>
  )
}
export default PopOverInfo;
