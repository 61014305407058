import React, { useState } from 'react';
import { Paper, TextField, Divider, ClickAwayListener } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
const SerialNoComp = ({mode, crudData, onFieldChange, onChangeSerialNo, error, updateErrorField}) => {
    const [isLoading, setLoading] = useState(false)
    const [isOpen, setOpen] = useState(false);
    const [assetsInfo, setAssetsInfo] = useState({list:[], pagination:{}})
    const getOptions = (query, type) => {
        if(crudData.is_global_msn){
            setLoading(true);
            globalGetService(`valuation/fleetData/search/`,{...query, fleet_type:crudData.is_global_msn})
            .then(response => {
                if(checkApiStatus(response)){
                    setOpen(true);
                    if(type === 'replace'){
                        setAssetsInfo(response.data.data)
                    }else{
                        setAssetsInfo({...assetsInfo, list:[...assetsInfo.list, ...response.data.data.list], pagination:response.data.data.pagination})
                    }
                }
                setLoading(false);
            })
        }
    }
    const initialLoad = () => {
        if(crudData.is_global_msn){
            if(!assetsInfo.list.length){
                getOptions({page:1, serial_number:''});
            }
        }
    }
    const moveNextPage = () => {
        let nextPage = parseInt(assetsInfo.pagination.page) + 1;
        getOptions({serial_number:crudData.serial_number, page:nextPage});
    }
    const onSelectAsset = (e, item) => {
        onChangeSerialNo(e, 'serial_number', item);
        handleClickAway();
    }
    const handleClickAway = () => {
        setAssetsInfo({list:[],pagination:{}});
        setOpen(false)
    }

    return(
        
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{position:'relative'}}>
                <TextField
                    placeholder='Enter Serial No.'
                    autoComplete='off'
                    id="serial_number"
                    label='Serial Number'
                    fullWidth
                    margin="normal"
                    disabled={mode !== 'add' ? true : false}
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{ shrink: true }}
                    value={crudData.serial_number}
                    onChange={(e) => { onFieldChange(e, 'serial_number', e.target.value); getOptions({serial_number:e.target.value, page:1}, 'replace')}}
                    error={error.serial_number ? true : false}
                    helperText={error.serial_number ? error.serial_number : ''}
                    onFocus={() => {updateErrorField('serial_number', '');initialLoad()}}
                    variant="outlined"
                />
                { crudData.is_global_msn && isOpen ?
                    <Paper style={{position:'absolute', width:'100%', zIndex:11}}>
                        <div className='serial-custom-dropdown' style={{maxHeight:'220px', overflow:'auto'}}>
                            {assetsInfo.list.map((item, index) => 
                                <>
                                    <div className='serial-no-option' onClick={(e) => onSelectAsset(e, item)} style={{padding:'8px 10px'}}> {item.serial_number} (<span style={{fontSize:'13px'}}>{item?.aircraft_type?.name||''}</span>)</div>
                                </>
                            )}
                            {assetsInfo.pagination.has_next?
                                <div className='serial-no-load-more' style={{cursor:'pointer', textAlign:'center'}} onClick={(e) => {isLoading ? e.preventDefault():getOptions({page:parseInt(assetsInfo.pagination.page)+1, serial_number:crudData.serial_number}, 'append')}} style={{padding:'4px 10px'}}>{isLoading ? 'Loading...':'Load more..'}</div>:null
                            }
                        </div>
                    </Paper>:null
                }
            </div>
        </ClickAwayListener>
        
    )
}
export default SerialNoComp;