import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
const AirframeBasic = ({error, seatConfigs, lessee, aircraftTypes, mods, mntGroups, onFieldChange, updateErrorField, crudData, mode, permissionFlag}) => {
  const dom = moment(crudData.generic.date_of_manufacture)
  const diffCurrent = (moment().diff(dom) /86400000) * 0.00273973;
  const maxDate = moment().add(25 - diffCurrent, 'years');
  return(
    <Fragment>
      <Grid item md={4} xs={12}>
        <TextField
          id="serial_number"
          label='Serial Number'
          fullWidth
          margin="normal"
          disabled={mode !== 'add' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.airframe.serial_number}
          onChange={(e) => onFieldChange(e, 'serial_number', e.target.value, 'airframe')}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode !== 'add' || !permissionFlag ? true:false}
          options={aircraftTypes}
          getOptionLabel={option => option.name}
          id="aircraftType"
          value={crudData.airframe.aircraftType?crudData.airframe.aircraftType:null}
          onChange={(e, value) => onFieldChange(e, 'aircraftType', value, 'airframe')}
          renderInput={params => <TextField required error={error.aircraftType ? true : false} helperText={error.aircraftType ? error.aircraftType : ''} onFocus={() => updateErrorField('aircraftType', '', 'airframeError')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>
      { mode === 'add' ?
        <Grid item md={4} xs={12}>
          <Autocomplete
            disabled={mode === 'view' || !permissionFlag ? true:false}
            options={mntGroups}
            getOptionLabel={option => option.label}
            id="maintenance_program"
            value={crudData.airframe.maintenance_program?crudData.airframe.maintenance_program:null}
            onChange={(e, value) => onFieldChange(e, 'maintenance_program', value, 'airframe')}
            renderInput={params => <TextField required error={error.maintenance_program ? true : false} helperText={error.maintenance_program ? error.maintenance_program : ''} onFocus={() => updateErrorField('maintenance_program', '', 'airframeError')} {...params} label="Maintenance Program" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          />
        </Grid>:null
      }

      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            disabled={mode !== 'add' || !permissionFlag ? true:false}
            margin="normal"
            id="date_of_manufacture"
            label="Date of Manufacture"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            maxDate={crudData.generic.report_date ? moment(crudData.generic.report_date):moment()}
            minDate={crudData.generic.report_date ? moment(crudData.generic.report_date ).subtract(25, 'years'):moment().subtract(25, 'years')}
            onChange={(data, value) => onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat) : data, 'generic')}
            value={crudData.generic.date_of_manufacture}
            error={error.date_of_manufacture ? true : false}
            helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
            onFocus={() => updateErrorField('date_of_manufacture', '', 'airframeError')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          required
          id="mtow"
          label='Max Take of Weight'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.generic.mtow}
          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mtow', e.target.value, 'generic'):e.preventDefault()}}
          error={error.mtow ? true : false}
          helperText={error.mtow ? error.mtow : ''}
          onFocus={() => updateErrorField('mtow', '', 'airframeError')}
          InputProps={{
            endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          required
          id="mtow_kgs"
          label='Max Take of Weight'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.generic.mtow_kgs}
          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'mtow_kgs', e.target.value, 'generic'):e.preventDefault()}}
          InputProps={{
            endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disableClearable
          disabled={mode === 'view' || !permissionFlag ? true:false}
          options={crudData.airframe && crudData.airframe.aircraftType ? [{value:1,label:'Average'}, {value:2, label:'Planned'}]:[{value:1,label:'Average'}]}
          getOptionLabel={option => option.label}
          id="utilization_type"
          value={crudData.airframe.utilization_type?[{value:1,label:'Average'}, {value:2, label:'Planned'}].find(item => item.value == crudData.airframe.utilization_type):null}
          onChange={(e, value) => onFieldChange(e, 'utilization_type', value ? value.value:value, 'airframe')}
          renderInput={params => <TextField required error={error.utilization_type ? true : false} helperText={error.utilization_type ? error.utilization_type : ''} onFocus={() => updateErrorField('utilization_type', '', 'airframeError')} {...params} label="Utilization Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>
      { crudData.airframe.utilization_type === 2 ?
        <Fragment>
          <Grid item md={4} xs={12}>
            <TextField
              required
              id="planned_fh"
              label='Annual Planned FH'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.airframe.planned_fh}
              onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'planned_fh', e.target.value, 'airframe'):e.preventDefault()}}
              error={error.planned_fh ? true : false}
              helperText={error.planned_fh ? error.planned_fh : ''}
              onFocus={() => updateErrorField('planned_fh', '', 'airframeError')}
              InputProps={{
                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              required
              id="planned_fc"
              label='Annual Planned FC'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={crudData.airframe.planned_fc}
              onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'planned_fc', e.target.value, 'airframe'):e.preventDefault()}}
              error={error.planned_fc ? true : false}
              helperText={error.planned_fc ? error.planned_fc : ''}
              onFocus={() => updateErrorField('planned_fc', '', 'airframeError')}
              InputProps={{
                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
        </Fragment>:null
      }

      <Grid item md={4} xs={12}>
        <TextField
          required
          id="tsn"
          label='TSN'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.airframe.tsn}
          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, 'airframe'):e.preventDefault()}}
          error={error.tsn ? true : false}
          helperText={error.tsn ? error.tsn : ''}
          onFocus={() => updateErrorField('tsn', '', 'airframeError')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          required
          id="csn"
          label='CSN'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.airframe.csn}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value, 'airframe'): e.preventDefault()}}
          error={error.csn ? true : false}
          helperText={error.csn ? error.csn : ''}
          onFocus={() => updateErrorField('csn', '', 'airframeError')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            disabled={mode === 'view' || !permissionFlag ? true:false}
            margin="normal"
            id="utilization_as_of_date"
            label="Utilization As of Date"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            minDate={crudData.generic.date_of_manufacture ?  moment(crudData.generic.date_of_manufacture) : moment().subtract('24', 'years')}
            maxDate={maxDate}
            onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, 'airframe')}
            value={crudData.airframe.utilization_as_of_date}
            error={error.utilization_as_of_date ? true : false}
            helperText={error.utilization_as_of_date ? error.utilization_as_of_date : ''}
            onFocus={() => updateErrorField('utilization_as_of_date', '', 'airframeError')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode === 'view' || !permissionFlag ? true:false}
          options={lessee}
          getOptionLabel={option => option.name}
          id="lessee_id"
          value={crudData.generic.lessee_id?crudData.generic.lessee_id:null}
          onChange={(e, value) => onFieldChange(e, 'lessee_id', value, 'generic')}
          renderInput={params => <TextField {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode === 'view' || !permissionFlag ? true:false}
          options={seatConfigs}
          getOptionLabel={option => option.label}
          id="seating_configuration"
          value={crudData.airframe.seating_configuration?crudData.airframe.seating_configuration:null}
          onChange={(e, value) => onFieldChange(e, 'seating_configuration', value, 'airframe')}
          renderInput={params => <TextField {...params} label="Main Deck Configuration" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode === 'view' || !permissionFlag ? true:false}
          options={mods}
          getOptionLabel={option => option.name}
          id="mods"
          value={crudData.generic.mods?crudData.generic.mods:null}
          onChange={(e, value) => onFieldChange(e, 'mods', value, 'generic')}
          renderInput={params => <TextField {...params} label="Modification/Enhancements" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
          multiple={true}
          filterSelectedOptions={true}
          openOnFocus={true}
        />
      </Grid>
    </Fragment>
  )
}
export default AirframeBasic;
