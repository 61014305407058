import React, {  Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getLocalStorageInfo } from '../utils';
import { isMobile } from 'react-device-detect';
import restrictIcon from '../assets/images/restrict_icon.svg';
import config from '../config';
export default function userRoleBaseAccessHoc(HocComponent, extraProps=[]){
  return class extends Component{
    render(){
      if(getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.valuation){
        return(<HocComponent {...this.props} />)
      }else{
        if(!getLocalStorageInfo()){
          <div className="page-not-found">Loading...</div>
        }else{
          return(
            <div>
              <div className="page-not-found">
                <Grid container spacing={0}>
                  <Grid xs={12}>
                    <img src={restrictIcon} alt="Icon"/>
                    <h2>You’re not permitted to see this.</h2>
                    <p>The page you’re trying to access has restricted access,<br/>
                      please contact <a style={{color:'#3F51B5', textDecoration:'auto'}} href="mailto:support@sparta.aero">support@sparta.aero</a> to access this application.
                     </p>
                    <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign(`${config.domain.subDomian}`)}>Return Home</Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }
      }
    }
  }
}
