import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import moment from 'moment';
import { errorCode } from '.'
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';

export function getValuationLogApi(props = {}, id, query = {}) {
  return new Promise(
    function (resolve, reject) {
      globalGetService(`valuation/logs/${id}/`, query)
        .then(response => {
          resolve(response);
        })
    }
  )
}
export function createValuationLogApi(props = {}, data = {}) {
  this.setState({ pageLoader: true });
  globalPostService(`valuation/initiate/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        props.history.push(`/logs/${response.data.data.log_id}`);
        trackActivity('Log Generated', {
          type: 'BV/CMV and Future Base Value',
          log_id: response.data.data.log_id
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        trackActivity('Log Generation Failed', {
          source: 'backend'
        })
      }
    })
}
export function updateValuationLogApi(props = {}, data = {}, getResponseBack) {
  this.setState({ pageLoader: true });
  globalPutService(`valuation/logs/${this.props.match.params.id}/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        props.history.push(`/logs/${response.data.data.log_id}`);
        this.setState({ mode: 'view' })
        trackActivity('Log Edited', {
          type: 'BV/CMV and Future Base Value',
          log_id: props.match.params.id
        });
        getResponseBack();
      } else {
        trackActivity('Log Edit Failed', { source: 'backend' })
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function exportValuationLogApi(props = {}, query = {}, data) {

  this.setState({ pageLoader: true });
  var utility_type_name=data.utility_type==1?'Base Value':'Future Value'
  globalExportService(`valuation/logs/${props.match.params.id}/`, { download: query.extension, type: query.key, utility_type: 'future_value' })
    .then(response => {
      this.setState({ pageLoader: false, query: {}, type: '' });
      trackActivity('Report Exported', {
        type: utility_type_name,
        log_id: props.match.params.id,
        file_type: query.extension,
        report_type: query.key
      })
      downloadFileType(response.data, (`${data.id}_${utility_type_name}_${this.state.valuationCrud.aircraft_type.name?this.state.valuationCrud.aircraft_type.name:this.state.valuationCrud.engine_type.name}_${query.key}_${moment().format('YYYY-MM-DD')}`), query.extension);
    })
}

export function changeLogStatusApi(props, data) {
  this.setState({ pageLoader: true})
  globalPutService(`valuation/update-lock/`, data)
    .then(response => {
      this.setState({ pageLoader: false})
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState(prevState => ({
            ...prevState,
            valuationCrud:{
                ...prevState.valuationCrud,
                lock_status:data.is_locked,
            },
            mode: data.is_locked ? 'view':prevState.mode
        }))
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
