import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import BaseLeaseRates from './containers'
export const blrRoutes = [
  {
    path:'/blr/create',
    component:pageLayoutHoc(userRoleBaseAccessHoc(BaseLeaseRates,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'BaseLeaseRates'
  },
  {
    path:'/blr/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(BaseLeaseRates,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'BaseLeaseRates'
  }
]
