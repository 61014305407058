import { backendDateFormat } from '../../constants';
import { regexConstants, errorMsg1 } from '../../constants/regEx';
import moment from 'moment';
export const levCrud = {
  base_value_date: moment().format(backendDateFormat),                    // Report Date
  serial_number: "",
  aircraft_type: null,
  date_of_manufacture: null,
  engine_type: null,
  mtow: '',
  mtow_kgs: '',                                          // in lbs
  lessee: '',                                     // Lessee/Operator ID
  mods: [],
  registration_number: null,
  lease_start_date: null,
  lease_end_date: null,
  rental_type: null,                                // 1: Fixed || 2: Stepped
  rental_payment_type: { value: 1, label: 'In Advance', type: 'lev_rental_payment_type', remarks: null },                          // 1: In Advance || 2: In Arrears
  rent: '',
  annual_discount: 7.5,                            // Percentage
  markdown_on_residual_value: "",
  annual_inflation_rate: null,                        // Percentage
  asset_management_charges: 0,
  maintenance_condition: 50,
  is_full_life_maintenance_condition: 0,
  step_rentals: [],
  maintenance_program: {}
}
export const levErrorCode = {
  serial_number: {
    0: '',
    4: errorMsg1
  },
  serial_numberObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  aircraft_type: {
    0: '',
    1: 'Please select Aircraft Type'
  },
  aircraft_typeObj: {
    required: true
  },
  maintenance_program: {
    0: '',
    1: 'Please select Maintenace Program'
  },
  maintenance_programObj: {
    required: true,
  },
  date_of_manufacture: {
    0: '',
    1: 'Please enter Date of Manufacture',
    5: 'Please enter Valid Date'
  },
  date_of_manufactureObj: {
    required: true,
    datePattern: true
  },
  base_value_date: {
    0: '',
    1: 'Please enter Report Date',
    5: 'Please enter Valid Date'
  },
  base_value_dateObj: {
    required: true,
    datePattern: true
  },
  engine_type: {
    0: '',
    1: 'Please select Engine Type'
  },
  engine_typeObj: {
    required: true
  },
  mtow: {
    0: '',
    1: 'Please enter Max Take Off Weight(Lbs)'
  },
  mtowObj: {
    required: true,
  },
  mtow_kgs: {
    0: '',
    1: 'Please enter Max Take Off Weight(Kgs)'
  },
  mtow_kgsObj: {
    required: true,
  },
  lessee: {
    0: '',
    1: 'Please select Operator / Lessee'
  },
  lessee_idObj: {
    required: true
  },
  lease_start_date: {
    0: '',
    1: 'Please enter Lease Start Date',
    5: 'Please enter Valid Date'
  },
  lease_start_dateObj: {
    required: true,
    datePattern: true
  },
  lease_end_date: {
    0: '',
    1: 'Please enter Lease End Date',
    5: 'Please enter Valid Date'
  },
  lease_end_dateObj: {
    required: true,
    datePattern: true
  },
  rental_type: {
    0: '',
    1: 'Please select Rental Type'
  },
  rental_typeObj: {
    required: true
  },
  rental_payment_type: {
    0: '',
    1: 'Please select Rental Payment Type'
  },
  rental_payment_typeObj: {
    required: true
  },
  rent: {
    0: '',
    1: 'Please enter Rent'
  },
  rentObj: {
    required: true
  },
  markdown_on_residual_value: {
    0: '',
    1: 'Please enter Markdown Residual Value',
  },
  markdown_on_residual_valueObj: {
    required: true,
  },
  start_date: {
    0: '',
    1: 'Please enter Start Date',
    5: 'Please enter Valid Date'
  },
  start_dateObj: {
    required: true,
    datePattern: true
  },
  end_date: {
    0: '',
    1: 'Please enter End Date',
    5: 'Please enter Valid Date'
  },
  end_dateObj: {
    required: true,
    datePattern: true
  },
  stepRent: {
    0: '',
    1: 'Please enter Rent'
  },
  stepRentObj: {
    required: true
  },
  country: {
    0: '',
    1: 'Please enter Country'
  },
  countryObj: {
    required: true
  },
  contact_address: {
    0: '',
    1: 'Please enter Contact Address'
  },
  contact_addressObj: {
    required: true
  },
  logo: {
    0: '',
    1: 'Please upload Logo'
  },
  logoObj: {
    required: true
  },
  maintenance_program: {
    0: '',
    1: 'Please select Maintenance Program'
  },
  maintenance_programObj: {
    required: true
  }
}
export const levListHd = [
  { label: 'Date of Payment', sortOption: false },
  { label: 'No of Payment', sortOption: false },
  { label: 'Rent', sortOption: false },
  { label: 'Present Value of Rent', sortOption: false },
];
export const levListPerHd = [
  { label: 'Date of Payment', sortOption: false },
  { label: 'No of Payment', sortOption: false },
  { label: 'Rent', sortOption: false },
  { label: 'Present Value of Rent', sortOption: false },
];
export const levListPerHdForValAdmin = [
  { label: 'Date of Payment', sortOption: false },
  { label: 'No of Payment', sortOption: false },
  { label: 'Rent', sortOption: false },
  { label: 'Rent Post Deduction of Asset Management Expenses', sortOption: false },
  { label: 'Present Value Factor', sortOption: false },
  { label: 'Present Value of Rent', sortOption: false },
];
