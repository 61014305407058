import React, { Fragment, useState } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment'
import { displayDateFormatShort } from '../../constants'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import LabelValueCard from './LabelValueCard';
const RevisionEditCard = ({item}) => {
  const [showData, setShowData] = useState(false)
  return(
    <Fragment>
      <Grid container spacing={3}>
        {
          item.component ?
          <LabelValueCard md={4} label="Component"  value={item.component ? item.component : '--'} />
          : null
        }

        <LabelValueCard md={item.component ? 4 : 6} label='Updated at' value={moment(item.timestamp).format('MMMM Do YYYY, h:mm a')} />
        <LabelValueCard md={item.component ? 4 : 6} label='Updated by' value={item.user && item.user.name ? item.user.name:'--'} />
      </Grid>
      <div style={{marginBottom:'10px'}}>
        {
            showData ?
              <span onClick={(e) => {e.preventDefault(); e.stopPropagation();setShowData(false)}} style={{textDecoration:'none', fontSize:'12px', cursor:'pointer'}}><strong>Show Less <ArrowDropUpIcon style={{position:'relative', top:"8px"}} color="primary"  /> </strong></span> :
              <span onClick={(e) => {e.preventDefault(); e.stopPropagation();setShowData(true)}} style={{textDecoration:'none', fontSize:'12px', cursor:'pointer'}}><strong>Show More <ArrowDropDownIcon style={{position:'relative', top:"8px"}} color="primary"  /> </strong></span>
        }
      </div>
      {
        showData ?
        <div className="panel">
          <div className="edit-detail-table">
            <table>
              <thead>
                <tr>
                  <th style={{width:"100px"}}>Field</th>
                  <th>Previous Status</th>
                  <th>Current Status</th>
                </tr>
              </thead>
              <tbody>
                { Object.keys(item.changes).map(label =>
                  <tr>
                    <td>{label}</td>
                    <td>{item.changes[label][0]}</td>
                    <td>{item.changes[label][1]}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> : null
      }


    </Fragment>
  )
}
export default RevisionEditCard;
