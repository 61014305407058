import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import Portfolios from './containers/';
import PortfolioView from './containers/PortfolioView';
import PortfolioFBV from './containers/PortfolioFBV';
import PortfolioAnalytic from './containers/PortfolioAnalytic';
export const portfolioRoutes = [
  {
    path:'/portfolios',
    component:pageLayoutHoc(userRoleBaseAccessHoc(Portfolios,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'Portfolios'
  },
  {
    path:'/portfolio/:slug',
    component:pageLayoutHoc(userRoleBaseAccessHoc(PortfolioView,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'PortfolioView'
  }
]
