import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { imgStoragePath } from '../../../constants';
class ResetSuccess extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div>
        <div className="reset-password-block text-center">
              <div className="reset-block">
                <img src={imgStoragePath+'phase-2/success.png'} style={{ marginBottom: '15px' }}/>
                <h2>Your password has been reset successfully</h2>
                <p className="paragraph" style={{ marginBottom: '15px' }}>
                    You have successfully reset your password, now you <br/>
                    can login with your user credentials.
                </p>
                <Link to="/login" className="primary-btn">OK</Link>
              </div>
            </div>
      </div>
    )
  }
}

export default withSnackbar(ResetSuccess)

