import React, { Fragment } from 'react';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Paper, Grid } from '@material-ui/core';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
const BvFbvValueCard = ({valuationLog}) => {
  return(
    <Fragment>
      <Grid container spacing={isMobile ? 1 : 3}>
        <Grid item md={3} xs={12}>
          <div className="base-value-amount">
            {valuationLog.base_value_date ?
              <h6 className="flex-centered">Half Life BV as of {moment(valuationLog.base_value_date).format(displayDateFormatShort)}</h6>
              : null
            }
            {valuationLog.base_value ?
              <Paper>
                <h2><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.base_value}</NumericalLabel> m</h2>
              </Paper> : null
            }
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className="base-value-amount">
            {valuationLog.cmv_value ?
              <h6 className="flex-centered">Half Life CMV as of {moment(valuationLog.base_value_date).format(displayDateFormatShort)}</h6>
              : null
            }
            {valuationLog.cmv_value ?
              <Paper>
                <h2><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.cmv_value}</NumericalLabel> m</h2>
              </Paper> : null
            }
          </div>
        </Grid>

        { valuationLog.is_full_life_maintenance_condition ?
          <Fragment>
            <Grid item md={3} xs={12}>
              <div className="base-value-amount">
                {valuationLog.base_value_date ?
                  <h6 className="flex-centered">Full Life BV as of {moment(valuationLog.base_value_date).format(displayDateFormatShort)}</h6>
                  : null
                }
                {valuationLog.full_life_base_value ?
                  <Paper>
                    <h2><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.full_life_base_value}</NumericalLabel> m</h2>
                  </Paper> :
                  <Paper>
                    <h2>N/A</h2>
                  </Paper>

                }
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className="base-value-amount">
                {valuationLog.cmv_value ?
                  <h6 className="flex-centered">Full Life CMV as of {moment(valuationLog.base_value_date).format(displayDateFormatShort)}</h6>
                  : null
                }
                {valuationLog.full_life_cmv_value ?
                  <Paper>
                    <h2><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.full_life_cmv_value}</NumericalLabel> m</h2>
                  </Paper> :
                  <Paper>
                    <h2>N/A</h2>
                  </Paper>
                }
              </div>
              { !(valuationLog.full_life_cmv_value || valuationLog.full_life_base_value) ?
                <span style={{fontSize:'14px', color:'#6e6403',fontWeight:800}}>Note: Minimum difference b/w DOM & Report date should be 8yr for Full life values </span>
                :null
              }
            </Grid>
            <Grid item md={4} ></Grid>

            {valuationLog.full_life_cmv_base_value ?
                <Grid item  md={4} xs={12}>
              <div className="base-value-amount">
                  <Paper>
                    <h2>Full Life CMV / Full Life BV = <NumericalLabel>{valuationLog.full_life_cmv_base_value}</NumericalLabel></h2>
                  </Paper>
                  </div>
                </Grid>
                : <Grid item  md={4} xs={12}></Grid>
            }
          </Fragment>:null
        }
      </Grid>

    </Fragment>
  )
}

export default BvFbvValueCard;
