import { globalGetService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
export function getFleetAssetsApi(){
  return new Promise(function(resolve, reject){
    globalGetService(`valuation/fleetData/search/`)
    .then(response => {
      if(checkApiStatus(response)){
        resolve(response.data.data.list)
      }else{
        resolve([])
      }
    })
  })
}
export function getAircraftModsApi(props = {}, query = {}) {
  return new Promise(function(resolve, reject){
    globalGetService(`valuation/mods/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        resolve(response.data.data.mods)
      }else{
        resolve([])
      }
    })
  })
}
export function getMntEventsApi(props = {}, aircraftTypeId) {
  return new Promise(function(resolve, reject){
    globalGetService(`console/aircraft-type/${aircraftTypeId}/events/`, { module: 'valuation' })
    .then(response => {
      if(checkApiStatus(response)){
        let maintanenceList = [];
        const data = response.data.data;
        Object.keys(data).map((item, index) => {
          maintanenceList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
        });
        resolve(maintanenceList);
      }else{
        resolve([]);
      }
    })
  })
}

export function getEnginePRSVEventsApi(props, aircraftTypeId, engineTypeId){
  return new Promise(function(resolve, reject){
    globalGetService(`valuation/get-engine-sv-events/${aircraftTypeId}/${engineTypeId}/`)
    .then(response => {
      resolve(response)
    })
  })
}
