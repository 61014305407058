import React, { Fragment } from 'react';
import moment from 'moment';
import { Button, Paper, Grid, Tooltip } from '@material-ui/core';
import { DialWidgetCard } from '../../Elements';
import { displayDateFormatShort } from '../../../constants';

import FbvProjection from './FbvProjection'
const BvFbvOutput = ({ prevMntCond, logResult, maxYear, minYear }) => {
  return (
    <Fragment>
      <div className="bv-fbv-output">
      {logResult.future_value && Object.keys(logResult.future_value).length ?
          <Paper className="output-fbv-card flex-centered">
            <div>
              <h4>Future Base Value</h4>
              <p>Projection Till: <span>{moment(logResult.projection_to).format(displayDateFormatShort)}</span></p>
            </div>
            <FbvProjection prevMntCond={prevMntCond} logResult={logResult} maxYear={maxYear} minYear={minYear} />
          </Paper> : null
        }
        <Paper className="output-widget-card">
          <p style={{textAlign:'right',paddingTop:'10px', paddingRight:'10px'}}> Value as of: {moment(logResult.base_value_date).format(displayDateFormatShort)}</p>
          <div className="widget-inner-wrapper">
            <Grid container spacing={1}>
             {prevMntCond !=1 ?
              <Grid item md={prevMntCond==2 ? 6 : 12} xs={12}>
                <DialWidgetCard value={`${logResult.base_value.toFixed(3)}`} label="Half Life" unit='m' />
              </Grid>:null
              }
              {prevMntCond !=0 ?
                <Grid item md={prevMntCond==2 ? 6 : 12} xs={12}>
                  <DialWidgetCard value={`${logResult.full_life_base_value?logResult.full_life_base_value.toFixed(3):0}`} label="Full Life" unit='m' />
                </Grid> : null
              }
            </Grid>
            <h4>Current Base Value</h4>
          </div>
          <div className="widget-inner-wrapper">
            <Grid container spacing={1}>

            {prevMntCond !=1 ?
              <Grid item md={prevMntCond==2 ? 6 : 12} xs={12}>
                <DialWidgetCard value={`${logResult.cmv_value.toFixed(3)}`} label="Half Life" unit='m' />
              </Grid>:null
              }
              {prevMntCond !=0 ?
                <Grid item md={prevMntCond==2 ? 6 : 12} xs={12}>
                  <DialWidgetCard value={`${logResult.full_life_cmv_value?logResult.full_life_cmv_value.toFixed(3):0}`} label="Full Life" unit='m' />
                </Grid> : null
              }
            </Grid>
            <h4>Current Market Value</h4>
          </div>
          {logResult.full_life_cmv_base_value ?
           <div style={{ paddingBottom: '20px' }}>
                <Grid item md={12} xs={12}>
                  <DialWidgetCard value={`${logResult.full_life_cmv_base_value.toFixed(3)}`} label="" unit='' show_dollar='' />
                </Grid>
            <h4>Full Life CMV/Full Life BV</h4>
          </div>
           :null
          }
        </Paper>


      </div>
    </Fragment>
  )
}
export default BvFbvOutput;
