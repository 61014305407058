import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { checkUserTypeFn, permissionCheckFn } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const LgEvent = ({error, item, itemIndex, onFieldChange, updateErrorField, crudData, mode, permissionFlag}) => {
  return(
    <Fragment>
      <Grid item md={4} xs={12}>
        <TextField
          id="tsn_at_lsv"
          label='TSN @ LSV'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.tsn_at_lsv?item.tsn_at_lsv:''}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'landing_gear','', itemIndex): e.preventDefault()}}
          error={error.tsn_at_lsv ? true : false}
          helperText={error.tsn_at_lsv ? error.tsn_at_lsv : ''}
          onFocus={() => updateErrorField('tsn_at_lsv', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          id="csn_at_lsv"
          label='CSN @ LSV'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.csn_at_lsv?item.csn_at_lsv:''}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'landing_gear','', itemIndex):e.preventDefault()}}
          error={error.csn_at_lsv ? true : false}
          helperText={error.csn_at_lsv ? error.csn_at_lsv : ''}
          onFocus={() => updateErrorField('csn_at_lsv', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            disabled={mode === 'view' || !permissionFlag? true:false}
            margin="normal"
            id="lsv_date"
            label="LSV Date"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            minDate={moment().subtract(25, 'years')}
            onChange={(data, value) => onFieldChange(value, 'lsv_date', data ? moment(data).format(backendDateFormat) : data, 'landing_gear','', itemIndex)}
            value={item.lsv_date?item.lsv_date:null}
            error={error.lsv_date ? true : false}
            helperText={error.lsv_date ? error.lsv_date : ''}
            onFocus={() => updateErrorField('lsv_date', '')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      { checkUserTypeFn() && permissionCheckFn({secondaryKey:'hthl', keyIndex:'OV'}) ?
        <Fragment>
          <Grid item md={4} xs={12}>
            <TextField
              id="interval_fc"
              label='Interval FC'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.interval_fc?item.interval_fc:''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'interval_fc', e.target.value, 'landing_gear','', itemIndex): e.preventDefault()}}
              error={error.interval_fc ? true : false}
              helperText={error.interval_fc ? error.interval_fc : ''}
              onFocus={() => updateErrorField('interval_fc', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="interval_mo"
              label='Interval MO'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.interval_mo?item.interval_mo:''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value)? onFieldChange(e, 'interval_mo', e.target.value, 'landing_gear','', itemIndex): e.preventDefault()}}
              error={error.interval_mo ? true : false}
              helperText={error.interval_mo ? error.interval_mo : ''}
              onFocus={() => updateErrorField('interval_mo', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">MO</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="cost"
              label='Event Cost'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.cost?item.cost:''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value)? onFieldChange(e, 'cost', e.target.value, 'landing_gear','', itemIndex): e.preventDefault()}}
              error={error.cost ? true : false}
              helperText={error.cost ? error.cost : ''}
              onFocus={() => updateErrorField('cost', '')}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="remarks"
              label='Remarks'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag? true:false}
              inputProps={{ maxLength: 250 }}
              InputLabelProps={{ shrink: true }}
              value={item.remarks?item.remarks:''}
              onChange={(e) => onFieldChange(e, 'remarks', e.target.value, 'landing_gear','', itemIndex)}
              error={error.remarks ? true : false}
              helperText={error.remarks ? error.remarks : ''}
              onFocus={() => updateErrorField('remarks', '')}
              variant="outlined"
              multiline={true}
            />
          </Grid>
        </Fragment>:null
      }
    </Fragment>
  )
}
export default LgEvent;
