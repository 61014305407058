import React from 'react';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { Link, withRouter } from 'react-router-dom';
import { ExportManu } from '../../../shared_elements';
import { Typography, Breadcrumbs, Button, Tooltip } from '@material-ui/core';
import { RevisionHistory } from '../../../shared_elements';
const BvBreadCrumbs = ({ match, skeletonLoader, logResult, onChangeMode, mode, exportValuationLog, changeLogStatus }) => {
  return (
    <div className="breadcrumbs-section flex-centered">
      <Breadcrumbs>
        <Link to="/logs">Logs</Link>
        <Typography color="textPrimary">Base Lease Rate</Typography>
      </Breadcrumbs>
      {match.params.id && !skeletonLoader ?
        <ul className="list-inline utils-cta flex-centered">
          <li className="list-inline-item">
            <Tooltip title="Lock/Unlock" arrow>
              {logResult.lock_status ?
                <LockIcon onClick={() => changeLogStatus({ log_id: match.params.id, is_locked: false })} color="primary" fontSize="small" />
                : <LockOpenIcon onClick={() => changeLogStatus({ log_id: match.params.id, is_locked: true })} color="primary" fontSize="small" />}
            </Tooltip>
          </li>
          <li className="list-inline-item">
            <Tooltip title="Revision History" arrow>
              <RevisionHistory
                url="audit/valuation/vtutilitylogs/"
                queryParams={{ action: 1, object_id: logResult.id }}
                buttonType={true}
                component={true}
                exportRevisionHistory={false}
                exportQueryparam={{ action: 1, object_id: logResult.id, download: 'xls' }}
                moduleType={"Base Value"}
              />
            </Tooltip>
          </li>
          {/* <li className="list-inline-item">
            <Button color="primary" variant="outlined" size='small'>Request Signed Report</Button>
          </li> */}
          <li className="list-inline-item">
            <ExportManu
              variant="contained"
              title="Export"
              files={logResult.future_blr_value && Object.keys(logResult.future_blr_value).length ?
                [{ title: 'Yearly Excel', extension: 'xls', key: 'yearly' },  { title: 'Yearly PDF', extension: 'pdf', key: 'yearly' }, { title: "Yearly Docx", extension: 'docx', key: 'yearly' }] :
                [{ title: 'PDF', extension: 'pdf', key: '' }, { title: "Docx", extension: 'docx', key: '' }]
              }
              exportReportFn={(file) => exportValuationLog(file)}
            />
          </li>
        </ul> : null
      }
    </div>
  )
}

export default withRouter(BvBreadCrumbs);
