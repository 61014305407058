import Login from './containers/Login';
import Forgot from './containers/Forgot';
import Register from './containers/Register';
import Otp from './containers/Otp';
import Reset from './containers/Reset';
import ResetSuccess from './containers/ResetSuccess';
import LessorSelection from './containers/LessorSelection';
import TermsConditions from './containers/TermsConditions';
import DataPolicy from './containers/DataPolicy';
import About from './containers/About';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userLoggedCheckHoc from '../../hocs/userLoggedCheckHoc'
export const authRoutes = [
  {
    path: '/',
    component: userLoggedCheckHoc(Login),
    key: 'login',
    apps:'Auth'
  },
  {
    path: '/login',
    component:userLoggedCheckHoc(Login) ,
    key: 'login1',
    apps:'Auth'
  },
  {
    path: '/signup',
    component: userLoggedCheckHoc(Register),
    key: 'signup',
    apps:'Auth'
  },
  {
    path: '/otp-verification/:token',
    component: Otp,
    key: 'otp',
    apps:'Auth'
  },
  {
    path: '/forgot-password',
    component: Forgot,
    key:'forgot-password',
    apps:'Auth'
  },
  {
    path: '/select-lessor',
    component: LessorSelection,
    key: 'select-lessor',
    apps:'Auth'
  },
  {
    path: '/reset-successfully',
    component: ResetSuccess,
    key: 'reset-successfully',
    apps:'Auth'
  },
  {
    path: '/create-successfully',
    component: pageLayoutHoc(ResetSuccess, {apps: 'Auth', layoutPhase: 0}),
    key: 'create-successfully',
    apps:'Auth'
  },
  {
    path: '/password/reset/:token',
    component: Reset,
    key: 'password/reset/:token',
    apps:'Auth'
  },
  {
    path: '/password/create/:token',
    component: pageLayoutHoc(Reset, {apps: 'Auth', layoutPhase: 0}),
    key: 'password/create/:token',
    apps:'Auth'
  },
  {
    path: '/termscondition',
    component: TermsConditions,
    key: 'TermsCondition',
    apps:'Auth'
  },
  {
    path: '/data-policy',
    component: DataPolicy,
    key: 'dataPolicy',
    apps:'Auth'
  },
  {
    path:'/about',
    component:About,
    key:'about',
    apps:'Auth'
  }
]
