// /^[\w\-\s]+$/

export const fieldDateFormat = 'DD-MM-YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MMMM DD, YYYY';
export const displayDateFormatShort = 'MMM DD, YYYY';
export const imgStoragePath = 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/';
export const dropzonePreviewUI = {
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  justify: 'flex-start',
  item: {
      style: { padding: 10 },
      xs: 'auto',
  },
  container: {
    spacing: 0,
    margin: 0,
    width: '100%',
    direction: 'row',
    justify: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
}
export const assetType = {
  1: 'aircraft',
  2: 'engine',
  3: 'apu',
  4: 'lg',
  5: 'propeller'
}
export const assetTypeId = {
  'aircraft': 1,
  'engine': 2,
  'apu': 3,
  'lg': 4,
  'propeller': 5
}
export const monthArray = [
  {label: 'Jan',value: '01'},
  {label: 'Feb',value: '02'},
  {label: 'Mar',value: '03'},
  {label: 'Apr',value: '04'},
  {label: 'May',value: '05'},
  {label: 'Jun',value: '06'},
  {label: 'Jul',value: '07'},
  {label: 'Aug',value: '08'},
  {label: 'Sep',value: '09'},
  {label: 'Oct',value: '10'},
  {label: 'Nov',value: '11'},
  {label: 'Dec',value: '12'}
];
export const monthDays = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value:10},
  {label: '11', value:11},
  {label: '12', value:12},
  {label: '13', value:13},
  {label: '14', value:14},
  {label: '15', value:15},
  {label: '16', value:16},
  {label: '17', value:17},
  {label: '18', value:18},
  {label: '19', value:19},
  {label: '20', value:20},
  {label: '21', value:21},
  {label: '22', value:22},
  {label: '23', value:23},
  {label: '24', value:24},
  {label: '25', value:25},
  {label: '26', value:26},
  {label: '27', value:27},
  {label: '28', value:28},
  {label: '29', value:29},
  {label: '30', value:30}
];
export const monthList = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};
export const invoiceTypes = [
  {label: 'MR Invoice', value: 1},
  {label: 'Rental Invoice', value: 2},
  {label: 'Penalty Invoice', value: 3},
  {label: 'Debit Note Invoice', value: 4},
  {label: 'Credit Note Invoice', value: 5}
];
export const invoiceStatusDrpdwn = [
  {value: 1, label: 'Draft'},
  {value: 2, label: 'Sent'},
  {value: 3, label: 'Paid'},
  {value: 4, label: 'Cancelled'},
];
export const rentalTypes = [
  {value: 1, label: 'Fixed'},
  {value: 2, label: 'Custom ( With Payment Schedule )'}
];
export const statusLabel = {
  'On Lease': 'green',
  'Draft': 'blue'
}
export const securityGroups = {
  lessor_admin: 29,
  technical_admin: 24
};
