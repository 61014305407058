import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Paper, Grid, FormControl, FormLabel, FormControlLabel, FormGroup, RadioGroup, Radio, Checkbox, TableRow, TableCell } from '@material-ui/core';
import { ValuationOutPlaceHolder, MaintenaceCondition, BasicDetail, FbvInputs, ValueAsOfCard } from '../../Elements'
import { BvFbvOutput, BvBreadCrumbs, FbvProjectionGraph, EngineMods } from '../components';
import { PageLoader, TableListComp } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { getAircraftTypesAc, getEngineTypesAc } from '../../../shared_elements/actionCreators';
import { getValuationLogApi, createValuationLogApi, updateValuationLogApi, exportValuationLogApi, changeLogStatusApi } from '../apiServices';
import { getFleetAssetsApi, getAircraftModsApi, getMntEventsApi, getEnginePRSVEventsApi } from '../../Elements/apiServices';
import { checkApiStatus, removeEmptyKey } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { valuationCrud, errorCode } from '../'
import * as actions from '../../../shared_elements/actions';
const queryString = require('query-string');
class BaseValue extends Component {
  constructor(props) {
    super(props);
    let initialPayload = {};
    if (props.match.params && props.match.params.id) {
      initialPayload = {};
    } else {
      let query = queryString.parse(props.location.search);
      initialPayload = { ...valuationCrud, ...query };
    }
    this.state = {
      skeletonLoader: this.props.match.params.id ? false : true,
      prevMntCond: null,
      pageLoader: false,
      mode: this.props.match.params && this.props.match.params.id ? 'view' : 'add',
      valuationCrud: initialPayload,
      minYear: null,
      maxYear: null,
      error: {},
      mods: [],
      mntGroups: [],
      fleetInfo:{}
    }
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getValuationLogApi = getValuationLogApi.bind(this);
    this.getMntEventsApi = getMntEventsApi.bind(this);
    this.getEnginePRSVEventsApi = getEnginePRSVEventsApi.bind(this);
    this.createValuationLogApi = createValuationLogApi.bind(this);
    this.updateValuationLogApi = updateValuationLogApi.bind(this);
    this.exportValuationLogApi = exportValuationLogApi.bind(this);
    this.changeLogStatusApi = changeLogStatusApi.bind(this);
    this.getFleetAssetsApi = getFleetAssetsApi.bind(this);
  }
  componentDidMount() {
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.getFleetAssetsApi()
      .then(response => {
        this.setState({fleetInfo:response});
      })
    if (this.props.match.params.id) {
      this.fetchValuationLog('skeletonLoader');
    }
  }
  fetchValuationLog = (loaderType = "pageLoader") => {
    this.setState({ pageLoader: true });
    this.setState({ [loaderType]: true });
    this.setState({ error: {} })
    this.getValuationLogApi(this.props, this.props.match.params.id)
      .then(response => {
        this.setState({ pageLoader: false });
        this.setState({ [loaderType]: false });
        if (checkApiStatus(response)) {
          let data = Object.assign({}, response.data.data);
          if (data.asset_type === 2) {
            data = {
              ...data,
              utility_type: data.future_value && Object.keys(data.future_value).length ? 2 : 1,
            }
            this.setState({ prevMntCond: data.is_full_life_maintenance_condition, valuationCrud: data, mode: 'view' });
          } else {
            this.props.getEngineTypes({ aircraft_type: response.data.data.aircraft_type.id });
            this.getAircraftModsApi(this.props, { aircraft_type: response.data.data.aircraft_type.id })
              .then(response => {
                this.setState({ mods: response });
              });
            this.getMntEventsApi(this.props, data.aircraft_type.id)
              .then(response => {
                this.setState({ mntGroups: response })
              });
            let maxY, minY;
            if (Object.keys(response.data.data.future_value)[0]) {
              minY = Object.keys(response.data.data.future_value)[0];
            } else {
              minY = '0000';
            }
            if (Object.keys(response.data.data.future_value)[Object.keys(response.data.data.future_value).length - 1]) {
              maxY = Object.keys(response.data.data.future_value)[Object.keys(response.data.data.future_value).length - 1]
            } else {
              maxY = '0000';
            }
            this.setState({ minYear: minY.slice(0, 4), maxYear: maxY.slice(0, 4) })
            if (data.is_full_life_maintenance_condition) {
              this.getEnginePRSVEventsApi(this.props, data.aircraft_type.id, data.engine_type.id)
                .then(response => {
                  let oldEngines = data.engine_sv_details;
                  if (checkApiStatus(response)) {
                    let newEngines = {}
                    if (data.engine_sv_details && data.engine_sv_details.length) {
                      newEngines = {
                        no_of_engines: data.engine_sv_details.length,
                        is_propeller_aircraft: false,
                        engines: response.data.data.engines.map((item, index) => oldEngines[index] ? oldEngines[index] : item)
                      };
                    } else {
                      newEngines = response.data.data;
                    }
                    data = {
                      ...data,
                      mtow_kgs: (data.mtow * 0.4535970244).toFixed(4),
                      engine_sv_details: newEngines,
                      utility_type: data.future_value && Object.keys(data.future_value).length ? 2 : 1,
                    }
                    this.setState({ prevMntCond: data.is_full_life_maintenance_condition, valuationCrud: data, mode: 'view' });
                  }
                })
            } else {
              data = {
                ...data,
                utility_type: data.future_value && Object.keys(data.future_value).length ? 2 : 1,
                mtow_kgs: (response.data.data.mtow * 0.4535970244).toFixed(4)
              }
              this.setState({ prevMntCond: data.is_full_life_maintenance_condition, valuationCrud: data, mode: 'view' });
            }
          }
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          this.props.history.push('/logs');
        }
      })
  }
  onChangeEvents = (e, keyParam, value, index) => {
    if (keyParam === 'no_of_engines') {
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          engine_sv_details: {
            ...prevState.valuationCrud.engine_sv_details,
            no_of_engines: value
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          engine_sv_details: {
            ...prevState.valuationCrud.engine_sv_details,
            engines: prevState.valuationCrud.engine_sv_details.engines.map((engine, engineIndex) => engineIndex === index ? { ...engine, [keyParam]: value } : engine)
          }
        }
      }))
    }
  }
  onChangeEngineMods = (e, keyParam, value, index, modeType) => {
    if (modeType === 'edit') {
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          engine_mods: prevState.valuationCrud.engine_mods.map((item, itemIndex) => itemIndex === index ? { ...item, [keyParam]: value } : item)
        }
      }))
    }
    if (modeType === 'add') {
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          engine_mods: [...prevState.valuationCrud.engine_mods, { name: '', value: '' }]
        }
      }))
    }
    if (modeType === 'delete') {
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          engine_mods: prevState.valuationCrud.engine_mods.filter((item, itemIndex) => itemIndex !== index)
        }
      }))
    }
  }
  onChangeSerialNo = (e, keyParam, value) => {
    const { aircraftTypes } = this.props;
    this.setState(prevState => ({
      ...prevState,
      valuationCrud: {
        ...prevState.valuationCrud,
        engine_type: value ? value.engine_type:null,
        mtow_kgs:value ? value.mtow:'',
        mtow:value ?(value.mtow * 2.2046).toFixed(4):'',
        date_of_manufacture:value ? value.date_of_manufacture:null,
        serial_number:value ? value.serial_number:'',
        aircraft_type:value ? aircraftTypes.find(item => item.id === value.aircraft_type.id):null
      }
    }));
    if(value){
      this.getAircraftModsApi(this.props, { aircraft_type: value.aircraft_type.id })
      .then(response => {
        this.setState({ mods: response });
      })
      this.getMntEventsApi(this.props, value.aircraft_type.id)
      .then(response => {
        this.setState({ mntGroups: response })
      })
      this.props.getEngineTypes({ aircraft_type: value.aircraft_type.id, valuation: 'valuation' })
    }else{
      this.setState({ mods: [], mntGroups: [] });
      this.props.clearEngineTypes();
    }
  }
  onFieldChange = (e, keyParam, value) => {
    const { valuationCrud } = this.state;
    this.updateFormFields(keyParam, value);
    if (keyParam === 'asset_type') {
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          is_global_msn:null,
          serial_number: '',
          mtow:'',
          mtow_kgs:'',
          date_of_manufacture:null,
          engine_type:null,
          aircraft_type:null
        }
      }));
      this.props.clearEngineTypes();
      if (value === 2) {
        this.props.getEngineTypes({ valuation: 'valuation', 'with_bv': true })
      } else {
        if (valuationCrud.aircraft_type && valuationCrud.aircraft_type.id) {
          this.props.getEngineTypes({ aircraft_type: valuationCrud.aircraft_type.id, valuation: 'valuation' })
        }
      }
    }
    if (keyParam === 'aircraft_type') {
      this.updateFormFields('mods', []);
      this.updateFormFields('maintenance_program', null);
      this.updateFormFields('engine_type', null);
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
          .then(response => {
            this.setState({ mods: response });
          })
        this.getMntEventsApi(this.props, value.id)
          .then(response => {
            this.setState({ mntGroups: response })
          })
        this.props.getEngineTypes({ aircraft_type: value.id, valuation: 'valuation' })
      } else {
        this.setState({ mods: [], mntGroups: [] })
      }
    }
    if ((keyParam === 'mtow' || keyParam === 'mtow_kgs') && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(4));
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(4));
      }
    }
    if (keyParam === 'date_of_manufacture') {
      this.updateFormFields('base_value_date', null);
    }
    if (keyParam === 'is_full_life_maintenance_condition') {
      if (valuationCrud.asset_type && valuationCrud.asset_type === 1) {
        if (value) {
          this.getEnginePRSVEventsApi(this.props, valuationCrud.aircraft_type.id, valuationCrud.engine_type.id)
            .then(response => {
              if (checkApiStatus(response)) {
                this.setState(prevState => ({
                  ...prevState,
                  valuationCrud: {
                    ...prevState.valuationCrud,
                    engine_sv_details: response.data.data
                  }
                }))
              }
            })
        } else {
          this.updateFormFields('engine_sv_details', []);
        }
      }
    }
    if (['aircraft_type', 'engine_type'].includes(keyParam)) {
      this.updateFormFields('is_full_life_maintenance_condition', 0);
      this.updateFormFields('engine_sv_details', null);
    }
    if(keyParam === 'is_global_msn'){
      if(!value){
        this.props.clearEngineTypes();
      }
      this.setState(prevState => ({
        ...prevState,
        valuationCrud: {
          ...prevState.valuationCrud,
          serial_number: '',
          mtow:'',
          mtow_kgs:'',
          date_of_manufacture:null,
          engine_type:null,
          aircraft_type:null
        }
      }));
    }
  }
  updateFormFields = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      valuationCrud: {
        ...prevState.valuationCrud,
        [key]: value
      }
    }));
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  createValuationFn = () => {
    const { valuationCrud } = this.state;
    let validationInputs = {};
    if (valuationCrud.asset_type === 1) {
      validationInputs = {
        aircraft_type: errorCode['aircraft_type'][fieldValidation({ ...errorCode['aircraft_typeObj'], fieldval: valuationCrud.aircraft_type })],
        serial_number: errorCode['serial_number'][fieldValidation({ ...errorCode['serial_numberObj'], fieldval: valuationCrud.serial_number })],
        date_of_manufacture: errorCode['date_of_manufacture'][fieldValidation({ ...errorCode['date_of_manufactureObj'], fieldval: valuationCrud.date_of_manufacture })],
        engine_type: errorCode['engine_type'][fieldValidation({ ...errorCode['engine_typeObj'], fieldval: valuationCrud.engine_type })],
        mtow: errorCode['mtow'][fieldValidation({ ...errorCode['mtowObj'], fieldval: valuationCrud.mtow })],
        mtow_kgs: errorCode['mtow_kgs'][fieldValidation({ ...errorCode['mtow_kgsObj'], fieldval: valuationCrud.mtow_kgs })],
        base_value_date: errorCode['base_value_date'][fieldValidation({ ...errorCode['base_value_dateObj'], fieldval: valuationCrud.base_value_date })],
        projection_to: parseInt(valuationCrud.utility_type) !== 1 ? errorCode['projection_to'][fieldValidation({ ...errorCode['projection_toObj'], fieldval: valuationCrud.projection_to })] : '',
        maintenance_program: valuationCrud.is_full_life_maintenance_condition !== 0 ? errorCode['maintenance_program'][fieldValidation({ ...errorCode['maintenance_programObj'], fieldval: valuationCrud.maintenance_program })] : '',
        escalation_percentage: parseInt(valuationCrud.utility_type) !== 1 ? valuationCrud.escalation_percentage && valuationCrud.escalation_percentage.toString().trim().length ? parseFloat(valuationCrud.escalation_percentage) >= 1 && parseFloat(valuationCrud.escalation_percentage) <= 5 ? '' : 'Value should be b/w 1 to 5' : 'Please enter Escalation Percentage' : ''
      }
    } else {
      validationInputs = {
        serial_number: errorCode['serial_number'][fieldValidation({ ...errorCode['serial_numberObj'], fieldval: valuationCrud.serial_number })],
        engine_type: errorCode['engine_type'][fieldValidation({ ...errorCode['engine_typeObj'], fieldval: valuationCrud.engine_type })],
        base_value_date: errorCode['base_value_date'][fieldValidation({ ...errorCode['base_value_dateObj'], fieldval: valuationCrud.base_value_date })],
        projection_to: parseInt(valuationCrud.utility_type) !== 1 ? errorCode['projection_to'][fieldValidation({ ...errorCode['projection_toObj'], fieldval: valuationCrud.projection_to })] : '',
        escalation_percentage: parseInt(valuationCrud.utility_type) !== 1 ? valuationCrud.escalation_percentage && valuationCrud.escalation_percentage.toString().trim().length ? parseFloat(valuationCrud.escalation_percentage) >= 1 && parseFloat(valuationCrud.escalation_percentage) <= 5 ? '' : 'Value should be b/w 1 to 5' : 'Please enter Escalation Percentage' : ''
      }
    }
    if (valuationCrud.base_value_date) {
      if (valuationCrud.asset_type === 2) {
        validationInputs = {
          ...validationInputs,
          base_value_date: moment(valuationCrud.base_value_date).isBetween(moment().startOf('year').subtract(1, 'day'), moment().endOf('year')) ? '' : 'Value as of date should be in current year'
        }
      } else {
        validationInputs = {
          ...validationInputs,
          base_value_date: moment(valuationCrud.base_value_date).isBetween(moment().subtract(1, 'year').subtract(1, 'day'), moment().endOf('year')) ? '' : `Value as of date should be in current year or previous year`
        }
      }
    }

    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      let payload = Object.assign({}, valuationCrud);
      delete payload.mtow_kgs;
      if (parseInt(valuationCrud.utility_type) === 1) {
        delete payload.projection_to
        delete payload.projection_from
        delete payload.escalation_percentage
      } else {
        if (payload.projection_to) {
          payload = {
            ...payload,
            projection_from: payload.base_value_date,
          }
        }
      }
      if (valuationCrud.asset_type === 1) {
        if (payload.mods.length) {
          payload = {
            ...payload,
            mods: valuationCrud.mods.map(mod => mod.id)
          }
        }
        if (payload.is_full_life_maintenance_condition) {
          payload = {
            ...payload,
            engine_sv_details: valuationCrud.engine_sv_details.engines.slice(0, valuationCrud.engine_sv_details.no_of_engines)
          }
          if (payload.maintenance_program) {
            payload = {
              ...payload,
              maintenance_program: valuationCrud.maintenance_program.value
            }
          }
        }
      }
      if (valuationCrud.asset_type === 2) {
        payload = {
          ...payload,
          mtow: null,
          engine_mods: payload.engine_mods.filter(item => item.name && item.value).map(item => { return { name: item.name, value: Number(item.value) } })
        }
      }

      if ((payload.utility_type in [1, 2]) && (payload.is_full_life_maintenance_condition)) {
        if (payload.base_value_date.includes('2022-')) {
          this.props.enqueueSnackbar('Full life calculation not available for 2022 Year', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        else {
          if (this.props.match.params && this.props.match.params.id) {
            debugger
            this.updateValuationLogApi(this.props, payload, this.fetchValuationLog);
          } else {
            this.createValuationLogApi(this.props, payload);
          }
        }
      }
      else {
        if (this.props.match.params && this.props.match.params.id) {
          this.updateValuationLogApi(this.props, payload, this.fetchValuationLog);
        } else {
          this.createValuationLogApi(this.props, payload);
        }
      }
    } else {
      let target = Object.keys(removeEmptyKey(validationInputs))[0];
      if (this.props.match.params.id) {
        window.location.href = `/logs/${this.props.match.params.id}#${target}`;
      } else {
        window.location.href = `/logs/create${this.props.location.search}#${target}`;
      }
      this.setState({ error: validationInputs });
    }
  }
  render() {
    const { fleetInfo, prevMntCond, pageLoader, mode, valuationCrud, error, mods, mntGroups, minYear, maxYear, skeletonLoader } = this.state;
    const { aircraftTypes, engineTypes } = this.props;
    return (
      <section className="valuation-app-section">
        <BvBreadCrumbs skeletonLoader={skeletonLoader} changeLogStatus={(data) => this.changeLogStatusApi(this.props, data)} logResult={valuationCrud} mode={mode} onChangeMode={() => this.setState(prevState => ({ ...prevState, mode: mode === 'view' ? 'edit' : 'view' }))} exportValuationLog={(file) => this.exportValuationLogApi(this.props, file, valuationCrud)} />
        <Grid container spacing={1}>
          <Grid item md={8} sm={8} xs={12}>
            <Paper className="add-bv-fbv-form">
              <div style={{ maxHeight: `${window.innerHeight - 195}px`, overflow: 'auto', padding: '10px 15px', position: 'relative' }}>
                {
                  !valuationCrud.lock_status && mode === 'view' && !skeletonLoader ? <span onClick={() => this.setState({ mode: 'edit' })} className="edit-icon"><EditIcon color="primary" fontSize="small" /></span> : null
                }
                <form className="form">
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <div className="select-utils-type">
                        <div className="util-card flex-centered" style={{ marginTop: '15px' }}>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                disabled={mode === 'view' ? true : false}
                                value="end"
                                control={<Checkbox style={{ cursor: 'disabled' }}
                                  checked={true} color="primary" />}
                                label={<Fragment>BV and CMV<sup style={{ fontSize: '9px', color: '#3f51b5' }}>(DEFAULT)</sup></Fragment>}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                disabled={mode === 'view' ? true : false}
                                value="end"
                                control={<Checkbox onChange={(e) => this.onFieldChange(e, 'utility_type', e.target.checked ? 2 : 1)}
                                  checked={((parseInt(valuationCrud.utility_type) === 2) || (valuationCrud.utility_type == 'Future Value')) ? true : false} color="primary" />}
                                label="FBV"
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                          <div className='flex-centered' style={{ marginLeft: 'auto' }}>
                            <span style={{ paddingRight: '10px', fontSize: '15px' }}><b>Asset Type:</b></span>
                            <FormControl component="fieldset" disabled={this.props.match.params.id ? true : false}>
                              <RadioGroup row aria-label="asset_type" name="asset_type">
                                <FormControlLabel
                                  value="asset_type"
                                  control={<Radio size="small" onClick={(e) => this.onFieldChange(e, 'asset_type', 1)} checked={valuationCrud.asset_type === 1 ? true : false} color="primary" />}
                                  label="Aircraft"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value="asset_type"
                                  control={<Radio size="small" onClick={(e) => this.onFieldChange(e, 'asset_type', 2)} checked={valuationCrud.asset_type === 2 ? true : false} color="primary" />}
                                  label="Engine"
                                  labelPlacement="end"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>

                      </div>
                    </Grid>

                    <BasicDetail
                      mode={mode}
                      crudData={valuationCrud}
                      onFieldChange={this.onFieldChange}
                      onChangeSerialNo={this.onChangeSerialNo}
                      updateErrorField={this.updateErrorField}
                      error={error}
                      mods={mods}
                      aircraftTypes={aircraftTypes}
                      engineTypes={engineTypes}
                      fleetInfo={fleetInfo}
                    />
                    <Grid item md={12}>
                      {valuationCrud.asset_type && valuationCrud.asset_type === 2 ?
                        <div className='engine-mods'>
                          <div className="flex-centered title-blk">
                            <h6>Modifications/Enhancements</h6>
                            {mode !== 'view' ? <Button onClick={(e) => this.onChangeEngineMods(e, null, null, null, 'add')} size="small" color="primary" variant="outlined">Add</Button> : null}
                          </div>
                          <TableListComp
                            heads={mode === 'view' ? [{ label: 'Name', sortOption: false }, { label: 'Amount(US$)', sortOption: false }] : [{ label: 'Name', sortOption: false }, { label: 'Amount(US$)', sortOption: false }, { label: 'Action', sortOption: false }]}
                            data={valuationCrud.engine_mods.length ? valuationCrud.engine_mods.map((item, index) =>
                              <EngineMods
                                mode={mode}
                                item={item}
                                index={index}
                                onChangeEngineMods={this.onChangeEngineMods}
                              />
                            ) : <TableRow><TableCell align="center" colSpan={mode === 'view' ? 2 : 3}>Mods Not Added</TableCell></TableRow>}
                          />
                        </div> : null
                      }
                    </Grid>
                    <MaintenaceCondition
                      mode={mode}
                      error={error}
                      maintenaceObj={valuationCrud}
                      onFieldChange={this.onFieldChange}
                      updateErrorField={this.updateErrorField}
                      onChangeEvents={this.onChangeEvents}
                      mntGroups={mntGroups}
                    />

                    {parseInt(valuationCrud.utility_type) === 2 ?
                      <FbvInputs
                        mode={mode}
                        valuationCrud={valuationCrud}
                        onFieldChange={this.onFieldChange}
                        updateErrorField={this.updateErrorField}
                        error={error}
                        minYear={minYear}
                        maxYear={maxYear}
                      /> : null
                    }

                  </Grid>
                </form>
              </div>
              <ValueAsOfCard
                mode={mode}
                error={error}
                onFieldChange={this.onFieldChange}
                updateErrorField={this.updateErrorField}
                logResult={valuationCrud}
                onSubmit={this.createValuationFn}
                onCancel={this.fetchValuationLog}
              />
            </Paper>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <div style={{ maxHeight: `${window.innerHeight - 110}px`, overflow: 'auto' }}>
              {this.props.match.params.id && Object.keys(valuationCrud).length && prevMntCond !== null ?
                <BvFbvOutput prevMntCond={prevMntCond} logResult={valuationCrud} minYear={minYear} maxYear={maxYear} /> :
                <ValuationOutPlaceHolder />
              }
            </div>
          </Grid>
        </Grid>
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: () => dispatch(getAircraftTypesAc(ownProps, { valuation: 'valuation' })),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    clearEngineTypes: () => dispatch({ type: actions.ENGINE_TYPES, payload: [] })
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(BaseValue))
