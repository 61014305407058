import React, { Component, Fragment } from 'react';
import { Drawer, Grid, IconButton, Tooltip, Button, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { imgStoragePath } from '../../constants';
import { trackActivity } from '../../utils/mixpanel';
import { getRevisionHistoryApi, exportRevisionHistory } from '../apiServices';
import HistoryIcon from '@material-ui/icons/History';
import EmptyCollection from './EmptyCollection';
import PageLoader from './PageLoader';
import RevisionEditCard from './RevisionEditCard';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloseIcon from '@material-ui/icons/Close';
class RevisionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pageLoader: false,
      revisionHistories: []
    }
    this.getRevisionHistoryApi = getRevisionHistoryApi.bind(this);
    this.exportRevisionHistory = exportRevisionHistory.bind(this);
  }
  componentDidMount() {
    trackActivity('Opened Revision History ', {
      type: this.props.moduleType,
      log_id: this.props.queryParams.object_id
    })
  }
  render() {
    const { open, pageLoader, revisionHistories } = this.state;
    const { url, queryParams, buttonType, exportRevisionHistory, exportQueryparam, moduleType, exportPermission } = this.props;
    return (
      <Fragment>
        {buttonType ?
          <Tooltip title="Revison History" arrow>
            <IconButton size="small" color="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.getRevisionHistoryApi(url, queryParams) }}>
              <HistoryIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip> :
          <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.getRevisionHistoryApi(url, queryParams) }} >
            <Button>
              <Typography variant={"h6"} color="primary" style={{ color: '#4051b5', fontSize: '14px', fontWeight: "600" }}>
                <HistoryIcon color="primary" style={{ position: 'relative', top: '7px', marginRight: '5px' }} />
                Revision History
              </Typography>
            </Button>
          </span>
        }
        <Drawer anchor="right" open={open} onClose={() => this.setState({ open: false, revisionHistories: [] })}>
          <div className="edit-revision-history" style={{ width: '600px' }}>
            <Grid container spacing={2} style={{ borderBottom: '1px solid #e8e8e8', marginBottom: '30px' }}>
              <Grid item md={6} xs={10} >
                <h4>Revision History
                  {
                    exportRevisionHistory && revisionHistories.length && exportPermission ?
                      <span className="mobile-margin" style={{ marginLeft: '5px' }}><Button variant="outlined" color="primary" size="small" onClick={(file) => this.exportRevisionHistory(url, exportQueryparam, moduleType)} > <SaveAltIcon /> Export</Button></span>
                      : null
                  }
                </h4>
              </Grid>
              <Grid item md={6} xs={2}>
                <span style={{ float: 'right', cursor: 'pointer' }}>
                  <CloseIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ open: false }) }} color="primary" />
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} >
              <Grid item ></Grid>
            </Grid>
            <ul className="list-unstyled">
              {revisionHistories.map((item, index) =>
                <li>
                  <span className="timeline-icon"><img src={`${imgStoragePath}${item.action === 1 ? 'edit_audit_icn.png' : 'add_audit_icn.png'}`} alt="icon" /></span>
                  <RevisionEditCard item={item} />
                </li>
              )}
            </ul>
            {!revisionHistories.length ? <EmptyCollection title="No records found" description="" /> : null}
          </div>
        </Drawer>
        {pageLoader ? <PageLoader /> : null}
      </Fragment>
    )
  }
}
export default withSnackbar(RevisionHistory);
