import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import BaseValueCalculation from './containers/BaseValueCalculation';
import FutureValueProjection from './containers/FutureValueProjection';
import CreateLog from './containers/CreateLog';
import ValuationLogs from './containers/';
import IntermediateHla from './containers/IntermediateHla';
import InitiateHlam from './containers/InitiateHlam';
import OutputHlam from './containers/OutputHlam';

export const valuationRoutes = [
  {
    path: '/base-value/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(BaseValueCalculation, []), { apps: 'Valuation', layoutPhase: 1 }),
    key: 'BaseValueCalculation'
  },
  {
    path: '/base-value/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(BaseValueCalculation, []), { apps: 'Valuation', layoutPhase: 1 }),
    key: 'BaseValueCalculation'
  },
  {
    path: '/future-value-projection/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(FutureValueProjection, []), { apps: 'Valuation', layoutPhase: 1 }),
    key: 'FutureValueProjection'
  },
  {
    path: '/logs',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ValuationLogs, []), { apps: 'Valuation', layoutPhase: 1 }),
    key: 'ValuationLogs'
  },
  {
    path: '/create-log',
    component: pageLayoutHoc(userRoleBaseAccessHoc(CreateLog, []), { apps: 'Valuation', layoutPhase: 1 }),
    key: 'CreateLog'
  },
  {
    path: '/update-log/:id/:type',
    component: pageLayoutHoc(userRoleBaseAccessHoc(CreateLog, []), { apps: 'Valuation', layoutPhase: 1 }),
    key: 'CreateLog'
  },
  {
    path: '/half-life/intermediate/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(IntermediateHla, []), { apps: 'valuation', layoutPhase: 1 }),
    key: 'IntermediateHla'
  },
  {
    path: '/half-life/initiate',
    component: pageLayoutHoc(userRoleBaseAccessHoc(InitiateHlam, []), { apps: 'valuation', layoutPhase: 1 }),
    key: 'InitiateHlam'
  },
  {
    path: '/half-life/output/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(OutputHlam, []), { apps: 'valuation', layoutPhase: 1 }),
    key: 'OutputHlam'
  }

]
