import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { TableListComp, EmptyCollection, FilterUIComp, PageLoader, } from '../../../shared_elements';
import { Paper, Grid, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { AssetFBV, FBVHD, PortfolioBasicDetail, FBVBreadCrumbs, FBVFleetGraph, AssetFBVGraph } from '../components'
import { getPortfolioFBVLogsApi, getPortfolioApi, exportPortfolioReportApi, getPortfolioAircraftsApi, getPortfolioEngineTypesApi } from '../apiServices';
import { getAircraftTypesAc } from '../../../shared_elements/actionCreators'
import { convertFilterObject, removeEmptyKey } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { portfolioFBVFilterOps } from '../';
class PortfolioFBV extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      portfolioLoader:false,
      portfolioDetail:{},
      portfolioFBVLogs:{
        logs:[],
        total:{}
      },
      filter:{},
      applyingFilter:{},
      fbvViewType:0,
      assetFBVModel:false,
      assetFBVIndex:null,
      portfolioAircrafts:[],
      portfolioEngineTypes:[]
    }
    this.getPortfolioApi = getPortfolioApi.bind(this);
    this.getPortfolioFBVLogsApi = getPortfolioFBVLogsApi.bind(this);
    this.exportPortfolioReportApi = exportPortfolioReportApi.bind(this);
    this.getPortfolioAircraftsApi = getPortfolioAircraftsApi.bind(this);
    this.getPortfolioEngineTypesApi = getPortfolioEngineTypesApi.bind(this);
  }
  componentDidMount(){
    this.getPortfolioApi(this.props, 'portfolioLoader');
    this.getPortfolioFBVLogsApi(this.props);
    trackActivity('Portfolio', {
      event_type: 'Page Visited',
      page_title: 'Portfolio FBV'
    })
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, portfolioFBVLogs } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getPortfolioFBVLogsApi(this.props, {...query}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  render(){
    const { pageLoader, filter, applyingFilter, fbvViewType, assetFBVModel, assetFBVIndex, portfolioDetail, portfolioFBVLogs, portfolioEngineTypes,portfolioAircrafts } = this.state;
    let filterOptions = portfolioFBVFilterOps;
    filterOptions = {
      ...filterOptions,
      aircraft_type: {
        ...filterOptions.aircraft_type,
        options:portfolioAircrafts
      }
    }
    return(
      <section className="portfolio-section">
        <FBVBreadCrumbs
          fbvViewType={fbvViewType}
          onChangeView={(value) => this.setState({fbvViewType:value})}
          exportPortfolioReport={() => this.exportPortfolioReportApi(this.props,{ ...filter})}
          portfolioDetail={portfolioDetail}
        />
        <div className="portfolio-fbv-container">
          <PortfolioBasicDetail portfolioDetail={portfolioDetail} />
          { portfolioFBVLogs.logs &&
            <Fragment>
              { fbvViewType === 0 ?
                <Paper className="portfolio-fbv-table">
                  <FilterUIComp
                    filter={filter}
                    applyingFilter={applyingFilter}
                    removeFilter={this.removeFilter}
                    removeAllFilter={() => {this.getPortfolioFBVLogsApi(this.props, {}, 'pageLoader'); this.setState({applyingFilter:{}})}}
                    submitFilter={() => this.getPortfolioFBVLogsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
                    applyFilter={this.applyFilter}
                    filterMenu={filterOptions}
                  />
                  <table className="outer-table">
                    <FBVHD
                      portfolioDetail={portfolioDetail}
                      years={portfolioFBVLogs.total && portfolioFBVLogs.total.future_value ? Object.keys(portfolioFBVLogs.total.future_value).map(year => year.slice(0,4)):[]} />
                    <tbody className="outer-body">
                      { portfolioFBVLogs.logs.map((item,index) =>
                        <AssetFBV toggleModalFn={() => this.setState({assetFBVModel:true, assetFBVIndex:index})} item={item} />
                      )}
                      <tr>
                        <th>
                          <div className="asset-details" style={{width:'150px'}}>
                            <h4>Total</h4>
                          </div>
                        </th>
                        {portfolioFBVLogs.total &&  portfolioFBVLogs.total.future_value && Object.keys(portfolioFBVLogs.total.future_value).map((key, index) =>
                          <td className="content-box" align="center">
                            <table width="200">
                              <tr>
                                <td><h4 style={{fontSize:'13px', textAlign:'center'}}>{portfolioFBVLogs.total.future_value[key] ? `US$ ${portfolioFBVLogs.total.future_value[key].toFixed(3)}`:'--'}</h4></td>
                                <td><h4 style={{fontSize:'13px', textAlign:'center'}}>{portfolioFBVLogs.total.inflated_future_value[key] ? `US$ ${portfolioFBVLogs.total.inflated_future_value[key].toFixed(3)}`:'--'}</h4></td>
                              </tr>
                            </table>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </Paper>:
                <Paper className="portfolio-fbv-graph">
                  { portfolioFBVLogs.total ?
                    <FBVFleetGraph portfolioDetail={portfolioDetail} portfolioFBVLogs={portfolioFBVLogs} />:null
                  }
                </Paper>
              }
            </Fragment>
          }
        </div>
        { assetFBVModel && <AssetFBVGraph portfolioDetail={portfolioDetail} fbvLog={portfolioFBVLogs.logs[assetFBVIndex]} toggleModalFn={() => this.setState({assetFBVModel:false, assetFBVIndex:null})} assetFBVModel={assetFBVModel} />}
        { pageLoader ? <PageLoader />:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: () => dispatch(getAircraftTypesAc(ownProps, {valuation:'valuation'})),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(PortfolioFBV))
