import React, { Component } from 'react';
import { Paper, Grid, Breadcrumbs, Button } from '@material-ui/core';
import { getPortfolioAnalyticApi } from '../apiServices';
class PortfolioAnalytic extends Component{
  constructor(props){
    super(props);
    this.state = {
      portfolioAnalytic:{}
    };
    this.getPortfolioAnalyticApi = getPortfolioAnalyticApi.bind(this);
  }
  componentDidMount(){
    this.getPortfolioAnalyticApi(this.props)
  }
  render(){
    const { portfolioAnalytic } = this.state;
    return(
      <section className="portfolio-section">
        {Object.keys(portfolioAnalytic).length ?
          <div>

          </div>:null
        }

      </section>
    )
  }
}
export default PortfolioAnalytic;
