export const lgTypesObj = {
  3:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'LH MLG', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'RH MLG', position:3, tsn:'',csn:'',utilization_as_of_date:null},
  ],
  4:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'Centre', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'LH MLG', position:3, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'RH MLG', position:4, tsn:'',csn:'',utilization_as_of_date:null}
  ],
  5:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WLH', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BLH', position:3, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WRH', position:4, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BRH', position:5, tsn:'',csn:'',utilization_as_of_date:null}
  ],
  6:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'Centre', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WLH', position:3, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BLH', position:4, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WRH', position:5, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BRH', position:6, tsn:'',csn:'',utilization_as_of_date:null}
  ]
}
export const componentBreakdownHd = [
  {label:'Component', sortOption:false},
  {label:'Event', sortOption:false},
  {label:'Next Due', sortOption:false},
  {label:'Full Life Remaining', sortOption:false},
  {label:'Half Life Remaining', sortOption:false},
  {label:'Half Life Maintenance Adjustment', sortOption:false},
  {label:'Final Values in Million', sortOption:false},
];
export const componentBreakdownHd1 = [
  {label:'Component', sortOption:false},
  {label:'Event', sortOption:false},
  {label:'Last Event Before Forecast', sortOption:false},
  {label:'Full Life Remaining', sortOption:false},
  {label:'Half Life Remaining', sortOption:false},
  {label:'Half Life Maintenance Adjustment', sortOption:false},
  {label:'Final Values in Million', sortOption:false},
  {label:'Next Event after Forecast Date', sortOption:false}
];
export const engineCrud = {
  engineType:null,
  identical_data:true,
  no_of_engines:1,
  engineInfo:[
    {id:1, tsn:'', csn:'', utilization_as_of_date:null}
  ]
}
export const propellerCrud = {
  identical_data:true,
  propellerInfo:[
    {id:'', position:'', tsn:'', csn:'',utilization_as_of_date:null}
  ]
}
export const apuCrud = {
  apuType:null,
  hours:'',
  apu_hours:'',
  utilization_as_of_date:null
}
export const landing_gearCrud = {
  lg_utilization_flag:true,
  mlg_utilization_flag:false,
  lgInfo:[
    {id:'',tsn:'',csn:'',utilization_as_of_date:null,position:1}
  ]
}
export const hlaCrud = {
  apuOptional:true,
  airframe: {
    aircrafType: null,
    maintenance_program:null,
    seating_configuration:null,
    utilization_as_of_date:null,
    tsn:0,
    csn:0,
    serial_number:'',
    utilization_type:1,
    planned_fc:0,
    planned_fh:0
  },
  generic:{
    asset_type:1,
    date_of_manufacture:null,
    lessee_id:null,
    mods:[],
    mtow:'',
    mtow_kgs:'',
    report_date:null
  }
}
export const airframeErrorCode = {
  aircraftType:{
    0:"",
    1:'Please select Aircraft Type'
  },
  aircraftTypeObj:{
    required:true
  },
  engineType:{
    0:"",
    1:'Please select Engine Type'
  },
  engineTypeObj:{
    required:true
  },
  maintenance_program:{
    0:"",
    1:'Please select Maintenance Program'
  },
  maintenance_programObj:{
    required:true
  },
  date_of_manufacture:{
    0:"",
    1:'Please enter Date of Manufacture'
  },
  date_of_manufactureObj:{
    required:true
  },
  report_date:{
    0:"",
    1:'Please enter Report Date'
  },
  report_dateObj:{
    required:true
  },
  mtow:{
    0:"",
    1:'Please enter MTOW'
  },
  mtowObj:{
    required:true
  },
  utilization_type:{
    0:"",
    1:'Please select Utilization Type'
  },
  utilization_typeObj:{
    required:true
  },
  planned_fh:{
    0:"",
    1:'Please enter Planned FH'
  },
  planned_fhObj:{
    required:true
  },
  planned_fc:{
    0:"",
    1:'Please enter Planned FC'
  },
  planned_fcObj:{
    required:true
  },
  tsn:{
    0:"",
    1:'Please enter TSN'
  },
  tsnObj:{
    required:true
  },
  csn:{
    0:"",
    1:'Please enter CSN'
  },
  csnObj:{
    required:true
  },
  utilization_as_of_date:{
    0:"",
    1:'Please enter Utilization Date'
  },
  utilization_as_of_dateObj:{
    required:true
  },
}
export const apuErrorCode = {
  apuType:{
    0:"",
    1:'Please select APU Type'
  },
  apuTypeObj:{
    required:true
  },
  hours:{
    0:"",
    1:'Please select APU Hours'
  },
  hoursObj:{
    required:true
  },
  utilization_as_of_date:{
    0:"",
    1:'Please enter Utilization Date'
  },
  utilization_as_of_dateObj:{
    required:true
  },
}
