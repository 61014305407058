import React from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
export default function ForgotPwdForm({error, authInfo, handleChange, onForgot, formSubmitLoader}){
  return(
    <form onSubmit={(e) => {e.preventDefault(); onForgot()}}>
      <TextField
        id="email"
        label="Registered Email Address"
        fullWidth
        margin="normal"
        value={authInfo.email}
        error={error.email ? true:false }
        helperText={error.email}
        InputLabelProps={{shrink: true}}
        onChange={(e) => handleChange(e.target.value, 'email')}
      />
      <div className="text-center" style={{marginTop: '20px'}}>
        <Button style={{width:'120px'}} variant="contained" type="submit" disabled={formSubmitLoader} color="primary">{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Continue' }</Button>
      </div>
    </form>
  )
}
