import React from 'react';
import Container from '@material-ui/core/Container';
import { Button, Grid } from '@material-ui/core';
import AuthImageSlider from './AuthImageSlider';
import SpartaLogo from '../../../assets/images/sparta_logo.png';
import config from '../../../config';
export default function BackBanner({children}) {
  return (
    <section className="auth-section">
      {/* <AuthHeader /> */}
      <Container maxWidth="lg">
        <div className="auth-main-wrapper">
          <Grid container spacing={0}>
            <Grid item md={6}>
              <div className="auth-left-blk">
                <img className="sparta-logo" src={SpartaLogo} alt="Sparta Logo" />
                {
                !window.location.href.includes('select-lessor') ?
                <div className="auth-cta">
                  <ul className="list-inline">     
                    <li className='list-inline-item' >
                      <Button className="list-inline-item-solutions" variant="outlined" size="small"><a href={`${config.domain.subDomian}solutions`} target="_blank" >Solutions</a></Button>
                    </li>
                  </ul>
                </div>
                :null}
                {children}
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="auth-right-blk">
                <AuthImageSlider/>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  )
}
