import React, {Component} from 'react';
import {Tab, Tabs, Button} from '@material-ui/core';
class LLPTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { llpTabIndex, changeTabs, SecondaryLLPList, staticLlpList } = this.props;
        return(
            <div className="llp-tabs">
                <Tabs
                    value={llpTabIndex}
                    onChange={(event, newValue) => changeTabs(event, newValue, event.currentTarget.innerText)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label='Primary LLP'/>
                    <Tab label='Secondary LLP'/>
                    <Tab label='Static LLP'/>
                </Tabs>
            </div>
        )
    }
}
export default LLPTabs;
