import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withSnackbar } from 'notistack';
import { Typography, Button } from '@material-ui/core';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import { TableListComp, EmptyCollection, FilterUIComp, PageLoader, DeletePopUp } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Portfolio, AddEditPortfolio } from '../components';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { getPortfoliosApi, exportPortfoliosApi, lockUnlockPortfolioApi } from '../apiServices';
import { checkApiStatus, removeEmptyKey, convertFilterObject, permissionCheckFn } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { portfolioHd, portfolioFilterOps } from '../'
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class Portfolios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      deleteModal: false,
      deleteIds: null,
      sort: 'id',
      sort_by: 'desc',
      portfolios: {
        list: [],
        pagination: {}
      },
      filter: {},
      applyingFilter: {},
    };
    this.getPortfoliosApi = getPortfoliosApi.bind(this);
    this.exportPortfoliosApi = exportPortfoliosApi.bind(this);
    this.lockUnlockPortfolioApi = lockUnlockPortfolioApi.bind(this);
  }
  componentDidMount() {
    this.getPortfoliosApi(this.props, { sort: 'id', sort_by: 'desc' }, 'skeletonLoader');
    trackActivity('Portfolio', {
      event_type: 'Page Visited',
      page_title: 'Portfolio Listing'
    })
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if (rangeKey) {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]: value
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }

  removeFilter = (keyParam) => {
    const { filter, applyingFilter, portfolios } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    delete applyingFilter[keyParam];
    this.getPortfoliosApi(this.props, { ...query, per_page: portfolios.pagination.per_page }, 'pageLoader');
    this.setState({ applyingFilter })
  }

  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, portfolios } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getPortfoliosApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', per_page: portfolios.pagination.per_page }, 'pageLoader');
      } else {
        this.getPortfoliosApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page: portfolios.pagination.per_page }, 'pageLoader');
      }
    } else {
      this.getPortfoliosApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page: portfolios.pagination.per_page }, 'pageLoader');
    }
  }

  downloadAttachments = (files, zipFilename) => {
    const zip = new JSZip();
    let count = 0;
    let urls = files.map(file => file.url);
    urls.forEach(async function (url) {
      const urlArr = url.split('/');
      // const filename = `file_${count}.xls`;
      const fName = decodeURIComponent(url).split('?')[0].split('/');
      const filename = fName[fName.length - 1];
      try {
        const file = await JSZipUtils.getBinaryContent(url)
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  render() {
    const { skeletonLoader, pageLoader, deleteModal, deleteIds, portfolios, sort, sort_by, filter, applyingFilter } = this.state;

    return (
      <section className="portfolio-section">
        <DeploymentMessage />
        <div className="flex-centered" style={{ marginBottom: '15px' }}>
          <div className="page-description">
            <Typography variant="h2" className="page-title">Portfolio</Typography>
            {!isMobile ? <p className="page-para">Offers a list view of all Portfolio created for Valuation in SPARTA.</p> : null}
          </div>
          {permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'C' }) ?
            <span style={{ marginLeft: 'auto' }}>
              <AddEditPortfolio getResponseBack={() => this.getPortfoliosApi(this.props, {}, 'pageLoader')} mode='A' />
            </span> : null
          }
          {permissionCheckFn({ secondaryKey: 'portfolio', keyIndex: 'EXP_XLS' }) ?
            <span style={{ marginLeft: '10px' }}>
              <Button onClick={() => this.exportPortfoliosApi(this.props, { ...filter, page: parseInt(portfolios.pagination.page), per_page: portfolios.pagination.per_page })} color="primary" variant="outlined" size="small" ><SaveAltIcon />Export</Button>
            </span> : null
          }
        </div>
        {skeletonLoader ? <STableLoader count={8} /> :
          <div style={{ marginBottom: '30px' }}>
            <FilterUIComp
              filter={filter}
              applyingFilter={applyingFilter}
              removeFilter={this.removeFilter}
              removeAllFilter={() => { this.setState({ applyingFilter: {} }); this.getPortfoliosApi(this.props, removeEmptyKey({ per_page: portfolios.pagination.per_page, sort: sort, sort_by: sort_by }), 'pageLoader') }}
              submitFilter={() => this.getPortfoliosApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), portfolioFilterOps) }, 'pageLoader')}
              applyFilter={this.applyFilter}
              filterMenu={portfolioFilterOps}
            />
            <TableListComp
              sort={sort}
              sort_by={sort_by}
              heads={portfolioHd}
              data={portfolios.list.map((item, index) =>
                <Portfolio
                  key={index}
                  item={item}
                  changeLockStatusFn={(data) => this.lockUnlockPortfolioApi(this.props, data)}
                  downloadAttachments={() => this.downloadAttachments(item.files, item.name)}
                />
              )}
              noRecord={portfolios.list.length ? null :
                <EmptyCollection
                  title="No records found"
                />
              }
              pagination={portfolios.pagination}
              onChangePage={(event, newPage) => this.getPortfoliosApi(this.props, removeEmptyKey({ ...filter, sort: sort, sort_by: sort_by, page: newPage + 1, per_page: portfolios.pagination.per_page }), 'pageLoader')}
              onChangeRowsPerPage={(event) => this.getPortfoliosApi(this.props, removeEmptyKey({ ...filter, sort: sort, sort_by: sort_by, page: 1, per_page: event.target.value }), 'pageLoader')}
              createSortHandler={this.createSortHandler}
            />
          </div>
        }
        {deleteModal &&
          <DeletePopUp
            modal={deleteModal}
            toggleModalFn={() => this.setState({ deleteModal: false, deleteIds: null })}
            title={`Portfolio ${deleteIds && deleteIds.is_locked ? 'Unlock' : 'Lock'}`}
            content={<h4>Are you sure you want to {deleteIds && deleteIds.is_locked ? 'Unlock' : 'Lock'}?</h4>}
            deleteRecordFn={() => this.lockUnlockPortfolioApi(this.props, deleteIds)}
            confirmText='YES'
          />
        }
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
export default withSnackbar(Portfolios);
