import React , { Fragment} from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import UserPermission from './UserPermission';
const UserPermissionRow= ({permissionDetail, component}) => {
  return(
    <Fragment>
      <TableRow hover tabIndex={-1}>
        <TableCell>
          {component}
        </TableCell>
        <TableCell>
          <UserPermission permissionDetail={permissionDetail} permissionValue="create" />
        </TableCell>
        <TableCell>
          <UserPermission permissionDetail={permissionDetail} permissionValue='view' />
        </TableCell>
        <TableCell>
          <UserPermission permissionDetail={permissionDetail} permissionValue='update' />
        </TableCell>
        <TableCell>
          <UserPermission permissionDetail={permissionDetail} permissionValue='export_pdf' />
        </TableCell>
        <TableCell>
          <UserPermission permissionDetail={permissionDetail} permissionValue='export_xls' />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default UserPermissionRow
