import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const errorCode = {
    company_name:{
        0:'',
        1: 'Please enter Company Name',
        4: errorMsg1
      },
      company_nameObj:{
        required: true,
        regexPattern: regexConstants.alphanumericWithHyphenSpace
      },
      company_domain:{
        0:'',
        1: 'Please enter Website',
        4: errorMsg1
      },
      company_domainObj: {
        required: true,
      },
      email:{
        0:'',
        1: 'Please enter Email',
        4: 'Please enter Email in correct format'
      },
      emailObj: {
        required: true,
        regexPattern: regexConstants.email
      },
      full_name:{
        0:'',
        1: 'Please enter Full Name',
        4: errorMsg1
      },
      full_nameObj: {
        required: true,
        regexPattern: regexConstants.alphanumericWithHyphenSpace
      },
      password:{
        0:'',
        1: 'Please enter Password',
        4: 'Password does not meet the policy requirements'
      },
      passwordObj: {
        required: true,
        regexPattern: regexConstants.password
      },
      confirm_password:{
        0:'',
        1: 'Please enter Confirm Password',
      },
      confirm_passwordObj: {
        required: true,
        regexPattern: regexConstants.password 
      },
      contact_no:{
        0:'',
        4: 'Please enter valid Mobile Number'
      },
      contact_noObj: {
        regexPattern: regexConstants.phoneNumber
      },
      
}
