import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey, permissionCheckFn } from '../../utils';
import moment from 'moment';
import { errorCode, airframeErrorCode, apuErrorCode } from '.'
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';
export function getDefaultUtilsApi(props, query){
  globalGetService(`valuation/annual-utilization/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      let payout = response.data.data;
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          airframe: {
            ...prevState.halfLifeCrud.airframe,
            planned_fh:payout.annual_fh_cost?payout.annual_fh_cost:'',
            planned_fc:payout.annual_fc_cost?payout.annual_fc_cost:''
          }
        }
      }))
    }
  })
}

export function createHlaLogApi(props, data){
  let lgError = [], lgErrorFlag=true, propellerError = [], propellerErrorFlag=true, engineError = [], engineErrorFlag=true;
  let validationInputs = {
    aircraftType: airframeErrorCode['aircraftType'][fieldValidation({ ...airframeErrorCode['aircraftTypeObj'], fieldval: data.airframe.aircraftType })],
    maintenance_program: airframeErrorCode['maintenance_program'][fieldValidation({ ...airframeErrorCode['maintenance_programObj'], fieldval: data.airframe.maintenance_program })],
    date_of_manufacture: airframeErrorCode['date_of_manufacture'][fieldValidation({ ...airframeErrorCode['date_of_manufactureObj'], fieldval: data.generic.date_of_manufacture })],
    report_date: airframeErrorCode['report_date'][fieldValidation({ ...airframeErrorCode['report_dateObj'], fieldval: data.generic.report_date })],
    mtow: airframeErrorCode['mtow'][fieldValidation({ ...airframeErrorCode['mtowObj'], fieldval: data.generic.mtow })],
    utilization_type: airframeErrorCode['utilization_type'][fieldValidation({ ...airframeErrorCode['utilization_typeObj'], fieldval: data.airframe.utilization_type })],
    utilization_as_of_date: airframeErrorCode['utilization_as_of_date'][fieldValidation({ ...airframeErrorCode['utilization_as_of_dateObj'], fieldval: data.airframe.utilization_as_of_date })],
    tsn:data.airframe.tsn === '' ? 'Please enter TSN':'',
    csn:data.airframe.csn === '' ? 'Please enter CSN':'',
    engineType:data.airframe.aircraftType ? airframeErrorCode['engineType'][fieldValidation({ ...airframeErrorCode['engineTypeObj'], fieldval: data.engine.engineType })]  :''
  }
  if(data.airframe.utilization_type && data.airframe.utilization_type === 2){
    validationInputs = {
      ...validationInputs,
      planned_fh:data.airframe.planned_fh.trim().length ? '':'Please enter Planned FH',
      planned_fc:data.airframe.planned_fc.trim().length ? '':'Please enter Planned FC',
    }
  }

  if(data.airframe.aircraftType){
    // Check for Engine Validations
    engineError = data.engine.engineInfo.slice(0,data.engine.identical_data ? 1:data.engine.engineInfo.length).map(item => {
    return {
      tsn: item.tsn === '' ? 'Please enter TSN':'',
      csn: item.csn === '' ? 'Please enter CSN':'',
      as_of_date: item.as_of_date ? '':'Please enter Utilization Date',
    }});
    engineError.map(data => {
      if(!Object.keys(data).every((k) => { return data[k] === ''})){
        engineErrorFlag = false
      }
    });
    if(!engineErrorFlag){
      this.setState({engineError:engineError})
    }

    // Check for Landing Gear Validations
    lgError = data.landing_gear.lgInfo.slice(0,data.landing_gear.identical_data ? 1:data.landing_gear.lgInfo.length).map(item => {
    return {
      csn: item.csn === '' ? 'Please enter CSN':'',
      utilization_as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
    }});
    lgError.map(data => {
      if(!Object.keys(data).every((k) => { return data[k] === ''})){
        lgErrorFlag = false
      }
    });
    if(!lgErrorFlag){
      this.setState({lgError:lgError})
    }

    // Check for APU Validations
    if(data.airframe.aircraftType.has_apu && data.apuOptional){
      let apuValidations = {
        apuType: apuErrorCode['apuType'][fieldValidation({ ...apuErrorCode['apuTypeObj'], fieldval: data.apu.apuType })],
        hours: apuErrorCode['hours'][fieldValidation({ ...apuErrorCode['hoursObj'], fieldval: data.apu.hours })],
        utilization_as_of_date: apuErrorCode['utilization_as_of_date'][fieldValidation({ ...apuErrorCode['utilization_as_of_dateObj'], fieldval: data.apu.utilization_as_of_date })],
      }
      if(Object.keys(apuValidations).every((k) => { return validationInputs[k] === '' })) {

      }else{
        this.setState({apuError:apuValidations})
      }
    }
    // Check for Propellar Validations
    if(data.airframe.aircraftType.has_propeller){
      propellerError = data.propeller.propellerInfo.slice(0,data.propeller.identical_data ? 1:data.propeller.propellerInfo.length).map(item => {
      return {
        tsn: item.tsn === '' ? 'Please enter TSN':'',
        utilization_as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
      }});
      propellerError.map(data => {
        if(!Object.keys(data).every((k) => { return data[k] === ''})){
          propellerErrorFlag = false
        }
      });
      if(!propellerErrorFlag){
        this.setState({propellerError:propellerError})
      }
    }
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {

  }else{
    this.setState({airframeError:validationInputs})
  }
}

export function updateHlaEventsApi(props, data){
  let apuError={}, airframeError=[], lgError=[], propellerError=[], engineError=[];
  let ovPermission = permissionCheckFn({secondaryKey:'hthl', keyIndex:'OV'});
  // Check for Airframe Validations
  if(ovPermission){
    airframeError = data.airframe.maintenance_events.map(item => {
    return {
      cost: item.cost ? '':'Please enter Cost',
      remarks: item.remarks ?'':'Please enter Remarks',
    }});
  }
  // Check for APU Validations
  if(data.airframe.aircraftType && data.airframe.has_apu){
    if(data.apu.hours_at_lsv || data.apu.cycles_at_lsv || data.apu.lsv_date){
      apuError = {
        ...apuError,
        hours_at_lsv:data.apu.hours_at_lsv?'':'Please enter Hours',
      }
    }
    if(ovPermission){
      apuError = {
        ...apuError,
        interval_fh:data.apu.interval_fh?'':'Please enter Interval',
        cost:data.apu.cost?'':'Please enter Cost',
        remarks:data.apu.remarks?'':'Please enter Remarks',
      }
    }
  }

  // Check for Landing Gear Validations
  lgError = data.landing_gear.map(item => {
    return {
      interval_fc: ovPermission?item.interval_fc ? '':'Please enter FC':'',
      interval_mo: ovPermission? item.interval_mo ? '':'Please enter MO':'',
      cost: ovPermission? item.cost ? '':'Please enter Cost':'',
      remarks: ovPermission ?item.remarks ?'':'Please enter Remarks':'',
      csn_at_lsv:(item.csn_at_lsv || item.lsv_date) ?item.csn_at_lsv === '' ? 'Please enter CSN @ LSV':'':'',
      lsv_date: (item.csn_at_lsv || item.lsv_date) ? item.lsv_date ? '':'Plese enter LSV Date':''
    }
  });

  propellerError = data.propeller.map(item => {
    return {
      interval_fh: ovPermission?item.interval_fc ? '':'Please enter FC':'',
      interval_mo: ovPermission? item.interval_mo ? '':'Please enter MO':'',
      cost: ovPermission? item.cost ? '':'Please enter Cost':'',
      remarks: ovPermission ?item.remarks ?'':'Please enter Remarks':'',
      tsn_at_lsv:(item.tsn_at_lsv || item.lsv_date) ?item.tsn_at_lsv === '' ? 'Please enter CSN @ LSV':'':'',
      lsv_date: (item.tsn_at_lsv || item.lsv_date) ? item.lsv_date ? '':'Plese enter LSV Date':''
    }
  });
}

export function getValuationHlaApi(props) {
  globalGetService(`valuation/half-life-adjustment/logs/${props.match.params.id}/`)
  .then(response => {
    if (checkApiStatus(response)) {
      this.setState({halfLifeCrud:response.data.data})
    }
  })
}
export function getValuationHlaResultApi(props) {
  return new Promise(function(resolve,reject){
    globalGetService(`valuation/half-life-adjustment/result/${props.match.params.id}/`)
    .then(response => {
      resolve(response)
    })
  });
}
export function lockUnlockHlaApi(props, data){
  this.setState({pageLoader:true});
  globalPutService(`valuation/update-lock/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      window.location.reload(false);
    }else{
      this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  })
}
export function exportHlaReportApi(props, query = {}) {
  this.setState({ pageLoader: true })
  globalExportService(`valuation/half-life-adjustment/result/${props.match.params.id}/`, { download: query.extension })
    .then(response => {
      trackActivity('Exported Result', {
        type: "Half Life Adjustment",
        log_id: props.match.params.id,
        extension: query.extension
      })
      this.setState({ pageLoader: false });
      downloadFileType(response.data, (`LogId_${this.props.match.params.id}_Maintenance_Adjusted_Base_Value_${moment().format(displayDateFormatShort)}`), query.extension);
    })
}
