import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';
import { AuthHeader, Footer } from '../components';

export default class About extends Component{
constructor(props){
    super(props);
    }
      render(){
         return(
            <section className="termsHd">
                <div className="auth-section">
                    <AuthHeader />
                    <Container maxWidth="lg">
                        <Grid container spacing={0} alignItems="center">
                            <Grid item md={6}>
                                <div className="auth-help-text">
                                <h1>About SPARTA</h1>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="termsAndCondition">
                    <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={12}>
                            <p>
                                <strong>SPARTA</strong> is a Digital Asset Manager which has been developed indigenously by coupling Acumen’s depth of knowledge in Aviation Asset Management sector and an innate inquisitive and problem-solving approach. The product has been developed using the latest technologies to enhance scope, scale and adoption of the product. The platform also uses Machine Learning and Artificial Intelligence technologies to augment the features and functions offered in the platform.
                            </p>
                            <h2>About SPARTA Asset Values </h2>
                                <p>
                                    <strong>SPARTA Asset Values</strong>
                                    &nbsp;is a proprietary product within the SPARTA Platform. It derives its key functions from the knowledge, experience and expertise of Acumen’s Valuation Team comprised of ISTAT and ASA Certified Appraisers. The product aims at delivering a superior valuations and appraisal experience to users through its new-age UX design and robust database on which the product computes the values.
                                </p>

                                <h2>About Acumen</h2>
                                <p>Acumen is an established player in the Aviation industry with its global presence in 4 countries and more than 11 years of company existence. Acumen offers end to end Asset Management solutions to its clients and operates across the length and breadth of the industry. Acumen’s three key business avenues are Services, Digital and Training. Know more on
                                <strong> <a href="https://www.acumen.aero/" target="_blank" style={{textDecoration:'none',color:'#2f8cfe' }}>https://acumen.aero</a></strong>. </p>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Footer />
            </section>
         )
    }
}
