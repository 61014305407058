import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import Fleet from './pages/Fleet'
export const fleetRoutes = [
  {
    path:'/fleets',
    component:pageLayoutHoc(userRoleBaseAccessHoc(Fleet,[]), {apps: 'Valuation', layoutPhase: 1}),
    key:'Fleet'
  },
]
