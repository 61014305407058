import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Tooltip } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import { globalPostService, globalExportService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';
const ImportFleet = ({getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [modal,setModal] = useState(false);
    const onFieldChange = (key, files) => {
        setFiles(files);
    }
    const onImportAssets = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('files', files[0]);
        globalPostService(`valuation/fleetData/import/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                getResponseBack();
                setModal(false);setFiles([])
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    const exportSampleTemplate = () => {
        globalExportService(`valuation/fleetData/sample_download/`)
        .then(response => {
            downloadFileType(response.data, 'Sample Fleet Template', 'xls');
            enqueueSnackbar('Sample Fleet Template exported successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
        })
    }
    return(
        <>
            <Button onClick={() => {setModal(true);setFiles([])}} color='primary' size='small' variant='contained'>Import Fleet(s)</Button>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false);setFiles([])}}
                    maxWidth='md'
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Assets
                    </DialogTitle>
                    <DialogContent dividers={true} className="add-portfolio-dialog">
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <p>Refer the sample sheet
                                 <a onClick={exportSampleTemplate} style={{textDecoration:'underline', cursor:'pointer', color:'#3f51b5'}}>Download</a>
                                </p>
                                <div style={{width:'320px'}}>
                                    <DropzoneArea
                                        acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                                        filesLimit={1}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        useChipsForPreview={true}
                                        dropzoneText={<p>Drop files or add attachments here</p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['error', 'info']}
                                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                        onDrop={(files) => {onFieldChange('file', files)}}
                                        onDelete={(files) => onFieldChange('file', files)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false);setFiles([])}} color='primary' size='small' variant='outlined'>Cancel</Button>
                        {files.length ?
                            <Button disabled={isLoading} onClick={onImportAssets} color='primary' size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={24} />:'Save'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null
            }
        </>
        
    )
}
export default ImportFleet;