import React, { Fragment } from 'react';
import { Tabs, Tab } from '@material-ui/core';
const EventsTab = ({tabIndex, tabs, handleTabChange}) => {
  return(
    <Tabs
      value={tabIndex}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleTabChange}
    >
      {tabs.map((item, index) =>
        <Tab label={item} />
      )}
    </Tabs>
  )
}
export default EventsTab;
